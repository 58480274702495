const CardData20 = [
  {
    title: 'Pedro Infante',
    description:
      'Pedro Infante Cruz (Mazatlán, Sinaloa, 18 de noviembre de 1917-Mérida, Yucatán, 15 de abril de 1957) fue un actor y cantante mexicano. Es considerado como uno de los actores más recordados del cine en México.Por su actuación en la película Tizoc (1956), fue ganador de un Oso de Plata​ del Festival Internacional de Cine de Berlín en la categoría a «mejor actor principal», y también obtuvo un premio Globo de Oro​ por mejor película extranjera, el cual se le fue otorgado por la prensa extranjera acreditada en Hollywood..',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVODBGJurCWQdYWZ7VH_Nhd',
    price: '$50',
    coverImg: 'pedroinfante.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.biografiasyvidas.com/biografia/i/infante_pedro.htm#:~:text=Pedro%20Infante%20%28Mazatl%C3%A1n%2C%20Sinaloa%2C%201917%20-%20M%C3%A9rida%2C%20Yucat%C3%A1n%2C,musical%20que%20actuaba%20en%20la%20localidad%20de%20Guasave.',
    youtube: 'https://www.youtube.com/channel/UCCZRmXAChQJF1P6xsX28TTQ',
    facebook: 'https://www.facebook.com/profile.php?id=100052259945722',
    instagram: 'https://www.instagram.com/pedro_infante_official/',
    twitter: 'https://twitter.com/Pedro_Infante1',
    spotify: 'https://open.spotify.com/artist/7y33enVLfDvft6HGNmcxdV',
    deezer: 'https://www.deezer.com/mx/artist/264106',
    apple: 'https://music.apple.com/mx/artist/pedro-infante/2137889',
    soundcloud: 'https://soundcloud.com/pedro-infante-official',
    tidal: 'https://listen.tidal.com/artist/13256',
    wiki: 'https://es.wikipedia.org/wiki/Pedro_Infante',
    ticketmaster:
      'https://www.noroeste.com.mx/entretenimiento/espectaculos/llenaran-de-tributos-a-pedro-infante-PVNO1078632',
    napster: 'https://music.amazon.com.mx/artists/B000QKD2XQ/pedro-infante',
    tiktok: 'https://www.tiktok.com/@pedroinfanteidolo',
  },
  {
    title: 'Jorge Negrete',
    description:
      'Jorge Alberto Negrete Moreno (Guanajuato, 30 de noviembre de 1911-Los Ángeles, California, 5 de diciembre de 1953), conocido como El Charro Cantor, fue un actor y cantante mexicano. Fundó el Sindicato de Trabajadores de la Producción Cinematográfica de la República Mexicana1​ y reorganizó, junto con un grupo selecto de actores, la Asociación Nacional de Actores.',

    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVngSBUUO1Yyb7E9KNPUvjl',
    price: '$50',
    coverImg: 'jorgenegrete2.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '.',
    youtube: 'https://www.youtube.com/c/jorgenegretefans',
    facebook: 'https://www.facebook.com/profile.php?id=100059758101482',
    instagram: 'https://www.instagram.com/jorgenegreteforever/',
    twitter: 'https://twitter.com/DonJorgeNegrete',
    spotify: 'https://open.spotify.com/artist/4W5RbLZybsLfAzE7QqdibE',
    deezer: 'https://www.deezer.com/mx/artist/299515',
    soundcloud: 'https://soundcloud.com/jorgenegreteofficial',
    tidal: 'https://listen.tidal.com/artist/3661015',
    wiki: 'https://es.wikipedia.org/wiki/Jorge_Negrete',
    ticketmaster:
      'https://www.ticketmaster.com.mx/teatro-jorge-negrete-boletos-mexico/venue/163990',
    napster: 'https://music.amazon.com.mx/artists/B000QK2D7C/jorge-negrete',
    tiktok: 'https://www.tiktok.com/search?q=Jorge%20Negrete&t=1667333198086',
  },
  {
    title: 'Rocio Durcal',
    description:
      'María de los Ángeles de las Heras Ortiz, conocida por su nombre artístico Rocío Dúrcal, fue una actriz y cantante española. Dúrcal es considerada como la «reina de las rancheras» y es una de las mujeres de habla hispana con más ventas en la industria. En 2005, un año antes de su muerte, recibió el Grammy Latino a la excelencia musical.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lMv8ZIewIbDR8z4ckj4pbzhXV8GtrWh58',
    price: '$50',
    coverImg: 'rociodurcal.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    youtube: 'https://www.youtube.com/channel/UCBgAZZ95M0pSBo69Qb6EuTQ',
    facebook: 'https://www.facebook.com/RocioDurcalMusic',
    instagram:
      'https://www.instagram.com/rociodurcal.music/?fbclid=IwAR1j7EblxeIYpgKWQfpesMeVe2l-6TfNhzfv7Hterp7PVJPLcWjCSPR6n_I',
    twitter:
      'https://twitter.com/rocio_durcal?fbclid=IwAR0QUd4K5u9dWS3S72dobhOD_ktY5dPbr-386AiKlv411DTQPTAhsnOY-vA',
    spotify: 'https://open.spotify.com/artist/2uyweLa0mvPZH6eRzDddeB',
    deezer: 'https://www.deezer.com/mx/artist/75284',
    apple: 'https://music.apple.com/mx/artist/roc%C3%ADo-d%C3%BArcal/6742584',
    soundcloud: 'https://soundcloud.com/rocio-durcal-official',
    tidal: 'https://listen.tidal.com/artist/3660925',
    wiki: 'https://en.wikipedia.org/wiki/Roc%C3%ADo_D%C3%BArcal',
    ticketmaster:
      'https://www.ticketmaster.com/rocio-durcal-tickets/artist/754908',
    napster: 'https://music.amazon.com.mx/artists/B00386NT7O/roc%C3%ADo-d%C3%BArcal',
    tiktok: 'https://www.tiktok.com/@rociodurcal.oficial',
  },
  {
    title: 'Jose Alfredo Jimenez',
    description:
      'José Alfredo Jiménez Sandoval (Dolores Hidalgo, Guanajuato, 19 de enero de 1926-Ciudad de México, 23 de noviembre de 1973) fue un cantante y compositor mexicano. Jiménez fue el autor de varios temas musicales del género regional mexicano, específicamente de rancheras, sones, corridos, y huapangos a ritmo de mariachi.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_k2kN1d58ypIxZcl6DEtIT_2tsuaZpeWJM',
    price: '$50',
    coverImg: 'JoseAlfredo.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    youtube: 'https://www.youtube.com/channel/UC3w7TN1QkWk9ecC0q_ebm7Q',
    facebook: 'https://www.facebook.com/JoseAlfredoJimenezElRey/',
    instagram: 'https://www.instagram.com/josealfredojimenezoficial/',
    twitter: 'https://twitter.com/JoseAlfredoJmz',
    spotify: 'https://open.spotify.com/artist/2T06whb4s6UiufL1j5Qtz9',
    deezer: 'https://www.deezer.com/mx/artist/343074',
    apple:
      'https://music.apple.com/mx/artist/jos%C3%A9-alfredo-jim%C3%A9nez/332254',
    soundcloud: 'https://soundcloud.com/josealfredojimenez',
    tidal: 'https://listen.tidal.com/artist/4029320',
    wiki: 'https://en.wikipedia.org/wiki/Jos%C3%A9_Alfredo_Jim%C3%A9nez',
    ticketmaster:
      'https://www.ticketmaster.com/jose-alfredo-jimenez-medel-tickets/artist/2036602',

    tiktok:
      'https://www.tiktok.com/search?q=Jos%C3%A9%20Alfredo%20Jim%C3%A9nez&t=1658091294171',
    napster: 'https://music.amazon.com.mx/artists/B000RHNATE/jos%C3%A9-alfredo-jim%C3%A9nez',
  },
  {
    title: 'Juan Gabriel',
    description:
      'Alberto Aguilera Valadez, conocido como Juan Gabriel, fue un cantante, compositor, arreglista, productor musical, músico, filántropo y actor mexicano. Son notables sus contribuciones a la música popular en español en diferentes géneros como balada, ranchera, bolero, pop, música norteña, rumba flamenca, huapango, música chicana, salsa, cumbia, tango, polka, samba, vals, son de mariachi, joropo, banda sinaloense, disco, jazz, new age, country, rock and roll, big band y hasta canciones de cuna que escribió a cada uno de sus hijos.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_ldwlb88-oemhNHFY2wJDvWnwu56vjdy2o',
    price: '$50',
    coverImg: 'JuanGabriel.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://juangabriel.com/',
    youtube: 'https://www.youtube.com/channel/UCnLJnKKCxLRsAktAB9iMSeQ',
    facebook: 'https://www.facebook.com/JuanGabrielPorLosSiglos',
    instagram: 'https://www.instagram.com/soyjuangabriel_/?hl=en',
    twitter: 'https://twitter.com/soyjuangabriel',
    spotify: 'https://open.spotify.com/artist/2MRBDr0crHWE5JwPceFncq',
    deezer: 'https://www.deezer.com/mx/artist/71813',
    apple: 'https://music.apple.com/mx/artist/juan-gabriel/295294',
    soundcloud: 'https://soundcloud.com/juan-gabriel-official',
    tidal: 'https://listen.tidal.com/artist/4347',
    wiki: 'https://en.wikipedia.org/wiki/Juan_Gabriel',
    ticketmaster:
      'https://www.ticketmaster.com/juan-gabriel-tickets/artist/761622',

    tiktok: 'https://www.tiktok.com/@soyjuangabriel',
    napster: 'https://music.amazon.com.mx/artists/B000QJJM6I/juan-gabriel',
  },
  {
    "title": "Alberto Cortez",
    "description": "José Alberto García Gallo (Rancul, La Pampa, 11 de marzo de 1940-Móstoles, Madrid, 4 de abril de 2019), más conocido como Alberto Cortez, fue un cantautor y poeta argentino nacionalizado español.Por sus orígenes la obra de Alberto Cortez muestra una influencia del tango, del folclore argentino y del cancionero popular de Hispanoamérica. Muchos poetas y escritores han dejado huella en su obra como Antonio Machado; Pedro Bonifacio Palacios, Almafuerte; Miguel Hernández, o Pablo Neruda. Más adelante su gran referente musical fue Jacques Brel. Multitud de galardones han reconocido su extensa obra, con más de cuarenta discos publicados y premios tan importantes como el Grammy Latino a la Excelencia del año 2007 o la Medalla de Oro al Mérito en las Bellas Artes en España en 2015.",
    "videourl": "https://youtube.com/playlist?list=OLAK5uy_kZSitCqUdNF18gswszqNz3RRrK1yq864s&si=OkTxlPPoAkN-wE40",
    "price": "$50",
    "coverImg": "albertocortez.jpg",
    "category": "Balada",
    "location": "Argentina",
    "locationImg": "argentinaflag.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "",
    "youtube": "https://www.youtube.com/channel/UCA4myuKLfVatut6pSlYjOIQ",
    "facebook": "https://www.facebook.com/p/Alberto-Cortez-in-memoriam-100068053182739/",
    "instagram": "https://www.instagram.com/reels/audio/228828487711947/gracias-a-la-vida/",
    "twitter": "https://x.com/CortezMemoriam",
    "spotify": "https://open.spotify.com/artist/0Iww53p8UY2oYtQhfzsSY9",
    "deezer": "https://www.deezer.com/mx/artist/242276",
    "apple": "https://soundcloud.com/albertocortez-music",
    "soundcloud": "https://soundcloud.com/albertocortez-music",
    "tidal": "https://soundcloud.com/albertocortez-music",
    "wiki": "https://es.wikipedia.org/wiki/Alberto_Cortez",
    "ticketmaster": "https://www.ticketmaster.com/alberto-cortez-tickets/artist/754871",
    "tiktok": "https://www.tiktok.com/search/video?lang=es&q=Alberto%20Cortez&t=1726354218297",
    "napster": "https://music.amazon.com.mx/artists/B000QKFNHE/alberto-cortez"
},


  {
    title: 'Los Panchos',
    description:
      'Los Panchos fueron un trío musical romántico internacional formado por los mexicanos Chucho Navarro y Alfredo Gil y el puertorriqueño Hernando Avilés en la década de los 40 en la Ciudad de México. Se les considera como uno de los mejores tríos musicales y uno de los artistas latinoamericanos más influyentes de todos los tiempos,siendo populares en todo el mundo. Han celebrado conciertos durante más de 70 años y han aparecido en más de 50 películas. El trío se convirtió en uno de los máximos exponentes del bolero y la balada romántica en Latinoamérica.',

    videourl: 'https://youtube.com/playlist?list=UUnEk7ymV4bZlk2MW-kir9dw',
    price: '$50',
    coverImg: 'LosPanchos.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Los_Panchos',
    youtube: 'https://www.youtube.com/channel/UCnEk7ymV4bZlk2MW-kir9dw',
    facebook: 'https://www.facebook.com/lospanchosoficial',
    instagram:
      'https://www.instagram.com/triolospanchosoficial/?fbclid=IwAR0ZUMwr8iIqJ3cb0qIS3qScxN57NVkXOMhHZFdvKcu64f5boNfeRD5EhsU',
    twitter: 'https://twitter.com/triolospanchos',
    spotify: 'https://open.spotify.com/artist/3Ker27Wbb9OcUHGs54JIAz',
    deezer: 'https://www.deezer.com/mx/artist/16396',
    apple: 'https://music.apple.com/mx/artist/los-panchos/26172312',
    soundcloud: 'https://soundcloud.com/triolospanchos',
    tidal: 'https://listen.tidal.com/artist/4347',
    wiki: 'https://en.wikipedia.org/wiki/Los_Panchos',
    ticketmaster:
      'https://www.ticketmaster.com/los-panchos-tickets/artist/2250936',

    tiktok: 'https://www.tiktok.com/@triolospanchosoficial',
    napster: 'https://music.amazon.com.mx/artists/B000QJSJBC/los-panchos',
  },
  {
    title: 'Vicente Fernandez',
    description:
      'Vicente Fernández Gómez, también conocido como El Charro de Huentitán o Chente, fue un cantante de ranchera, empresario, productor discográfico y actor mexicano. Su trabajo en la música le valió dos premios Grammy, ocho premios Grammy Latinos, catorce premios Lo Nuestro y una estrella en el paseo de la fama de Hollywood. En abril del 2010, alcanzó la cifra de 60 millones de copias vendidos en todo el mundo. Su hijo, Alejandro Fernández, también es cantante.',

    videourl: 'https://youtube.com/playlist?list=PL47228A7CBC410B5F',
    price: '$50',
    coverImg: 'VicenteFernandez.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.vicentefernandez.mx/',
    youtube: 'https://www.youtube.com/channel/UCwPTC2n6D8asyi1G5uBZlpw',
    facebook: 'https://www.facebook.com/VicenteFernandezOficial',
    instagram: 'https://www.instagram.com/_vicentefdez/',
    twitter: 'https://twitter.com/_VicenteFdez',
    spotify: 'https://open.spotify.com/artist/4PPoI9LuYeFX8V674Z1R6l',
    deezer: 'https://www.deezer.com/mx/artist/8798',
    apple: 'https://music.apple.com/mx/artist/vicente-fern%C3%A1ndez/6742830',
    soundcloud: 'https://soundcloud.com/vicentefernandez',
    tidal: 'https://listen.tidal.com/artist/4030361',
    wiki: 'https://en.wikipedia.org/wiki/Vicente_Fern%C3%A1ndez',
    ticketmaster:
      'https://www.ticketmaster.com.mx/vicente-fernandez-boletos/artist/754927',

    tiktok: 'https://www.tiktok.com/@_vicentefdez',
    napster: 'https://www.ticketmaster.com/vicente-fernandez-tickets/artist/754927',
  },
  
  
  {
    title: 'Antonio Aguilar',
    description:
      'Don Antonio Aguilar representa y encarna a la música mexicana. Es un símbolo y un referente cuando se habla de nuestras raíces culturales, ya sea con su amplio y exitoso repertorio musical o en sus inolvidables trabajos cinematográficos. Por algo orgullosamente se le conoce como El Charro de México.',

    videourl:
      'http://youtube.com/playlist?list=PLfjaVNeEP9fSdn5r0C6thkKVPs23g9rZ2',
    price: '$50',
    coverImg: 'AntonioAguilar.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://found.ee/mmq-antonioaguilar-digital-lat',
    youtube: 'https://music.youtube.com/channel/UCrudP827nGhBGLHCUDQzSPg',
    facebook: 'https://www.facebook.com/AntonioAguilarFans1/',
    instagram: 'https://www.instagram.com/el_charro_de.mexico/',
    twitter: '',
    spotify: 'https://open.spotify.com/artist/0PN0fbe41KbuzlRYnoajNm',
    deezer: 'https://www.deezer.com/mx/artist/96684',
    apple: 'https://music.apple.com/mx/artist/antonio-aguilar/6545576',
    soundcloud: 'https://soundcloud.com/antonioaguilarofficial',
    tidal: 'https://listen.tidal.com/artist/3665841',
    wiki: 'https://en.wikipedia.org/wiki/Antonio_Aguilar',
    ticketmaster:
      'https://www.ticketmaster.com/antonio-aguilar-tickets/artist/754762',

    tiktok: 'https://www.tiktok.com/@antonioaguilar.2022',
    napster: 'https://music.amazon.com.mx/artists/B000S2F8JI/antonio-aguilar',
  },
  {
    "title": "Jose-Jose",
    "description": "José Rómulo Sosa Ortiz (Clavería, Azcapotzalco, Ciudad de México, 17 de febrero de 1948-Homestead, Florida, 28 de septiembre de 2019), conocido como José José, fue un cantante y actor mexicano. Es considerado como un icono musical y popular del siglo XX,​ así como uno de los cantantes latinos más exitosos e importantes de la historia, logrando vender más de 100 millones de discos en todo el mundo.​ Su legado y trayectoria como cantante le valieron el llegar a ser apodado como el Príncipe de la Canción.Nacido en una familia de músicos, José comenzó su carrera musical en su adolescencia tocando la guitarra y cantando serenatas.7​ Más tarde se unió a Los PEG, un trío de jazz y bossa nova en el que cantó y tocó el bajo y el contrabajo.8​ José encontró el éxito como solista a principios de la década de los setenta, demostrando su habilidad vocal con una impresionante interpretación de la canción «El triste» en el segundo Festival de la Canción Latina celebrado en Ciudad de México en 1970, subiendo a las listas latinas durante esos años.9​ Habiendo alcanzado el reconocimiento como baladista, su canto obtuvo la aclamación crítica universal de sus pares musicales y medios de comunicación.",
    "videourl": "https://youtube.com/playlist?list=PL26274DCDCD4770D8",
    "price": "$50",
    "coverImg": "josejose.jpg",
    "category": "Balada",
    "location": "Mexico",
    "locationImg": "mexiflag.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "https://www.sonymusic.com.mx/artist/jose-jose/",
    "youtube": "https://www.youtube.com/channel/UCSPwNgHHyAtHanVaGN2LLWA",
    "facebook": "https://www.facebook.com/OficialJoseJose",
    "instagram": "https://www.instagram.com/explore/tags/josejose/?hl=en",
    "twitter": "https://twitter.com/JoseJoseOficial",
    "spotify": "https://open.spotify.com/artist/4mN0qcMxWX8oToqfDPM5yV",
    "deezer": "https://www.deezer.com/mx/artist/71835",
    "apple": "https://music.apple.com/mx/artist/jos%C3%A9-jos%C3%A9/333303",
    "soundcloud": "https://soundcloud.com/jose-jose-official",
    "tidal": "https://listen.tidal.com/artist/4343",
    "wiki": "https://es.wikipedia.org/wiki/Jos%C3%A9_Jos%C3%A9",
    "ticketmaster": "https://www.ticketmaster.com/jose-jose-tickets/artist/756645",
    "tiktok": "https://www.tiktok.com/@josejoseoficial",
    "napster": "https://music.amazon.com.mx/artists/B001369092/jos%C3%A9-jos%C3%A9"
  },
  {
    "title": "Julio Iglesias",
    "description": "Julio José Iglesias de la Cueva (Madrid, 23 de septiembre de 1943) es un cantante, compositor, músico, productor, exfutbolista, abogado y empresario español.​Es acreditado como el artista de habla hispana más exitoso de la historia. En 1983 fue reconocido por The World Records Guinness como el artista de lengua hispana con más ventas en todo el mundo y la figura musical con más grabaciones en diferentes idiomas. ​Es reconocido también como el cantante europeo con más éxito comercial a nivel internacional hasta hoy día.",
    "videourl": "https://www.youtube.com/playlist?list=PLO5Zd_or60jao46r4m88WpXB9UkEcYdmH",
    "price": "$50",
    "coverImg": "julioiglesias.jpg",
    "category": "Balada",
    "location": "Mexico",
    "locationImg": "mexiflag.jpg",
    "openSpots": null,
    "likes": 1000,
    "websiteurl": "https://www.julioiglesias.com/",
    "youtube": "https://www.youtube.com/channel/UCRdbdzGS_oGvT1CttIUzPFA",
    "facebook": "https://www.facebook.com/groups/3607946422574804",
    "instagram": "https://www.instagram.com/julioiglesias/",
    "twitter": "https://x.com/JulioIglesias",
    "spotify": "https://open.spotify.com/artist/4etuCZVdP8yiNPn4xf0ie5",
    "deezer": "https://www.deezer.com/mx/artist/8750",
    "apple": "https://music.apple.com/mx/artist/julio-iglesias/540287",
    "soundcloud": "https://soundcloud.com/julio-iglesias-official",
    "tidal": "https://listen.tidal.com/artist/62",
    "wiki": "https://es.wikipedia.org/wiki/Julio_Iglesias",
    "ticketmaster": "https://www.ticketmaster.com/julio-iglesias-tickets/artist/742500",
    "tiktok": "https://www.tiktok.com/@julioiiglesias",
    "napster": "https://music.amazon.com.mx/artists/B000QKDIPI/julio-iglesias"
  },

  {
    title: 'Julio Jaramillo',
    description:
      'Julio Jaramillo, nacido el 1 de octubre de 1935 en Guayaquil y fallecido en la misma ciudad el 9 de febrero de 1978, es una figura icónica en la música latinoamericana. Su talento como cantante y compositor le permitió convertirse en una leyenda del bolero, pasillo, vals y tango. A lo largo de su carrera, Jaramillo grabó alrededor de 1800 temas, dejando una huella indeleble en la música de América Latina. Su canción más emblemática es "Nuestro Juramento," un bolero que ha resonado profundamente con sus fans y que se ha convertido en un clásico atemporal. Su habilidad para transmitir emociones a través de su interpretación vocal lo hizo destacar y le ganó el aprecio de una amplia audiencia. Julio Jaramillo es recordado por su contribución a la música ecuatoriana y latinoamericana, y su legado sigue vivo a través de sus grabaciones y la influencia que ha ejercido en generaciones de músicos y amantes de la música..',
    videourl:
      'https://youtube.com/playlist?list=UULPzxR6DFuNPHQI8Wvjv7ip7A&si=Q-D3QKEFmeVRiZZP',
    price: '$50',
    coverImg: 'juliojaramillo.jpeg',
    category: 'Boleros',
    location: 'Ecuador',
    locationImg: 'ecuador.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.clubdefansjuliojaramillo.com/',
    youtube: 'https://www.youtube.com/channel/UCzxR6DFuNPHQI8Wvjv7ip7A',
    facebook: 'https://www.facebook.com/mr.juramento/?locale=es_LA',
    instagram: 'https://www.instagram.com/juliojaramillooficial/',
    twitter: 'https://x.com/juliojara7186',
    spotify: 'https://open.spotify.com/artist/6HqPNOo6OV9rPbEY7MP9T8',
    deezer: 'https://www.deezer.com/mx/artist/97125',
    apple: 'https://music.apple.com/mx/artist/julio-jaramillo/2140774',
    soundcloud: 'https://soundcloud.com/juliojaramillo-sc',
    tidal: 'https://listen.tidal.com/artist/37252',
    wiki: 'https://es.wikipedia.org/wiki/Julio_Jaramillo',
    ticketmaster:
      'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@juliojaramillomusic?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJY1D2/julio-jaramillo',
  },
  {
    title: 'Eydie Gorme',
    description:
      "Eydie Gormé, nacida Edith Gormezano en Nueva York el 16 de agosto de 1928 y fallecida en Las Vegas el 10 de agosto de 2013, fue una destacada cantante estadounidense conocida por su talento y su influencia en la música pop y el jazz. De origen judío sefardí, Gormé alcanzó la fama por su voz distintiva y su estilo elegante, y es recordada por su habilidad para interpretar tanto estándares del pop como jazz. Junto a su esposo, Steve Lawrence, Gormé formó un icónico dúo musical que dejó una marca significativa en la industria. Su repertorio abarcaba una variedad de géneros, y juntos lograron numerosos éxitos y premios. Eydie Gormé también tuvo una exitosa carrera en solitario, con éxitos como Blame It on the Bossa Nova y Cien años.Gormé fue conocida por su versatilidad y capacidad para conectar con su audiencia, y su legado sigue vivo a través de sus grabaciones y contribuciones a la música popular.",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lIpjwBOlTAWTGDl5SA_z5mcdFONYwtj-I&si=341fqkKGpZk0AZXf',
    price: '$50',
    coverImg: 'eydiegorme.jpg',
    category: 'Boleros',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'http://www.steveandeydie.com/',
    youtube: 'https://www.youtube.com/channel/UCN_V0kRUFVu7pYtgnfc2qcA',
    facebook: 'https://www.facebook.com/profile.php?id=100041727681306',
    instagram: 'https://www.instagram.com/eydiegormeofficial/',
    twitter: 'https://x.com/ProjectRUnisono',
    spotify: 'https://open.spotify.com/artist/6HnHBbeScFiQKXt3sUQA3Z',
    deezer: 'https://www.deezer.com/mx/artist/104055',
    apple:
      'https://music.apple.com/mx/artist/eydie-gorme/773976',
    soundcloud: 'https://soundcloud.com/eydie-gorme',
    tidal: 'https://listen.tidal.com/artist/27664',
    wiki: 'https://es.wikipedia.org/wiki/Eydie_Gorm%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/eydie-gorme-tickets/artist/734049',
    tiktok: 'https://www.tiktok.com/search?lang=es&q=Eydie%20Gorme&t=1726087276263',
    napster: 'https://music.amazon.com/artists/B000QKG59O/eydie-gorme',
  },
  {
    title: 'Armando Manzanero',
    description:
      'Armando Manzanero Canché (Mérida, Yucatán, 7 de febrero de 1935-Ciudad de México, 28 de diciembre de 2020),fue un compositor, cantante, actor, músico y productor discográfico mexicano, considerado por parte de especialistas, prensa y músicos como uno de los compositores más exitosos de la historia.Escribió más de cuatrocientas canciones, de las cuales más de cincuenta han alcanzado fama internacional, como «Somos novios», «Esta tarde vi llover», «Contigo aprendí» y «Adoro». Sus temas le valieron el seudónimo de Rey del Romanticismo​ y fueron interpretados por múltiples estrellas internacionales',

    videourl:
      'https://youtube.com/playlist?list=PLgaFNC_I_ZknuC_VCFjRB3FHPFg19M4iM&si=APjDnbQPxN2hZOqY',
    price: '$50',
    coverImg: 'armandomanzanero.png',
    category: 'Boleros',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rawdistrict.com/',
    youtube: 'https://www.youtube.com/channel/UCBGLmDwsKut36KsdMyPJfpQ',
    facebook: 'https://www.facebook.com/camanzanero/',
    instagram: 'https://www.instagram.com/camanzanero',
    twitter: 'https://x.com/hashtag/ArmandoManzanero?src=hashtag_click',
    spotify: 'https://open.spotify.com/artist/0PN0fbe41KbuzlRYnoajNm',
    deezer: 'https://www.deezer.com/mx/artist/15489',
    apple: 'https://music.apple.com/mx/artist/armando-manzanero/88986',
    soundcloud: 'https://soundcloud.com/armando-manzanero-official',
    tidal: 'https://listen.tidal.com/artist/12584',
    wiki: 'https://es.wikipedia.org/wiki/Armando_Manzanero',
    ticketmaster:
      'https://www.ticketmaster.com/armando-manzanero-tickets/artist/1170744',

    tiktok: 'https://www.tiktok.com/@armando.manzaner',
    napster: 'https://music.amazon.com.mx/artists/B000QJJTJ8/armando-manzanero',
  },
  
  {
    title: 'Chamin Correa',
    description:
      'Benjamín Correa Pérez de León, más conocido por su nombre artístico Chamín Correa (Ciudad de México, 4 de diciembre de 1929 - 14 de enero de 2020), fue un músico mexicano especializado en la interpretación de música romántica con diversos grupos y cantantes. Es célebre por su forma virtuosa de tocar el requinto.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l1nzDipbkiWtR1BPgEYlQ79EckOnJnQ7k&si=3pCybWje8Jsml-_M',
    price: '$50',
    coverImg: 'chamincorrea.jpeg',
    category: 'Guitarra',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.chamincorrea.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCsjSB7kzgjCpjlvh_MDZ3Lg',
    facebook: 'https://www.facebook.com/profile.php?id=100063048889812',
    instagram: 'https://www.instagram.com/chamin_correa/',
    twitter: 'https://x.com/chamincorrea?lang=en',
    spotify: 'https://open.spotify.com/artist/5nbu0z9YPCdLMUr4OsiQ9w',
    deezer: 'https://www.deezer.com/mx/artist/1266367',
    apple: 'https://music.apple.com/mx/artist/chamin/513919813',
    soundcloud: 'https://soundcloud.com/chamincorreaofficial',
    tidal: 'https://listen.tidal.com/artist/4949977',
    wiki: 'https://es.wikipedia.org/wiki/Cham%C3%ADn_Correa',
    ticketmaster:
      'https://www.ticketmaster.com.mx/',
    tiktok: 'https://es.wikipedia.org/wiki/Cham%C3%ADn_Correa',
    napster: 'https://music.amazon.com.mx/artists/B00136FA5A',
  },
  {
    title: 'Agustin Lara',
    description:
      'Ángel Agustín María Carlos Fausto Mariano Alfonso del Sagrado Corazón de Jesús Lara y Aguirre del Pino,4​ (Tlacotalpan, 30 de octubre de 1897-Ciudad de México, 6 de noviembre de 1970), conocido como Agustín Lara, fue un cantante, compositor y actor mexicano. Dentro de la música, se especializó en el género de bolero. También se le conoció con los apodos de El músico poeta y El flaco de oro',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lUP-LPazLwXZaID_5BUMnGfvJvx6nTC7I&si=t6M1_wqIfsxgW0WP',
    price: '$50',
    coverImg: 'agustinlara.jpeg',
    category: 'Boleros',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.gob.mx/siap/articulos/agustin-lara-una-de-las-figuras-mas-representativas-de-la-musica-nacional',
    youtube: 'https://www.youtube.com/channel/UCE1S4CxOLAd8c9hsT2HWrkA',
    facebook: 'https://www.facebook.com/groups/2005891829635936',
    instagram: 'https://www.instagram.com/agustinlaraof/',
    twitter: 'https://x.com/DonAgustinLara',
    spotify:
      'https://open.spotify.com/artist/3ihXVyWYDuTBVpEDrr1Lop',
    deezer: 'https://www.deezer.com/mx/artist/71805',
    apple: 'https://music.apple.com/mx/artist/agust%C3%ADn-lara/294795',
    soundcloud: 'https://soundcloud.com/agustinlara-sc',
    tidal:
      'https://listen.tidal.com/artist/3561549',
    wiki: 'https://es.wikipedia.org/wiki/Agust%C3%ADn_Lara',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Agustin%20Lara&t=1726085902712',
    napster: 'https://music.amazon.com/artists/B000QJV1B2/agust%C3%ADn-lara',
  },
  {
    title: 'Javier Solis',
    description:
      'Javier Solís, cuyo nombre real era Gabriel Siria Levario, nació el 4 de septiembre de 1931 en Ciudad de México y falleció el 19 de abril de 1966 en la misma ciudad. Es ampliamente reconocido como uno de los grandes intérpretes del bolero y de la música ranchera en México.Su voz distintiva y su estilo emotivo hicieron de Solís una figura prominente en la música latinoamericana. A lo largo de su corta carrera, dejó un impacto duradero con éxitos como Sombras,Copa tras copa, y El Loco. Su habilidad para transmitir profundas emociones a través de sus interpretaciones contribuyó a su estatus como una leyenda del género. Javier Solís también incursionó en la actuación, aunque su carrera en el cine fue más breve en comparación con su éxito musical. A pesar de su temprana muerte a los 34 años, su legado continúa vivo y su música sigue siendo apreciada por nuevas generaciones.',
    videourl:
      'https://youtube.com/playlist?list=PLkohGyKrHOM8WaJcSnIlDU4wW07vagDrJ&si=xxptzUJgGNLKR5Mt',
    price: '$50',
    coverImg: 'javiersolis.png',
    category: 'Boleros',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://joansebastianoficial.com/',
    youtube: 'https://www.youtube.com/channel/UC5CjRhS2bv626o9hIi6U8gQ',
    facebook: 'https://www.facebook.com/JavierSolisFans',
    instagram: 'https://www.instagram.com/javieer_solis/?hl=es-la',
    twitter: 'https://x.com/search?q=Javier%20Solis&src=typed_query',
    spotify:
      'https://open.spotify.com/artist/7jerD1mbWgyDukHAmCvdCj',
    deezer: 'https://www.deezer.com/mx/artist/10400',
    apple: 'https://music.apple.com/us/artist/javier-sol%C3%ADs/2846887',
    soundcloud: 'https://soundcloud.com/javiersolisofficial',
    tidal: 'https://listen.tidal.com/artist/3633759',
    wiki: 'https://es.wikipedia.org/wiki/Javier_Sol%C3%ADs',
    ticketmaster:
      'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@javiersolisoficial?lang=es',
    napster: 'https://music.amazon.com/artists/B001BKA5VM/javier-sol%C3%ADs',
  },
  {
    title: 'Leo Dan',
    description:
      'Leopoldo Dante Tévez (Estación Atamisqui, Santiago del Estero, 22 de marzo de 1942), más conocido como Leo Dan, es un cantante, compositor y actor argentino que ha producido música en diferentes géneros. Ha grabado más de 70 álbumes en Argentina, Perú, Chile, Colombia, España y México.',
    videourl:
      'https://youtube.com/playlist?list=PLY-fXQ8Qqi77K87BhMQjavExAWBDWmBFH&si=463wkDT_emBHG8lc',
    price: 36,
    coverImg: 'leodan.jpeg',
    category: 'Tropical',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.cmtv.com.ar/biografia/show.php?bnid=1954&banda=Leo_Dan',
    youtube: 'https://www.youtube.com/channel/UC5D-hftrs8cVUeW3we-VHXw',
    facebook: 'https://www.facebook.com/Leo.Dan.Oficial/',
    instagram: 'https://www.instagram.com/leodanoficial/',
    twitter: 'https://x.com/Leo_DanOficial',
    spotify: 'https://open.spotify.com/artist/6qhOH2mrlqUDod9sWA5kR2',
    deezer: 'https://www.deezer.com/mx/artist/364905',
    apple: 'https://music.apple.com/mx/artist/leo-dan/295769',
    soundcloud: 'https://soundcloud.com/leo-dan-official',
    tidal: 'https://listen.tidal.com/artist/3658398',
    wiki: 'https://es.wikipedia.org/wiki/Leo_Dan',
    ticketmaster: 'https://www.ticketmaster.com/leo-dan-el-adios-de-una-rosemont-illinois-09-14-2024/event/04006033E3DC2F7F',
    tiktok: 'https://www.tiktok.com/@leo_danoficial',
    napster: 'https://music.amazon.com.mx/artists/B000QKIZ4C/leo-dan',
  },
  {
    title: 'Mike Laure',
    description:
      'Miguel Laure Rubio (El Salto, Jalisco, México, 29 de septiembre de 1937-Ciudad de México, 19 de noviembre de 2000),​ conocido popularmente por su nombre artístico Mike Laure, fue un músico mexicano',
    videourl:
      'https://youtube.com/playlist?list=PLZJRSWx-FYQG0hufw4Ush1vtYMFijBtOj&si=n99YIHMud3ZihVfg',
    price: 36,
    coverImg: 'mikelaure.jpeg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.milenio.com/politica/comunidad/mike-laure-biografia-musico-cumbia-jalisco',
    youtube: 'https://www.youtube.com/channel/UCHIDnxVPWZYfFNBYNkriGcQ',
    facebook: 'https://www.facebook.com/profile.php?id=100008158986200',
    instagram: 'https://www.instagram.com/loshijosde_mikelaure/',
    twitter: 'https://x.com/SarkadiTam6172',
    spotify: 'https://open.spotify.com/artist/39vVl1HOIZwca5O4g8QQ8h?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/94596',
    apple: 'https://music.apple.com/us/artist/mike-laure/251550386',
    soundcloud: 'https://soundcloud.com/mikelaure',
    tidal: 'https://listen.tidal.com/artist/3658398',
    wiki: 'https://es.wikipedia.org/wiki/Mike_Laure',
    ticketmaster: 'https://www.tomaticket.es/artista/mike-laure',
    tiktok: 'https://www.tiktok.com/search?q=Mike%20Laure&t=1726261353210',
    napster: 'https://music.amazon.com.mx/artists/B000S2D0U2/mike-laure',
  },
  {
    title: 'Tito Puente',
    description:
      'Ernesto Antonio Puente (Nueva York, 20 de abril de 1923-ibídem, 31 de mayo de 2000), conocido como Tito Puente, fue un legendario percusionista estadounidense de origen puertorriqueño. Nombre ineludible del jazz a nivel mundial, desarrolló su trabajo en el campo de la música cubana (son montuno, chachachá, mambo, bolero, pachanga, guaracha), y del jazz afrocubano, el jazz latino y la salsa.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lyzRM7XkStXcwWB6l9JM5jefPCaFjFWmc',
    price: 36,
    coverImg: 'titopuente.gif',
    category: 'Mambo',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Tito_Puente',
    youtube: 'https://www.youtube.com/channel/UC-80HNiK3Vwd0zcFcfB8hRw',
    facebook: 'https://www.facebook.com/pages/Tito%20Puente/108467629176892/',
    instagram: 'https://www.instagram.com/_tito_puente_/',
    twitter: 'https://twitter.com/roberjba',
    spotify: 'https://open.spotify.com/artist/6SPpCqM8gOzrtICAxN5NuX',
    deezer: 'https://www.deezer.com/mx/artist/8661',
    apple: 'https://music.apple.com/mx/artist/tito-puente/105119',
    soundcloud: 'https://soundcloud.com/tito-puente-official',
    tidal: 'https://listen.tidal.com/artist/1895',
    wiki: 'https://en.wikipedia.org/wiki/Tito_Puente',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Tito+Puente+JR',
    tiktok: 'https://www.tiktok.com/search?q=Tito%20puente&t=1658084271449',
    napster: 'https://music.amazon.com.mx/artists/B000QJW6N4/tito-puente',
  },
  {
    title: 'Buena Vista Social Club',
    description:
      'Buena Vista Social Club es el nombre de un club social muy popular de La Habana, Cuba, cuyos miembros practicaban el baile y la música. También es el nombre de una agrupación musical creada en la década de 1990, casi 50 años después de que el club cerrase, que inspiró una grabación hecha por el músico cubano Juan de Marcos González y por el guitarrista y folclorista estadounidense Ry Cooder con músicos cubanos tradicionales, muchos de ellos antiguos miembros del club en el que se presentaron cuando su popularidad estaba en la cumbre.',

    videourl:
      'https://youtube.com/playlist?list=PLfdBj3OfNtmwqvW93GyDyeAGq6dFsoB8i&si=FNwlGb9rJr8FpPNY',
    price: '$50',
    coverImg: 'buenavista.jpg',
    category: 'Mambo',
    location: 'Cuba',
    locationImg: 'cubaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.buenavistasocialclub.com/',
    youtube: 'https://www.youtube.com/channel/UCJYl3tOsrTkCCidMjhxdAeQ',
    facebook: 'https://www.facebook.com/buenavistasocialclubofficial',
    instagram: 'https://www.instagram.com/buenavistasocialclubofficial',
    twitter: 'https://x.com/buenavistasc?lang=en',
    spotify: 'https://open.spotify.com/artist/11kBu957KTYoAltZHDm8gW',
    deezer: 'https://www.deezer.com/mx/artist/2799',
    apple: 'https://music.apple.com/mx/artist/buena-vista-social-club/5620149',
    soundcloud: 'https://soundcloud.com/buena-vista-social-club',
    tidal: 'https://listen.tidal.com/artist/3521820',
    wiki: 'https://es.wikipedia.org/wiki/Buena_Vista_Social_Club',
    ticketmaster:'https://www.ticketmaster.com/hommage-a-buena-vista-social-club-tickets/artist/2501195',
    tiktok: 'https://www.tiktok.com/@bvscofficial',
    napster: 'https://music.amazon.com.mx/artists/B0011ZOQNA/buena-vista-social-club',
  },
  
  {
    title: 'Silvio Rodriguez',
    description:
      'Silvio Rodríguez Domínguez (San Antonio de los Baños, Cuba; 29 de noviembre de 1946), conocido como Silvio Rodríguez, El Aprendiz o El Escaramujo, es un cantautor, guitarrista, poeta y político cubano, exponente característico de la música de su país surgida con la Revolución cubana, conocida como la Nueva Trova, que comparte con otros reconocidos cantautores tales como Pablo Milanés, Noel Nicola y Vicente Feliú.',

    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nkSWrqjiIW2Mpw5SUNiDlPF9Llu9nx7TY&si=EuoZqgCVG25ZJgDO',
    price: '$50',
    coverImg: 'sylvio.jpg',
    category: 'Trova',
    location: 'Cuba',
    locationImg: 'cubaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.zurrondelaprendiz.com/',
    youtube: 'https://www.youtube.com/channel/UC16aF70kysMIJdz51HdMx9Q',
    facebook: 'https://www.facebook.com/zurrondelaprendiz',
    instagram: 'https://www.instagram.com/zurron_del_aprendiz',
    twitter: 'https://x.com/zurron_aprendiz',
    spotify: 'https://open.spotify.com/artist/4rUyBlggM5tZUH5QZn9ZuO',
    deezer: 'https://www.deezer.com/mx/artist/4340',
    apple: 'https://music.apple.com/mx/artist/silvio-rodr%C3%ADguez/303508',
    soundcloud: 'https://soundcloud.com/silviorodriguezofficial',
    tidal: 'https://listen.tidal.com/artist/3521820',
    wiki: 'https://es.wikipedia.org/wiki/Silvio_Rodr%C3%ADguez',
    ticketmaster:'https://www.ticketmaster.com/silvio-rodriguez-tickets/artist/1171066',
    tiktok: 'https://www.tiktok.com/@silviorodriguesmusic',
    napster: 'https://music.amazon.com.mx/artists/B000QKFHVQ/silvio-rodr%C3%ADguez',
  },
  {
    title: 'Pablo Milanes',
    description:
      'Pablo Milanés Arias (Bayamo, 24 de febrero de 1943-Madrid, 22 de noviembre de 2022) Nació en Bayamo, provincia de Oriente, actual provincia de Granma, Cuba. Estudió música en el Conservatorio Municipal de La Habana. En sus comienzos estuvo muy influido por la música tradicional cubana y por el feeling (sentimiento, en inglés). El feeling es un estilo musical que se inició en Cuba en los años 1940 y suponía una nueva manera de afrontar la canción, donde el sentimiento definía la interpretación y estaba influido por las corrientes estadounidenses de la canción romántica y del jazz. El feeling se acompañaba de una guitarra, al estilo de los viejos trovadores pero enriquecido por armonizaciones jazzísticas. Así se establecía esta nueva forma de comunicación o "feeling" con el público.',

    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l47ADXdJJJQvnrxx6LkL9dNH30KN1aa84&si=A10mr6MWXZqDMWjO',
    price: '$50',
    coverImg: 'pablomilanes.jpg',
    category: 'Trova',
    location: 'Cuba',
    locationImg: 'cubaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.milanespablo.com/',
    youtube: 'https://www.youtube.com/channel/UCg1l44T5Irwm5K849uO9Hiw',
    facebook: 'https://www.facebook.com/pmilanesoficial',
    instagram: 'https://www.instagram.com/pablo_milanes_oficial/',
    twitter: 'https://x.com/pmilanesoficial',
    spotify: 'https://open.spotify.com/artist/4vOfKh5wz7lTcdqB3EwsC5',
    deezer: 'https://www.deezer.com/mx/artist/75087',
    apple: 'https://music.apple.com/mx/artist/pablo-milan%C3%A9s/132280',
    soundcloud: 'https://soundcloud.com/pablomilanes',
    tidal: 'https://listen.tidal.com/artist/35825',
    wiki: 'https://es.wikipedia.org/wiki/Pablo_Milan%C3%A9s',
    ticketmaster:'https://www.ticketmaster.com/pablo-milanes-tickets/artist/762879',
    tiktok: 'https://www.tiktok.com/search/video?q=Pablo%20Milanes&t=1726169450082',
    napster: 'https://music.amazon.com.mx/artists/B000QKA3M4/pablo-milan%C3%A9s',
  },
  {
    title: 'Celia Cruz',
    description:
      'Úrsula Hilaria Celia de la Caridad de la Santísima Trinidad Cruz Alfonso (La Habana, Cuba; 21 de octubre de 1925-Fort Lee, Nueva Jersey, Estados Unidos; 16 de julio de 2003), más conocida como Celia Cruz, fue una cantante cubana de música tropical. Apodada «La Reina de la Salsa»y «La Guarachera de Cuba», es ampliamente considerada una de las artistas latinas más populares e importantes del siglo XX y un icono de la música latina. Fue una de las máximas exponentes de su género, así como una de las artistas más influyentes de la música de su país.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXagibiHOsWNzrXSIFZj6hq',
    price: 36,
    coverImg: 'celiacruz.gif',
    category: 'Mambo',
    location: 'Cuba',
    locationImg: 'cubaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://celiacruz.com/',
    youtube: 'https://www.youtube.com/channel/UC0ici7PK6RBnCUQ2mqVGzKA',
    facebook: 'https://www.facebook.com/CeliaCruzTheQueen',
    instagram: 'https://www.instagram.com/celiacruz/',
    twitter: 'https://twitter.com/CeliaCruz',
    spotify: 'https://open.spotify.com/artist/2weA6hhVqTIN2gSn9PUB9U',
    deezer: 'https://www.deezer.com/mx/artist/5024',
    apple: 'https://music.apple.com/mx/artist/celia-cruz/105181',
    soundcloud: 'https://soundcloud.com/celia-cruz-official',
    tidal: 'https://listen.tidal.com/artist/6072',
    wiki: 'https://en.wikipedia.org/wiki/Celia_Cruz',
    ticketmaster:
      'https://www.ticketmaster.com/Celia-Cruz-tickets/artist/760911',
    napster: 'https://music.amazon.com.mx/artists/B000QJTBT6/celia-cruz',
    tiktok: 'https://www.tiktok.com/@soyceliacruz',
  },
  
]

export default CardData20
