const CardData16 = [
  {
    title: 'Los Pericos',
    description:
      ' Los Pericos es una banda de reggae y ska argentina fundada en 1986. En 2006 había superado los dos millones y medio de discos vendidos y más de tres mil presentaciones en vivo. Fueron nombrados embajadores del reggae por Jamaica..',
    videourl:
      'https://youtube.com/playlist?list=PL5l3Oa8FE8lgHrUKRDJMGU_z1zQxeA73x',
    price: '$50',
    coverImg: 'lospericos.jpg',
    category: 'Reggae',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://pericos.com/',
    youtube: 'https://www.youtube.com/channel/UCbVxcCD25pMVM4RVPb15DVA',
    facebook: 'https://www.facebook.com/Lospericos?fref=ts',
    instagram: 'https://www.instagram.com/los_pericos/',
    twitter: 'https://www.instagram.com/los_pericos/',
    spotify: 'https://open.spotify.com/artist/7FnZWGw9lwOr7WzieTKEPR',
    deezer: 'https://www.deezer.com/mx/artist/6441',
    apple: 'https://music.apple.com/mx/artist/los-pericos/559050',
    soundcloud: 'https://soundcloud.com/los-pericos',
    tidal: 'https://listen.tidal.com/artist/56840',
    wiki: 'https://es.wikipedia.org/wiki/Los_Pericos',
    ticketmaster:
      'https://www.ticketmaster.com/los-pericos-tickets/artist/782857',
    tiktok: 'https://www.tiktok.com/@lospericosoficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RHTAWA/los-pericos',
  },
  {
    title: 'Elefante',
    description:
      'Elefante es una banda de rock alternativo y pop rock formada en la Ciudad de México a principios de la década de 1990. Han lanzado cinco álbumes, y su álbum homónimo fue nominado a un Premio Grammy Latino en 2005. El grupo alcanzó prominencia cuando abrieron para Joaquín Sabina en un espectáculo en el Auditorio Nacional de la Ciudad de México. Tras varias giras por las Américas, abriendo para Shakira y Maná, su vocalista principal, Reyli, dejó la banda en 2003 para seguir una carrera en solitario. Como reemplazo, reclutaron a Jorge Martínez Guevara, exvocalista del grupo Caos, quien participó en su álbum de 2005, Elefante. Jorge Martínez dejó la banda en 2007, y el compositor/guitarrista Rafael Rafa López y Javi Cantero asumieron las funciones vocales en los lanzamientos posteriores.',
    videourl: 'https://youtube.com/playlist?list=PLGmZEpYPNGnH5RzBwJYcyMG4ARVnOhx1T&si=9AwQ8B6p_adavhB1',
    price: '$50',
    coverImg: 'elefante.jpg',
    category: 'Baladas',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://www.elefanteoficial.com/',
    youtube: 'https://www.youtube.com/c/elefanteOficial',
    facebook: 'https://www.facebook.com/elefanteOficial/?locale=es_LA',
    instagram: 'https://www.instagram.com/elefanteoficial/',
    twitter: 'https://x.com/elefanteoficial',
    spotify: 'https://open.spotify.com/artist/5oYHL2SijkMY52gKIhYJNb',
    deezer: 'https://www.deezer.com/mx/artist/5308',
    apple: 'https://music.apple.com/mx/artist/elefante/7046239',
    soundcloud: 'https://soundcloud.com/elefante-oficial',
    tidal: 'https://listen.tidal.com/artist/616',
    wiki: 'https://en.wikipedia.org/wiki/Elefante_(Mexican_band)',
    ticketmaster:
      'https://www.ticketmaster.com.mx/reyli-barba-boletos/artist/1197375',
    tiktok: 'https://www.tiktok.com/@elefanteoficial?fbclid=IwAR0RYW8igsq6yXbBVavSVZ5X3moz2bMXDXdwIlpOUe8hgKX941Ah7kIQFHo',
    napster: 'https://music.amazon.com/artists/B000S2BG8U/reyli-barba',
  },
  {
    title: 'Los Cafres',
    description:
      ' Los Cafres es una banda argentina de reggae. Formada en 1987 en Buenos Aires, fue la primera en cantar roots reggae en castellano. Liderados por su cantante Guillermo Bonetto,1​ a lo largo de sus más de 30 años de historia Los Cafres editaron catorce discos, de los cuales tres se convirtieron en Disco de Oro. En reiteradas ocasiones, fueron nominados a los Premios Grammy..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nktYf49We-hyWSs_4QO6sHQ2ViNj2DEKg',
    price: '$50',
    coverImg: 'loscafres.jpg',
    category: 'Reggae',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://loscafres.net/',
    youtube: 'https://www.youtube.com/channel/UCeQ-A7LTWGmN4AIVpVo7u1w',
    facebook: 'https://www.facebook.com/loscafrespaginaoficial',
    instagram: 'https://www.instagram.com/loscafres/',
    twitter: 'https://twitter.com/LOSCAFRES',
    spotify: 'https://open.spotify.com/artist/2ST5XwWB4uXGKk2NXP8DUI',
    deezer: 'https://www.deezer.com/mx/artist/14523',
    apple: 'https://music.apple.com/mx/artist/los-cafres/128904969',
    soundcloud: 'https://soundcloud.com/loscafres',
    tidal: 'https://listen.tidal.com/artist/3685831',
    wiki: 'https://es.wikipedia.org/wiki/Los_Cafres',
    ticketmaster:
      'https://www.ticketmaster.com/los-cafres-tickets/artist/2104221',
    tiktok: 'https://www.tiktok.com/@loscafres?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000XXRZ5M/los-cafres',
  },
  {
    title: 'Fernando Delgadillo',
    description:
      'Fernando Delgadillo González (Tlalnepantla, 11 de noviembre de 1965) es un cantautor mexicano del género trova o nueva canción. Estudió en la Escuela de Música Libre José F. Vázquez y en la escuela de la Sociedad de Autores y Compositores de México. A los 16 años fue percusionista en una agrupación de música andina llamada Huancayo, con los cuales se presentaba en la peña El Sapo Cancionero;4​ e integró el colectivo SEYMUS.1​ Comenzó actividad artística en 1985. En 1997, Delgadillo fue invitado a China para participar en el Beijing International Film Festival. Después de dicho festival, algunas de sus canciones fueron traducidas al chino. Más tarde, en agosto, lo invitaron al Festival Internacional de la Juventud, con sede en La Habana, Cuba',
    category: 'Trova',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nzInDmkRTLzFkkj-lLHjOvQj9YpR9rKug',
    price: 80,
    coverImg: 'fernandodelgadillo.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://www.fdelgadillo.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCVeaNTYY8SGCMqiY31Kc-tA',
    facebook: 'https://www.facebook.com/FernandoDelgadilloOficial/',
    instagram: 'https://www.instagram.com/fernandodelgadillooficial/?hl=es-la',
    twitter: 'https://x.com/DelgadilloFer',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/14801',
    apple: 'https://music.apple.com/mx/artist/fernando-delgadillo/145982983',
    soundcloud: 'https://soundcloud.com/search?q=Fernando%20Delgadillo',
    tidal: 'https://listen.tidal.com/artist/4159503',
    wiki: 'https://es.wikipedia.org/wiki/Fernando_Delgadillo',
    ticketmaster:
      'https://www.ticketmaster.com/fernando-delgadillo-tickets/artist/1174042',
    tiktok: 'https://www.tiktok.com/search?q=Fernando%20Delgadillo&t=1726170971501',
    napster: 'https://music.amazon.com.mx/artists/B000QKD32G/fernando-delgadillo',
  },
  {
    title: 'Mikel Erentxun',
    description:
      'Mikel Erentxun Acosta (Caracas, 23 de febrero de 1965) es un cantante y escritor Español, nacido en Venezuela, donde residió desde su primer año de vida para posteriormente, con sus padres, mudarse a San Sebastián, donde reside actualmente.1​ Conocido por ser el líder de la banda española Duncan Dhu. Actualmente continúa destacándose como cantante solista.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_ll8p97KNMcVAgmqOhopNdCio_gW_Jo-0s&si=em6M3fDaE2ArIkfD',
    price: '$50',
    coverImg: 'mikelerenxtun.jpg',
    category: 'Rock Pop',
    location: 'Venezuela',
    locationImg: 'venezuelaflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://www.mikelerentxun.ws/',
    youtube: 'https://www.youtube.com/@mikelerentxun_oficial',
    facebook: 'https://www.facebook.com/mikelerentxun',
    instagram: 'https://www.instagram.com/erentxun/',
    twitter: 'https://x.com/MikelErentxun',
    spotify: 'https://open.spotify.com/artist/7thnnayFyJnVOAJrpe5wMC',
    deezer: 'https://www.deezer.com/mx/artist/13508',
    apple: 'https://music.apple.com/mx/artist/mikel-erentxun/725328',
    soundcloud: 'https://soundcloud.com/mikelerentxunlasmalasinfluencias',
    tidal: 'https://listen.tidal.com/artist/12190',
    wiki: 'https://es.wikipedia.org/wiki/Mikel_Erentxun',
    ticketmaster:
      'https://www.ticketmaster.com.mx/mikel-erentxun-tour-2024-guadalajara-19-11-2024/event/3D00610FE3913C13',
    tiktok: 'https://www.tiktok.com/@mikelerentxun?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013CPN3S/mikel-erentxun',
  },{
    title: 'INSITE',
    description:
      'Insite es considerada la banda de rock más importante de Mexicali de los últimos años. Su sonido es intenso y estridente, y a menudo contrasta con letras y melodías vocales mucho más sutiles. Este poderoso estilo los ha llevado a tocar en escenarios tan icónicos como el Teatro Metropolitan, El Plaza Condesa y el House of Blues en L.A. La banda se formó en 2001, pero no fue hasta 2007 que debutaron oficialmente con su material discográfico “Una Vida No Es Suficiente”. Gracias a ese álbum fueron reconocidos ya no sólo en su natal Mexicali, sino en el resto de la escena de la música independiente en México y Latinoamérica. Actualmente Insite está conformado por César Cossío y Gabriel Felix en las guitarras, Carlos Esquer en el bajo, Johanan Lam en la batería y Antonio Altamirano en la voz, el más reciente integrante que llegó a Insite para renovar su energía y seguir consolidando la carrera de la banda.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXcBYlfhaTjPB4I4FnNCkaH&si=pNl64GOGGhYYvUHl',
    price: '$50',
    coverImg: 'insite.jpg',
    category: 'Rock',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://negropasion.com/collections/insite?srsltid=AfmBOoqSpBhHpkYc8DQprkiscRgyJPTob4aqmCY8-RblMmp3_BSBtj5o',
    youtube: 'https://www.youtube.com/@mikelerentxun_oficial',
    facebook: 'https://www.facebook.com/insitemusic',
    instagram: 'https://www.instagram.com/insitemusic/',
    twitter: 'https://x.com/rockinsite?lang=en',
    spotify: 'https://open.spotify.com/artist/2Dh7EC5nA37i7JH3UNB8YX',
    deezer: 'https://www.deezer.com/mx/artist/391678',
    apple: 'https://music.apple.com/mx/artist/insite/1606153104',
    soundcloud: 'https://soundcloud.com/user-191702099',
    tidal: 'https://listen.tidal.com/artist/3570987',
    wiki: 'https://negropasion.com/collections/insite?srsltid=AfmBOoqSpBhHpkYc8DQprkiscRgyJPTob4aqmCY8-RblMmp3_BSBtj5o',
    ticketmaster:
      'https://www.ticketmaster.com.mx/insite-thermo-guadalajara-03-10-2024/event/3D00608FC15825F9',
    tiktok: 'https://www.tiktok.com/@insitemusic?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013N74QG/insite',
  },
]

export default CardData16
