const CardData1 = [
  {
    title: 'El Compa Sacra',
    description:
      '¿Quién es El Compa Sacra? Sacramento Ramírez, conocido artísticamente como El Compa Sacra, nació en La Piedad, Michuacán, México. Llego a Estados Unidos de Norte America a comienzos de 1980. Comenzó su carrera musical con la agrupación Los Razos, la cual alcanzó reconocimiento en los años 80 y 90.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kJ5AizQuObUTIu65sr1wXNEw2Sft1-D1M',
    price: '$50',
    coverImg: 'CompaSacra.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/ElCompaSacraOficial',
    youtube: 'https://www.youtube.com/c/CompaSacraOficial',
    facebook: 'https://www.facebook.com/ElCompaSacraOficial',
    instagram: 'https://www.instagram.com/elcompasacra1/',
    twitter: 'https://twitter.com/1COMPASACRA',
    spotify: 'https://open.spotify.com/artist/5agbiu3kZu8DaZMkUnB3gm',
    deezer: 'https://www.deezer.com/mx/artist/14102571',
    apple: 'https://music.apple.com/mx/artist/el-compa-sacra/467743622',
    soundcloud: 'https://soundcloud.com/elcompasacraelultimorazo',
    tidal: 'https://listen.tidal.com/artist/5473567',
    wiki:
      'https://www.buenamusica.com/el-compa-sacra/biografia#:~:text=%C2%BFQui%C3%A9n%20es%20El%20Compa%20Sacra%3F%20Sacramento%20Ram%C3%ADrez%2C%20conocido,alcanz%C3%B3%20reconocimiento%20en%20los%20a%C3%B1os%20%E2%80%9980%20y%20%E2%80%9990.',
    ticketmaster:
      'https://www.ticketmaster.com/el-compa-sacra-tickets/artist/1607456',
    tiktok: 'https://www.tiktok.com/@elcompasacrachingon?lang=es',
  },

  {
    title: 'Jesse Uribe',
    description:
      ' Yesid Eduardo Uribe Ordóñez (Bucaramanga, 22 de marzo de 1987), más conocido como Jessi Uribe, es un cantante y músico colombiano, reconocido principalmente por su participación en los realities La Voz Colombia y A otro nivel del Canal Caracol..',
    videourl:
      'http://youtube.com/playlist?list=PL4UtXzxnvDJ2LHj0DgQc4jh9UQI-j9kTM',
    price: 50,
    coverImg: 'JesseUribe.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.jessiuribe.com/',
    youtube: 'https://www.youtube.com/jessiuribe3',
    facebook: 'https://www.facebook.com/jessiuribemusic',
    instagram: 'https://www.instagram.com/jessiuribe3/',
    twitter: 'https://twitter.com/Jessiuribe3',
    spotify: 'https://open.spotify.com/artist/3SN7I8KV2qBwTCZ4aNDcbS',
    deezer: 'https://www.deezer.com/mx/artist/7979540',
    apple: 'https://music.apple.com/mx/artist/jessi-uribe/998331667',
    soundcloud: 'https://soundcloud.com/jessiuribeofficial',
    tidal: 'https://listen.tidal.com/artist/7454431',
    wiki: 'https://es.wikipedia.org/wiki/Jessi_Uribe',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@jessiuribeoficial?lang=es',
    napster: 'https://web.napster.com/artist/jessi-uribe',
  },

  {
    title: 'Luis Angel-El Flaco',
    description:
      'Luis Ángel Franco Rivera mejor conocido como Luis Angel El flaco” es un famoso cantante de música de banda y regional mexicano que ha logrado millones de fans por su gran talento vocal. Luis Angel -El flaco” nació el 7 de marzo de 1987 en Mazatlán Sinaloa y también es muy reconocido por haber sido vocalista de La Banda Los Recoditos por más de 16 años..',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m_Jv-ZG4bHK1gIJjgHqlUjZQcLBCxnla8',
    price: 50,
    coverImg: 'ElFlaco.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/LuisAngelElFlaco/',
    youtube: 'https://www.youtube.com/channel/UCdhfjV6C97UkLgOYonsj3Zw',
    facebook: 'https://www.facebook.com/LuisAngelElFlaco/',
    instagram: 'https://www.instagram.com/luisangelelflaco/',
    twitter: 'https://twitter.com/luisangelelfla',
    spotify:
      'https://open.spotify.com/artist/4kJ2OBSNasUA4yOT5NCfCl?si=mkd-8murRnuOfzdqr9XL_Q&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/85216072',
    apple: 'https://music.apple.com/mx/artist/luis-angel-el-flaco/1497949275',
    soundcloud: 'https://soundcloud.com/luisangelelflaco',
    tidal: '',
    wiki:
      'https://www.grmediostv.com/2022/06/15/siguen-los-exitos-para-luis-angel-el-flaco/',
    ticketmaster:
      'https://www.ticketmaster.com.mx/luis-angel-el-flaco-boletos/artist/2771331',
    tiktok: 'https://www.tiktok.com/@luisangelelflaco1?lang=es',
    napster: 'https://web.napster.com/artist/luis-angel-el-flaco',
  },

  {
    title: 'Espinoza Paz',
    description:
      'Isidro Chávez Espinoza, conocido artísticamente como Espinoza Paz (29 de octubre de 1981, Angostura, Sinaloa), es un músico y cantautor mexicano, especializado en los estilos de banda, norteño, mariachi, duranguense y country en español.',
    videourl:
      'http://youtube.com/playlist?list=PL0bZXeO0KLMMf6zz6etoYNEWEijQ9ZoLA',
    price: 36,
    coverImg: 'espinoza.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.espinozapaz.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCSN8m0p-1RNoNljhNawQRhA',
    facebook: 'https://www.facebook.com/EspinozaPaz',
    instagram: 'https://www.instagram.com/espinozapaz/',
    twitter: 'https://twitter.com/EspinozaOficial',
    spotify: 'https://open.spotify.com/artist/01rgao9OzfBm2BOHWJpi1Y',
    deezer: 'https://www.deezer.com/mx/artist/363114',
    apple: 'https://music.apple.com/mx/artist/espinoza-paz/151079916',
    soundcloud: 'https://web.napster.com/artist/espinoza-paz',
    tidal: 'https://listen.tidal.com/artist/3909164',
    wiki: 'https://es.wikipedia.org/wiki/Espinoza_Paz',
    ticketmaster:
      'https://www.ticketmaster.com/espinoza-paz-tickets/artist/1388282',
    tiktok: 'https://www.tiktok.com/@espinozapaz?lang=es',
    napster: 'https://web.napster.com/artist/espinoza-paz',
  },
  {
    title: 'Pancho Barraza',
    description:
      'Pancho Barraza nació en Villa Union, municipio de Mazatlan, Sinaloa el 17 DE JUNIO DE 1961. Desde que era niño demostró inquietudes por la música, adquirió experiencia participando en diversos concursos. Estudio la carrera de profesor de música en la Bénemerita Escuela Normal de Sinaloa y ejerció como tal en una secundaría del poblado Villa Unión hasta los 22 años cuando se integró como bajista al grupo Karisma y así mismo al coro de la iglesia de dicho pueblo.',
    videourl: 'http://youtube.com/playlist?list=UU182E6DfqeGhPcyo-6N8xig',
    price: 50,
    coverImg: 'PanchoBarraza.jpg',
    category: 'Banda Instrumental',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://en.wikipedia.org/wiki/Pancho_Barraza',
    youtube: 'https://www.youtube.com/c/PanchoBarrazaOficial',
    facebook: 'https://www.facebook.com/pbarrazaoficial/',
    instagram: 'https://www.instagram.com/panchobarrazaoficial/',
    twitter: 'https://twitter.com/panchobarraza20',
    spotify: 'https://open.spotify.com/artist/5dmU7FrmtbQaSzIvGsE4Jp',
    deezer: 'https://www.deezer.com/mx/artist/98005',
    apple: 'https://music.apple.com/mx/artist/pancho-barraza/251493410',
    soundcloud: 'https://soundcloud.com/pancho-barraza-official',
    tidal: 'https://listen.tidal.com/artist/3715927',
    wiki: 'https://en.wikipedia.org/wiki/Pancho_Barraza',
    ticketmaster:
      'https://www.ticketmaster.com/pancho-barraza-tickets/artist/784419',
    tiktok: 'https://www.tiktok.com/@panchobarrazaoficial?lang=es',
    napster: 'https://web.napster.com/artist/pancho-barraza',
  },

  {
    title: 'Julio Preciado',
    description:
      'Julio César Preciado Quevedo (Mazatlán, Sinaloa, México, 1 de diciembre de 1966) es un cantante mexicano de banda sinaloense que formó parte de la banda más representativa de este género musical la Banda del Recodo de don Cruz Lizárraga como vocalista de 1991 a 1998 siguiendo posteriormente una carrera en solitario con la Banda Perla del Pacífico. .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lANuTCU3POCJ6nmDBkuuQyQq6Z1F17ohE',
    price: 50,
    coverImg: 'JulioPreciado.jpg',
    category: 'Banda Instrumental',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Julio_Preciado',
    youtube: 'https://www.youtube.com/channel/UC1X--Zjk2esCKjkm5jtM_VA',
    facebook: 'https://www.facebook.com/Julio.C.Preciado/',
    instagram: 'https://www.instagram.com/juliopreciado_/',
    twitter: 'https://twitter.com/Julio_Preciado_',
    spotify: 'https://open.spotify.com/artist/7yUfXtRIpBZK5bggQsQohI',
    deezer: 'https://www.deezer.com/mx/artist/309216',
    apple: 'https://music.apple.com/mx/artist/julio-preciado/2357835',
    soundcloud: 'https://soundcloud.com/julio-preciado-official',
    tidal: 'https://listen.tidal.com/artist/3624039',
    wiki: 'https://es.wikipedia.org/wiki/Julio_Preciado',
    ticketmaster:
      'https://www.ticketmaster.com/julio-preciado-tickets/artist/796690',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Julio%20Preciado&t=1655491640150',
    napster: 'https://web.napster.com/artist/julio-preciado',
  },
  {
    title: 'Lupillo Rivera',
    description:
      'Guadalupe Martín Rivera Saavedra conocido como Lupillo Rivera es un cantante y compositor de música regional, estadounidense de origen mexicano.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kuI9H6qIYevoWHaK6WAwVr0lxALer6cZA',
    price: '$50',
    coverImg: 'LupilloRivera.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://elreydelosborrachos.com/es/',
    youtube: 'https://www.youtube.com/channel/UCRClyr2rk75NdcEUtICewOA',
    facebook: 'https://www.facebook.com/LupilloRiveraOficial',
    instagram: 'https://www.instagram.com/LupilloRiveraOfficial/',
    twitter: 'https://twitter.com/LUPILLO_LUPILLO',
    spotify: 'https://open.spotify.com/artist/341CfLcUdaBGixB8IJjYwW',
    deezer: 'https://www.deezer.com/mx/artist/147304',
    apple: 'https://music.apple.com/mx/artist/lupillo-rivera/65432879',
    soundcloud: 'https://soundcloud.com/lupillo-rivera-official',
    tidal: 'https://listen.tidal.com/artist/3675105',
    wiki: 'https://es.wikipedia.org/wiki/Lupillo_Rivera',
    ticketmaster:
      'https://www.ticketmaster.com/lupillo-rivera-tickets/artist/786508',
    tiktok: 'https://www.tiktok.com/@lupilloriveraoficial?lang=es',
    napster: 'https://web.napster.com/artist/lupillo-rivera',
  },

  {
    title: 'Ariel Kamacho y Los Plebes del Rancho',
    description:
      'Los Plebes del Rancho de Ariel Camacho es una agrupación de música regional mexicana, especializado en el estilo del Sierreño-Banda. Son originarios de Guamúchil, Sinaloa. El grupo fue fundado en 2013 como Ariel Camacho y los Plebes del Rancho, por Ariel Camacho y su amigo César Sánchez. Firmaron originalmente con DEL Records en 2014, pero luego se retiraron en 2016 debido a disputas generadas con el incumplimiento del contrato y firmaron con JG Entertainment. El fallecimiento de Ariel Camacho provocó cambios en el grupo.',
    videourl:
      'http://youtube.com/playlist?list=PL16_wFPiPzHKJGkgBVgOnZolTNmLtVU4E',
    price: 50,
    coverImg: 'ArielKamacho.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    likes: 1000,
    openSpots: null,
    websiteurl: 'https://es.wikipedia.org/wiki/Ariel_Camacho',
    youtube: 'https://www.youtube.com/channel/UCzuCcWDyNg0SeqNiTZD9MqA',
    facebook: 'https://www.facebook.com/groups/277878853754154',
    instagram: 'https://www.instagram.com/arielcamacho_oficial/',
    twitter: 'https://twitter.com/ArielCamachoMx',
    spotify: 'https://open.spotify.com/artist/2Lxa3SFNEW0alfRvtdXOul',
    deezer: 'https://www.deezer.com/mx/artist/6229004',
    apple:
      'https://music.apple.com/mx/artist/ariel-camacho-y-los-plebes-del-rancho/904130604',
    soundcloud: 'https://soundcloud.com/arielcamachopromo',
    tidal: 'https://listen.tidal.com/artist/5863539',
    wiki: 'https://es.wikipedia.org/wiki/Ariel_Camacho',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@arielcamacho_official?lang=es',
    napster: 'https://web.napster.com/artist/art.172977623',
  },
  {
    title: 'Chuy Lizarraga',
    description:
      'Chuy Lizarraga es “La cara alegre de la banda”, así es conocido ahora el cantante oriundo de Mazatlán Sinaloa, México; nacido el 9 de Noviembre de 1976 y con sus largos 10 años de carrera artística ahora incursiona en el medio musical como solista después de haber militado por varias agrupaciones del mismo estilo musical. .',
    videourl:
      'http://youtube.com/playlist?list=PLnQJvKNB7Plq5WdeHT05UmZbinWq7X9BD',
    price: 53,
    coverImg: 'chuylizzarraga.jpg',
    category: 'Banda Instrumental',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.facebook.com/yochuylizarraga',
    youtube: 'https://www.youtube.com/channel/UCBiiKL2TfCpFVpwW-CJ0NCg',
    facebook: 'https://www.facebook.com/yochuylizarraga',
    instagram: 'https://www.instagram.com/yochuylizarraga/',
    twitter: 'https://twitter.com/yochuylizarraga',
    spotify:
      'https://open.spotify.com/artist/1DA8SLXtp8MMVpgaOWzMQr?si=aYLIs1_-Q8u0VWvPmNcDcw&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/324302',
    apple:
      'https://music.apple.com/mx/artist/chuy-liz%C3%A1rraga-y-su-banda-tierra-sinaloense/260821242',
    soundcloud: 'https://soundcloud.com/user-983297265',
    tidal: 'https://listen.tidal.com/artist/8197769',
    wiki: 'https://www.buenamusica.com/chuy-lizarraga/biografia',
    ticketmaster:
      'https://www.ticketmaster.com/chuy-lizarraga-tickets/artist/1665983',
    tiktok: 'https://www.tiktok.com/@chuylizarraga13?lang=es',
    napster: 'https://web.napster.com/artist/art.54718648',
  },
  {
    title: 'Banda MS',
    description:
      'La Banda Sinaloense MS de Sergio Lizárraga también conocida como Banda MS es una agrupación de música de banda sinaloense creada en la ciudad de Mazatlán, Sinaloa, en el año de 2003..',
    videourl:
      'http://youtube.com/playlist?list=PLQDuTkZkYAFkYOzl5ba0PIarAXME9D0jI',
    price: 50,
    coverImg: 'Banda-MS.jpg',
    category: 'Banda Instrumental',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandams.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCV6ZlWpVitOcQvaL_RFgu5w',
    facebook: 'https://www.facebook.com/BANDAMSOFICIAL',
    instagram: 'https://www.instagram.com/bandamsoficial/',
    twitter: 'https://twitter.com/BANDA_MS',
    spotify:
      'https://open.spotify.com/user/sergioliz%C3%A1rraga?si=Lo0706DmRWCdd17X7GHjWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/50683152',
    apple:
      'https://music.apple.com/mx/artist/banda-ms-de-sergio-liz%C3%A1rraga/413048014',
    soundcloud: 'https://soundcloud.com/bandasinaloensemsdesergiolizarraga',
    tidal: 'https://listen.tidal.com/artist/9097771',
    wiki: 'https://es.wikipedia.org/wiki/Banda_MS',
    ticketmaster: 'https://www.ticketmaster.com/artist/1571992',
    tiktok: 'https://www.tiktok.com/@banda_ms_fans_tr',
    napster:
      'https://web.napster.com/artist/banda-sinaloense-ms-de-sergio-lizarraga',
  },
  {
    title: 'Carin Leon',
    description:
      'Óscar Armando Díaz De León Huez, mejor conocido en el medio musical como Carín León, es un cantante, guitarrista y compositor mexicano. Nació en Hermosillo, Sonora, México, el 26 de julio de 1989. El cantante comenzó su carrera formando parte de distintas agrupaciones, tales como Los Reales y Grupo Arranke. En el 2018 comenzó con su carrera como solista, logrando un amplio reconocimiento en la escena en poco tiempo.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nJNKtjy1IdtjM71VR780T24y2MetYuNSg',
    coverImg: 'carinleon.jpg',
    category: 'Banda Instrumental',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCywpKTAjNjHv53c0TX3K_KQ',
    facebook: 'https://www.facebook.com/carinleonoficial',
    instagram: 'https://www.instagram.com/carinleonoficial/',
    twitter: 'https://twitter.com/carinleonofi',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/14539467',
    apple: 'https://music.apple.com/mx/artist/carin-leon/1370196486',
    soundcloud: 'https://soundcloud.com/carinleon',
    tidal: 'https://listen.tidal.com/artist/9739372',
    wiki:
      'https://es.wikipedia.org/w/index.php?search=Carin+Leon&title=Especial%3ABuscar&go=Ir&ns0=1&ns100=1&ns104=1',
    ticketmaster: 'https://www.ticketmaster.com/calibre-50-tickets/artist/183',
    tiktok: 'https://www.tiktok.com/@oficialcalibre50?lang=es',
    napster: 'https://web.napster.com/artist/calibre-50',
  },
  {
    title: 'Los Tucanes de Tijuana',
    description:
      'Los Tucanes de Tijuana son una agrupación de música regional mexicana liderada por Mario Quintero Lara, especializada en el estilo norteño y corrido. Fue creada en la ciudad de Tijuana, Baja California, México en el año de 1983 y formalizada en 1987. Durante su carrera han cosechado diversos premios y reconocimientos, entre los que destacan un Grammy Latino en 2012 por el álbum 365 días, cinco nominaciones a los Premios Grammy, nueve a los Premios Lo Nuestro y múltiples Premios BMI para Quintero como compositor. Son la primera banda de música norteña en ganar un premio de cine internacional al obtener el galardón «Un Certain Regard Angel Film» en el Festival Internacional de Cine de Mónaco por su participación en el documental Los ilegales. En 2008 el grupo recibió una estrella en el Paseo de la Fama de Las Vegas.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_kVyzS5YvKNUaKdLtqU0C7djL5D8vMM52U',
    price: '$50',
    coverImg: 'TucanesdeTijuana.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lostucanesdetijuana.com/',
    youtube: 'https://www.youtube.com/channel/UCQfVQ8ycXpY9zzEhci6-0wg',
    facebook: 'https://www.facebook.com/lostucanesdetijuana',
    instagram: 'https://www.instagram.com/lostucanesdetijuana/',
    twitter: 'https://twitter.com/LosTucanesdeTJ',
    spotify: 'https://open.spotify.com/artist/014WIDx7H4BRCHB1faiisK',
    deezer: 'https://www.deezer.com/mx/artist/192548',
    apple: 'https://music.apple.com/mx/artist/los-tucanes-de-tijuana/90197',
    soundcloud: 'https://soundcloud.com/los-tucanes-de-tijuana-official',
    tidal: 'https://listen.tidal.com/artist/17521',
    wiki: 'https://es.wikipedia.org/wiki/Los_Tucanes_de_Tijuana',
    ticketmaster:
      'https://www.ticketmaster.com/los-tucanes-de-tijuana-tickets/artist/764354',
    tiktok: 'https://www.tiktok.com/@lostucanesdetijuana?lang=es',
    napster: 'https://web.napster.com/artist/los-tucanes-de-tijuana',
  },
  {
    title: 'Gerardo Ortiz',
    description:
      'Gerardo Ortiz Medina es un cantante y compositor mexicano-estadounidense de música regional mexicana, especializado en los estilos de banda, norteño, norteño-banda, sierreño, sierreño-banda y mariachi. Es especialmente famoso por sus corridos progresivos.',
    videourl:
      'http://youtube.com/playlist?list=PLByjh6DfxKTKdej2wbJqw6UmA2KHszPyG',
    price: 50,
    coverImg: 'gerardoOrtiz.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.comerecallado.com/',
    youtube: 'https://www.youtube.com/channel/UCfxLuDIVfwArsz5waYMMLDQ',
    facebook: 'https://www.facebook.com/GerardoOrtizNet',
    instagram: 'https://www.instagram.com/gerardoortizoficial/',
    twitter: 'https://twitter.com/gerardoortiznet',
    spotify: 'https://GerardoOrtiz.lnk.to/ElComando... ',
    deezer: 'https://www.deezer.com/mx/artist/468783',
    apple: 'https://music.apple.com/mx/artist/gerardo-ortiz/339929849',
    soundcloud: 'https://soundcloud.com/gerardoortizofficial',
    tidal: 'https://listen.tidal.com/artist/6174510',
    wiki: 'https://es.wikipedia.org/wiki/Gerardo_Ortiz',
    ticketmaster:
      'https://www.ticketmaster.com/gerardo-ortiz-tickets/artist/1386548',
    tiktok: 'https://www.tiktok.com/@....gerardoortizoficial?lang=es',
    napster: 'https://web.napster.com/artist/gerardo-ortiz',
  },
  {
    title: 'Neto Bernal',
    description:
      'Ernesto Bernal Contreras, mejor conocido como Neto Bernal, es un cantante de música regional, procedente de Mexicali, Baja California, México. El joven, que nació el 1 de octubre de 1997, comparte junto con su madre el origen en la ciudad fronteriza, Mexicali, mientras que su padre viene de Sonora. El artista, de tan sólo 19 años, creció allí junto con su hermano menor, Alexis Bernal.',
    videourl:
      'http://youtube.com/playlist?list=PLaSnKw4FFePPPOXaDVDk3SObo2PbAeqbp',
    price: 50,
    coverImg: 'NetoBernal.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC0tWKR_TBob21vJTEO67UfA',
    facebook: 'https://www.facebook.com/NetoBernalOficial',
    instagram: 'https://www.instagram.com/netobernaloficial/?hl=en',
    twitter: 'https://twitter.com/elnetillobernal',
    spotify:
      'https://open.spotify.com/artist/0X8PwlFMDK85fIadwq3cA5?si=wQEx4ri9Q_yhkyqLdv1t5w&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/10693157',
    apple: 'https://music.apple.com/mx/artist/neto-bernal/1133791209',
    soundcloud: 'https://soundcloud.com/neto-bernal',
    tidal: 'https://listen.tidal.com/artist/7990895',
    wiki:
      'https://laletrade.com/biografias/regional-mexicano/18635-neto-bernal',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@netobernal?lang=es',
    napster: 'https://web.napster.com/artist/neto-bernal',
  },
  {
    title: 'El Tigrillo Palma',
    description:
      'El Tigrillo Palma” nació en El Baral, Guasave, municipio del estado de Sinaloa, a los seis años de edad, Efrén descubrió el gusto por el canto, y a los diez años ya había grabado su primer disco en un grupo que formó con su hermana y que se llamaba “Los Vaqueritos de El Baral”. En ese tiempo, Efrén y su hermana vivían en la ciudad de México, D.F., ya que pa… Más información',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_luleD3J1PjmzogRZZOegcd6scqvgXF0ys',
    price: '$50',
    coverImg: 'TigrilloPalma.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCrnyN77Ui3c66i00uym7oWg',
    facebook: 'https://www.facebook.com/ElTigrilloPalmaOficial',
    instagram: 'https://www.instagram.com/eltigrillopalma/',
    twitter: 'https://twitter.com/eltigrillopalm2',
    spotify: 'https://open.spotify.com/artist/2usAJ9Mtrw570XlQ5MHJek',
    deezer: 'https://www.deezer.com/mx/artist/256206',
    apple: 'https://music.apple.com/mx/artist/el-tigrillo-palma/211631447',
    soundcloud: 'https://soundcloud.com/el-tigrillo-palma',
    tidal: 'https://listen.tidal.com/artist/3581077',
    wiki: 'https://www.buenamusica.com/el-tigrillo-palma/biografia',
    ticketmaster:
      'https://www.ticketmaster.com/el-tigrillo-palma-tickets/artist/1623190',
    tiktok: 'https://www.tiktok.com/@eltigrillopalmaoficial?lang=es',
    napster: 'https://web.napster.com/artist/el-tigrillo-palma',
  },
  {
    title: 'Tercer Elemento',
    description:
      'T3R Elemento fue formado por tres jóvenes de origen mexicano y un cubano en Las Vegas, Estados Unidos. Dado que realizaron dos intentos previos de formar un grupo, ante esos fracasos decidieron llamar al grupo T3R Elemento en referencia a que era la tercera vez que lo intentaban.Kristopher Nava fue el fundador del grupo. En 2016 publicaron su primer disco llamado Rafa Caro en Vivo y en 2018 publicaron the green trip. En 2018 fueron nominados por Álbum Favorito-Regional Mexicano a los Premios Latin American Music debido a su creciente popularidad la cual se ha dado en plataformas como Youtube. Sus temas han sido incluidos en la lista Billboard en los Estados Unidos .',
    videourl:
      'http://youtube.com/playlist?list=PLkzsyPO-ybMTw1qR-AdG9_urQq3-nfr6b',
    price: 50,
    coverImg: '3erElemento.jpg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    youtube: 'https://www.youtube.com/channel/UCucxqba8ReybE6oIs9D1_sg',
    facebook: 'https://www.facebook.com/t3relemento/',
    instagram: 'https://www.instagram.com/t3relementooficial/',
    twitter: 'https://twitter.com/t3r_elemento?lang=en',
    spotify: 'https://open.spotify.com/artist/34nbQa7Hug9DYkRJpfKNFv',
    deezer: 'https://www.deezer.com/mx/artist/72293892',
    apple: 'https://music.apple.com/mx/artist/t3r-elemento/1099155524',
    soundcloud: 'https://soundcloud.com/tercer-elemento',
    tidal: 'https://listen.tidal.com/album/129171726',
    wiki: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@t3relementoofficial?lang=es',
    napster: 'https://web.napster.com/artist/t3r-elemento',
    bandera: '/tercerelemento',
  },
  {
    title: 'Grupo 360',
    description:
      'Conjunto de Musica Norteña ,Sierreña y Comica de la ciudad de Tijuana Baja California .SEGUIMOS LABORANDO,AQUILESORGANIGRAMA, THE PANIN ,LA BArDA , ZONA DE GUERRA ,SOMOS DE ACCIÓN ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=PLF-cRDh2mrlXPMOHbKSqgWtqH2kZjNMvA',
    price: 80,
    coverImg: 'tres60.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/grupo360oficiall',
    instagram: 'https://www.instagram.com/grupo360oficial_/',
    twitter: 'https://twitter.com/GrupoTr3s60',
    spotify: 'https://open.spotify.com/artist/2h9VgLswbVrXfdjW8UVqHC',
    deezer: 'https://www.deezer.com/mx/artist/5159420',
    apple: 'https://music.apple.com/mx/artist/grupo-360/1313996832',
    soundcloud: 'https://soundcloud.com/grupo360official',
    tidal: 'https://listen.tidal.com/artist/5679524',
    wiki: 'https://www.buenamusica.com/grupo-360/biografia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupo360oficiall?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00F2VL1PA/grupo-360',
  },

  {
    title: 'Los Hijos de Garcia',
    description:
      'Los Hijos De Garcia Proveniente de la ciudad de Las Vegas, Nevada ha captado la atención por su peculiar manera para interpretar sus corridos y canciones. A Los Hijos de García los categorizan como “Regional Urbano”, debido a sus líricas y manera de tocar la música norteña. Su sonido único de la acordeón los hacen notar, lo cual se demuestra en sus éxitos underground como son “18 Libras”, “Los Lujos de La Vida”, “El Tuna”, entre otros más.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlWnKytmIAEKPrCV4ONP_1VP',
    price: 50,
    coverImg: 'LosHijosDeGarcia.jpeg',
    category: 'Norteño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.lyricasdelrap.com/2020/12/letra-mundo-desgraciado-los-hijos-de.html',
    youtube: 'https://www.youtube.com/channel/UCkyH1NljG_ebXZeTuz9wF9w',
    facebook: 'https://www.facebook.com/LosHijosdeGarcia',
    instagram: 'https://www.instagram.com/loshijosdegarcia/',
    twitter: 'https://twitter.com/hijosdegarcia',
    spotify: 'https://open.spotify.com/artist/3Aw1WaC6Xm8cOOppvXcnfa',
    deezer: 'https://www.deezer.com/mx/artist/13959247',
    apple: 'https://music.apple.com/mx/artist/los-hijos-de-garcia/1104717895',
    soundcloud: 'https://soundcloud.com/loshijosdegarcia',
    tidal: 'https://listen.tidal.com/artist/9417940',
    wiki:
      'https://www.lyricasdelrap.com/2020/12/letra-mundo-desgraciado-los-hijos-de.html',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@hijosdegarcia?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B01EE9PSVI/los-hijos-de-garcia',
  },
  {
    title: 'Herencia de Patrones',
    description:
      'Biografía de Herencia de Patrones Inicios en la vida de Herencia de Patrones . Herencia de Patrones es un grupo exponente del regional mexicano, banda y corridos originarios del norte de México quienes fusionaron la música urbana.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL-Kd808w8x8f-kFj7_HyT0_',
    price: 50,
    coverImg: 'HerenciaDePatrones.jpeg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandera.netlify.app/',
    youtube: 'https://www.youtube.com/channel/UCkyH1NljG_ebXZeTuz9wF9w',
    facebook: 'https://www.facebook.com/Herencia-de-patrones-110852401173688',
    instagram: 'https://www.instagram.com/herenciadepatronesoficial/',
    twitter: 'https://twitter.com/joru_hp',
    spotify: 'https://open.spotify.com/artist/1Q6SZxTvaE3HhslV0iXbI6',
    deezer: 'https://www.deezer.com/mx/artist/13387589',
    apple: 'https://music.apple.com/mx/artist/herencia-de-patrones/1295294734',
    soundcloud: 'https://soundcloud.com/herenciadepatrones',
    tidal: 'https://listen.tidal.com/artist/9180832',
    wiki: '',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@herencia.de.patronesfp?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B076BYDRQB/herencia-de-patrones',
  },
  {
    title: 'Grupo Marca Registrada',
    description:
      'Marca Registrada es una agrupación originaria de Culiacán que se fundó durante 2014 y sus integrantes son Fidel Castro, Ángel Mondragón, Fernando Medina, Sergio Espinozam y Rudy Mascareño. Estudié Ciencias de la Comunicación en la Universidad Autónoma de Sinaloa..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nNvXohVC7ZkjMAR4Fv-2v8j2brqIkFcyU',
    price: 50,
    coverImg: 'MarcaRegistrada.jpeg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandera.netlify.app/',
    youtube: 'https://www.youtube.com/channel/UCJrXHTk9F1cFhjc88PALfgA',
    facebook: 'https://www.facebook.com/MarcaRegistradaMROficial',
    instagram: 'https://www.instagram.com/herenciadepatronesoficial/',
    twitter: 'https://twitter.com/grupo_marcareg',
    spotify: 'https://open.spotify.com/artist/1gW6pz5n1aK249L0GvfQCC',
    deezer: 'https://www.deezer.com/mx/artist/9095678',
    apple:
      'https://music.apple.com/mx/artist/grupo-marca-registrada/1051127522',
    soundcloud: 'https://soundcloud.com/grupomarcaregistradaofficial',
    tidal: 'https://listen.tidal.com/artist/7317857',
    wiki:
      'https://www.debate.com.mx/show/Jesus-Ortiz-y-el-Grupo-Marca-Registrada-tiene-mancuerna-de-lujo-en-una-noche-inolvidable-20220227-0293.html',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00BTAG3JW/marca-registrada',
  },
  {
    title: 'Los Dos Carnales',
    description:
      'Los Dos Carnales comenzaron su carrera desde muy jóvenes, respectivamente, pero luego empezaron a realizar show de payasos en donde tocaban sus canciones. “Tocando en sí tenemos un buen rato, mi hermano empezó a los 9 años y yo tenía 14. Cuando empezamos en este rollo de la música, nos tocó ser payasos. Hacíamos show y tocabamos”, mencionó Poncho Quezada  .',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mw2bd-OH5SC66vMYVJWLGnPHXcY2s64rg',
    price: 50,
    coverImg: 'losDosCarnales.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCHU1sgg0A354C5-jtunzHPQ',
    facebook: 'https://www.facebook.com/losdoscarnales',
    instagram: 'https://www.instagram.com/los2carnales/',
    twitter: 'https://twitter.com/DosCarnales',
    spotify: 'https://open.spotify.com/playlist/4BHoJpgGu79T7pfltNBIS9',
    deezer: 'https://www.deezer.com/mx/artist/14858167',
    apple: 'https://music.apple.com/mx/artist/los-dos-carnales/1463668529',
    soundcloud: 'https://soundcloud.com/losdoscarnales',
    tidal: 'https://listen.tidal.com/artist/3665785c',
    wiki:
      'https://www.laletrade.com/biografias/regional-mexicano/20821-los-dos-carnales',
    ticketmaster:
      'https://www.ticketmaster.com/los-dos-carnales-tickets/artist/2778512',
    tiktok: 'https://www.tiktok.com/@losdoscarnales?lang=es',
    napster: 'https://web.napster.com/artist/los-dos-carnales',
  },

  {
    title: 'Los de La Noria',
    description:
      'Los de la noria está conformado por: En el 2015 la empresa de representación artística Andaluz Music presenta a su nuevo y talentoso grupo Los de la Noria un conjunto de 3 jóvenes músicos Sinaloenses que unen sus acordes para dar un sonido dinámico y fresco al estilo sierreño.',
    videourl:
      'https://youtube.com/playlist?list=PLx8xu1SPDGv_inyAMZ605De9lbYzxvR_u',
    price: '$50',
    coverImg: 'LosDeLaNoria.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://losdelanoria.com/',
    youtube: 'https://www.youtube.com/channel/UC0ueTmm7Om50V566Y5OGMXA',
    facebook: 'https://www.facebook.com/LosDeLaNoria',
    instagram: 'https://www.instagram.com/losdelanoriaoficial/',
    twitter: 'https://twitter.com/losdelanoria',
    spotify: 'https://open.spotify.com/artist/4S5t9CJJNjl6zEp5e1OMiO',
    deezer: 'https://www.deezer.com/mx/artist/11474306',
    apple: 'https://music.apple.com/mx/artist/los-de-la-noria/1165820147',
    soundcloud: 'https://soundcloud.com/losdelanoria',
    tidal: 'https://listen.tidal.com/artist/8291166',
    wiki: 'https://losdelanoria.com/',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@losdelanoria?lang=es',
    napster: 'https://web.napster.com/artist/art.242132068',
  },
  {
    title: 'Grupo Firme',
    description:
      'Grupo Firme es una agrupación de música regional mexicana. Empezaron cómo grupo norteño, pero también han grabado con banda sinaloense. Ha recibido nominaciones para una variedad de programas que incluyen: Premios Juventud y Premio Lo Nuestro, resultando ganador en 2021 de este último con cinco distinciones.El fundador de la agrupación musical es el Choixcense de 32 años, Jairo Corrales el cual creó a la banda después de conocerse en un evento en la ciudad de Tijuana.',
    videourl: 'http://youtube.com/playlist?list=UUFlC8U0Uif2hF0FIgsSIIYQ',
    price: 50,
    coverImg: 'grupoFirme.jpg',
    category: 'Conjunto',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://grupofirmecorp.com/',
    youtube: 'https://www.youtube.com/channel/UCFlC8U0Uif2hF0FIgsSIIYQ',
    facebook: 'https://www.facebook.com/GrupoFirmeOficial',
    instagram: 'https://www.instagram.com/grupofirme/',
    twitter: 'https://twitter.com/GrupoFirme',
    spotify:
      'https://open.spotify.com/artist/1dKdetem2xEmjgvyymzytS?si=tWOpv5SYT7CkjsAVgCHHjw&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/grupo-firme/1086320682',
    soundcloud: 'https://soundcloud.com/grupofirme',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://en.wikipedia.org/wiki/Grupo_Firme',
    ticketmaster:
      'https://www.ticketmaster.com/grupo-firme-tickets/artist/2724935',
    tiktok: 'https://www.tiktok.com/@grupofirme?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B01BZRCST2/grupo-firme',
  },
  {
    title: 'Eden Muñoz',
    description:
      'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
    coverImg: 'EdenMuñoz.jpg',
    category: 'Regional Mexicano',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
    facebook: 'https://www.facebook.com/EdenMunozOficial/',
    instagram: 'https://www.facebook.com/EdenMunozOficial/',
    twitter: 'https://twitter.com/edenmunozof',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/4414006',
    apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
    soundcloud: 'https://soundcloud.com/edenmunoz-music',
    tidal: 'https://listen.tidal.com/artist/12062930',
    wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
    ticketmaster:
      'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
    tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B003X2YJR2/eden-mu%C3%B1oz',
  },

  {
    title: 'El Mimoso',
    description:
      'Luis Antonio Lopez Flores mejor conocido como “El Mimoso” nació el 21 de noviembre de 1979 en Concordia, Sinaloa. El tercero de cuatro hermanos, sus padres José Cruz López Salazar y Guadalupe Flores Rodríguez; vieron que desde muy chico le nació el gusto por el canto, en su natal Concordia.',
    videourl:
      'http://youtube.com/playlist?list=PLO8c1UjEPsQvoDnx4IMtFBHm0SljWDWj6',
    price: '$50',
    coverImg: 'ElMimoso.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCOMwD1nrJZ6-0Ml5KyanVSg',
    facebook: 'https://www.facebook.com/mimosoyooficial?_rdr=p',
    instagram: 'https://www.instagram.com/mimosoyo/',
    twitter: 'https://twitter.com/mimosoyo',
    spotify: 'https://open.spotify.com/artist/7AUgYiThuW80zSOwY7Ub2g',
    deezer: 'https://www.deezer.com/mx/artist/1595755',
    apple:
      'https://music.apple.com/mx/artist/el-mimoso-luis-antonio-l%C3%B3pez/434844110',
    soundcloud: 'https://soundcloud.com/el-mimoso-luis-antonio-lopez',
    tidal: 'https://listen.tidal.com/artist/4672027',
    wiki: 'https://www.musica.com/letras.asp?biografia=36424',
    ticketmaster:
      'https://www.ticketmaster.com/el-mimoso-tickets/artist/2833728',
    tiktok: 'https://www.tiktok.com/@mimosoyo?lang=es',
    napster: 'https://web.napster.com/artist/art.45943024',
  },
  {
    title: 'Marca-MP',
    description:
      'Marca MP es una agrupación de música regional mexicana, principalmente de banda, mariachi y ranchera. Incursionaron al mundo de la música profesionalmente en 2019 con su sencillo “El Güero”, que interpretaron al lado de Grupo Firme. Se ganaron al público desde su primer sencillo, muestra de ello, es que “El Güero” permaneció en la cima de la lista como el video grupero más visto de ese año.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lbDCxSZ88e4zNta116vD4z3LCWJovkhmA',
    price: 50,
    coverImg: 'marca-mp.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: ' https://www.facebook.com/grupomarcamp',
    youtube: 'https://www.youtube.com/c/MarcaMP​',
    facebook: ' https://www.facebook.com/grupomarcamp/​',
    instagram: 'https://instagram.com/mmp_oficial​',
    twitter: 'https://twitter.com/marcampoficial',
    spotify: 'https://open.spotify.com/artist/44mEtidu0VdRkIqO4IbkNa',
    deezer: 'https://www.deezer.com/mx/artist/61384072',
    apple: 'https://music.apple.com/mx/artist/marca-mp/1493050737',
    soundcloud: 'https://soundcloud.com/mmp_oficial',
    tidal: 'https://listen.tidal.com/artist/15257396',
    wiki:
      'https://popnable.com/mexico/artists/52383-marca-mp/biography-and-facts#:~:text=Marca%20Mp%20Wiki%20Latin%20group%20with%20a%20notable,prominently%20performed%20in%20Mexico%20and%20the%20United%20States.',
    ticketmaster:
      'https://www.ticketmaster.com/marca-mp-tickets/artist/2802450',
    tiktok: 'https://www.tiktok.com/@mmp.oficial?lang=es',
    napster: 'https://web.napster.com/artist/marca-mp',
  },
  {
    title: 'Peso Pluma',
    description:
      'Hassan Emilio Kabande Laija (Zapopan, Jalisco; 15 de junio de 1999), conocido como Peso Pluma, es un cantante mexicano. Se especializa en el subgénero de corridos tumbados.Su fama internacional comenzó en 2022, después de colaborar con cantantes como Luis R. Conriquez y Natanael Cano.3​ Algunos de sus éxitos son «El Belicón», «Siempre pendientes», «PRC», «Por las noches», «AMG», «Ella baila sola» y «La bebé (Remix)». Debido a su fama ha logrado colaborar también con artistas de éxito mundial como Becky G,5​ Nicki Nicole6​, Marshmello,7​y Ovy on the Drums.Su estilo musical se caracteriza por un toque acústico con influencias de la música urbana como el trap mezclados con sonido de los corridos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXbLwh-AdLa1iHtuW_y_J9b',
    price: 80,
    coverImg: 'pesopluma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rioranchoeventscenter.com/event/peso-pluma/',
    youtube: 'https://www.youtube.com/channel/UCzrM_068Odho89mTRrrxqbA',
    facebook: 'https://www.facebook.com/PesoPlumaOficial',
    instagram: 'https://www.instagram.com/pesopluma/',
    twitter: 'https://twitter.com/ElPesoPluma',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/80365122',
    apple: 'https://music.apple.com/mx/artist/peso-pluma/1500139475?l=en',
    soundcloud: 'https://soundcloud.com/pesopluma',
    tidal: 'https://listen.tidal.com/artist/17614478',
    wiki: 'https://es.wikipedia.org/wiki/Peso_Pluma_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com/peso-pluma-tickets/artist/2979041',
    tiktok: 'https://www.tiktok.com/@therealpesopluma',
    napster: 'https://music.amazon.com.mx/artists/B082C6RXYG',
  },
  {
    title: 'Junior H',
    description:
      'Antonio Herrera Pérez (Cerano, Guanajuato, México; 23 de abril de 2000) mejor conocido como Junior H, es un cantante y compositor mexicano. Se especializa en el subgénero de corridos tumbados..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVQKFIhIcWGLTCXsFlB7b5K',
    price: 80,
    coverImg: 'juniorh.jpeg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://elpais.com/mexico/2023-05-17/junior-h-el-chico-triste-que-triunfa-a-base-de-corridos-tumbados.html',
    youtube: 'https://www.youtube.com/channel/UCwA5HHGsAlpU1UlhwWD52uA',
    facebook: 'https://www.facebook.com/juniorhoficial/photos',
    instagram: 'https://www.instagram.com/juniorh/',
    twitter: 'https://twitter.com/juniorh_oficial',
    spotify: 'https://open.spotify.com/artist/7Gi6gjaWy3DxyilpF1a8Is',
    deezer: 'https://www.deezer.com/mx/artist/54047652',
    apple: 'https://music.apple.com/mx/artist/junior-h/1442059565',
    soundcloud: 'https://soundcloud.com/juniorh-music',
    tidal: 'https://listen.tidal.com/artist/10590337',
    wiki: 'https://es.wikipedia.org/wiki/Junior_H',
    ticketmaster:
      'https://www.ticketmaster.com/junior-h-tickets/artist/2820826',

    tiktok: 'https://www.tiktok.com/@juniorh_h',
    napster: 'https://music.amazon.com.mx/artists/B07KFMJCTH/junior-h',
  },

  {
    title: 'El Fantasma',
    description:
      "Alexander García was born in Las Canas, Durango. He worked as a gardener for six years before becoming a singer.He performs regional Mexican music[2] and corrido songs about hardworking people. On social media, fans nickname him the King from the Underground.His nickname began because his friends and family call him El Fanta.[3] He was finalist at the 2019 Billboard Latin Music Awards.In 2019, El Fantasma's single Encantadora charted number 1 on Regional Mexican Airplay. On August 14, 2021, his single Soy Buen Amigo charted number 1 on Regional Mexican Airplay. It also charted 27 on Hot Latin Songs",
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLaKik8hFaPZGymkNFbeU8zvZVj0QvugDV',
    price: 80,
    coverImg: 'elfantasma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC9kHcAu8pGPY7PyKOnK7pfg',
    facebook: 'https://www.facebook.com/elfantasmaoficial/',
    instagram: 'https://www.instagram.com/elfantasmaoficial/',
    twitter: 'https://twitter.com/El_Fanta45',
    spotify: 'https://open.spotify.com/artist/0my6Pg4I28dVcZLSpAkqhv',
    deezer: 'https://www.deezer.com/mx/artist/5230088',
    apple: 'https://music.apple.com/mx/artist/el-fantasma/1245916228',
    soundcloud: 'https://soundcloud.com/elfantasma-music',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/El_Fantasma_(singer)',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@elfantasma.oficial',
    napster: 'https://music.amazon.com.mx/artists/B003DHLCDQ/el-fantasma',
  },
  {
    title: 'Natanael Cano',
    description:
      'Natanael Cano, nombre artístico de Nathanahel Rubén Cano Monge (Hermosillo, 27 de marzo de 2001), es un rapero y cantautor del género regional mexicano. Es el creador del concepto corridos tumbados, nombre de uno de sus discos. Su música fusiona géneros de música regional mexicana tales como el corrido y sierreño-banda con rap, trap, y hip-hop ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natanael.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UCW2_ESwKrYgSjqQjrR09n0g',
    facebook: 'https://www.facebook.com/profile.php?id=100077546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07F747D1Q/natanael-cano',
  },
  {
    title: 'Rancho Humilde Records',
    description:
      'Jimmy Humilde, es considerado como “uno de los hombres que más misterio genera entorno a su persona” en el mundo del espectáculo musical.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL90dAa2YBl3WBt_zO-drgdq',
    price: 50,
    coverImg: 'RanchoHumilde.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://ranchohumildestore.com/',
    youtube: 'https://www.youtube.com/c/RanchoHumildeoficial',
    facebook: 'https://www.facebook.com/ranchohumildeofficial',
    instagram: 'https://www.instagram.com/ranchohumilde/',
    twitter: 'https://twitter.com/ranchohumilde',
    spotify: 'https://open.spotify.com/artist/7iSpMTdILobashwyZorN25',
    deezer: 'https://www.deezer.com/mx/artist/89145252',
    apple: 'https://music.apple.com/mx/artist/rancho-humilde/1077575821',
    soundcloud: 'https://soundcloud.com/ranchohumilde',
    tidal: 'https://listen.tidal.com/artist/14100262',
    wiki:
      'https://www.latimes.com/espanol/entretenimiento/articulo/2021-05-21/rancho-humilde-records-con-sede-en-downey',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://music.amazon.com.mx/albums/B08QHZ673C',
  },
  {
    title: 'DEL Records',
    description:
      'DEL Records es un sello discográfico estadounidense de música en español fundado por Ángel del Villar en 2008. Su sede se encuentra en el suburbio de Bell Gardens en Los Ángeles, California, e incluye DEL Records, DEL Publishing, DEL Studios y DEL Entertainment, los cuales se centran en la música regional mexicana, específicamente en géneros del pacífico mexicano cómo la banda sinaloense, el norteño estilo pacífico, el norteño-banda, el sierreño, el sierreño-banda y el mariachi. DEL Records ha dado inicio a carreras musicales de artistas que mantienen actualmente buen desempeño comercial como Tercer Elemento, Lenin Ramírez, Ulices Chaidez, Luis Carrazco, entre otros. DEL Records y sus artistas son ganadores frecuentes en los Premios Billboard de la música latina..',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mhD873MzMdsLA0BGNtyoc_7znFASWHWUI',
    price: '$50',
    coverImg: 'DELrecords.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.delrecords.com/',
    youtube: 'https://www.youtube.com/user/DELRECORDS',
    facebook: 'https://www.facebook.com/DelRecordsOficial',
    instagram: 'https://www.instagram.com/delrecords/',
    twitter: 'https://twitter.com/delrecords',
    spotify: 'https://open.spotify.com/user/del_records',
    deezer: 'https://www.deezer.com/mx/artist/7655148',
    apple: 'https://music.apple.com/mx/curator/del-records/1171184109',
    soundcloud: 'https://soundcloud.com/delrecordsoficial',
    tidal: 'https://listen.tidal.com/artist/9548427',
    wiki: 'https://es.wikipedia.org/wiki/DEL_Records',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@delrecordsofficial?lang=es',
    napster: 'https://web.napster.com/artist/del-records',
  },
  {
    title: 'Luis R Conriquez',
    description:
      'Luis R Conriquez es un compositor que se hizo conocido entre el público gracias a su estilo interpretativo, la honestidad de sus letras, sus mensajes de superación personal y un talento innato para componer corridos modernos especializado en corridos bélicos y otros géneros del estilo de Sinaloa. Alcanzaría la fama en 2023 por colaborar con la banda mexicana La Adictiva y el cantante Peso Pluma, en los sencillos "JGL" y "Siempre Pendientes", respectivamente. Desde entonces, también ha grabado y lanzado algunas canciones en el género urbano latino.',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mvY17MuqZOMYb6-wT6NdqOmrRkYYQi1iw&si=YLa0xduq3a9dHCeo',
    price: 80,
    coverImg: 'luisrconriquez.jpeg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://kartelmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCFwuPQIpFbDlcQmwAc-jW8g',
    facebook: 'https://www.facebook.com/profile.php?id=100050488239777',
    instagram: 'https://www.instagram.com/luisrconriquez.fan.page/',
    twitter: 'https://twitter.com/LuisRConriquez',
    spotify: 'https://open.spotify.com/artist/0pePYDrJGk8gqMRbXrLJC8',
    deezer: 'https://www.deezer.com/en/artist/14004075',
    apple: 'https://music.apple.com/mx/artist/luis-r-conriquez/1252271456',
    soundcloud: 'https://soundcloud.com/luisrconriquez',
    tidal: 'https://listen.tidal.com/artist/9471448',
    wiki: 'https://en.wikipedia.org/wiki/Luis_R_Conriquez',
    ticketmaster:
      'https://www.ticketmaster.com/luis-r-conriquez-tickets/artist/2832072',
    tiktok: 'https://www.tiktok.com/@luisrconriquezoficial?lang=es',
    napster: 'https://us.napster.com/artist/luis-r-conriquez',
  },
  
  {
    title: 'Fuerza Regida',
    description:
      'FUERZA REGIDA es un grupo de música Regional Mexicana, conformado por Jesús Ortiz Paz, Samuel Jaimez, Khrystian Ramos y José, originarios de Sinaloa, México. ',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mbBjDr6BF9efxuubR7uCa4j7nO3rxXlL4',
    price: '$50',
    coverImg: 'FuerzaRegida.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://fuerzaregida.shop/',
    youtube: 'https://www.youtube.com/channel/UCFLI6KAjrKRtydD7KqiYvAw',
    facebook: 'https://www.facebook.com/fuerzaregidaoficial',
    instagram: 'https://www.instagram.com/fuerzaregidaoficial/',
    twitter: 'https://twitter.com/FuerzaRegida1',
    spotify: 'https://open.spotify.com/artist/0ys2OFYzWYB5hRDLCsBqxt',
    deezer: 'https://www.deezer.com/mx/artist/13916083',
    apple: 'https://music.apple.com/mx/artist/fuerza-regida/1275690475',
    soundcloud: 'https://soundcloud.com/fuerzaregida',
    tidal: 'https://listen.tidal.com/artist/9435954',
    wiki: 'https://www.buenamusica.com/fuerza-regida/biografia',
    ticketmaster: 'https://www.ticketmaster.com/artist/2499958',
    tiktok: 'https://www.tiktok.com/@fuerzaregidaoficial?lang=es',
    napster: 'https://web.napster.com/artist/fuerza-regida',
  },
]

export default CardData1
