const CardData28 = [
    {
      title: 'The Chi-Lites',
      description:
        'The Chi-Lites se formó en Chicago como un grupo de doo wop a fines de la década de 1950 mientras sus miembros estudiaban en la escuela preparatoria. Los integrantes originales fueron el cantante principal Eugene Record, Robert «Squirrel» Lester, Clarence Johnson, Burt Bowen y Eddie Reed, de The Chanteurs.3​ Todos asistieron a la preparatoria Hyde Park excepto Record. El grupo actuó por primera vez en un espectáculo de talentos escolares y poco después lanzaron su primer sencillo «Youve Got a Great Love» escrito por Johnson. Bowen dejó el grupo y fue reemplazado por Sollie McElroy de The Flamingos, quien pronto fue sustituido por Marshall Thompson de The Desideros. En 1960, Reed murió y Thompson contrató a Creadel Red Jones, también miembro de The Desideros. A principios de 1964, el grupo cambió su nombre a The Hi-Lites y lanzó una canción titulada Im So Jealous con Record a la cabeza',
      videourl:
        'https://youtube.com/playlist?list=PL3BC8DD56AA15B046&si=krDSTsnk6_3zghon',
      price: 50,
      coverImg: 'chi-lites.jpg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.chi-litesofficial.com/',
      youtube: 'https://www.youtube.com/channel/UCOA3-s8Kv-ufvgMnYLmTMsg',
      facebook: 'https://www.facebook.com/TheChiLitesOfficial',
      instagram: 'https://www.instagram.com/thechilitesofficial/',
      twitter: 'https://x.com/therealchilites',
      spotify: 'https://open.spotify.com/artist/7BFr36uI1dzJyo6tpa5Ued?autoplay=true',
      deezer: 'https://www.deezer.com/us/artist/123530',
      apple: 'https://music.apple.com/mx/artist/the-chi-lites/79637849',
      soundcloud: 'https://soundcloud.com/the-chi-lites',
      tidal: 'https://listen.tidal.com/artist/3519252',
      wiki: 'https://es.wikipedia.org/wiki/The_Chi-Lites',
      ticketmaster:
        'https://www.ticketmaster.com/the-chilites-tickets/artist/845690?_gl=1*1atn7sb*_gcl_au*MTI2ODA2MTIxNC4xNzE2ODc4NTc4*_ga*MTU4ODQ1Njg2My4xNzE2ODc4NTgw*_ga_H1KKSGW33X*MTcxNjg3ODU3OS4xLjAuMTcxNjg3ODU3OS42MC4wLjA.',
      tiktok: 'https://www.tiktok.com/@thechi_litesofficial?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B00GDVGM4I/the-chi-lites',
    },
    {
      title: 'Al Green',
      description:
      "Albert Leornes Greene (Forrest City, Arkansas; 13 de abril de 1946) conocido como reverendo Al Green, es un cantante y compositor estadounidense pentecostal. La mayoría de sus interpretaciones musicales pertenecen a los géneros góspel y soul. Ha sido unánimemente reconocido por fusionar estos diferentes estilos musicales entre sí, logrando la unión de lo secular con lo religioso. Durante los años 1970s obtuvo su mayor éxito, convirtiéndose en uno de los músicos con más superventas de esa época. En el año 2008, Green recibió un premio por toda su trayectoria artística llamado Lifetime Achievement Award durante el evento de premiación BET Awards. Su estilo vocal ha sido descrito como un áspero y cálido barítono y un etéreo falsetto.",
      videourl:
        'https://youtube.com/playlist?list=PLJHhuu0vGMO3SDnv0kuLRjRUjJNs_jE_4&si=a0sm4waw48kQ2DFV',
      price: 50,
      coverImg: 'algreen.jpg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.al-green.com/',
      youtube: 'https://www.youtube.com/channel/UCiE5dPVpnFpPl8ki4QjLH5Q',
      facebook: 'https://www.facebook.com/algreen/',
      instagram: 'https://www.instagram.com/algreen/',
      twitter: 'https://x.com/algreen',
      spotify: 'https://open.spotify.com/artist/3dkbV4qihUeMsqN4vBGg93',
      deezer: 'https://www.deezer.com/en/artist/2369',
      apple: 'https://itunes.apple.com/us/artist/al-green/id99603',
      soundcloud: 'https://soundcloud.com/al-green-official',
      tidal: 'https://listen.tidal.com/artist/11263',
      wiki: 'https://es.wikipedia.org/wiki/Al_Green',
      ticketmaster:
        'https://www.ticketmaster.com/al-green-tickets/artist/735206',
      tiktok: 'https://www.tiktok.com/@algreenofficial?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B000QJR7T2/al-green',
    },
    {
      title: 'The Manhattans',
      description:
      "The Manhattans are an American popular R&B vocal group. Their songs Kiss and Say Goodbye, recorded in 1976, and 1980's Shining Star, both sold millions of copies.[1] The Manhattans have recorded 45 hits on the Billboard R&B Chart, including twelve top-10 R&B hits in the United States, starting in 1965.[2] Sixteen of their songs have reached the Billboard Hot 100, including two top 10s and a number-one hit with their song Kiss and Say Goodbye. They also charted eight U.S. R&B top 20 Albums, three of which were RIAA certified gold.",
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_n153EKXT2YoWuoHMuuQ4duakrgQUvBqbg&si=1MtddJgwtcde21K6',
      price: 50,
      coverImg: 'manhattans.jpeg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://letsjustkissandsaygoodbye.com/',
      youtube: 'https://www.youtube.com/channel/UCqXO01U-aqCEh1bGLizNEFg',
      facebook: 'https://www.facebook.com/TheManhattansFeat.GeraldAlston/',
      instagram: 'https://www.instagram.com/the_manhattans_fga/',
      twitter: 'https://x.com/GManhattans',
      spotify: 'https://open.spotify.com/artist/1DpIDwg8FGD50N9Tfunfsf',
      deezer: 'https://www.deezer.com/en/artist/10975',
      apple: 'https://music.apple.com/mx/artist/the-manhattans/461153',
      soundcloud: 'https://soundcloud.com/the-manhattans-official',
      tidal: 'https://listen.tidal.com/artist/1230',
      wiki: 'https://en.wikipedia.org/wiki/The_Manhattans',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Music/The-Manhattans-Boletos',
      tiktok: 'https://www.tiktok.com/search/video?lang=es&q=tHE%20mANHATTANS&t=1726195668532',
      napster: 'https://music.amazon.com.mx/artists/B00GAR6VHI/the-manhattans',
    },
    {
      title: 'The Delfonics',
      description:
      "The Delfonics fue un grupo vocal estadounidense de R&B/soul con base en Filadelfia muy populares entre finales de los años 60 y comienzos de los 70. Sus éxitos más destacables fueron \"La-La (Means I Love You)\", \"Break Your Promise\" y \"Ready or Not Here I Come . Sus principales éxitos fueron escritos por el vocalista principal del grupo William \"Poogie\" Hart, y arreglados y producidos por Thom Bell. Sus canciones han formado parte de la banda sonora de numerosas películas como Jackie Brown, de Quentin Tarantino así como de videojuegos como Grand Theft Auto V.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_m-lzAfU0hM1FG3og7PmNEDWvdDlCbQUEY&si=nCnxAaNrJZfSiifQ",
      price: 50,
      coverImg: 'delfonics.jpg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.blackpast.org/african-american-history/groups-organizations-african-american-history/delfonics-the-1966-1975/',
      youtube: 'https://www.youtube.com/channel/UClJk5xbAApWv8Q50jNwa1zQ',
      facebook: 'https://www.facebook.com/PhillyGrooveRecords',
      instagram: 'https://www.instagram.com/the_official_delfonics/',
      twitter: 'https://x.com/THE_DELFONICS',
      spotify: 'open.spotify.com/artist/3dkbV4qihUeMsqN4vBGg93',
      deezer: 'https://www.deezer.com/en/artist/10526',
      apple: 'https://music.apple.com/us/artist/the-delfonics/128649182',
      soundcloud: 'https://soundcloud.com/the-delfonics',
      tidal: 'https://listen.tidal.com/artist/2468',
      wiki: 'https://es.wikipedia.org/wiki/The_Delfonics',
      ticketmaster:
        'https://www.ticketmaster.com/the-delfonics-tickets/artist/734903',
      tiktok: 'https://www.tiktok.com/search?lang=e&q=The%20Delfonics&t=1716925612686',
      napster: 'https://music.amazon.com.mx/artists/B00G6KUN5K/the-delfonics',
    },
    {
      title: 'Marvin Gaye',
      description:
      "Marvin Pentz Gay, Jr. (Washington D. C., 2 de abril de 1939 - Los Ángeles, California, 1 de abril de 1984), más conocido por su nombre artístico Marvin Gaye, fue un cantante, compositor, pianista y productor estadounidense de música soul y uno de los cantantes fundamentales del sonido Motown.​ Era apodado como el Príncipe del Soul",
      videourl:
        "https://youtube.com/playlist?list=PLKb94wjd-C5sBHCBKWIoncnhmEgdKpAu1&si=QsXEmlx2YijNNG61",
      price: 50,
      coverImg: 'marvingaye.jpg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.marvingaye.net/',
      youtube: 'https://www.youtube.com/channel/UCV0Arhg2bZuyL6CutipMduw',
      facebook: 'https://facebook.com/MarvinGaye',
      instagram: 'https://instagram.com/officialmarvingayemusic',
      twitter: 'https://x.com/MarvinGayeForum',
      spotify: 'https://open.spotify.com/artist/3koiLjNrgRTNbOwViDipeA?si=wnjhlAWnRqSqq3ktjs',
      deezer: 'https://www.deezer.com/en/artist/1154',
      apple: 'https://music.apple.com/us/artist/marvin-gaye/127329',
      soundcloud: 'https://soundcloud.com/marvin-gaye',
      tidal: 'https://listen.tidal.com/artist/711',
      wiki: 'https://es.wikipedia.org/wiki/Marvin_Gaye',
      ticketmaster:
        'https://www.ticketmaster.com/marvin-gaye-tickets/artist/959553',
      tiktok: 'https://www.tiktok.com/@marvingaye?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B000QJKE4C/marvin-gaye',
    },
    {
      title: 'Otis Redding',
      description:
      "Otis Ray Redding Jr. (Dawson, Georgia, 9 de septiembre de 1941-Madison, Wisconsin, 10 de diciembre de 1967) fue un cantante de Stax y Volt Records, compositor, productor de discos, arreglista y buscador de talentos estadounidense, apodado «King of Soul»,​ (título que comparte con Sam Cooke) en reconocimiento a su habilidad para transmitir emociones a través de su voz.Es considerado uno de los más grandes cantantes en la historia de la música estadounidense y un artista seminal en la música soul y rhythm and blues.​ Su estilo de canto procedía de la música góspel, el precedente de su género musical. Su estilo de canto influyó en muchos otros cantantes de soul de los años 1960. Durante su vida, sus grabaciones fueron producidas por Stax Records, establecida en Memphis, Tennessee.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_mUONdXsOMZvHzqbYEuo8dAv4QwI6sdPEQ&si=NIobsmTVSjJ-sp5C",
      price: 50,
      coverImg: 'otisredding.jpg',
      category: 'Funk Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://otisredding.com',
      youtube: 'https://www.youtube.com/channel/UC0d604wgOhA8on5Lgw4lMYQ',
      facebook: 'https://www.facebook.com/otisredding',
      instagram: 'https://www.instagram.com/otisredding',
      twitter: 'https://x.com/OtisRedding',
      spotify: 'https://open.spotify.com/artist/60df5JBRRPcnSpsIMxxwQm',
      deezer: 'https://www.deezer.com/en/artist/1154',
      apple: 'https://music.apple.com/us/artist/otis-redding/189254',
      soundcloud: 'https://soundcloud.com/otisredding',
      tidal: 'https://listen.tidal.com/artist/13368',
      wiki: 'https://es.wikipedia.org/wiki/Otis_Redding',
      ticketmaster:
        'https://www.ticketmaster.com/tribute-to-otis-redding-tickets/artist/1656978',
      tiktok: 'https://www.tiktok.com/@otisredding?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B000QJQ9KK/otis-redding',
    },{
      title: 'Aaron Neville',
      description:
      "Aaron Neville (Nueva Orleans, 24 de enero de 1941)1​ es un cantante estadounidense de R&B y soul. A lo largo de su carrera, ha obtenido cuatro álbumes de platino y cuatro Top 10 en los Estados Unidos. Famoso por su voz aguda y suave, ha cosechado éxitos como Tell It Like It Is, Even If my Heart Would Break, Don't Know Much, All My Life (junto a Linda Ronstadt) o una versión del Ave María, utilizada como cortina musical en la película ¡Viven!. Ha ganado cinco premios Grammy y publicado casi veinte álbumes en diferentes géneros musicales.También ha grabado con sus tres hermanos como los Neville Brothers y es el padre del cantante y teclista Ivan Neville.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_l-v6vbkkv6lduQkiNqm3mu7Z4IdzfTRck&si=roYittESxJA1Yf1P",
      price: 50,
      coverImg: 'aaronneville.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://aaronneville.com/',
      youtube: 'https://www.youtube.com/user/AaronNeville504',
      facebook: 'https://www.facebook.com/aaronneville504',
      instagram: 'https://www.instagram.com/aaronneville/',
      twitter: 'https://x.com/aaronneville?lang=en',
      spotify: 'https://open.spotify.com/artist/57ALvbCBaCkNlgTOSiUPdT',
      deezer: 'https://www.deezer.com/en/artist/7445',
      apple: 'https://music.apple.com/us/artist/aaron-neville/94614',
      soundcloud: 'https://soundcloud.com/aaron_neville',
      tidal: 'https://listen.tidal.com/artist/10529',
      wiki: 'https://es.wikipedia.org/wiki/Aaron_Neville',
      ticketmaster:
        'https://www.ticketmaster.com/aaron-neville-tickets/artist/735737',
      tiktok: 'https://www.tiktok.com/@.aaronneville?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B000QJHVHU/aaron-neville',
    },
    {
      title: 'Teddy Pendergrass',
      description:
      "Theodore DeReese Teddy Pendergrass (Filadelfia, Pensilvania, 26 de marzo de 1950​-Bryn Mawr, Pensilvania, 13 de enero de 2010​) fue un cantante y compositor estadounidense de R&B y música soul.Se hizo conocido gracias a su trabajo como cantante principal del grupo Harold Melvin & the Blue Notes en los años 1970, tras lo cual desarrolló una exitosa carrera en solitario hacia el final de esa década. En 1982 sufrió un grave accidente automovilístico en Filadelfia, como consecuencia del cual quedó paralizado de cintura abajo. Tras el accidente, el artista fundó la fundación Teddy Pendergrass Alliance, dedicada a ayudar a las personas con lesiones de columna vertebral.Está estrechamente vinculado al género del soul conocido como Philadelphia soul, precursor de la música disco. Murió a causa de una insuficiencia respiratoria.",
      videourl:
        "https://youtube.com/playlist?list=PLUW56nubk9EhxZPIkMT4jGpNxhSdwgCsQ&si=1RLFPAYuTMgUoLzS",
      price: 50,
      coverImg: 'pendegrass.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://teddypendergrassofficial.com/',
      youtube: 'https://www.youtube.com/channel/UCZWdbVjofcSWVus4_dt9ngA',
      facebook: 'https://www.facebook.com/TeddyPendergrassMusic',
      instagram: 'https://www.instagram.com/teddylovetko/',
      twitter: 'https://x.com/YemTeddy',
      spotify: 'https://open.spotify.com/artist/68kACMx6A3D2BYiO056MeQ',
      deezer: 'https://www.deezer.com/en/artist/10808',
      apple: 'https://music.apple.com/us/artist/teddy-pendergrass/162183',
      soundcloud: 'https://soundcloud.com/search?q=Teddy%20Pendegrass',
      tidal: 'https://listen.tidal.com/artist/14625',
      wiki: 'https://es.wikipedia.org/wiki/Teddy_Pendergrass',
      ticketmaster:
        'https://www.ticketmaster.com/aaron-neville-tickets/artist/735737',
      tiktok: 'https://www.tiktok.com/@.aaronneville?lang=e',
      napster: 'https://music.amazon.com.mx/artists/B000QJHW6U/teddy-pendergrass',
    },
    {
      title: 'Sam Cooke',
      description:
      "Samuel Cook (Clarksdale, Misisipi, 22 de enero de 1931–Los Ángeles, 11 de diciembre de 1964), más conocido por su nombre artístico Sam Cooke, fue un cantante estadounidense de música góspel, rhythm and blues, soul y pop; compositor y empresario musical. Es uno de los pioneros de la música soul y una de las grandes influencias en la música pop, el R&B y la neo soul.Muchos lo consideran El Rey del Soul —título que comparte con Otis Redding— por haber logrado una treintena de hits entre 1957 y 1965.Fue el primer cantante negro en fundar su propia empresa discográfica, SAR Records, y también un reconocido activista dentro del Movimiento por los Derechos Civiles. Además, en su época fue uno de los artistas con mayor audiencia no negra. El documental sobre su vida: Las dos muertes de Sam Cooke, trata sobre la vida de Cooke.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_kBFII6F3Ac-LPCgrLFgBRPv5VSV96kDEo&si=V0P_NGBpd9naBTfF",
      price: 50,
      coverImg: 'samcooke.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://officialsamcooke.com/',
      youtube: 'https://www.youtube.com/channel/UC2vyD1cRA7Wt_w5_yuwzygQ',
      facebook: 'https://www.facebook.com/officialsamcooke',
      instagram: 'https://www.instagram.com/officialsamcooke',
      twitter: 'https://x.com/officialscooke',
      spotify: 'https://open.spotify.com/artist/6hnWRPzGGKiapVX1UCdEAC?si=bTNueVBCQoCgFvbZHqAC-g&nd=1&dlsi=9d6cbe83f17f4ec9',
      deezer: 'https://www.deezer.com/en/artist/900',
      apple: 'https://music.apple.com/us/artist/sam-cooke/1195231',
      soundcloud: 'https://soundcloud.com/samcooke',
      tidal: 'https://listen.tidal.com/artist/3500082',
      wiki: 'https://es.wikipedia.org/wiki/Sam_Cooke',
      ticketmaster:
        'https://www.ticketmaster.com/the-cooke-book-tickets/artist/1830523',
      tiktok: 'https://www.tiktok.com/@officialsamcooke',
      napster: 'https://music.amazon.com.mx/artists/B000QJPXHA/sam-cooke',
    },
    {
      title: 'Charles Bradley',
      description:
      "Charles Edward Bradley (5 de noviembre de 1948 - 23 de septiembre de 2017) fue un cantante estadounidense.​ Después de años de oscuridad y una carrera musical a tiempo parcial, Bradley saltó a la fama a los 50 años. Sus actuaciones y estilo de grabación fueron consistentes con el enfoque revivalista de su sello principal Daptone Records, celebrando la sensación de la música funk y soul de las décadas de 1960 y 1970.2​ Una reseña dijo que se hace eco de la entrega evocadora de Otis Redding. Conocido como The Screaming Eagle of Soul (El Águila Chillón del Soul), Bradley fue el tema del documental Soul of America que se estrenó en South by Southwest en 2012.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_nEPn4SmypDEP1q8bHSp37uJSG6eq8gKa8&si=FrJvpHKJKJ67YN2c",
      price: 50,
      coverImg: 'chbradley.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.diagnosticocultura.com/charles-bradley-no-apto-para-no-sonadores/',
      youtube: 'https://www.youtube.com/channel/UCP6eGHJYZIJiRqABUeZiIAw',
      facebook: 'https://www.facebook.com/thecharlesbradley',
      instagram: 'https://www.instagram.com/charlesbradley/',
      twitter: 'https://x.com/Charles_Bradley',
      spotify: 'https://open.spotify.com/artist/6hnWRPzGGKiapVX1UCdEAC?si=bTNueVBCQoCgFvbZHqAC-g&nd=1&dlsi=9d6cbe83f17f4ec9',
      deezer: 'https://www.deezer.com/en/artist/1027239',
      apple: 'https://music.apple.com/us/artist/charles-bradley/206064023',
      soundcloud: 'https://soundcloud.com/search?q=Charles%20Bradley',
      tidal: 'https://listen.tidal.com/artist/3860529',
      wiki: 'https://es.wikipedia.org/wiki/Charles_Bradley_(cantante)',
      ticketmaster:
        'https://www.ticketmaster.com/charles-bradley-tickets/artist/1543301',
      tiktok: 'https://www.tiktok.com/search/video?q=Charles%20Bradley&t=1726198478847',
      napster: 'https://music.amazon.com.mx/artists/B0011Z773G/charles-bradley',
    },
    {
      title: 'Donny Hathaway',
      description:
      "Donny Hathaway (Chicago, 1 de octubre de 1945 – Nueva York, 13 de enero de 1979) fue un músico estadounidense de soul. Sus numerosas colaboraciones con la cantante Roberta Flack le llevaron directamente a lo alto de todas las listas de éxitos y ambos fueron galardonados con un premio Grammy por su dueto en la canción Where Is the Love? en 1973. El 13 de enero de 1979 fue hallado muerto en el exterior del hotel Essex House en Nueva York; todos los indicios apuntaron a que la causa de su muerte fue el suicidio.",
      videourl:
        "https://youtube.com/playlist?list=OLAK5uy_m9QdHWv7yj17OVLNBAigqgWFMLVp4YY5I&si=lqHTyKzdtoiyLrbV",
      price: 50,
      coverImg: 'dhathaway.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.elmundo.es/cultura/2015/10/07/561427d922601dc1168b45fc.html',
      youtube: 'https://www.youtube.com/channel/UC4eOTxe9XeQ9nuC-wDX6qQw',
      facebook: 'https://www.facebook.com/DonnyHathawayOfficial',
      instagram: 'https://www.instagram.com/donnyhathaway/',
      twitter: 'https://x.com/DonnyHathawayLP',
      spotify: 'https://open.spotify.com/artist/0HU0U9kdXEHZVxUNbuQe8S',
      deezer: 'https://www.deezer.com/en/artist/4613',
      apple: 'https://music.apple.com/mx/artist/donny-hathaway/259459',
      soundcloud: 'https://soundcloud.com/donnyhathaway',
      tidal: 'https://listen.tidal.com/artist/13583',
      wiki: 'https://es.wikipedia.org/wiki/Donny_Hathaway',
      ticketmaster:
        'https://www.ticketweb.uk/event/donny-hathaways-birthday-party-the-forge-tickets/13712933?REFERRAL_ID=tmfeed',
      tiktok: 'https://www.tiktok.com/@officialdonnyhathaway',
      napster: 'https://music.amazon.com.mx/artists/B000QJPXB6/donny-hathaway',
    },
    {
      title: 'Tina Turner',
      description:
        'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
      videourl:
        'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
      price: '$50',
      coverImg: 'TinaTurner.jpg',
      category: 'Jazz',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.tinaturnerofficial.com/',
      youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
      facebook: 'https://www.facebook.com/TinaTurner/',
      instagram: 'https://www.instagram.com/tinaturner/?hl=en',
      twitter: 'https://twitter.com/LoveTinaTurner',
      spotify:
        'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
      deezer: 'https://www.deezer.com/mx/artist/1454',
      apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
      soundcloud: 'https://soundcloud.com/tina-turner-official',
      tidal: 'https://listen.tidal.com/artist/9214',
      wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
      ticketmaster:
        'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
      tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
      napster: 'https://us.napster.com/artist/tina-turner',
    },
    {
      title: 'James Brown',
      description:
        'James Joseph Brown fue un cantante de soul y funk estadounidense. Progenitor de la música funk y una figura importante de la música y la danza del siglo XX, a menudo se le refiere por los apodos honoríficos Padrino del Soul, Sr. Dinamita y Hermano Soul No. 1',
      videourl:
        'https://youtube.com/playlist?list=PLho6UqUrhDcXJ1yQBwcbP2mAkhGFTmcFa',
      price: '$50',
      coverImg: 'JamesBrown.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'http://www.jamesbrown.com/',
      youtube: 'https://www.youtube.com/channel/UCOCZxe0gNRA7c3PGWPGoiGg',
      facebook: 'https://www.facebook.com/JamesBrown',
      instagram: 'https://www.instagram.com/jamesbrown/',
      twitter: 'https://twitter.com/JamesBrownsSoul',
      spotify: 'https://open.spotify.com/artist/7GaxyUddsPok8BuhxN6OUW',
      deezer: 'https://www.deezer.com/mx/artist/1622',
      apple: 'https://music.apple.com/mx/artist/james-brown/117118',
      soundcloud: 'https://soundcloud.com/james-brown-music',
      tidal: 'https://listen.tidal.com/artist/6361',
      wiki: 'https://es.wikipedia.org/wiki/James_Brown',
      ticketmaster:
        'https://www.ticketmaster.com/james-brown-tickets/artist/734650',
      tiktok: 'https://www.tiktok.com/@jamesbrown?lang=es',
      napster: 'https://us.napster.com/artist/james-brown',
    },
    {
      title: 'The Isley Brothers',
      description:
        'The Isley Brothers es un grupo estadounidense de soul, funk y góspel, formado en 1954 en Cincinnati, Ohio. La banda ostenta el récord de ser el grupo que más tiempo ha permanecido en activo en la historia de la música. El grupo ha abarcado décadas, ha incorporado a miembros de dos generaciones, y ha ejecutado géneros musicales tan diversos como el góspel, soul, funk, doo-wop, rhythm and blues, rock, soft rock e incluso el hip hop.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_nwB_LKbGLCaEOwpu5rHA6DwpYfiXalTug&si=40stBTnVaAZfP2cZ',
      price: '$50',
      coverImg: 'isleybrothers.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://officialisleybrothers.com/',
      youtube: 'https://www.youtube.com/channel/UCSUUJi1Q3tiY3eFjPtRVBfg',
      facebook: 'https://www.facebook.com/isleybrothers',
      instagram: 'https://www.instagram.com/theisleybrothers/',
      twitter: 'https://x.com/isleybrothers',
      spotify: 'https://open.spotify.com/artist/53QzNeFpzAaXYnrDBbDrIp',
      deezer: 'https://www.deezer.com/mx/artist/1622',
      apple: 'https://music.apple.com/mx/artist/the-isley-brothers/121582',
      soundcloud:'https://soundcloud.com/theisleybrothers',
      tidal: 'https://listen.tidal.com/artist/97',
      wiki: 'https://es.wikipedia.org/wiki/The_Isley_Brothers',
      ticketmaster:
        'https://www.ticketmaster.com/the-isley-brothers-tickets/artist/735343',
      tiktok: 'https://www.tiktok.com/@theisleybrothers?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B00G9Y9XEA/the-isley-brothers',
    },
    {
      title: 'The Temptations',
      description:
        'The Temptations es una banda estadounidense formada en Detroit, Estados Unidos. Este grupo vocal estadounidense está considerado uno de los más exitosos en la historia de la música.1​2​ Ha vendido decenas de millones de álbumes3​ y es famoso por ser una de las formaciones más relevantes de Motown Records.',
      videourl:
        'https://youtube.com/playlist?list=PLTGZr8YmZMtvTtVgaq6yesF68ahy-1VRD&si=VfVHIsG_xIQUb36',
      price: '$50',
      coverImg: 'thetemptations.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.temptationsofficial.com/#/',
      youtube: 'https://www.youtube.com/channel/UCee8VNCWfOCYccbFhDM8B_A',
      facebook: 'https://facebook.com/thetemptations',
      instagram: 'https://instagram.com/thetemptations/',
      twitter: 'https://x.com/thetemptations',
      spotify: 'https://open.spotify.com/artist/3RwQ26hR2tJtA8F9p2n7jG',
      deezer: 'https://www.deezer.com/mx/artist/2730',
      apple: 'https://music.apple.com/mx/artist/the-isley-brothers/121582',
      soundcloud:'https://soundcloud.com/the-temptations',
      tidal: 'https://listen.tidal.com/artist/15727',
      wiki: 'https://es.wikipedia.org/wiki/The_Temptations',
      ticketmaster:
        'https://www.ticketmaster.com/the-temptations-tickets/artist/736274',
      tiktok: 'https://www.tiktok.com/@officialtemptations',
      napster: 'https://music.amazon.com.mx/artists/B000QK1RF6/the-temptations',
    },
    {
      title: 'Aretha Franklin',
      description:
        'Aretha Louise Franklin (Memphis, 25 de marzo de 1942-Detroit, 16 de agosto de 2018)​ fue una cantante estadounidense de soul, R&B y góspel. Apodada «Lady Soul» (la Dama del Soul) o «Queen of soul» (la Reina del Soul), fue una de las mayores representante de dicho género. A mediados de la década de 1960 se consolidó como estrella femenina del Soul, algo que usó en favor de los derechos raciales en Estados Unidos, siendo un elemento influyente dentro del movimiento racial y de liberación femenina.',
      videourl:
        'https://youtube.com/playlist?list=PLCx4rpClwwTAnYs_sgXpHj3_zPsYonM_8&si=S01fDrK32mnpyYw4',
      price: '$50',
      coverImg: 'ArethaFranklin.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.arethafranklin.net/',
      youtube: 'https://www.youtube.com/channel/UCGDOBEUVPWkzmHo4dDa96zQ',
      facebook: 'https://www.facebook.com/arethafranklin',
      instagram: 'https://www.instagram.com/arethafranklin/',
      twitter: 'https://x.com/ArethaFranklin',
      spotify: 'https://open.spotify.com/artist/7nwUJBm0HE4ZxD3f5cy5ok',
      deezer: 'https://www.deezer.com/mx/artist/2059',
      apple: 'https://music.apple.com/mx/artist/aretha-franklin/98742',
      soundcloud:'https://soundcloud.com/arethafranklin',
      tidal: 'https://listen.tidal.com/artist/152',
      wiki: 'https://es.wikipedia.org/wiki/Aretha_Franklin',
      ticketmaster:
        'https://www.ticketmaster.com/aretha-franklin-tickets/artist/735118',
      tiktok: 'https://www.tiktok.com/@arethafranklin1942',
      napster: 'https://music.amazon.com.mx/artists/B000QJICRS/aretha-franklin',
    },
    {
      title: 'Stevie Wonder',
      description:
        'Stevland Hardaway Morris (Saginaw, Míchigan; 13 de mayo de 1950), conocido artísticamente como Stevie Wonder, es un cantautor y activista social estadounidense; uno de los más exitosos y reconocidos artistas en la compañía discográfica Motown, con más de 100 millones de discos vendidos. Ciego prácticamente de nacimiento, tras algunas complicaciones después del parto,​ fue un niño prodigio y durante su carrera ha grabado diversos discos y sencillos que fueron aclamados por la crítica;​ también ha escrito y producido para otros artistas.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mhdM8vy9RGFqVrqpoTozbnCYOBqHBb6EM&si=6-8rkjnbAM7BxE-_',
      price: '$50',
      coverImg: 'steviewonder.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'http://www.steviewonder.net/',
      youtube: 'https://www.youtube.com/channel/UCGD7CfG3JgZF52QpIRivV1Q',
      facebook: 'https://www.facebook.com/StevieWonder/',
      instagram: 'https://www.instagram.com/steviewonderoficial/',
      twitter: 'https://x.com/StevieWonder',
      spotify: 'https://open.spotify.com/artist/7guDJrEfX3qb6FEbdPA5qi',
      deezer: 'https://www.deezer.com/mx/artist/166',
      apple: 'https://music.apple.com/mx/artist/aretha-franklin/98742',
      soundcloud:'https://soundcloud.com/arethafranklin',
      tidal: 'https://listen.tidal.com/artist/152',
      wiki: 'https://es.wikipedia.org/wiki/Stevie_Wonder',
      ticketmaster:
        'https://www.ticketmaster.com/stevie-wonder-tickets/artist/772848',
      tiktok: 'https://www.tiktok.com/@steviewonder',
      napster: 'https://music.amazon.com.mx/artists/B000QJJQNM/stevie-wonder',
    },
    {
      title: 'Smokey Robinson',
      description:
        'Smokey Robinson, nacido como William Robinson Jr. (Detroit, Míchigan; 19 de febrero de 1940),​ es un cantante, compositor y productor discográfico estadounidense de R&B y soul. Es conocido, tanto por liderar el grupo vocal The Miracles entre 1955 y 1972, como por su posterior carrera en solitario. Entre 1965 y 1990 fue vicepresidente de la compañía discográfica Motown.',
      videourl:
        'https://youtube.com/playlist?list=UULFW8_krLUqX1EVR_vXD1ml4g&si=BEY8Sa1FOz5QILjO',
      price: '$50',
      coverImg: 'smokeyrobinson.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://smokeyrobinson.com/',
      youtube: 'https://www.youtube.com/channel/UCW8_krLUqX1EVR_vXD1ml4g',
      facebook: 'hhttps://www.facebook.com/thesmokeyrobinson',
      instagram: 'https://www.instagram.com/smokeyrobinson/',
      twitter: 'https://x.com/smokeyrobinson',
      spotify: 'https://open.spotify.com/artist/0h9smro0z3HqUbD94jotU8',
      deezer: 'https://www.deezer.com/mx/artist/16528',
      apple: 'https://music.apple.com/mx/artist/aretha-franklin/98742',
      soundcloud:'https://soundcloud.com/arethafranklin',
      tidal: 'https://listen.tidal.com/artist/16866',
      wiki: 'https://es.wikipedia.org/wiki/Smokey_Robinson',
      ticketmaster:
        'https://www.ticketmaster.com/smokey-robinson-tickets/artist/735991',
      tiktok: 'https://www.tiktok.com/@smokeyrobinson',
      napster: 'https://music.amazon.com.mx/artists/B000QJTBXC/smokey-robinson',
    },
    {
      title: 'Barry White',
      description:
        'Barrence Eugene Carter (Galveston, Texas, 12 de septiembre de 1944-Los Ángeles, California, 4 de julio de 2003), conocido artísticamente como Barry White, y El Maestro,​ por un círculo cercano, fue un compositor, cantante, arreglista y productor musical estadounidense de los géneros R&B y soul. Caracterizado por un timbre bajo, grave y ronco, ganó dos premios Grammy en el 2000 y otros reconocimientos, como los premios Soul Train o los American Music. El mayor éxito de White se produjo a partir de 1973, al emerger repentinamente como artista solista, construyendo así un legado que se mantuvo por el resto de la década de 1970, aunque su carrera continuó hasta 1999 con la producción de un total de 20 álbumes de estudio con su nombre. Llegó tres veces al número 1 de las listas Hot 100 en su país, con las canciones que lo hicieron mundialmente famoso: Loves Theme (interpretado por su orquesta, The Love Unlimited Orchestra)',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mp_-OIrCKWajzsNqS3IBisqIuTwn0iFhk&si=lQ5TT_8zrmCwVYKt',
      price: '$50',
      coverImg: 'barrywhite.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.barrywhiteofficial.com/',
      youtube: 'https://www.youtube.com/channel/UCyw75l63rtHtCH3v9HxQvmQ',
      facebook: 'https://www.facebook.com/BarryWhite',
      instagram: 'https://www.instagram.com/barrywhitemusic/',
      twitter: 'https://x.com/BarryWhite',
      spotify: 'https://open.spotify.com/artist/3rfgbfpPSfXY40lzRK7Syt',
      deezer: 'https://www.deezer.com/mx/artist/34',
      apple: 'https://music.apple.com/us/artist/barry-white/129233',
      soundcloud:'https://soundcloud.com/barry-white-official',
      tidal: 'https://listen.tidal.com/artist/51176',
      wiki: 'https://es.wikipedia.org/wiki/Barry_White',
      ticketmaster:
        'https://www.ticketmaster.com/barry-white-tickets/artist/736444',
      tiktok: 'https://www.tiktok.com/@barrywhite',
      napster: 'https://music.amazon.com.mx/artists/B000QJQ5P4/barry-white',
    },
    {
      title: 'Luther Vandross',
      description:
        'Luther Ronzoni Vandross Junior, más conocido como Luther Vandross (Manhattan, Nueva York, 20 de abril de 1951 - Edison, Nueva Jersey, 1 de julio de 2005), fue un cantante, compositor y productor de R&B, soul y góspel estadounidense. Conocido por su voz dulce y conmovedora, Vandross ha vendido más de 40 millones de discos en todo el mundo.​ Logró once álbumes de platino consecutivos y ocho premios Grammy, incluyendo Mejor Interpretación Vocal Masculina de R&B en cuatro ocasiones diferentes. En 2004, Vandross ganó un total de cuatro premios Grammy',

      videourl:
        'https://youtube.com/playlist?list=PLoTCV0yVw1g997c7IgSmfGc3JvIgyN-YA&si=n3Z_2vYMJjPKBkUK',
      price: '$50',
      coverImg: 'luthervandross.jpg',
      category: 'Funk',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://luthervandross.com/',
      youtube: 'https://www.youtube.com/channel/UC3sbjt9jIZc1W3wfVlYbG8Q',
      facebook: 'https://www.facebook.com/luthervandross',
      instagram: 'https://www.instagram.com/luthervandross/',
      twitter: 'https://x.com/luthervandross',
      spotify: 'https://open.spotify.com/artist/1ThoqLcyIYvZn7iWbj8fsj',
      deezer: 'https://www.deezer.com/mx/artist/521',
      apple: 'https://music.apple.com/us/artist/luther-vandross/23393',
      soundcloud:'https://soundcloud.com/luthervandrossmusic',
      tidal: 'https://listen.tidal.com/artist/145',
      wiki: 'https://es.wikipedia.org/wiki/Luther_Vandross',
      ticketmaster:'https://www.viagogo.com.mx/Boletos-Conciertos/Cover-Tribute/Luther-Vandross-Tribute-Boletos?agqi=5c6b7c75a533180e55728bf05d5efa56&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
      tiktok: 'https://www.youtube.com/c/luthervandross',
      napster: 'https://music.amazon.com.mx/search/luther+vandross',
    },
    {
      title: 'The Dramatics',
      description:
        'The Dramatics son un grupo vocal de música soul estadounidense, formado en Detroit, Michigan, en 1964. Son más conocidos por sus éxitos de la década de 1970 "In the Rain" y "Whatcha See Is Whatcha Get", ambos de los cuales fueron éxitos en el Top 10 de Pop, así como por su colaboración de 1993 "Doggy Dogg World" con Snoop Dogg, un éxito en el top 20 de la lista Billboard Rhythmic Top 40.',

      videourl:'https://youtube.com/playlist?list=OLAK5uy_lgG97i23HAy6evFY3b3TvtVYU9ijsweYM&si=tgkxEebK_1xKe1Xh',
      price: '$50',
      coverImg: 'dramatics.png',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://staxrecords.com/artist/the-dramatics/',
      youtube: 'https://www.youtube.com/channel/UCXPGaby-C4v6ZeIAXoUH_FA',
      facebook: 'https://www.facebook.com/L.J.REYNOLD',
      instagram: 'https://www.instagram.com/thedramatics/',
      twitter: 'https://x.com/PeteWilliamsMus/status/1793319824463917429',
      spotify: 'https://open.spotify.com/artist/2W8UTum7bU7ue6m0r14H97',
      deezer: 'https://www.deezer.com/mx/artist/6447',
      apple: 'https://music.apple.com/mx/artist/the-dramatics/2770837',
      soundcloud:'https://soundcloud.com/the-dramatics-official',
      tidal: 'https://listen.tidal.com/artist/55752',
      wiki: 'https://en.wikipedia.org/wiki/The_Dramatics',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Music/The-Dramatics-Boletos?agqi=c45785068e3d13ff8a442a044baa6b4d&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
      tiktok: 'https://www.tiktok.com/search/video?lang=es&q=The%20Dramatics&t=1726298110515',
      napster: 'https://music.amazon.com.mx/artists/B000QJIID6/the-dramatics',
    },
    {
      title: 'The O Jays',
      description:
        'The O Jays son un grupo estadounidense de R&B originarios de Canton, Ohio, formado en 1958 originalmente por Eddie Levert, Walter Williams, William Powell, Bobby Massey y Bill Isles. The O Jays debutaron en las listas de éxitos en 1963 con el sencillo "Lonely Drifter", pero alcanzaron sus mayores cotas de éxito cuando se asociaron con los productores Kenneth Gamble y Leon Huff, propietarios del sello discográfico Philadelphia International en 1972. Con Gamble y Huff, the O Jays, convertidos en trío tras la marcha de Isles y Massey, emergieron como abanderados del Sonido Filadelfia con temas como Love Train, número uno de Billboard Hot 100 en 1973, considerado como el primer éxito de la música disco. En 2004 fueron incluidos en el Salón de la Fama de los Grupos Vocales​ y en 2005 en el Salón de la Fama del Rock and Roll.',

      videourl:
        'https://youtube.com/playlist?list=PLyOtQeA7HWthxHFvJoNW5NO2paz-G8Nw0&si=AKCgoUg2EboDx2Qe',
      price: '$50',
      coverImg: 'ojays.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.mightyojays.com/',
      youtube: 'https://www.youtube.com/channel/UCbZoqpq2EXyMV4RJUyLBehA',
      facebook: 'https://www.facebook.com/themightyojays/',
      instagram: 'https://www.instagram.com/themightyojays/',
      twitter: 'https://x.com/themightyojays',
      spotify: 'https://open.spotify.com/artist/38h03gA85YYPeDPd9ER9rT',
      deezer: 'https://www.deezer.com/mx/artist/5451',
      apple: 'https://music.apple.com/us/artist/the-ojays/67816',
      soundcloud:'https://soundcloud.com/theojays',
      tidal: 'https://listen.tidal.com/artist/240',
      wiki: 'https://es.wikipedia.org/wiki/The_O%27Jays',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/R+B-Urban-Soul/The-Mighty-OJays-Boletos',
      tiktok: 'https://www.tiktok.com/search/video?q=The%20%20O%27jays&t=1726202295262',
      napster: 'https://music.amazon.com.mx/artists/B00G6AJ02C/the-o jays',
    },
    {
      title: 'The Stylistics',
      description:
        'The Stylistics son un grupo de soul de Filadelfia que alcanzó su mayor éxito en las listas en la década de 1970. Se formaron en 1968, con una formación de cantantes compuesta por Russell Thompkins Jr., Herb Murrell, Airrion Love, James Smith y James Dunn. Todos sus éxitos en Estados Unidos fueron baladas caracterizadas por el falsete de Russell Thompkins Jr. y la producción de Thom Bell. A principios de la década de 1970, el grupo tuvo doce éxitos consecutivos en el top ten de R&B, incluyendo Stop, Look, Listen, You Are Everything, Betcha by Golly, Wow, Im Stone in Love with You, Break Up to Make Up y You Make Me Feel Brand New, lo que les valió 5 sencillos de oro y 3 álbumes de oro.',

      videourl:
        'https://www.youtube.com/playlist?list=OLAK5uy_kKa17zdne6HTDtm0rgs5VDUr4p7onGTbs',
      price: '$50',
      coverImg: 'stylistics.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.thestylistics.org/',
      youtube: 'https://www.youtube.com/channel/UCvdxB-s4VxPBZNs9I1P_gTw',
      facebook: 'https://www.facebook.com/thestylisticsofficial/',
      instagram: 'https://www.instagram.com/thestylisticsofficial/',
      twitter: 'https://x.com/RealStylistics',
      spotify: 'https://open.spotify.com/artist/2O0Hw1WSMbskB5tD9aWah3',
      deezer: 'https://www.deezer.com/mx/artist/2396',
      apple: 'https://music.apple.com/us/artist/the-stylistics/1385237217',
      soundcloud:'https://soundcloud.com/the-stylistics-official',
      tidal: 'https://listen.tidal.com/artist/32709',
      wiki: 'https://en.wikipedia.org/wiki/The_Stylistics',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/The-Stylistics-Boletos',
      tiktok: 'https://www.tiktok.com/@thestylisticsofficial',
      napster: 'https://music.amazon.com.mx/artists/B000QK3B2I/the-stylistics',
    },
    {
      title: 'Commodores',
      description:
        'The Commodores es un grupo estadounidense, conocido por su estilo musical funk/soul, que destacó en las décadas de 1970 y 1980. Se formaron en Detroit en 1968, y al año firmaron contrato con Motown Records.1​ En 2003 fueron incluidos en el Salón de la Fama de los Grupos Vocales',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mw-U2-Bzcrvi-uESBPvi8BNKqVEx4PW7Q&si=8Ljod542Dk640_KW',
      price: '$50',
      coverImg: 'comodores.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.commodoresmerch.com/',
      youtube: 'https://www.youtube.com/channel/UC2Q68DxvOG_9_ZQX_ZF4ITw',
      facebook: 'https://www.facebook.com/TheCommodores/',
      instagram: 'https://www.instagram.com/thecommodores/',
      twitter: 'https://x.com/the__commodores',
      spotify: 'https://open.spotify.com/artist/6twIAGnYuIT1pncMAsXnEm',
      deezer: 'https://www.deezer.com/mx/artist/90459',
      apple: 'https://music.apple.com/us/artist/the-commodores/127282',
      soundcloud:'https://soundcloud.com/cyndi-mills-young/sets/the-commodores',
      tidal: 'https://listen.tidal.com/artist/3619958',
      wiki: 'https://en.wikipedia.org/wiki/Commodores',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/RB-and-Soul-Music/Commodores-Boletos',
      tiktok: 'https://www.tiktok.com/search/video?q=Commodores&t=1726203229386',
      napster: 'https://music.amazon.com.mx/artists/B000QJQOP0/the-commodores',
    },
    {
      title: 'Four Tops',
      description:
        'Los Four Tops es un cuarteto estadounidense activo desde 1954; pionero del sonido Motown, mantendría su alineación intacta por más de cuatro décadas, hasta la sucesiva muerte de varios de sus integrantes que posteriormente fueron reemplazados.​ Especialmente reconocidos son sus sencillos Reach Out I ll Be There y I Can t Help Myself (Sugar Pie Honey Bunch), que alcanzaron el primer lugar en la Billboard Hot 100.',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_kcfYOuRkE1GuHf_8Iz6ZreREv0IPcWJec&si=s9aqHe0HdtB8sH7d',
      price: '$50',
      coverImg: 'fourtops.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.motownmuseum.org/artist/the-four-tops/',
      youtube: 'https://www.youtube.com/channel/UCCc9TFYFrV3xpNl5onNtVqA',
      facebook: 'https://www.facebook.com/FourTops',
      instagram: 'https://www.instagram.com/thestylisticsofficial/',
      twitter: 'https://x.com/RealStylistics',
      spotify: 'https://open.spotify.com/artist/7fIvjotigTGWqjIz6EP1i4',
      deezer: 'https://www.deezer.com/mx/artist/2938',
      apple: 'https://music.apple.com/us/artist/four-tops/52206772?l=es-MX',
      soundcloud:'https://soundcloud.com/four-tops',
      tidal: 'https://listen.tidal.com/artist/16918',
      wiki: 'https://es.wikipedia.org/wiki/Four_Tops',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/RB-and-Soul-Music/The-Four-Tops-Boletos',
      tiktok: 'https://www.tiktok.com/search/video?q=fOUR%20tOPS&t=1726204196291',
      napster: 'https://music.amazon.com.mx/artists/B00GBCM2QG/the-four-tops',
    },
    {
      title: 'Etta James',
      description:
        'Jamesetta Hawkins (Los Ángeles, California, 25 de enero de 1938-Riverside, California, 20 de enero de 2012), más conocida por su nombre artístico Etta James, fue una cantante estadounidense de géneros soul, jazz y rhythm and blues, considerada una de las grandes voces en la historia del rhythm & blues.',

      videourl:
        'https://www.youtube.com/playlist?list=OLAK5uy_kWFWnlriFU9SrBudi69NUmq3Jq6jvFpRY',
      price: '$50',
      coverImg: 'ettajames.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.ettajamesofficial.com/',
      youtube: 'https://www.youtube.com/channel/UC5-VYAq2iM3qTTFqLZu3mjQ',
      facebook: 'https://www.facebook.com/EttaJames/',
      instagram: 'https://www.instagram.com/ettajamesofficial/',
      twitter: 'https://x.com/ettajames',
      spotify: 'https://open.spotify.com/artist/0iOVhN3tnSvgDbcg25JoJb',
      deezer: 'https://www.deezer.com/mx/artist/620',
      apple: 'https://music.apple.com/us/artist/etta-james/10811?l=es-MX',
      soundcloud:'https://soundcloud.com/ettajamesofficial',
      tidal: 'https://listen.tidal.com/artist/2579',
      wiki: 'https://es.wikipedia.org/wiki/Etta_James',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/Jazz-Blues/Etta-James-Tribute-Boletos',
      tiktok: 'https://www.tiktok.com/@officialettajames',
      napster: 'https://music.amazon.com.mx/artists/B000QJNRFU/etta-james',
    },
    {
      title: 'Bee Gees',
      description:
        "Bee Gees fue un grupo musical britanicoaustraliano de Disco formado por los hermanos Barry, Robin y Maurice Gibb en 1958, el cual se separó en 2012. Conocidos como los «reyes de la música disco» y ampliamente considerados como unas de las mejores agrupaciones en la historia de la música, gozaron de su mayor fama entre 1967 y 1979, con varios temas donde destaca el característico falsete del grupo. En el álbum doble Saturday Night Fever, banda sonora de la película homónima, los Bee Gees aportaron las seis canciones principales. Al vender más de 22 millones de copias, se convirtieron en uno de los grupos más exitosos de toda la historia de la música",
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_n75rcGxb7JxTLDmfpqR0v1-ZtVGP9bWLo&si=wbOJIhCw9zdzXH-6',
      price: 36,
      coverImg: 'beegees.jpg',
      category: 'Soft Rock',
      location: 'UK',
      locationImg: 'england.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.beegees.com/',
      youtube: 'https://www.youtube.com/channel/UCD9sCcKXnFxMeuFoNayVxeQ',
      facebook: 'https://www.facebook.com/beegees/',
      instagram: 'https://www.instagram.com/beegees',
      twitter: 'https://x.com/beegees',
      spotify: 'https://open.spotify.com/artist/1LZEQNv7sE11VDY3SdxQeN',
      deezer: 'https://www.deezer.com/mx/artist/1450',
      apple: 'https://music.apple.com/mx/artist/bee-gees/31937250',
      soundcloud: 'https://soundcloud.com/beegees',
      tidal: 'https://listen.tidal.com/artist/15096',
      wiki: 'https://es.wikipedia.org/wiki/Bee_Gees',
      ticketmaster:
        'https://www.ticketmaster.com/bee-gees-tickets/artist/1284',
      tiktok: 'https://www.tiktok.com/@beegees',
      napster: 'https://music.amazon.com.mx/artists/B000QJRN78/bee-gees',
    },
    
    {
      title: 'Diana Ross',
      description:
        'Diana Ernestine Earle Ross, conocida como Diana Ross (Detroit, Míchigan, 26 de marzo de 1944), es una cantante y actriz estadounidense. Fue la vocalista principal del grupo The Supremes, que se convirtió en el grupo de mayor éxito de Motown durante la década de 1960 y en uno de los grupos femeninos con mayores ventas de todos los tiempos. Siguen siendo el grupo femenino con más éxitos de la historia, con un total de doce sencillos número uno en la lista Billboard Hot 100 de Estados Unidos, entre ellos Where Did Our Love Go, Baby Love, Come See About Me, Stop! In the Name of Love y Love Child.',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mE7OCT5TtpGRgN5IhAD8dfJHGl8jMU-DM&si=UmQxOOUsU6ii0pZF',
      price: '$50',
      coverImg: 'dianaross.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.dianaross.com/',
      youtube: 'https://www.youtube.com/channel/UCP-6vOnN6GHZtJz0719GyDg',
      facebook: 'https://www.facebook.com/DianaRoss/',
      instagram: 'https://www.instagram.com/dianaross/',
      twitter: 'https://x.com/DianaRoss',
      spotify: 'https://open.spotify.com/artist/3MdG05syQeRYPPcClLaUGl',
      deezer: 'https://www.deezer.com/mx/artist/4699',
      apple: 'https://music.apple.com/us/artist/diana-ross/47566?l=es-MX',
      soundcloud:'https://soundcloud.com/dianaross',
      tidal: 'https://listen.tidal.com/artist/10871',
      wiki: 'https://es.wikipedia.org/wiki/Diana_Ross',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/RB-and-Soul-Music/Diana-Ross-Boletos',
      tiktok: 'https://www.tiktok.com/@dianaross',
      napster: 'https://music.amazon.com.mx/artists/B000QJK4TC/diana-ross',
    },{
      title: 'Dionne Warwick',
      description:
        'Marie Dionne Warwick (/diˈɒn ˈwɔːrwɪk/ dee-ON WOR-wik; nacida Warrick; 12 de diciembre de 1940) es una cantante, actriz y presentadora de televisión estadounidense. Warwick se encuentra entre los 40 artistas más exitosos de EE. UU. entre 1955 y 1999, basado en su historial en la lista Hot 100 de Billboard. Es la segunda vocalista femenina con más apariciones en la lista durante la era del rock (1955–1999). También es una de las vocalistas más destacadas de todos los tiempos, con 56 de sus sencillos alcanzando el Hot 100 entre 1962 y 1998 (12 de ellos en el Top Ten), y un total de 80 sencillos, ya sea en solitario o en colaboración, en el Hot 100, R&B o listas de adultos contemporáneos. Warwick ocupa el puesto número 74 en la lista de Billboard Hot 100 de Los mejores artistas de todos los tiempos.Durante su carrera, Warwick ha ganado muchos premios, incluidos seis Premios Grammy. Ha sido incluida en el Paseo de la Fama de Hollywood, el Salón de la Fama del Grammy, el Salón de la Fama del Rhythm & Blues Nacional, el Salón de la Fama del Rock and Roll y el Paseo de la Fama del Teatro Apollo. En 2019, Warwick ganó el Premio a la Trayectoria del Grammy. Tres de sus canciones ("Walk On By", "Alfie" y Dont Make Me Over) han sido incluidas en el Salón de la Fama del Grammy. Es una ex Embajadora de Buena Voluntad de la Organización de las Naciones Unidas para la Alimentación y la Agricultura.',

      videourl:
        'https://www.youtube.com/playlist?list=OLAK5uy_nJhTmHCD5JQDOeS758jlf_ShADHDXAKCk',
      price: '$50',
      coverImg: 'dionewarwick.jpeg',
      category: 'Rythm and Blues',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.officialdionnewarwick.com/',
      youtube: 'https://www.youtube.com/channel/UCtClZvSrqxcyPdWn3yiCzuw',
      facebook: 'https://www.facebook.com/DionneWarwick',
      instagram: 'https://www.instagram.com/therealdionnew/',
      twitter: 'https://x.com/dionnewarwick',
      spotify: 'https://open.spotify.com/artist/2JSjCHK79gdaiPWdKiNUNp',
      deezer: 'https://www.deezer.com/mx/artist/3138',
      apple: 'https://music.apple.com/us/artist/dionne-warwick/16198',
      soundcloud:'https://soundcloud.com/dionnewarwickofficial',
      tidal: 'https://listen.tidal.com/artist/1849',
      wiki: 'https://en.wikipedia.org/wiki/Dionne_Warwick',
      ticketmaster:'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Music/Dionne-Warwick-Boletos?agqi=707a4ebf4b499b049b595fbb4fcc3ea0&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
      tiktok: 'https://www.tiktok.com/@dionnethesinger?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000QJKJHY/dionne-warwick',
    },
    {
      title: 'Gladys Knight and the Pips',
      description:
        'Gladys Maria Knight (Atlanta, Georgia, 28 de mayo de 1944), conocida como «La Emperatriz del Soul», es una cantante, compositora, actriz y empresaria estadounidense. Por siete veces ha ganado el Premio Grammy. Ha grabado éxitos a través de los años 1960 s, 1970 s, y 1980 s con su grupo familiar Gladys Knight & the Pips, que incluye a su hermano Merald "Bubba" Knight y sus sobrinos William Guest y Edward Patten. Gladys Knight ha grabado dos número uno para las listas Billboard hot 100 ("Midnight Train to Georgia" y That s What Friends Are For junto con Dionne Warwick, Sir Elton John y Stevie Wonder), once número uno en las listas de R&B y seis número uno en álbumes R&B. Ha ganado siete Grammys (cuatro como artista en solitario y tres con los Pips) siendo inducida al Salón de la Fama del Rock And Roll y como grupo vocal en el Salón de la Fama con los Pips. Dos de sus canciones (I Heard It Through the Grapevine y Midnight Train to Georgia) fueron inducidas en el Salón de la Fama de los Grammys, por tener un valor significativo histórico y artístico. Grabó el tema de 1989 de la película de James Bond, Licence to Kill. La revista Rolling Stone coloca a Gladys Knight entre las 100 más grandes cantantes de todos los tiempos. También ha recibido la Medalla Nacional de Artes del Kennedy Center Honors.',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_lVc1-YGird_GKiaXvf53FxJMgzZ_WOesg&si=EBKAHNcJDKgZaleO',
      price: '$50',
      coverImg: 'gladys.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.dianaross.com/',
      youtube: 'https://www.youtube.com/channel/UC9x3z7pcuV-Ig-AA1qQsULQ',
      facebook: 'https://www.facebook.com/gladysknight',
      instagram: 'https://www.instagram.com/msgladysknight/',
      twitter: 'https://x.com/MsGladysKnight',
      spotify: 'https://open.spotify.com/artist/0TF2NxkJZPQoX1H53rEFM1',
      deezer: 'https://www.deezer.com/mx/artist/9999',
      apple: 'https://music.apple.com/us/artist/gladys-knight-the-pips/873623?l=es-MX',
      soundcloud:'https://soundcloud.com/gladys-knight-the-pips',
      tidal: 'https://listen.tidal.com/artist/17005',
      wiki: 'https://es.wikipedia.org/wiki/Gladys_Knight',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/R-B-Urban-Soul/Gladys-Knight-Boletos',
      tiktok: 'https://www.tiktok.com/@gladysknight.gk',
      napster: 'https://music.amazon.com.mx/artists/B000QJI0U2/gladys-knight',
    },
    {
      title: 'Bootsy Collins',
      description:
        'William Earl "Bootsy" Collins (Cincinnati, Ohio, Estados Unidos; 26 de octubre de 1951) es un bajista y cantante estadounidense de funk, conocido especialmente por sus trabajos con James Brown y con las dos bandas estandarte de movimiento p-funk, Parliament y Funkadelic, ambas lideradas por George Clinton.',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_kUvpdlW5E9IvgKGe8RStryLlrpmWuIPjU&si=oM5SM6KY0BknJ_wk',
      price: '$50',
      coverImg: 'bootsy.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://bootsycollinsfoundation.org/',
      youtube: 'https://www.youtube.com/channel/UCRKkYBaoEIaE6dIcIYZ1T5Q',
      facebook: 'https://www.facebook.com/BootsyCollins/',
      instagram: 'https://www.instagram.com/Bootsy_Collins',
      twitter: 'https://x.com/Bootsy_Collins',
      spotify: 'https://open.spotify.com/artist/0TF2NxkJZPQoX1H53rEFM1',
      deezer: 'https://www.deezer.com/mx/artist/9999',
      apple: 'https://music.apple.com/us/artist/bootsy-collins/712040',
      soundcloud:'https://soundcloud.com/bootsycollinsofficial',
      tidal: 'https://listen.tidal.com/artist/17005',
      wiki: 'https://es.wikipedia.org/wiki/Bootsy_Collins',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/R+B-Urban-Soul/Bootsy-Collins-Boletos',
      tiktok: 'https://www.tiktok.com/@bootsy_collins',
      napster: 'https://music.amazon.com.mx/artists/B000QJPJSS/bootsy-collins',
    },
    {
      title: ' Martha and The Vandellas',
      description:
        'Martha and the Vandellas (conocidas desde 1967 a 1972 como Martha Reeves and the Vandellas) fue un grupo vocal femenino, uno de los más exitosos de la compañía discográfica Motown durante el periodo comprendido entre 1963 y 1967. En contraste con otros grupos femeninos de Motown, como The Supremes o The Marvelettes, Martha and the Vandellas fueron conocidas por un sonido R&B más duro caracterizado en canciones como «(Love Is Like A) Heat Wave», «Nowhere to Run», «Jimmy Mack» o «Dancing in the Street». Durante sus nueve años funcionando como grupo de 1963 a 1972, Martha and the Vandellas crearon más de veintiséis éxitos y grabaron en diferentes estilos como doo-wop, R&B, pop, blues, rock y soul. Diez canciones alcanzaron el Top Ten de los Billboard R&B singles chart, entre ellos dos R&B número uno. Doce canciones consiguieron colocarse dentro del Top 40 del Billboard Hot 100, con seis canciones dentro de las diez mejores, entre ellas «Dancing in the Street», »Heat Wave», «Nowhere to Run» y «Jimmy Mack».',

      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_nanrno_xMyK7fw1SCxoirBZFMPacLYUUs&si=KWKv_pp4qEbARtRQ',
      price: '$50',
      coverImg: 'marthareeves.jpg',
      category: 'Soul',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.marthareevesofficial.com/',
      youtube: 'https://www.youtube.com/channel/UCuhny_QztUZ3-ayAcOaJzZg',
      facebook: 'https://www.facebook.com/marthareevesandthevandellas/',
      instagram: 'https://www.instagram.com/realmarthareeves/',
      twitter: 'https://x.com/marthareevesvan',
      spotify: 'https://open.spotify.com/artist/1Pe5hlKMCTULjosqZ6KanP',
      deezer: 'https://www.deezer.com/mx/artist/81078',
      apple: 'https://music.apple.com/us/artist/martha-reeves-the-vandellas/13431501?l=es-MX',
      soundcloud:'https://soundcloud.com/martha-reeves-the-vandellas',
      tidal: 'https://listen.tidal.com/artist/16873',
      wiki: 'https://es.wikipedia.org/wiki/Martha_and_the_Vandellas',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/RB-and-Soul-Music/Martha-Reeves-Boletos',
      tiktok: 'https://www.tiktok.com/@martha_reeves',
      napster: 'https://music.amazon.com.mx/artists/B000QKS3OY/martha-reeves-the-vandellas',
    },
    {
      title: 'Billy Paul Williams',
      description:
        'Billy Paul, nacido como Paul Williams (Filadelfia, Pensilvania, 1 de diciembre de 1934-Blackwood, Nueva Jersey, 24 de abril de 2016),​ fue un músico y cantante de música soul.Billy Paul comenzó su carrera musical a los doce años apareciendo en emisoras de radio locales de Filadelfia. Escuchando discos que su madre compraba desarrolló un estilo vocal con raíces en el jazz, el R&B y el pop.',

      videourl:
        'https://youtube.com/playlist?list=PLgbUxt9V-WIH_PsQWFR2v7wbRimqLIckp&si=vvi_p9n6PGjxhVoz',
      price: '$50',
      coverImg: 'billypaulwilliams.jpg',
      category: 'Jazz',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.discogs.com/artist/171857-Billy-Paul',
      youtube: 'https://www.youtube.com/channel/UCA9aSbqbhCGwsIQ8wpuSJxA',
      facebook: 'https://www.facebook.com/billy.p.williams',
      instagram: 'https://www.instagram.com/billy_paul_williams/',
      twitter: 'https://x.com/BillyPW',
      spotify: 'https://open.spotify.com/artist/187xgSpsFH8mMbAcoCW0zE',
      deezer: 'https://www.deezer.com/mx/artist/2076',
      apple: 'https://music.apple.com/us/artist/billy-paul/461093?l=es-MX',
      soundcloud:'https://soundcloud.com/billy-paul-official',
      tidal: 'https://listen.tidal.com/artist/511',
      wiki: 'https://es.wikipedia.org/wiki/Billy_Paul',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/RB-and-Soul-Music/Martha-Reeves-Boletos',
      tiktok: 'https://www.tiktok.com/@billypaulwilliams',
      napster: 'https://music.amazon.com.mx/artists/B000QJY1Z0/billy-paul-williams',
    },
    {
      title: 'Harold Melvin and The Blue Notes',
      description:
        'Harold Melvin & the Blue Notes fue una banda de R&B/Soul y grupo vocal, uno de los más populares de Filadelfia durante los años 70.El repertorio del grupo abarcaba los géneros de, R&B, Doo wop y música disco. Fundado en Filadelfia, Pensilvania durante los años 50 bajo el nombre de The Charlemagnes, el grupo se dio a conocer mediante varios hits entre 1972 y 1976, y continuaron grabando y tocando hasta la muerte de Melvin en 1997. Aun así, los miembros restantes de Blue Note han continuado. Más allá del fundador de grupo y cantante original Harold Melvin,  el miembro que alcanzó mayor popularidad fue Teddy Pendergrass, su cantante durante los exitosos años bajo el sello Philadelphia International. ',

      videourl:
        'https://youtube.com/playlist?list=PLyOtQeA7HWth3NpKQcQljuxlcF8N3ZNlN&si=C6VMLQOd5HzPQtiS',
      price: '$50',
      coverImg: 'haroldmelvin.jpg',
      category: 'Funk',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.discogs.com/artist/30144-Harold-Melvin-And-The-Blue-Notes',
      youtube: 'https://www.youtube.com/channel/UCQOaV8EJBsZw7hGXOcUWmcg',
      facebook: 'https://www.facebook.com/HaroldMelvinTheBlueNotes/',
      instagram: 'https://www.instagram.com/haroldmelvin_thebluenotes/',
      twitter: 'https://x.com/search?q=Harold%20Melvin%20and%20the%20Bluenotes&src=typed_query&f=media',
      spotify: 'https://open.spotify.com/artist/438JBZR1AR0l04AzcYW9gy',
      deezer: 'https://www.deezer.com/mx/artist/11211',
      apple: 'https://music.apple.com/us/artist/harold-melvin-the-blue-notes/661238',
      soundcloud:'https://soundcloud.com/harold-melvin-the-blue-notes',
      tidal: 'https://listen.tidal.com/artist/3885055',
      wiki: 'https://es.wikipedia.org/wiki/Harold_Melvin_%26_the_Blue_Notes',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/R-B-Urban-Soul/Harold-Melvin-and-the-Blue-Notes-Boletos',
      tiktok: 'https://www.tiktok.com/@billypaulwilliams',
      napster: 'https://music.amazon.com.mx/artists/B000QJY1Z0/billy-paul-williams',
    },
    {
      title: 'Lou Rawls',
      description:
        'Louis Allen Lou Rawls (Chicago, Illinois; 1 de diciembre de 1933–Los Ángeles, California; 6 de enero de 2006) fue un cantante estadounidense, compositor, actor, actor de doblaje y productor de discos de soul, jazz y blues. Lanzó más de 60 álbumes, vendió 15 millones de discos y tuvo numerosos sencillos en las listas, siendo la canción más notable You ll Never Find Another Love Like Mine (Nunca encontrarás otro amor como el mío). Trabajó en el cine, televisión y como actor de doblaje en muchos dibujos animados. Fue ganador en tres ocasiones del premio Grammy a la mejor interpretación masculina en R&B. ',

      videourl:
        'https://youtube.com/playlist?list=PLlKQQa8mCl34qvB94eEq_wTLxBqxfWITo&si=oS7wRvL3XK4RPXcU',
      price: '$50',
      coverImg: 'lourawls.jpg',
      category: 'Funk',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.britannica.com/biography/Lou-Rawls',
      youtube: 'https://www.youtube.com/channel/UCYHFm9qpYz6iLR-ok-ydshA',
      facebook: 'https://www.facebook.com/profile.php?id=61552624160818',
      instagram: 'https://www.instagram.com/lourawlsjr/',
      twitter: 'https://x.com/This_Is_Bama77',
      spotify: 'https://open.spotify.com/artist/1zJBFCev9UwOMcrZsLi2od',
      deezer: 'https://www.deezer.com/mx/artist/15939',
      apple: 'https://music.apple.com/us/artist/lou-rawls/640588',
      soundcloud:'https://soundcloud.com/lou-rawls',
      tidal: 'https://listen.tidal.com/artist/10107',
      wiki: 'https://es.wikipedia.org/wiki/Lou_Rawls',
      ticketmaster:
        'https://www.viagogo.com.mx/Boletos-Conciertos/R-B-Urban-Soul/Harold-Melvin-and-the-Blue-Notes-Boletos',
      tiktok: 'https://www.tiktok.com/search/video?q=lou%20Rawls&t=1726293032945',
      napster: 'https://music.amazon.com.mx/artists/B000QKGG1Q/lou-rawls',
    },
    {
      title: 'Bill Withers',
      description:
        'William Harrison Withers Jr. (July 4, 1938 – March 30, 2020) was an American singer and songwriter. He had several hits over a career spanning 18 years, including Aint No Sunshine (1971), Grandmas Hands (1971), Use Me (1972), Lean on Me (1972), Lovely Day (1977) and Just the Two of Us (1980). Withers won three Grammy Awards and was nominated for six more. His life was the subject of the 2009 documentary film Still Bill.[2] Withers was inducted into the Songwriters Hall of Fame in 2005 and Rock and Roll Hall of Fame in 2015. Two of his songs (Aint No Sunshine and Lean on Me) were inducted into the Grammy Hall of Fame',

      videourl:
        'https://youtube.com/playlist?list=PL48v1k-hVuC0U1xXEFjTlsPdaQC_a03_w&si=_kotSCf0mB1F2kSE',
      price: '$50',
      coverImg: 'billwithers.jpg',
      category: 'Funk',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://billwithers.com/',
      youtube: 'https://www.youtube.com/channel/UCBTrTXbSiv4Pq7As_tcB0UA',
      facebook: 'https://www.facebook.com/BillWithers/',
      instagram: 'https://www.instagram.com/RealBillWithers',
      twitter: 'https://x.com/billwithers',
      spotify: 'https://open.spotify.com/artist/1ThoqLcyIYvZn7iWbj8fsj',
      deezer: 'https://www.deezer.com/mx/artist/1118',
      apple: 'https://music.apple.com/us/artist/bill-withers/339834',
      soundcloud:'https://soundcloud.com/bill-withers-official',
      tidal: 'https://listen.tidal.com/artist/73',
      wiki: 'https://en.wikipedia.org/wiki/Bill_Withers',
      ticketmaster:'https://www.viagogo.com.mx/Boletos-Conciertos/R-B-Urban-Soul/Bill-Withers-Boletos?agqi=521fd86b3c1e4b9c0e435d03016ed422&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
      tiktok: 'https://www.tiktok.com/@billwithersfanpage?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000QJR8AK/bill-withers',
    },
    {
      title: 'Bill Withers',
      description:
        'William Harrison Withers Jr. (July 4, 1938 – March 30, 2020) was an American singer and songwriter. He had several hits over a career spanning 18 years, including Aint No Sunshine (1971), Grandmas Hands (1971), Use Me (1972), Lean on Me (1972), Lovely Day (1977) and Just the Two of Us (1980). Withers won three Grammy Awards and was nominated for six more. His life was the subject of the 2009 documentary film Still Bill.[2] Withers was inducted into the Songwriters Hall of Fame in 2005 and Rock and Roll Hall of Fame in 2015. Two of his songs (Aint No Sunshine and Lean on Me) were inducted into the Grammy Hall of Fame',

      videourl:
        'https://youtube.com/playlist?list=PL48v1k-hVuC0U1xXEFjTlsPdaQC_a03_w&si=_kotSCf0mB1F2kSE',
      price: '$50',
      coverImg: 'billwithers.jpg',
      category: 'Funk',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://billwithers.com/',
      youtube: 'https://www.youtube.com/channel/UCBTrTXbSiv4Pq7As_tcB0UA',
      facebook: 'https://www.facebook.com/BillWithers/',
      instagram: 'https://www.instagram.com/RealBillWithers',
      twitter: 'https://x.com/billwithers',
      spotify: 'https://open.spotify.com/artist/1ThoqLcyIYvZn7iWbj8fsj',
      deezer: 'https://www.deezer.com/mx/artist/1118',
      apple: 'https://music.apple.com/us/artist/bill-withers/339834',
      soundcloud:'https://soundcloud.com/bill-withers-official',
      tidal: 'https://listen.tidal.com/artist/73',
      wiki: 'https://en.wikipedia.org/wiki/Bill_Withers',
      ticketmaster:'https://www.viagogo.com.mx/Boletos-Conciertos/R-B-Urban-Soul/Bill-Withers-Boletos?agqi=521fd86b3c1e4b9c0e435d03016ed422&agi=stubhub&agut=a760ad47788c8d6046fa128d8b2df1efdb49c819',
      tiktok: 'https://www.tiktok.com/@billwithersfanpage?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000QJR8AK/bill-withers',
    },
]

export default CardData28