const CardData30 = [
  {
    title: 'Thalia',
    description:
      'Ariadna Thalía Sodi Miranda (Ciudad de México, 26 de agosto de 1971),nota 1​ conocida como Thalía, es una cantante, actriz y empresaria mexicana.Inició su carrera musical a principios de los años 1980 como vocalista de un grupo infantil llamado Din-Din y en 1986 se integró a la banda Timbiriche, con la que grabó tres álbumes de estudio,3​ y de la que se separó en 1989.4​ Poco después viajó a Los Ángeles (California) para prepararse como solista.5​ Regresó a México en 1990 y publicó su primer álbum como solista titulado Thalía, bajo el sello discográfico Fonovisa, al que siguieron Mundo de cristal en 1991 y Love en 1992, los cuales lograron éxito en ventas en territorio mexicano',
    videourl:
      'https://youtube.com/playlist?list=PL1Xix0gugm8tJnlJklE9F78MVWWvB6mCS',
    price: 50,
    coverImg: 'thalia.jpg',
    category: 'Pop',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thalia.com/',
    youtube: 'https://www.youtube.com/channel/UCgyFSSvdNv3MC_atQPqM_XA',
    facebook: 'https://www.facebook.com/Thalia',
    instagram: 'https://www.instagram.com/thalia/',
    twitter: 'https://twitter.com/thalia',
    spotify: 'https://open.spotify.com/artist/23wEWD21D4TPYiJugoXmYb',
    deezer: 'https://www.deezer.com/mx/artist/710',
    apple: 'https://music.apple.com/mx/artist/thalia/88612122',
    soundcloud: 'https://soundcloud.com/thalia-54826',
    tidal: 'https://listen.tidal.com/artist/3505335',
    wiki: 'https://es.wikipedia.org/wiki/Thal%C3%ADa',
    ticketmaster: 'https://www.ticketmaster.com./thalia-tickets/artist/910999',
    tiktok: 'https://www.tiktok.com/@thalia?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001JQEVBI/thalia',
  },
  {
    title: 'Peso Pluma',
    description:
      'Hassan Emilio Kabande Laija (Zapopan, Jalisco; 15 de junio de 1999), conocido como Peso Pluma, es un cantante mexicano. Se especializa en el subgénero de corridos tumbados.Su fama internacional comenzó en 2022, después de colaborar con cantantes como Luis R. Conriquez y Natanael Cano.3​ Algunos de sus éxitos son «El Belicón», «Siempre pendientes», «PRC», «Por las noches», «AMG», «Ella baila sola» y «La bebé (Remix)». Debido a su fama ha logrado colaborar también con artistas de éxito mundial como Becky G,5​ Nicki Nicole6​, Marshmello,7​y Ovy on the Drums.Su estilo musical se caracteriza por un toque acústico con influencias de la música urbana como el trap mezclados con sonido de los corridos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXbLwh-AdLa1iHtuW_y_J9b',
    price: 80,
    coverImg: 'pesopluma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rioranchoeventscenter.com/event/peso-pluma/',
    youtube: 'https://www.youtube.com/channel/UCzrM_068Odho89mTRrrxqbA',
    facebook: 'https://www.facebook.com/PesoPlumaOficial',
    instagram: 'https://www.instagram.com/pesopluma/',
    twitter: 'https://twitter.com/ElPesoPluma',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/80365122',
    apple: 'https://music.apple.com/mx/artist/peso-pluma/1500139475?l=en',
    soundcloud: 'https://soundcloud.com/pesopluma',
    tidal: 'https://listen.tidal.com/artist/17614478',
    wiki: 'https://es.wikipedia.org/wiki/Peso_Pluma_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com/peso-pluma-tickets/artist/2979041',
    tiktok: 'https://www.tiktok.com/@therealpesopluma',
    napster: 'https://music.amazon.com.mx/artists/B082C6RXYG',
  },
  {
    title: 'Gera MX',
    description:
      'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
    price: '$50',
    coverImg: 'GeraMX.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://richvagosent.com/',
    youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
    facebook: 'https://www.facebook.com/GERAMX444/',
    instagram: 'https://www.instagram.com/geramx1/',
    twitter: 'https://twitter.com/mxgeramx',
    spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
    deezer: 'https://www.deezer.com/mx/artist/6661704',
    apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
    soundcloud: 'https://soundcloud.com/geramx-music',
    tidal: 'https://listen.tidal.com/artist/8511790',
    wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
    ticketmaster:
      'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
    tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
    napster: 'https://app.napster.com/artist/gera-mx',
  },
  {
    title: 'El Fantasma',
    description:
      "Alexander García was born in Las Canas, Durango. He worked as a gardener for six years before becoming a singer.He performs regional Mexican music[2] and corrido songs about hardworking people. On social media, fans nickname him the King from the Underground.His nickname began because his friends and family call him El Fanta.[3] He was finalist at the 2019 Billboard Latin Music Awards.In 2019, El Fantasma's single Encantadora charted number 1 on Regional Mexican Airplay. On August 14, 2021, his single Soy Buen Amigo charted number 1 on Regional Mexican Airplay. It also charted 27 on Hot Latin Songs",
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLaKik8hFaPZGymkNFbeU8zvZVj0QvugDV',
    price: 80,
    coverImg: 'elfantasma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC9kHcAu8pGPY7PyKOnK7pfg',
    facebook: 'https://www.facebook.com/elfantasmaoficial/',
    instagram: 'https://www.instagram.com/elfantasmaoficial/',
    twitter: 'https://twitter.com/El_Fanta45',
    spotify: 'https://open.spotify.com/artist/0my6Pg4I28dVcZLSpAkqhv',
    deezer: 'https://www.deezer.com/mx/artist/5230088',
    apple: 'https://music.apple.com/mx/artist/el-fantasma/1245916228',
    soundcloud: 'https://soundcloud.com/elfantasma-music',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/El_Fantasma_(singer)',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@elfantasma.oficial',
    napster: 'https://music.amazon.com.mx/artists/B003DHLCDQ/el-fantasma',
  },
  {
    title: 'Rancho Humilde Records',
    description:
      'Jimmy Humilde, es considerado como “uno de los hombres que más misterio genera entorno a su persona” en el mundo del espectáculo musical.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL90dAa2YBl3WBt_zO-drgdq',
    price: 50,
    coverImg: 'RanchoHumilde.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://ranchohumildestore.com/',
    youtube: 'https://www.youtube.com/c/RanchoHumildeoficial',
    facebook: 'https://www.facebook.com/ranchohumildeofficial',
    instagram: 'https://www.instagram.com/ranchohumilde/',
    twitter: 'https://twitter.com/ranchohumilde',
    spotify: 'https://open.spotify.com/artist/7iSpMTdILobashwyZorN25',
    deezer: 'https://www.deezer.com/mx/artist/89145252',
    apple: 'https://music.apple.com/mx/artist/rancho-humilde/1077575821',
    soundcloud: 'https://soundcloud.com/ranchohumilde',
    tidal: 'https://listen.tidal.com/artist/14100262',
    wiki:
      'https://www.latimes.com/espanol/entretenimiento/articulo/2021-05-21/rancho-humilde-records-con-sede-en-downey',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://music.amazon.com.mx/albums/B08QHZ673C',
  },
  {
    title: 'Tercer Elemento',
    description:
      'T3R Elemento fue formado por tres jóvenes de origen mexicano y un cubano en Las Vegas, Estados Unidos. Dado que realizaron dos intentos previos de formar un grupo, ante esos fracasos decidieron llamar al grupo T3R Elemento en referencia a que era la tercera vez que lo intentaban.Kristopher Nava fue el fundador del grupo. En 2016 publicaron su primer disco llamado Rafa Caro en Vivo y en 2018 publicaron the green trip. En 2018 fueron nominados por Álbum Favorito-Regional Mexicano a los Premios Latin American Music debido a su creciente popularidad la cual se ha dado en plataformas como Youtube. Sus temas han sido incluidos en la lista Billboard en los Estados Unidos .',
    videourl:
      'http://youtube.com/playlist?list=PLkzsyPO-ybMTw1qR-AdG9_urQq3-nfr6b',
    price: 50,
    coverImg: '3erElemento.jpg',
    category: 'Sierreño',
    location: 'USA',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    youtube: 'https://www.youtube.com/channel/UCucxqba8ReybE6oIs9D1_sg',
    facebook: 'https://www.facebook.com/t3relemento/',
    instagram: 'https://www.instagram.com/t3relementooficial/',
    twitter: 'https://twitter.com/t3r_elemento?lang=en',
    spotify: 'https://open.spotify.com/artist/34nbQa7Hug9DYkRJpfKNFv',
    deezer: 'https://www.deezer.com/mx/artist/72293892',
    apple: 'https://music.apple.com/mx/artist/t3r-elemento/1099155524',
    soundcloud: 'https://soundcloud.com/tercer-elemento',
    tidal: 'https://listen.tidal.com/album/129171726',
    wiki: 'https://es.wikipedia.org/wiki/T3R_Elemento',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@t3relementoofficial?lang=es',
    napster: 'https://web.napster.com/artist/t3r-elemento',
    bandera: '/tercerelemento',
  },
  {
    title: 'Grupo Firme',
    description:
      'Grupo Firme es una agrupación de música regional mexicana. Empezaron cómo grupo norteño, pero también han grabado con banda sinaloense. Ha recibido nominaciones para una variedad de programas que incluyen: Premios Juventud y Premio Lo Nuestro, resultando ganador en 2021 de este último con cinco distinciones.El fundador de la agrupación musical es el Choixcense de 32 años, Jairo Corrales el cual creó a la banda después de conocerse en un evento en la ciudad de Tijuana.',
    videourl: 'http://youtube.com/playlist?list=UUFlC8U0Uif2hF0FIgsSIIYQ',
    price: 50,
    coverImg: 'grupoFirme.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://grupofirmecorp.com/',
    youtube: 'https://www.youtube.com/channel/UCFlC8U0Uif2hF0FIgsSIIYQ',
    facebook: 'https://www.facebook.com/GrupoFirmeOficial',
    instagram: 'https://www.instagram.com/grupofirme/',
    twitter: 'https://twitter.com/GrupoFirme',
    spotify:
      'https://open.spotify.com/artist/1dKdetem2xEmjgvyymzytS?si=tWOpv5SYT7CkjsAVgCHHjw&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/grupo-firme/1086320682',
    soundcloud: 'https://soundcloud.com/grupofirme',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://en.wikipedia.org/wiki/Grupo_Firme',
    ticketmaster:
      'https://www.ticketmaster.com/grupo-firme-tickets/artist/2724935',
    tiktok: 'https://www.tiktok.com/@grupofirme?lang=es',
    napster: 'https://app.napster.com/artist/grupo-firme',
  },
  {
    title: 'Natanael Cano',
    description: ' Auditorio Telmex - Jueves 01 Junio 21:00 hrs ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natan.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/profile.php?id=100077546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://app.napster.com/artist/natanael-cano',
  },

  {
    title: 'C Kan',
    description:
      'José Luis Maldonado Ramos (Guadalajara, Jalisco, 26 de julio de 1987),1​ más conocido por su nombre artístico C-Kan, es un rapero, compositor, productor y actor mexicano.2​ Su nombre artístico proviene de la palabra Can, (Perro)3​ y la letra C, de cancha: por el lugar en el que vivía La Cancha 98 C, Perro», estilizándose como C-Kan).4​ Ganó gran fama en 2012 con su álbum de estudio Voy por el sueño de muchos, alcanzando el número 1 de ventas en iTunes México​',
    videourl: 'https://youtube.com/playlist?list=UUkD89TEFWsTZluY6tk84yqg',
    price: 50,
    coverImg: 'ckan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.masteredtrax.com/',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/CKanMEX/',
    instagram: 'https://www.instagram.com/ckan98/',
    twitter: 'https://twitter.com/ckan98',
    spotify:
      'https://open.spotify.com/artist/1QhaqxeqF9sipS2gwbEKpu?si=qt0Wjxi4SiuG0poydNjOxA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4252576',
    apple: 'https://music.apple.com/mx/artist/c-kan/305830705',
    soundcloud: 'https://soundcloud.com/ckan98',
    tidal: 'https://listen.tidal.com/artist/4893770',
    wiki: 'https://es.wikipedia.org/wiki/C-Kan',
    ticketmaster: 'https://www.ticketmaster.com./ckan-tickets/artist/2258547',
    tiktok: 'https://www.tiktok.com/@c.kan98?lang=es',
    napster: 'https://app.napster.com/artist/c-kan',
  },
  {
    title: 'Santa Fe Klan',
    description:
      'Angel Jair Quezada Jasso (Guanajuato, Guanajuato, 29 de noviembre de 1999), más conocido por su nombre artístico Santa Fe Klan, es un rapero, cantante y compositor mexicano, que incorpora a su producción musical géneros como la cumbia y el rap mexicano.Nació y creció en la colonia Santa Fe de la ciudad de Guanajuato, de donde tomó su nombre artístico. Tuvo interés en la música desde muy pequeño luego de que sus padres le comprara instrumentos musicales. A los 13 años aprendió a grabar sus propias canciones y a los 14 ya contaba con un estudio propio. Se mudó de Guanajuato a Guadalajara para proseguir su carrera artística. En esa ciudad se unió a Alzada Récords, un grupo dedicado al impulso del rap mexicano, mismo con el que grabó sus primeras producciones, y que dejó en 2020. En ese año colaboró con Akwid y Neto Peña en el sencillo «Gallo de pelea».',
    videourl:
      'http://youtube.com/playlist?list=PLtm_sA0aLudCVWvu1Bo4FCcaHTfRpHkx_',
    price: '$50',
    coverImg: 'SantafeKlan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Santa_Fe_Klan',
    youtube: 'https://www.youtube.com/channel/UC4Ge3cqrdyOszmnJTcMICcg',
    facebook: 'https://www.facebook.com/santafeklanoficial',
    instagram: 'https://www.instagram.com/santa_fe_klan_473/?hl=es-la',
    twitter: 'https://twitter.com/santa_fe_klan_',
    spotify: 'https://open.spotify.com/artist/4tm8CEdm4pkQsEh4jIr9Yp',
    deezer: 'https://www.deezer.com/mx/artist/10816636',
    apple: 'https://music.apple.com/mx/artist/santa-fe-klan/1140191076',
    soundcloud: 'https://soundcloud.com/santafeklan',
    tidal: 'https://listen.tidal.com/artist/8034076',
    wiki: 'https://es.wikipedia.org/wiki/Santa_Fe_Klan',
    ticketmaster:
      'https://www.ticketmaster.com/santa-fe-klan-tickets/artist/2802476',
    tiktok: 'https://www.tiktok.com/@santa.feklan473?lang=es',
    napster: 'https://app.napster.com/artist/santa-fe-klan',
  },

  {
    title: 'Eden Muñoz',
    description:
      'Rodolfo Edén Muñoz Cantú (Los Mochis, Sinaloa, 25 de septiembre de 1990), es un cantante, compositor y productor mexicano de música regional mexicana. Muñoz es el menor de 4 hermanos del matrimonio entre Rodolfo Muñoz e Isidora Cantú quienes por azares del destino la vida los traslada a la ciudad de Mazatlán, Sinaloa, donde más adelante su crianza y entorno lo llevaría de lleno a la música.Desde muy pequeño Edén comenzó a dar muestra que había nacido para esto, ya que sin ninguna pena interpretaba canciones y le cambiaba la letra a estas sin saber que más adelante serviría de plataforma para lograr grandes composiciones que han marcado el rumbo de la música regional mexicana en los últimos tiempos..',
    videourl:
      'https://youtube.com/playlist?list=PLLlCI9n44muADESckd8b6oOL1cmfGLz35',
    coverImg: 'edenmunoz.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCJm7UPWbVhuNe3achnRi8iA',
    facebook: 'https://www.facebook.com/EdenMunozOficial/',
    instagram: 'https://www.facebook.com/EdenMunozOficial/',
    twitter: 'https://twitter.com/edenmunozof',
    spotify: 'https://open.spotify.com/artist/66ihevNkSYNzRAl44dx6jJ',
    deezer: 'https://www.deezer.com/mx/artist/4414006',
    apple: 'https://music.apple.com/mx/artist/eden-mu%C3%B1oz/383984059',
    soundcloud: 'https://soundcloud.com/edenmunoz-music',
    tidal: 'https://listen.tidal.com/artist/12062930',
    wiki: 'https://es.wikipedia.org/wiki/Ed%C3%A9n_Mu%C3%B1oz',
    ticketmaster:
      'https://www.ticketmaster.com/eden-munoz-tickets/artist/Z7r9jZa8yb',
    tiktok: 'https://www.tiktok.com/@edenmunoz1?lang=es',
    napster: 'https://web.napster.com/artist/eden-munoz-4',
  },

  {
    title: 'Glass Animals',
    description:
      'Glass Animals es una banda inglesa de indie rock originaria de Oxford. Sus miembros son Dave Bayley (vocalista principal, guitarra), Drew MacFarlane (guitarra, teclado, voces), Edmund Irwin-Singer (bajo, teclado, voces), y Joe Seaward (batería).1​ El grupo liberó su álbum debut Zaba en junio de 2014, bajo el sello Wolf Tone, del productor Paul Epworth, mientras que su segundo álbum How to Be a Human Being fue lanzado el 26 de agosto de 2016, siendo aclamado por la crítica. Su tercer álbum, Dreamland fue estrenado en agosto de 2020. La banda ha realizado tours internacionalmente y se ha presentado en festivales como Austin City Limits, Summer Well, Glastonbury, Coachella, Bonnaroo, Lollapalooza, Reading/Leeds, Falls Festival (Australia), Firefly Music Festival,2​ St Jerome Laneway Festival, Southbound y Bestival.',
    videourl:
      'https://youtube.com/playlist?list=PL8tkzXKlhGxma29yf8aCx8F65q62pVtJZ',
    price: 50,
    coverImg: 'glassanimals.jpeg',
    category: 'Pop',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://opensource.glassanimals.com/',
    youtube: 'https://www.youtube.com/channel/UCJTs-KheOMNstaGrDL4K55Q',
    facebook: 'https://www.facebook.com/glassanimals',
    instagram: 'https://www.instagram.com/glassanimals/',
    twitter: 'https://twitter.com/glassanimals',
    spotify: 'https://open.spotify.com/artist/4yvcSjfu4PC0CYQyLy4wSq',
    deezer: 'https://www.deezer.com/mx/artist/2489131',
    apple: 'https://music.apple.com/mx/artist/glass-animals/528928008',
    soundcloud: 'https://soundcloud.com/glassanimals',
    tidal: 'https://listen.tidal.com/artist/4611746',
    wiki: 'https://es.wikipedia.org/wiki/Glass_Animals',
    ticketmaster:
      'https://www.ticketmaster.com./glass-animals-tickets/artist/1916866',
    tiktok: 'https://www.tiktok.com/@glassanimalsofficial?lang=es',
    napster: 'https://us.napster.com/artist/glass-animals',
  },

  {
    title: 'Danna Paola',
    description:
      'Danna Paola Rivera Munguía (Ciudad de México, 23 de junio de 1995) es una cantante, actriz, modelo y compositora mexicana. Ha sido ganadora de diferentes premios, entre los que se encuentran 9 Kids Choice Awards, 4 MTV Millennial Awards, 4 MTV Italian Music Awards, 1 MTV Europe Music Awards y 2 premios de la Sociedad de Autores y Compositores de México por «Oye Pablo» y «Sodio». Además fue nominada al Grammy Latino por su sexto álbum de estudio K.O. en 2021..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mGnO3i2McuE6K6O4qSKx4F9shuqnG0bKg',
    price: '$50',
    coverImg: 'dannapaola.png',
    category: 'Bachatta',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://universalmusicmexico.lnk.to/TourXT4S1S',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/DannaPaola',
    instagram: 'https://www.instagram.com/dannapaola/',
    twitter: 'https://twitter.com/dannapaola',
    spotify: 'https://open.spotify.com/artist/5xSx2FM8mQnrfgM1QsHniB',
    deezer: 'https://www.deezer.com/mx/artist/318108',
    apple: 'https://music.apple.com/mx/artist/danna-paola/23148284',
    soundcloud: 'https://soundcloud.com/danna-paola-official',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://en.wikipedia.org/wiki/Danna_Paola',
    ticketmaster:
      'https://www.ticketmaster.com.mx/danna-paola-boletos/artist/2581279',
    tiktok: 'https://www.tiktok.com/@dannapaola?lang=es',
    napster: 'https://us.napster.com/artist/danna-paola',
  },
  {
    title: 'Rauw Alejandro',
    description:
      'Raúl Alejandro Ocasio Ruiz (born January 10, 1993), known professionally as Rauw Alejandro, is a Puerto Rican singer and songwriter. Referred to as the King of Modern Reggaeton, he belongs to the new generation of Puerto Rican urban musicians. His debut studio album, Afrodisíaco, was released in November 2020. His second studio album, Vice Versa, released in June 2021, featured the lead single Todo de Ti. He has won one Latin Grammy Award from four nominations..',
    videourl:
      'https://youtube.com/playlist?list=PLhK8A9K_cnZS4LXKUgFVIU8ogoASU3FKO',
    price: 80,
    coverImg: 'Rauw.png',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rauwalejandro.com/en/',
    youtube: 'https://www.youtube.com/channel/UC_Av98lDjf5KvFib5elhpYg',
    facebook: 'https://www.facebook.com/rauwalejandro',
    instagram: 'https://www.instagram.com/rauwalejandro/',
    twitter: 'https://twitter.com/rauwalejandro',
    spotify: 'https://open.spotify.com/artist/1mcTU81TzQhprhouKaTkpq',
    deezer: 'https://www.deezer.com/en/artist/11289472',
    apple: 'https://music.apple.com/mx/artist/rauw-alejandro/1107186883',
    soundcloud: 'https://soundcloud.com/rauwalejandro',
    tidal: 'https://listen.tidal.com/artist/8239477',
    wiki: 'https://en.wikipedia.org/wiki/Rauw_Alejandro',
    ticketmaster:
      'https://www.ticketmaster.com/rauw-alejandro-tickets/artist/2628019',
    tiktok: 'https://www.tiktok.com/@rauwalejandro?lang=es',
    napster: 'https://us.napster.com/artist/rauw-alejandro',
  },
  {
    title: 'Nikki Nicole',
    description:
      'Nicole Denise Cucco (Rosario, Santa Fe; 25 de agosto de 2000), conocida artísticamente como Nicki Nicole, es una cantante, rapera y compositora argentina.En 2019, saltó a la fama con el sencillo «Wapo Traketero», logrando obtener millones de reproducciones en YouTube en un corto período de tiempo después de ser publicado. La canción se convirtió en un fenómeno viral, logrando ingresar al listado Argentina Hot 100 de Billboard.1​2​3​En agosto de ese mismo año terminó ganando más reconocimiento a nivel nacional e internacional con la publicación de su Music Session junto al productor Bizarrap, la cual actualmente cuenta con más de 168 millones de reproducciones. En noviembre de ese mismo año lanzó su primer álbum de estudio de nombre Recuerdos, en el cual se encontraron temas como «Años luz», «Shorty», «Plegarias», entre otros. El álbum recibió nominaciones a distintos premios...',
    videourl:
      'https://youtube.com/playlist?list=PLx9s57CIB6KpDBix2RWKfFLnjT2uh9lk5',
    price: '$50',
    coverImg: 'nikkinicole.jpg',
    category: 'Bachatta',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.nickinicole.com/',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/naikinai19',
    instagram: 'https://www.instagram.com/nicki.nicole/',
    twitter: 'https://twitter.com/naikinai19',
    spotify: 'https://open.spotify.com/artist/2UZIAOlrnyZmyzt1nuXr9y',
    deezer: 'https://www.deezer.com/mx/artist/13299379',
    apple: 'https://music.apple.com/us/artist/nicki-nicole/1292945516',
    soundcloud: 'https://soundcloud.com/musica-urbana-rp/nicki-nicole-frio',
    tidal: 'https://listen.tidal.com/artist/13775348',
    wiki: 'https://es.wikipedia.org/wiki/Nicki_Nicole',
    ticketmaster:
      'https://www.ticketmaster.com/nicki-nicole-tickets/artist/Z7r9jZa3aR',
    tiktok: 'https://www.tiktok.com/@nicki.nicole',
    napster: 'https://us.napster.com/artist/nicki-nicole',
  },
  {
    title: 'Farruko',
    description:
      'Debutó en 2007 con «Bla, bla, bla», la cual tuvo la producción de Phantom y Villa y con el cual logró posicionarse dentro del ámbito musical, por lo que gracias a esto, lanzó canciones como «El Penthouse», «El paseo por el bloque» con Ñengo Flow y «No me atrevo» en 2007, «Escala a mi cama» con Galante & Killatonez y Ñengo Flow, «Sata es» con Galante & Killatonez y «Sexo fuera del planeta» con Fifer en 2008, con las cuales logró consolidarse como unos los talentos nuevos del reguetón.',
    videourl: 'http://youtube.com/playlist?list=PL98809762BD444BAB',
    price: 80,
    coverImg: 'Farruko.jpg',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://farruko.com/',
    youtube: 'https://www.youtube.com/c/farruko',
    facebook: 'https://www.facebook.com/farrukoofficial/',
    instagram: 'https://www.instagram.com/farruko/',
    twitter: 'https://twitter.com/FarrukoOfficial',
    spotify: 'https://open.spotify.com/artist/329e4yvIujISKGKz1BZZbO',
    deezer: 'https://www.deezer.com/mx/artist/614223',
    apple: 'https://music.apple.com/mx/artist/farruko/364377482',
    soundcloud: 'https://soundcloud.com/farrukooficial',
    tidal: 'https://listen.tidal.com/artist/4016402',
    wiki: 'https://es.wikipedia.org/wiki/Farruko',
    ticketmaster:
      'https://www.ticketmaster.com./farruko-tickets/artist/1936418',
    tiktok: 'https://www.tiktok.com/@farruko.fans?lang=es',
    napster: 'https://app.napster.com/artist/farruko',
  },
  {
    title: 'Karol G',
    description:
      'Carolina Giraldo Navarro Medellín, Colombia; 14 de febrero de 1991, conocida artísticamente como Karol G, es una cantante y compositora colombiana de reguetón, pop y trap latino.Se lanzó internacionalmente con la canción «301» junto con el cantante Reykon el líder en 2012, posicionado en el top de varias estaciones de radios y canales de televisión,3​ y le permitió realizar conciertos en países como Aruba, Colombia, Ecuador y Estados Unidos.4​ Obtuvo mayor reconocimiento en 2013, cuando lanzó la canción «Amor de dos» junto con Nicky Jam.5​ En 2016, luego de que lanzó varios sencillos, firmó un contrato con la discográfica Universal Music Latin Entertainment, y hubo un ascenso en su carrera..',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'karolG.jpeg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.karolgmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCZuPJZ2kGFdlbQu1qotZaHw',
    facebook: 'https://www.facebook.com/KarolGOficial',
    instagram: 'https://www.instagram.com/karolg/',
    twitter: 'https://twitter.com/KarolGOnStats',
    spotify: 'https://open.spotify.com/artist/790FomKkXshlbRYZFtlgla',
    deezer: 'https://www.deezer.com/mx/artist/5297021',
    apple: 'https://music.apple.com/us/artist/karol-g/290814601',
    soundcloud: 'https://soundcloud.com/karolgofficial',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://es.wikipedia.org/wiki/Karol_G',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Karol+G',
    tiktok: 'https://www.tiktok.com/@karolg',
    napster: 'https://us.napster.com/artist/karol-g',
  },
  {
    title: 'Maluma',
    description:
      'Maluma has a number of singles that have charted within the top ten on Billboard Hot Latin Songs, including Felices los 4, Borró Cassette, and Corazón. His collaborative efforts Chantaje with Shakira and Medellín with Madonna have reached the top of the Hot Latin Songs and the Dance Club Songs chart, respectively. He has worked with other international artists, such as Ricky Martin, J Balvin, and The Weeknd. Maluma has won a Latin Grammy Award, an MTV Video Music Award, two Latin American Music Awards, and been nominated for a Grammy Award for Best Latin Pop Album.',
    videourl:
      'http://youtube.com/playlist?list=PLI2uE28GO_poIPrHuSGt2Ru6Er5AzCyk5',
    price: 36,
    coverImg: 'maluma.jpg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://maluma.online/?fbclid=IwAR2kZKmUfqJ3sRMh-jl3eHSM0apR2-lEeKxsGC9d_VT1XsfmqCbVgyzZ5eY',
    youtube: 'https://www.youtube.com/c/MalumaOfficialChannel',
    facebook: 'https://www.facebook.com/MALUMAMUSIK',
    instagram: 'https://www.instagram.com/maluma/?hl=en',
    twitter:
      'https://twitter.com/maluma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/1r4hJ1h58CWwUQe3MxPuau',
    deezer: 'https://www.deezer.com/mx/artist/1424602',
    apple: 'https://music.apple.com/mx/artist/maluma/448916501',
    soundcloud: 'https://soundcloud.com/malumaofficial',
    tidal: 'https://listen.tidal.com/artist/4098182',
    wiki: 'https://es.wikipedia.org/wiki/Maluma',
    ticketmaster: 'https://www.ticketmaster.com./maluma-tickets/artist/2095829',
    tiktok: 'https://www.tiktok.com/@maluma1472?lang=es',
    napster: 'https://app.napster.com/artist/maluma',
  },
  {
    title: 'JBalvin',
    description:
      'José Álvaro Osorio Balvín, conocido artísticamente como J Balvin, es un cantante, compositor y productor discográfico colombiano. Ha logrado posicionarse tanto en el mercado musical hispano como en el mercado musical de habla inglesa, llegando a colocar sus temas en número uno en varias listas musicales, entre ellas Billboard. También es conocido como el Príncipe del Reguetón.',
    videourl:
      'http://youtube.com/playlist?list=PLWgVwtnp9KujXASQJ5gPUqHlEeLx-A4IE',
    price: '$50',
    coverImg: 'jBalvin.jpg',
    category: 'Reggaeton',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://jbalvin.com/',
    youtube: 'https://www.youtube.com/channel/UCt-k6JwNWHMXDBGm9IYHdsg',
    facebook: 'https://www.facebook.com/JBalvinReal',
    instagram: 'https://www.instagram.com/jbalvin.man.0/',
    twitter: 'https://twitter.com/jbalvin',
    spotify: 'https://open.spotify.com/artist/1vyhD5VmyZ7KMfW5gqLgo5',
    deezer: 'https://www.deezer.com/mx/artist/4860761',
    apple: 'https://music.apple.com/mx/artist/j-balvin/444520760',
    soundcloud: 'https://soundcloud.com/j-balvin-official',
    tidal: 'https://listen.tidal.com/artist/5054713',
    wiki: 'https://es.wikipedia.org/wiki/J_Balvin',
    ticketmaster:
      'https://www.ticketmaster.com./j-balvin-tickets/artist/1907948',
    tiktok: 'https://www.tiktok.com/@jbalvin2794?lang=es',
    napster: 'https://app.napster.com/artist/j-balvin',
  },

  {
    title: ' The Jonas Brothers',
    description:
      "The Jonas Brothers (/ˈdʒoʊnəs/) are an American pop rock band. Formed in 2005, they gained popularity from their appearances on the Disney Channel television network. They consist of three brothers: Kevin Jonas, Joe Jonas, and Nick Jonas.[1][2][3] Raised in Wyckoff, New Jersey, the Jonas Brothers moved to Little Falls, New Jersey, in 2005, where they wrote their first record that made its Hollywood Records release.[4] They starred in the 2008 Disney Channel Original Movie Camp Rock and its 2010 sequel, Camp Rock 2: The Final Jam. They also starred in their own Disney Channel series Jonas, which was rebranded as Jonas L.A. for its second season. The band have released five albums: It's About Time (2006), Jonas Brothers (2007), A Little Bit Longer (2008), Lines, Vines and Trying Times (2009), and Happiness Begins (2019).",
    videourl:
      'https://youtube.com/playlist?list=PLISjWix87QOmCbVCUY5g-vEMSgUITz575',
    price: '$50',
    coverImg: 'jonasbrothers.jpeg',
    category: 'Pop-Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.jonasbrothers.com/',
    youtube: 'https://www.youtube.com/channel/UCFvXnyAhluG3sa6p3eOs_LA',
    facebook: 'https://www.facebook.com/JonasBrothers/',
    instagram: 'https://www.instagram.com/jonasbrothers/',
    twitter: 'https://twitter.com/jonasbrothers',
    spotify: 'https://open.spotify.com/artist/7gOdHgIoIKoe4i9Tta6qdD',
    deezer: 'https://www.deezer.com/mx/artist/15888',
    apple: 'https://music.apple.com/us/artist/jonas-brothers/114342891',
    soundcloud: 'https://soundcloud.com/jonasbrothers',
    tidal: 'https://listen.tidal.com/artist/14641320',
    wiki: 'https://en.wikipedia.org/wiki/Jonas_Brothers',
    ticketmaster: 'https://boletos.lataquillamx.com/venta/es/jonasbrothersgdl',
    tiktok: 'https://www.tiktok.com/@jonasbrothers?lang=es',
    napster: 'https://us.napster.com/blog/post/latino-collabs-en',
  },

  {
    title: 'Grupo Cañaveral de Humberto Pabon',
    description:
      ' GRUPO CAñAVERAL nace en enero de 1995, con la idea de crear un grupo que rescatara el auténtico sabor de la cumbia en México. Creado por el . Humberto Pabón Olivares, y por primera vez en México, se integra a una agrupación auténticamente mexicana, el famosísimo Tambor Alegre originario de Colombia..',
    videourl:
      'https://youtube.com/playlist?list=PLJRMiyX0t8zQpn0k3ygRs4RoJwEtmGQQA',
    price: 36,
    coverImg: 'GRUPOcAñAVERAL.jpg',
    category: 'Tropical',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.grupocanaveral.com.mx/',
    youtube: 'https://www.youtube.com/channel/UCYz5tj23JpsjrUPL17qu0Aw',
    facebook: 'https://www.facebook.com/grupocanaveraloficial',
    instagram: 'https://www.instagram.com/grupocanaveral/',
    twitter: 'https://twitter.com/grupocanaveral?lang=es',
    spotify: 'https://open.spotify.com/artist/48zixAu4wMDZwpVbOenDU7',
    deezer: 'https://www.deezer.com/mx/artist/1705508?autoplay=true',
    apple:
      'https://music.apple.com/mx/artist/grupo-ca%C3%B1averal-de-humberto-pab%C3%B3n/475344077',
    soundcloud: 'https://soundcloud.com/grupocanaveralofficial',
    tidal: 'https://listen.tidal.com/artist/4115330',
    wiki:
      'https://commons.wikimedia.org/wiki/File:Grupo_Ca%C3%B1averal-_Celebraci%C3%B3n_25_a%C3%B1os.jpg',
    ticketmaster:
      'https://www.ticketmaster.com/grupo-canaveral-tickets/artist/791914',
    tiktok: 'https://www.tiktok.com/@grupocanaveraloficial',
    napster:
      'https://music.amazon.com.mx/artists/B0065CKKD4/grupo-ca%C3%B1averal-de-humberto-pab%C3%B3n',
  },

  {
    title: 'Los Fabulosos Cadillacs',
    description:
      'Los Fabulosos Cadillacs es una banda argentina de ska proveniente de Buenos Aires y fundada en 1984. Es considerada una de las mejores bandas de la historia de ese género en Latinoamérica. Llevan grabados 16 álbumes y a lo largo de sus distintas eras colaboraron con distintos artistas argentinos e internacionales, obteniendo en el medio un gran reconocimiento crítico y comercial. Varios de sus trabajos han sido incluidos en listas de mejores álbumes de rock latinoamericano y han recibido nominaciones y premaciones de MTV Latinoamérica, Premios Gardel, Fundación Konex y Grammy..',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mb7BtKgDmwJxfVeUV8bchdmIP1qgfvcKU',
    price: '$50',
    coverImg: 'LosFabulososCadillacs.jpg',
    category: 'Rock en Español',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.fabulosos-cadillacs.com/',
    youtube: 'https://www.youtube.com/channel/UCS7dfokaYTjV6M-cLJEW5RA',
    facebook: 'https://www.facebook.com/losfabulososcadillacs/',
    instagram: 'https://www.instagram.com/lfcoficial/',
    twitter: 'https://twitter.com/lfcoficial',
    spotify: 'https://open.spotify.com/artist/2FS22haX3FYbyOsUAkuYqZ',
    deezer: 'https://www.deezer.com/mx/artist/3491',
    apple: 'https://music.apple.com/mx/artist/los-fabulosos-cadillacs/300251',
    soundcloud: 'https://soundcloud.com/losfabulososcadillacs',
    tidal: 'https://listen.tidal.com/artist/2641',
    wiki: 'https://es.wikipedia.org/wiki/Los_Fabulosos_Cadillacs',
    ticketmaster:
      'https://www.ticketmaster.com.mx/search?q=Los+Fabulosos+Cadillacs',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=Los%20Fabulosos%20Cadillacs%20Canciones&t=1654467154219',
    napster: 'https://app.napster.com/artist/los-fabulosos-cadillacs',
  },
  {
    title: 'Mœnia',
    description:
      'Moenia (estilizado Mœnia) es un grupo mexicano de Synth-Pop en 1996 lanzarían su primer álbum oficial homónimo y dándose a conocer por su gran éxito No puedo estar sin ti, recibiendo numerosas aceptaciones en la industria de la música y el público en general. Juan Carlos Lozano abandonaría la agrupación después del éxito de su primer álbum. Optaron por regresar a sus orígenes con reintegrar a Alfonso Pichardo como vocalista principal. En 1999 lanzan su segundo álbum titulado Adición+ con Alfonso Pichardo como vocalista permanente de la banda.',
    videourl:
      'https://youtube.com/playlist?list=PLLOW3tRoU2ErRdFcwOaft4i735ZBeQUZk',
    price: '$50',
    coverImg: 'moenia.jpg',
    category: 'Rock en Español',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.moenia.com/',
    youtube: 'https://www.youtube.com/channel/UCEJr7n8Brn1zj0ZuWZPPajw',
    facebook: 'https://www.facebook.com/moeniamx',
    instagram: 'https://www.instagram.com/moeniamx/',
    twitter: 'https://twitter.com/MoeniaMx',
    spotify: 'https://open.spotify.com/artist/3QmmtMrEf7aQrsd1VtejAV',
    deezer: 'https://www.deezer.com/mx/artist/12043',
    apple: 'https://music.apple.com/mx/artist/moenia/112531',
    soundcloud: 'https://soundcloud.com/moenia-official',
    tidal: 'https://listen.tidal.com/artist/7719',
    wiki: 'https://en.wikipedia.org/wiki/M%C5%93nia',
    ticketmaster:
      'https://www.ticketmaster.com.mx/moenia-boletos/artist/794979',
    tiktok: 'https://www.tiktok.com/@moeniamx',
    napster: 'https://music.amazon.com.mx/artists/B000TE048U/moenia',
  },
  {
    title: 'Fobia',
    description:
      'La banda inició su trayectoria en la Ciudad de México en 1987, época en la que en México había poca difusión para los grupos de rock. La historia que ellos tienen acerca de la formación de la banda se remonta a un concierto de Las Insólitas Imágenes de Aurora en 1987, De Lozanne rompe la llave de su automóvil y pidió un "ride" a Huidobro, con quien platica sobre sus inquietudes musicales y juntos unieron sus talentos y forman una agrupación. Más tarde, De Lozanne le recomendó a Huidobro, un bajista al que consideraba muy bueno.',
    videourl:
      'https://youtube.com/playlist?list=PLoaJsUNpHzl3xJfuLbQ_AMaABXH8YBB5r',
    price: '$50',
    coverImg: 'Fobia.jpeg',
    category: 'Rock en Español',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.fobiamx.com/?fbclid=IwAR0K3-0GZ2SyKeYFn7jVaaRGZtZe6N_P9yo1UxnMP5qHRfa86-F7JhxJsk8',
    youtube: 'https://www.youtube.com/channel/UCs1r5JVyNLXHSs2UAlPV7KA',
    facebook: 'https://www.facebook.com/fobia30',
    instagram: 'https://www.instagram.com/fobiamx/?hl=es-la',
    twitter: 'https://twitter.com/fobiamx?lang=es',
    spotify: 'https://open.spotify.com/artist/3SqzxvGCKGJ9PYKXXPwjQS',
    deezer: 'https://www.deezer.com/mx/artist/14468',
    apple: 'https://music.apple.com/mx/artist/fobia/3297308',
    soundcloud: 'https://soundcloud.com/fobia-official',
    tidal: 'https://listen.tidal.com/artist/3642162',
    wiki: 'https://es.wikipedia.org/wiki/Fobia',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Fobia',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=fobia%20canciones&t=1654467860387',
    napster: 'https://app.napster.com/artist/fobia',
  },
  {
    title: ' Lila Downs',
    description:
      'Ana Lila Downs Sánchez, más conocida como Lila Downs (Heroica Ciudad de Tlaxiaco, Oaxaca, 19 de septiembre de 1968),​ es una cantante, compositora, productora, actriz y antropóloga​ mexicana. Además de cantar en español e inglés, también interpreta melodías en diversos idiomas nativos de su país, como mixteco y zapoteco, así como maya, purépecha y náhuatl. Reivindica sus raíces mexicanas y de los pueblos originarios, además de la música regional de Oaxaca.',
    videourl: 'https://youtube.com/playlist?list=PLB41351AC75255C71',
    price: '$50',
    coverImg: 'lilaDowns.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.liladowns.com/',
    youtube: 'https://www.youtube.com/c/LilaDownsOficial',
    facebook: 'https://www.facebook.com/LilaDownsOficial',
    instagram: 'https://www.instagram.com/liladowns/',
    twitter: 'https://twitter.com/liladowns',
    spotify: 'https://open.spotify.com/artist/3mXI2gpwWnNO9qbQG3n3EP',
    deezer: 'https://www.deezer.com/mx/artist/1525',
    apple: 'https://music.apple.com/mx/artist/lila-downs/653627',
    soundcloud: 'https://soundcloud.com/lila-downs',
    tidal: 'https://listen.tidal.com/artist/3504595',
    wiki: 'https://es.wikipedia.org/wiki/Lila_Downs',
    ticketmaster:
      'https://web.superboletos.com/SuperBoletos/JALISCO/CONCIERTO/LILA-DOWNS/McSSRJaflXG8t4x6oDEV2rBsw',
    tiktok: 'https://www.tiktok.com/@liladownsmx?lang=es',
    napster: 'https://us.napster.com/artist/lila-downs',
  },
  {
    title: 'Vaquero Negro',
    description:
      'Vaquero Negro es una propuesta musical fresca que surge en Jalisco por alla de 2015 en Guadalajara. La banda está compuesta por: Nacho (vocal), Nachin (batería), (guitarra) y (bajo). Su sonido resulta de la mezcla el rock, rap, hip-hop y el nü metal, que resuena con gran fuerza en cada canción..',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUjvApMMpZQgv1-JCyuuSjZ',
    price: 80,
    coverImg: 'vaqueronegro.jpg',
    category: 'Rapcore',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://vaqueronegro.com/',
    youtube: 'https://www.youtube.com/c/VaqueroNegro',
    facebook: 'https://www.facebook.com/vaqueronegromusic',
    instagram: 'https://www.instagram.com/vaqueronegro/',
    twitter: 'https://twitter.com/vaquero_negro',
    spotify: 'https://open.spotify.com/artist/4IsUhd2aPelcKtiOJMc1q8',
    deezer: 'https://www.deezer.com/search/vaquero%20negro',
    apple: 'https://music.apple.com/us/artist/vaquero-negro/1031983109',
    soundcloud: 'https://soundcloud.com/vaqueronegro',
    tidal: 'https://listen.tidal.com/artist/7170982',
    wiki:
      'https://www.milenio.com/cultura/laberinto/raprock-vaquero-negro-hara-temblar-foro-hilvana',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Pop-Rock/Vaquero-Negro-Boletos',
    tiktok: 'https://www.tiktok.com/@vaqueronegro_oficial?lang=es',
    napster: 'https://us.napster.com/search?query=Vaquero%20Negro',
  },

  {
    title: 'Kings of Lion',
    description:
      'Kings of Leon es grupo de música estadounidense de rock fundado en Nashville, Tennessee, en 1999. Está formado por tres hermanos, Caleb, Nathan y Jared Followill y su primo Matthew Followill. Cada miembro de la familia es conocido por su segundo nombre en vez del primero.1​ El nombre del grupo deriva del padre y abuelo de Nathan, Caleb y Jared, los dos llamados Leon.',
    videourl:
      'https://youtube.com/playlist?list=PLcaPdH-BvlV-H3MqKzUpvqj4TLL58ZATU',
    price: '$50',
    coverImg: 'KingsofLeon.jpeg',
    category: 'Alternative',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://kingsofleon.com/',
    youtube: 'https://www.youtube.com/channel/UCfJein8E4rcYZSUCc8UqyMA',
    facebook: 'https://www.facebook.com/KingsOfLeon',
    instagram: 'https://www.instagram.com/kingsofleon/',
    twitter: 'https://twitter.com/kingsofleon',
    spotify: 'https://open.spotify.com/artist/2qk9voo8llSGYcZ6xrBzKx',
    deezer: 'https://www.deezer.com/mx/artist/385',
    apple: 'https://music.apple.com/mx/artist/kings-of-leon/1883403',
    soundcloud: 'https://soundcloud.com/kingsofleon',
    tidal: 'https://soundcloud.com/kingsofleon',
    wiki: 'https://es.wikipedia.org/wiki/Kings_of_Leon',
    ticketmaster:
      'https://www.ticketmaster.com/kings-of-leon-tickets/artist/862453',
    tiktok: 'https://www.tiktok.com/@kingsofleon',
    napster: 'https://us.napster.com/artist/kings-of-leon',
  },

  {
    title: 'The Weeknd',
    description:
      'Abel Makkonen Tesfaye (Toronto, Ontario; 16 de febrero de 1990), conocido por su nombre artístico The Weeknd, es un cantante, compositor y productor canadiense, conocido por éxitos como «Save Your Tears», «Blinding Lights», «The Hills», «Starboy», «Earned It», entre otros.Empezó a ganar reconocimiento a finales de 2010, cuando subió de forma anónima varias canciones a YouTube bajo el nombre de The Weeknd. Durante el 2011 lanzó tres mixtapes de nueve pistas, titulados House of Balloons, Thursday y Echoes of Silence, que fueron muy aclamados por la crítica.1​ En el siguiente año, lanzó su primer trabajo recopilatorio titulado Trilogy, con treinta pistas remasterizadas de sus mixtapes y tres canciones más. Fue lanzado al mercado por la compañías discográficas Republic Records y XO..',
    videourl:
      'https://youtube.com/playlist?list=PLK2zhq9oy0K6rjySCH1nARTssbv8m2Kfm',
    price: '$50',
    coverImg: 'theweeknd.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UC0WP5P-ufpRfjbNrmOWwLBQ',
    facebook: 'https://www.facebook.com/theweeknd',
    instagram: 'https://www.instagram.com/theweeknd/',
    twitter: 'https://twitter.com/theweeknd',
    spotify: 'https://open.spotify.com/artist/1Xyo4u8uXC1ZmMpatF05PJ',
    deezer: 'https://www.deezer.com/mx/artist/4050205',
    apple: 'https://music.apple.com/mx/artist/the-weeknd/479756766',
    soundcloud: 'https://soundcloud.com/theweeknd',
    tidal: 'https://listen.tidal.com/artist/4761957',
    wiki: 'https://es.wikipedia.org/wiki/The_Weeknd',
    ticketmaster:
      'https://www.ticketmaster.com/the-weeknd-tickets/artist/1697014',
    tiktok: 'https://www.tiktok.com/@theweeknd?lang=es',
    napster: 'https://us.napster.com/artist/the-weeknd',
  },

  {
    title: 'Lady Gaga',
    description:
      'Stefani Joanne Angelina Germanotta, más conocida por su nombre artístico Lady Gaga, es una cantante, compositora, productora, bailarina, actriz, activista y diseñadora de moda estadounidense. Nacida y criada en la ciudad de Nueva York, estudió en la escuela Convent of the Sacred Heart y asistió por un tiempo breve a la Tisch School of the Arts, perteneciente a la Universidad de Nueva York, hasta que abandonó sus estudios para enfocarse en su carrera musical. Fue así como irrumpió en la escena del rock en el Lower East Side de Manhattan y firmó un contrato con Streamline Records hacia fines de 2007. En la época en que trabajaba como compositora para dicha discográfica, su voz llamó la atención del artista Akon, quien la hizo firmar un contrato con Kon Live Distribution',
    videourl:
      'https://youtube.com/playlist?list=PLf5BnbCRqFj5c1wwBIIOU5ir7VVAgiAEO',
    price: '$50',
    coverImg: 'LadyGaga.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ladygaga.com/',
    youtube: 'https://www.youtube.com/channel/UCNL1ZadSjHpjm4q9j2sVtOA',
    facebook: 'https://www.facebook.com/ladygaga',
    instagram: 'https://www.instagram.com/ladygaga/',
    twitter: 'https://twitter.com/ladygaga',
    spotify: 'https://open.spotify.com/artist/1HY2Jd0NmPuamShAr6KMms',
    deezer: 'https://www.deezer.com/mx/artist/75491',
    apple: 'https://music.apple.com/mx/artist/lady-gaga/277293880',
    soundcloud: 'https://soundcloud.com/ladygaga',
    tidal: 'https://listen.tidal.com/artist/3534754',
    wiki: 'https://es.wikipedia.org/wiki/Lady_Gaga',
    ticketmaster:
      'https://www.ticketmaster.com/lady-gaga-tickets/artist/1249444',
    tiktok: 'https://www.tiktok.com/@ladygaga?lang=es',
    napster: 'https://us.napster.com/artist/lady-gaga',
  },
  {
    title: 'Demi Lovato',
    description:
      'Demetria Devonne Lovato, cuyo nombre artístico es Demi Lovato, es una celebridad estadounidense profesional en el canto, la actuación y la composición. Fundó junto con Nick Jonas el sello discográfico Safehouse Records..',
    videourl:
      'https://youtube.com/playlist?list=PLy4Kg0J0TkebIr4dkt4rEgDns_ZwP0rzh',
    price: '$50',
    coverImg: 'demiLovato.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.demilovato.com/',
    youtube: 'https://www.youtube.com/channel/UCZkURf9tDolFOeuw_4RD7XQ',
    facebook: 'https://www.facebook.com/DemiLovato',
    instagram: 'https://www.instagram.com/ddlovato/',
    twitter:
      'https://twitter.com/ddlovato?fbclid=IwAR0O4EFAwe-J9akxjfltKN7XRDEwy5wAi1_pjdm_cbFTQCY4KB-twhabAms',
    spotify: 'https://open.spotify.com/artist/6S2OmqARrzebs0tKUEyXyp',
    deezer: 'https://www.deezer.com/mx/artist/193875',
    apple: 'https://music.apple.com/mx/artist/demi-lovato/280215821',
    soundcloud: 'https://soundcloud.com/ddlovato',
    tidal: 'https://listen.tidal.com/artist/3544816',
    wiki: 'https://es.wikipedia.org/wiki/Demi_Lovato',
    ticketmaster:
      'https://www.ticketmaster.com/demi-lovato-tickets/artist/1224909',
    tiktok: 'https://www.tiktok.com/@ddlovato?lang=es',
    napster: 'https://us.napster.com/artist/demi-lovato',
  },
  {
    title: 'Adele',
    description:
      'Adele Laurie Blue Adkins (Londres, 5 de mayo de 1988), conocida simplemente como Adele, es una cantautora y multinstrumentista británica. Es una de las artistas musicales con mayores ventas del mundo, con más de 120 millones de ventas entre discos y sencillos.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_n5Ls1CYg63QxBxeuJf4ICzSY-kvAMLQu8',
    price: '$50',
    coverImg: 'adele.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    merchsiteurl: 'https://shop.adele.com/',
    websiteurl: 'https://www.adele.com/',
    youtube: 'https://www.youtube.com/channel/UCsRM0YB_dabtEPGPTKo-gcw',
    facebook: 'https://www.facebook.com/adele?fref=ts',
    instagram: 'https://www.instagram.com/adele/',
    twitter: 'https://twitter.com/Adele',
    spotify: 'https://open.spotify.com/artist/4dpARuHxo51G3z768sgnrY',
    deezer: 'https://www.deezer.com/mx/artist/75798',
    apple: 'https://music.apple.com/mx/artist/adele/262836961',
    soundcloud: 'https://soundcloud.com/adelemusic',
    tidal: 'https://listen.tidal.com/artist/3521920',
    wiki: 'https://es.wikipedia.org/wiki/Adele',
    ticketmaster: 'https://www.ticketmaster.com/adele-tickets/artist/1159272',
    tiktok: 'https://www.tiktok.com/@adele.offi?lang=es',
    napster: 'https://us.napster.com/artist/adele',
  },

  {
    title: 'Taylor Swift',
    description:
      'Taylor Alison Swift (Reading, Pensilvania; 13 de diciembre de 1989) es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.Criada en Wyomissing (Pensilvania), se mudó a Nashville (Tennessee) a los 14 años para realizar una carrera de música country. Firmó con la compañía discográfica independiente Big Machine Records y se convirtió en la artista más joven contratada por Sony/ATV Music Publishing House. En 2006 lanzó su álbum debut homónimo Taylor Swift, el cual la estableció como una estrella en la música country. «Our Song», su tercer sencillo, la convirtió en la persona más joven en escribir temas sin ayuda de nadie e interpretar una canción número uno en la lista de Hot Country Songs. Recibió una nominación a mejor artista nuevo en los Premios Grammy de 2008.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kOjcMYd-zFhGK7iPzOb1m2-3ituAZSNe0',
    price: '$50',
    coverImg: 'TaylorSwift.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.taylorswift.com/',
    youtube: 'https://www.youtube.com/channel/UCqECaJ8Gagnn7YCbPEzWH6g',
    facebookurl: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify: 'https://open.spotify.com/artist/06HL4z0CvFAxyc27GXpf02',
    deezer: 'https://www.deezer.com/mx/artist/12246',
    apple: 'https://music.apple.com/mx/artist/taylor-swift/159260351',
    soundcloud: 'https://soundcloud.com/taylorswiftofficial',
    tidal: 'https://listen.tidal.com/artist/3557299',
    wiki: 'https://es.wikipedia.org/wiki/Taylor_Swift',
    ticketmaster:
      'https://www.ticketmaster.com/taylor-swift-tickets/artist/1094215',
    tiktok: 'https://www.tiktok.com/@taylorswift',
    napster: 'https://us.napster.com/artist/taylor-swift',
  },

  {
    title: 'OV7',
    description:
      'OV7 (conocido en sus inicios como La Onda Vaselina, luego Onda Vaselina) es un grupo musical pop mexicano formado en el año 1989. El grupo surgió en el año 1989 como una agrupación infantil creada por la cantante y actriz mexicana Julissa con el nombre de La Onda Vaselina. En el año 2000, el grupo se independiza de su fundadora y resurge con su nombre y concepto actual. En el año 2003, el grupo decide separarse para brindarle la oportunidad a sus integrantes de desarrollarse de forma independiente. En el año 2022 la famosa agrupación retoma sus presentaciones bajo el concepto de OV7 Treinta. Gira qué celebra más de 30 años de trayectoria',
    category: 'Pop',
    videourl: 'https://youtube.com/playlist?list=PLC7DFC3C897F1E77B',
    price: 80,
    coverImg: 'ov7.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/OV7OFICIAL',
    instagram: 'https://www.instagram.com/oficialov7/',
    twitter: 'https://twitter.com/ov7oficial',
    spotify:
      'https://open.spotify.com/artist/5zaT4Qu9otu6z4oyWjRqM2?si=xg1B-_dNSnKtXPgUNkIQqA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/491621',
    apple: 'https://music.apple.com/mx/artist/ov7/170956727',
    soundcloud: 'https://soundcloud.com/ov7official',
    tidal: 'https://listen.tidal.com/artist/1414',
    wiki: 'https://es.wikipedia.org/wiki/OV7',
    ticketmaster: 'https://www.ticketmaster.com/ov7-tickets/artist/1631627',
    tiktok: 'https://www.tiktok.com/@oficialov7?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013A38NC/ov7',
  },
  {
    title: 'Shakira',
    description:
      'Shakira Isabel Mebarak Ripoll (Barranquilla, Atlántico, Colombia; 2 de febrero de 1977), conocida simplemente como Shakira, es una cantautora, bailarina, actriz, embajadora de buena voluntad de UNICEF y empresaria colombiana.',
    videourl: 'https://youtube.com/playlist?list=PL4324C6BD2E9BA7F8',
    price: 50,
    coverImg: 'Shakira.jpg',
    category: 'Pop',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.shakira.com/',
    youtube: 'https://www.youtube.com/results?search_query=Shakira',
    facebook: 'https://www.facebook.com/shakira',
    instagram: 'https://www.instagram.com/shakira/',
    twitter: 'https://www.facebook.com/shakira',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/160',
    apple: 'https://music.apple.com/mx/artist/shakira/889327',
    soundcloud: 'https://soundcloud.com/shakira',
    tidal: 'https://listen.tidal.com/artist/1087',
    wiki: 'https://es.wikipedia.org/wiki/Shakira',
    ticketmaster: 'https://www.ticketmaster.com./shakira-tickets/artist/779049',
    tiktok: 'https://www.tiktok.com/@shakira?lang=es',
    napster: 'https://app.napster.com/artist/shakira',
  },
  {
    title: 'Jesse y Joy',
    description:
      'Jesse & Joy son un dúo mexicano de música pop, formado en 2005 por los hermanos Jesse (1982) 1​ y Joy Huerta (1986) en la Ciudad de México.El dúo ha lanzado seis álbumes de estudio, dos álbumes en vivo y un EP en Warner Music Latina. También ha realizado giras internacionales y ha ganado un Premio Grammy al Mejor Álbum Latino y cinco Premios Grammy Latinos en varias categorías.Jesse & Joy son probablemente unos de los artistas más puros y talentosos de nuestra generación, escribió Latin Times.',
    videourl:
      'https://youtube.com/playlist?list=PLy1NnxDiUL4iyLeRCsWq3vpQ6c_sInCyr',
    price: '$50',
    coverImg: 'jesseyjoy.jpg',
    category: 'Regional Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://jesseyjoy.com/',
    youtube: 'https://www.youtube.com/channel/UC6awBq3lKY35T__puHY2OYQ',
    facebook: 'https://www.facebook.com/JesseyJoyOficial',
    instagram: 'https://www.instagram.com/jesseyjoy/',
    twitter: 'https://twitter.com/jesseyjoy',
    spotify: 'https://open.spotify.com/artist/1mX1TWKpNxDSAH16LgDfiR',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/jesse-joy/167779502',
    soundcloud: 'https://soundcloud.com/user-996270785',
    tidal: 'https://listen.tidal.com/artist/12832964',
    wiki: 'https://es.wikipedia.org/wiki/Jesse_%26_Joy',
    ticketmaster:
      'https://www.ticketmaster.com.mx/jesse-joy-zapopan-07-05-2023/event/14005E6189360940',
    tiktok: 'https://www.tiktok.com/@jesseandjoy',
    napster: 'https://music.amazon.com.mx/artists/B001231YC2/jesse-joy',
  },
  {
    title: 'Becky G',
    description:
      'Rebbeca Marie Gómez1​ (Inglewood, California; 2 de marzo de 1997),​ conocida artísticamente como Becky G, es una cantante y actriz estadounidense de origen mexicano. Obtuvo reconocimiento por primera vez en 2011 cuando comenzó a publicar videos de ella misma interpretando canciones populares en YouTube. Uno de sus videos llamó la atención del productor Dr. Luke, quien posteriormente le ofreció un contrato discográfico conjunto con Kemosabe Records y RCA Records. Mientras trabajaba en su debut, Gómez colaboró ​​​​con los artistas will.i.am, Cody Simpson y Cher Lloyd. Su sencillo debut oficial, «Becky from the Block» (2013), recibió una recepción positiva tras su lanzamiento. Lanzó su EP debut, Play It Again (2013), más tarde ese mismo año. Su segundo sencillo, Cant Get Enough» (2014), contó con la voz invitada de Pitbull y llegó a lo más alto de la lista Latin Rhythm Airplay en los Estados Unidos..',
    videourl:
      'https://youtube.com/playlist?list=PLAE2PW67Xm8biSTHd94VEUmvu7pbAnRDe',
    price: '$50',
    coverImg: 'beckyg.jpg',
    category: 'Pop Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.iambeckyg.com/',
    youtube: 'https://www.youtube.com/channel/UCgtNC51EUSgcZ6kKyVoPxKA',
    facebook: 'https://www.facebook.com/iambeckyg',
    instagram: 'https://www.instagram.com/iambeckyg/',
    twitter: 'https://twitter.com/iambeckyg',
    spotify:
      'https://open.spotify.com/artist/4obzFoKoKRHIphyHzJ35G3?si=3aYrWCo9RIemtKGtcwyDWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/becky-g/550411604',
    soundcloud: 'https://soundcloud.com/iambeckyg',
    tidal: 'https://listen.tidal.com/artist/4903321',
    wiki: 'https://es.wikipedia.org/wiki/Becky_G',
    ticketmaster:
      'https://www.ticketmaster.com.mx/becky-g-boletos/artist/1896954',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B0088V0L5W/becky-g',
  },
  {
    title: 'Yuridia',
    description:
      'Yuridia Francisca Gaxiola Flores (Hermosillo, 4 de octubre de 1986), conocida como Yuridia, es una cantante y compositora mexicana. Saltó a la fama en 2005 como participante de la cuarta generación del reality show mexicano La Academia, donde obtuvo el segundo lugar.Cuenta con seis álbumes de estudio: La voz de un ángel, (2005), Habla el corazón (2006), Entre mariposas (2007), Nada es color de rosa (2009), Para mí (2011) y 6. (2015) y un álbum en vivo Primera fila: Yuridia(2017). Es una de las intérpretes de origen mexicano con gran logro comercial en la industria musical, registrando más de 2 millones de álbumes vendidos...',
    videourl:
      'https://youtube.com/playlist?list=PLyzN_J2WwjznyWCf3y9LQrG1Kz132CBFk',
    price: '$50',
    coverImg: 'Yuridia.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://yuritaflowers.com/',
    youtube: 'https://www.youtube.com/channel/UCrkMQGSWGoNokFc0vKfciKA',
    facebook: 'https://www.facebook.com/yuritaflowers',
    instagram: 'https://www.instagram.com/yuritaflowers/',
    twitter: 'https://twitter.com/yuritaflowers',
    spotify:
      'https://open.spotify.com/artist/5B8ApeENp4bE4EE3LI8jK2?autoplay=true',
    deezer: 'https://www.deezer.com/en/artist/274393',
    apple: 'https://music.apple.com/mx/artist/yuridia/119903555',
    soundcloud: 'https://soundcloud.com/yuridiamusic',
    tidal: 'https://listen.tidal.com/artist/3633725',
    wiki: 'https://es.wikipedia.org/wiki/Yuridia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B00136B3EC/yuridia',
  },
  {
    title: 'Gera MX',
    description:
      'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
    price: '$50',
    coverImg: 'GeraMX.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://richvagosent.com/',
    youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
    facebook: 'https://www.facebook.com/GERAMX444/',
    instagram: 'https://www.instagram.com/geramx1/',
    twitter: 'https://twitter.com/mxgeramx',
    spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
    deezer: 'https://www.deezer.com/mx/artist/6661704',
    apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
    soundcloud: 'https://soundcloud.com/geramx-music',
    tidal: 'https://listen.tidal.com/artist/8511790',
    wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
    ticketmaster:
      'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
    tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B079V2DR9G/gera-mx',
  },

  {
    title: 'Bizzarap',
    description:
      'Gonzalo Julián Conde (nacido el 29 de agosto de 1998), conocido profesionalmente como Bizarrap , es un productor discográfico y DJ argentino . Se especializa en EDM , trap latino y rap . Es conocido por sus Bzrp Music Sessions, que graba con una amplia variedad de artistas.  Fue nominado a un Premio Grammy Latino a Mejor Productor en 2021.Gonzalo nació el 29 de agosto de 1998 en Ramos Mejía , Argentina. Se interesó por la música desde muy joven, y tenía interés en la música electrónica y los DJ , más que en cantar. Sus principales influencias fueron Skrillex , David Guetta y Martin Garrix . A los 14 años comenzó a estudiar solfeo, se matriculó en clases de piano y comenzó a producir sus primeras canciones. .',
    videourl: 'https://youtube.com/playlist?list=UULFmS75G-98QihSusY7NfCZtw',
    price: 50,
    coverImg: 'bizzarap.jpeg',
    category: 'Rap Latino',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/albums/Bizarrap/Bzrp-music-sessions',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/profile.php?id=100068674086489',
    instagram: 'https://www.instagram.com/bizarrap/',
    twitter: 'https://twitter.com/bizarrap',
    spotify: 'https://open.spotify.com/artist/716NhGYqD1jl2wI1Qkgq36',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/bizarrap/1239658213',
    soundcloud: 'https://soundcloud.com/bizarrap',
    tidal: 'https://listen.tidal.com/artist/8787172',
    wiki: 'https://en.wikipedia.org/wiki/Bizarrap',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B071VZ53VB/bizarrap',
  },
  {
    title: 'Quevedo',
    description:
      'Pedro Luis Domínguez Quevedo nació en Madrid .A la edad de un año, se trasladó a Brasil y regresó a España a la edad de cinco, instalándose en Las Palmas , Islas Canarias.Después de incursionar en el rap de estilo libre , Quevedo lanzó su carrera musical en 2020 junto al productor musical Linton. Su sencillo Ahora y Siempre (2021) alcanzó el decimonoveno y segundo puesto en las listas Global Viral 50 de Spotify y España Viral 50, respectivamente.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUd88g-4QLth1IBBRt38xvX',
    price: 50,
    coverImg: 'quevedo.jpg',
    category: 'Rap Latino',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://genius.com/Bizarrap-and-quevedo-quevedo-bzrp-music-sessions-vol-52-lyrics',
    youtube: 'https://www.youtube.com/channel/UCYEmGiiM21tGuCEtDJTfP1w',
    facebook: 'https://www.facebook.com/bizarrap',
    instagram: 'https://www.instagram.com/quevedo.pd/',
    twitter: 'https://twitter.com/pedrodquevedo',
    spotify:
      'https://open.spotify.com/artist/52iwsT98xCoGgiGntTiR7K?si=woV5b46PT7aKn3z1nmc7fg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/quevedo/377761784',
    soundcloud: 'https://soundcloud.com/quevedo-music',
    tidal: 'https://listen.tidal.com/artist/8997487',
    wiki: 'https://en.wikipedia.org/wiki/Quevedo_(rapper)',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0029L8HTY/quevedo',
  },

  {
    title: 'Top 100 Videos de Musica',
    description:
      '100 Videos Mixtos de Musica Regional Mexicana que hicieron el 2022 explotar con alegria , romance y sensaciones extraordinarias ..',
    videourl:
      'https://youtube.com/playlist?list=PLVZtocpycmEXG_s1xX7sml55L4wYfS4Uy',
    price: '$50',
    coverImg: 'musicamex.jpg',
    category: 'Exitos 2022',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://djmagmx.com/edc-mexico-revela-alineacion-a-traves-musica/',
    youtube:
      'https://www.youtube.com/results?search_query=top+100songs+in+mexico',
    facebook: 'https://www.facebook.com/DjMagMex/',
    instagram: 'https://www.instagram.com/djmagmex/',
    twitter: 'https://twitter.com/DjMagMeX',
    spotify: 'https://open.spotify.com/playlist/4SF351Y9lTzATQkz4vrpXT',
    deezer: 'https://www.deezer.com/mx/playlist/10064138302',
    apple:
      'https://music.apple.com/us/playlist/top-100-mexico/pl.df3f10ca27b1479087de2cd3f9f6716b',
    soundcloud: 'https://soundcloud.com/djmagmx',
    tidal: 'https://listen.tidal.com/artist/4346014',
    wiki: 'https://es.wikipedia.org/wiki/Sony_Music_M%C3%A9xico',
    ticketmaster: 'https://www.ticketmaster.com.mx',
    tiktok: 'https://www.tiktok.com/@musica.mexicana_',
    napster: 'https://us.napster.com/blog/post/latino-collabs-en',
  },
  {
    title: 'Alex Fernandez',
    description:
      'Saltó a la fama en las redes sociales como hijo del reconocido cantante mexicano Alejandro Fernández. Ha heredado un seguimiento de Instagram de más de 650.000 fanáticos de miembros famosos de su familia. También es cantante y ha grabado dos discos. Nacido el 4 de noviembre de 1993, Alex Fernández Jr. es oriundo de México. Al igual que en 2022, la edad de Alex Fernández Jr. es de 29 años. Consulte a continuación para obtener más detalles sobre Alex Fernández Jr.. Esta página arrojará luz sobre la biografía, wiki, edad, cumpleaños, detalles familiares, aventuras, controversias, casta, altura, peso, rumores y hechos menos conocidos de Alex Fernández Jr. , y más.',
    videourl:
      'https://youtube.com/playlist?list=PLutFSHdaf1CL5TLFaNFDiAuYjsUoOlTKV',
    price: 50,
    coverImg: 'alexfernandez.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.seitrack.mx/alexfernandez?fbclid=IwAR0gfNb2bj5mfqgc1tcd88sERPHwgp5OY5qkOxjeBh8NuqCA74x8LkIGqBI',
    youtube: 'https://www.youtube.com/channel/UC5-ZtcsyTQNjrsR_9yRCZJg',
    facebook: 'https://www.facebook.com/AlexFdezMusica/',
    instagram: 'https://www.instagram.com/alexfernandez.g/',
    twitter: 'https://twitter.com/AlexFdezMusica',
    spotify: 'https://open.spotify.com/artist/4Xwvz864z2uP3bwPcjKJjC',
    deezer: 'https://www.deezer.com/mx/artist/9896010',
    apple: 'https://music.apple.com/mx/artist/alex-fern%C3%A1ndez/1453744801',
    soundcloud: 'https://soundcloud.com/alexfernandez-music',
    tidal: 'https://listen.tidal.com/artist/7622411',
    wiki: 'https://celebs.filmifeed.com/wiki/alex-fernandez-jr/',
    ticketmaster:
      'https://www.ticketmaster.com/alex-fernandez-tickets/artist/2729965',
    tiktok: 'https://www.tiktok.com/@alexfernandez.g?lang=es',
    napster: 'https://us.napster.com/artist/alex-fernandez-2',
  },

  {
    title: 'Cristian Nodal',
    description:
      'Christian Jesús González Nodal (Caborca , Sonora; 11 de enero de 1999), conocido como Christian Nodal, es un cantante, compositor mexicano. Su primer sencillo, «Adiós amor», publicado en 2016 bajo el sello discográfico Fonovisa, lo lanzó a la fama en diversos medios de comunicación en México y Estados Unidos.',
    videourl:
      'https://youtube.com/playlist?list=PL2cg72xd9U-ioO2IpH7OZrT43hVgC6fmm',
    price: 80,
    coverImg: 'nodal.jpg',
    category: 'Mariachi',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://shop.christiannodal.com/',
    youtube: 'https://www.youtube.com/channel/UCtqOng3GVhQVu1Gl-3UlyAQ',
    facebook: 'https://www.facebook.com/christiannodaloficialmx/',
    instagram: 'https://www.instagram.com/nodal/',
    twitter: 'https://twitter.com/elnodal?lang=en',
    spotify:
      'https://open.spotify.com/artist/0XwVARXT135rw8lyw1EeWP?si=xMgobcktQD6v1nbtGIxGhQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/9763952',
    apple: 'https://music.apple.com/mx/artist/christian-nodal/1079597775',
    soundcloud: 'https://soundcloud.com/christiannodal',
    tidal: 'https://listen.tidal.com/artist/7745729',
    wiki: 'https://en.wikipedia.org/wiki/Christian_Nodal',
    ticketmaster:
      'https://www.ticketmaster.com/christian-nodal-tickets/artist/2405178',

    tiktok: 'https://www.tiktok.com/@christiannodal',
    napster: 'https://web.napster.com/artist/christian-nodal',
  },
  {
    title: 'Intocable',
    description:
      'Intocable es un grupo estadounidense de origen mexicano de música tejana y norteña de Zapata, Texas, Estados Unidos, fundado a principios de la década de los 90 por Ricky Muñoz y René Martínez.1​ Dentro de algunos años, la banda logró subir a la cima de la música tejana y norteña, con un estilo musical que fusionó la música conjunto de Texas y los ritmos folclóricos de la música norteña con baladas de letras modernas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mEpEPj__3rc-1-CiPwYP0GS-JE_jY93CA',
    price: null,
    coverImg: 'intocable.jpg',
    category: 'Regional Mexicano',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://grupointocable.com/',
    youtube: 'https://www.youtube.com/channel/UCvQcMlXzM9JdAgzkBo1B0Ew',
    facebook: 'https://www.facebook.com/grupointocable/',
    instagram: 'https://www.instagram.com/grupointocable/?hl=en',
    twitter:
      'https://twitter.com/grupointocable?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/108moq3rq6bm1M4Ypz0J02',
    deezer: 'https://www.deezer.com/mx/artist/16644',
    apple: 'https://music.apple.com/mx/artist/intocable/549010',
    soundcloud: 'https://soundcloud.com/intocable-official',
    tidal: 'https://listen.tidal.com/artist/56242',
    wiki: 'https://es.wikipedia.org/wiki/Intocable_(banda)',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Intocable',
    tiktok: 'https://www.tiktok.com/@grupo_intocable?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000SA1FUQ/intocable',
  },

  {
    title: 'Marca-MP',
    description:
      'Marca MP es una agrupación de música regional mexicana, principalmente de banda, mariachi y ranchera. Incursionaron al mundo de la música profesionalmente en 2019 con su sencillo “El Güero”, que interpretaron al lado de Grupo Firme. Se ganaron al público desde su primer sencillo, muestra de ello, es que “El Güero” permaneció en la cima de la lista como el video grupero más visto de ese año.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lbDCxSZ88e4zNta116vD4z3LCWJovkhmA',
    price: 50,
    coverImg: 'marca-mp.jpg',
    category: 'Banda',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: ' https://www.facebook.com/grupomarcamp',
    youtube: 'https://www.youtube.com/c/MarcaMP​',
    facebook: ' https://www.facebook.com/grupomarcamp/​',
    instagram: 'https://instagram.com/mmp_oficial​',
    twitter: 'https://twitter.com/marcampoficial',
    spotify: 'https://open.spotify.com/artist/44mEtidu0VdRkIqO4IbkNa',
    deezer: 'https://www.deezer.com/mx/artist/61384072',
    apple: 'https://music.apple.com/mx/artist/marca-mp/1493050737',
    soundcloud: 'https://soundcloud.com/mmp_oficial',
    tidal: 'https://listen.tidal.com/artist/15257396',
    wiki:
      'https://popnable.com/mexico/artists/52383-marca-mp/biography-and-facts#:~:text=Marca%20Mp%20Wiki%20Latin%20group%20with%20a%20notable,prominently%20performed%20in%20Mexico%20and%20the%20United%20States.',
    ticketmaster:
      'https://www.ticketmaster.com/marca-mp-tickets/artist/2802450',
    tiktok: 'https://www.tiktok.com/@mmp.oficial?lang=es',
    napster: 'https://web.napster.com/artist/marca-mp',
  },
  {
    title: 'El Fantasma',
    description:
      "Alexander García was born in Las Canas, Durango. He worked as a gardener for six years before becoming a singer.He performs regional Mexican music[2] and corrido songs about hardworking people. On social media, fans nickname him the King from the Underground.His nickname began because his friends and family call him El Fanta.[3] He was finalist at the 2019 Billboard Latin Music Awards.In 2019, El Fantasma's single Encantadora charted number 1 on Regional Mexican Airplay. On August 14, 2021, his single Soy Buen Amigo charted number 1 on Regional Mexican Airplay. It also charted 27 on Hot Latin Songs",
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLaKik8hFaPZGymkNFbeU8zvZVj0QvugDV',
    price: 80,
    coverImg: 'elfantasma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC9kHcAu8pGPY7PyKOnK7pfg',
    facebook: 'https://www.facebook.com/elfantasmaoficial/',
    instagram: 'https://www.instagram.com/elfantasmaoficial/',
    twitter: 'https://twitter.com/El_Fanta45',
    spotify: 'https://open.spotify.com/artist/0my6Pg4I28dVcZLSpAkqhv',
    deezer: 'https://www.deezer.com/mx/artist/5230088',
    apple: 'https://music.apple.com/mx/artist/el-fantasma/1245916228',
    soundcloud: 'https://soundcloud.com/elfantasma-music',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/El_Fantasma_(singer)',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@elfantasma.oficial',
    napster: 'https://music.amazon.com.mx/artists/B003DHLCDQ/el-fantasma',
  },
  {
    title: 'Natanael Cano',
    description:
      'Natanael Cano, nombre artístico de Nathanahel Rubén Cano Monge (Hermosillo, 27 de marzo de 2001), es un rapero y cantautor del género regional mexicano. Es el creador del concepto corridos tumbados, nombre de uno de sus discos. Su música fusiona géneros de música regional mexicana tales como el corrido y sierreño-banda con rap, trap, y hip-hop ',
    category: 'Sierreño',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lomYN5ttmEGiAiXBpCviVwoe5toNC2YM8',
    price: 80,
    coverImg: 'natanael.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UCW2_ESwKrYgSjqQjrR09n0g',
    facebook: 'https://www.facebook.com/profile.php?id=100077546590950',
    instagram: 'https://www.instagram.com/Natanael_Cano/',
    twitter: 'https://twitter.com/NatanaelCanoOf2',
    spotify: 'https://open.spotify.com/artist/0elWFr7TW8piilVRYJUe4P',
    deezer: 'https://www.deezer.com/mx/artist/15304117',
    apple: 'https://music.apple.com/mx/artist/natanael-cano/1406984792',
    soundcloud: 'https://soundcloud.com/natanaelcano',
    tidal: 'https://listen.tidal.com/artist/10041822',
    wiki: 'https://en.wikipedia.org/wiki/Natanael_Cano',
    ticketmaster:
      'https://www.ticketmaster.com/natanael-cano-tickets/artist/2797745',
    tiktok: 'https://www.tiktok.com/@natanaelcano_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07F747D1Q/natanael-cano',
  },
  {
    title: 'Rancho Humilde Records',
    description:
      'Jimmy Humilde, es considerado como “uno de los hombres que más misterio genera entorno a su persona” en el mundo del espectáculo musical.',
    videourl:
      'https://youtube.com/playlist?list=PLj9gRUgWAIL90dAa2YBl3WBt_zO-drgdq',
    price: 50,
    coverImg: 'RanchoHumilde.jpg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://ranchohumildestore.com/',
    youtube: 'https://www.youtube.com/c/RanchoHumildeoficial',
    facebook: 'https://www.facebook.com/ranchohumildeofficial',
    instagram: 'https://www.instagram.com/ranchohumilde/',
    twitter: 'https://twitter.com/ranchohumilde',
    spotify: 'https://open.spotify.com/artist/7iSpMTdILobashwyZorN25',
    deezer: 'https://www.deezer.com/mx/artist/89145252',
    apple: 'https://music.apple.com/mx/artist/rancho-humilde/1077575821',
    soundcloud: 'https://soundcloud.com/ranchohumilde',
    tidal: 'https://listen.tidal.com/artist/14100262',
    wiki:
      'https://www.latimes.com/espanol/entretenimiento/articulo/2021-05-21/rancho-humilde-records-con-sede-en-downey',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@grupomarcaregistrada?lang=es',
    napster: 'https://music.amazon.com.mx/albums/B08QHZ673C',
  },
  {
    title: 'DEL Records',
    description:
      'DEL Records es un sello discográfico estadounidense de música en español fundado por Ángel del Villar en 2008. Su sede se encuentra en el suburbio de Bell Gardens en Los Ángeles, California, e incluye DEL Records, DEL Publishing, DEL Studios y DEL Entertainment, los cuales se centran en la música regional mexicana, específicamente en géneros del pacífico mexicano cómo la banda sinaloense, el norteño estilo pacífico, el norteño-banda, el sierreño, el sierreño-banda y el mariachi. DEL Records ha dado inicio a carreras musicales de artistas que mantienen actualmente buen desempeño comercial como Tercer Elemento, Lenin Ramírez, Ulices Chaidez, Luis Carrazco, entre otros. DEL Records y sus artistas son ganadores frecuentes en los Premios Billboard de la música latina..',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mhD873MzMdsLA0BGNtyoc_7znFASWHWUI',
    price: '$50',
    coverImg: 'DELrecords.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.delrecords.com/',
    youtube: 'https://www.youtube.com/user/DELRECORDS',
    facebook: 'https://www.facebook.com/DelRecordsOficial',
    instagram: 'https://www.instagram.com/delrecords/',
    twitter: 'https://twitter.com/delrecords',
    spotify: 'https://open.spotify.com/user/del_records',
    deezer: 'https://www.deezer.com/mx/artist/7655148',
    apple: 'https://music.apple.com/mx/curator/del-records/1171184109',
    soundcloud: 'https://soundcloud.com/delrecordsoficial',
    tidal: 'https://listen.tidal.com/artist/9548427',
    wiki: 'https://es.wikipedia.org/wiki/DEL_Records',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@delrecordsofficial?lang=es',
    napster: 'https://web.napster.com/artist/del-records',
  },
  {
    title: 'Luis R Conriquez',
    description:
      'Luis R Conriquez es un compositor que se hizo conocido entre el público gracias a su estilo interpretativo, la honestidad de sus letras, sus mensajes de superación personal y un talento innato para componer corridos modernos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVhgiYg1lFWJMnQ2A_3COmX',
    price: 80,
    coverImg: 'luisrconriquez.jpeg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://kartelmusic.com/',
    youtube: 'https://www.youtube.com/@kartelmusicoficial',
    facebook: 'https://www.facebook.com/profile.php?id=100050488239777',
    instagram: 'https://www.instagram.com/luisrconriquez.fan.page/',
    twitter: 'https://twitter.com/LuisRConriquez',
    spotify: 'https://open.spotify.com/artist/0pePYDrJGk8gqMRbXrLJC8',
    deezer: 'https://www.deezer.com/en/artist/14004075',
    apple: 'https://music.apple.com/mx/artist/luis-r-conriquez/1252271456',
    soundcloud: 'https://soundcloud.com/luisrconriquez',
    tidal: 'https://listen.tidal.com/artist/9471448',
    wiki: 'https://en.wikipedia.org/wiki/Luis_R_Conriquez',
    ticketmaster:
      'https://www.ticketmaster.com/luis-r-conriquez-tickets/artist/2832072',
    tiktok: 'https://www.tiktok.com/@luisrconriquezoficial?lang=es',
    napster: 'https://us.napster.com/artist/luis-r-conriquez',
  },
  {
    title: 'Fuerza Regida',
    description:
      'Fuerza Regida es un grupo estadounidense de música regional mexicana, formado en 2015 originalmente por Jesús Ortiz Paz (voz), Samuel Jáimez (guitarra de doce cuerdas), Khrystian Ramos (guitarra armonía) y José García (tuba); y desde 2021 también por Moisés López (tololoche) .Han lanzado 11 álbumes y han sido nominados en varias ocasiones a premios como iHeartRadio Music Awards, Premios Billboard Latino, Premios Lo Nuestro y Premios Juventud. El grupo debutó en la lista Hot 100 de Billboard con la canción «Bebé dame» en colaboración con Grupo Frontera, alcanzando la posición 252​ y ha enlistado varios sencillos en la lista Hot Latin Songs de Estados Unidos.​ Por lo que han logrado ser en la actualidad uno de los grupos más influyentes y populares de la música regional mexicana El grupo se formó en 2015, cuando los integrantes de raíces mexicanas pero nacidos en San Bernardino, California,​ hacían covers de otros artistas influenciados por música de Ariel Camacho, Hijos de Barrón e incluso la banda de rock Metallica, tocaban como un pasatiempo y también en fiestas locales, en 2017 crearon un canal en YouTube en dónde comenzaron a subir videos interpretando también algunas canciones escritas por ellos mismos.​ Su popularidad inició gracias a los videos que subían por lo que comenzaron a recibir miles de suscriptores en su canal.​ En 2018 una pista de ellos llamada «Radicamos en South Central» se hizo viral en YouTube, por lo que Ramón Ruiz, líder de la banda Legado 7, los descubrió y llamó su atención. Ruiz es el dueño de Lumbre Music y decidió contactarse con Ortiz Paz para ofrecerles un contrato, así como también para firmar con la compañía discográfica Rancho Humilde.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_mbBjDr6BF9efxuubR7uCa4j7nO3rxXlL4',
    price: '$50',
    coverImg: 'FuerzaRegida.jpg',
    category: 'Norteño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://fuerzaregida.shop/',
    youtube: 'https://www.youtube.com/channel/UCFLI6KAjrKRtydD7KqiYvAw',
    facebook: 'https://www.facebook.com/fuerzaregidaoficial',
    instagram: 'https://www.instagram.com/fuerzaregidaoficial/',
    twitter: 'https://twitter.com/FuerzaRegida1',
    spotify: 'https://open.spotify.com/artist/0ys2OFYzWYB5hRDLCsBqxt',
    deezer: 'https://www.deezer.com/mx/artist/13916083',
    apple: 'https://music.apple.com/mx/artist/fuerza-regida/1275690475',
    soundcloud: 'https://soundcloud.com/fuerzaregida',
    tidal: 'https://listen.tidal.com/artist/9435954',
    wiki: 'https://www.buenamusica.com/fuerza-regida/biografia',
    ticketmaster: 'https://www.ticketmaster.com/artist/2499958',
    tiktok: 'https://www.tiktok.com/@fuerzaregidaoficial?lang=es',
    napster: 'https://web.napster.com/artist/fuerza-regida',
  },
  {
    title: 'Peso Pluma',
    description:
      'Hassan Emilio Kabande Laija (Zapopan, Jalisco; 15 de junio de 1999), conocido como Peso Pluma, es un cantante mexicano. Se especializa en el subgénero de corridos tumbados.Su fama internacional comenzó en 2022, después de colaborar con cantantes como Luis R. Conriquez y Natanael Cano.3​ Algunos de sus éxitos son «El Belicón», «Siempre pendientes», «PRC», «Por las noches», «AMG», «Ella baila sola» y «La bebé (Remix)». Debido a su fama ha logrado colaborar también con artistas de éxito mundial como Becky G,5​ Nicki Nicole6​, Marshmello,7​y Ovy on the Drums.Su estilo musical se caracteriza por un toque acústico con influencias de la música urbana como el trap mezclados con sonido de los corridos',
    category: 'Sierreño',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlXbLwh-AdLa1iHtuW_y_J9b',
    price: 80,
    coverImg: 'pesopluma.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.rioranchoeventscenter.com/event/peso-pluma/',
    youtube: 'https://www.youtube.com/channel/UCzrM_068Odho89mTRrrxqbA',
    facebook: 'https://www.facebook.com/PesoPlumaOficial',
    instagram: 'https://www.instagram.com/pesopluma/',
    twitter: 'https://twitter.com/ElPesoPluma',
    spotify:
      'https://open.spotify.com/artist/12GqGscKJx3aE4t07u7eVZ?si=u4gDkNCrR_-gnhy-aFtWJQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/80365122',
    apple: 'https://music.apple.com/mx/artist/peso-pluma/1500139475?l=en',
    soundcloud: 'https://soundcloud.com/pesopluma',
    tidal: 'https://listen.tidal.com/artist/17614478',
    wiki: 'https://es.wikipedia.org/wiki/Peso_Pluma_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com/peso-pluma-tickets/artist/2979041',
    tiktok: 'https://www.tiktok.com/@therealpesopluma',
    napster: 'https://music.amazon.com.mx/artists/B082C6RXYG',
  },
  {
    title: 'Junior H',
    description:
      'Antonio Herrera Pérez nació el 23 de abril de 2001, en Cerano, Guanajuato en México, donde vivió su niñez hasta la edad de quince años cuando junto con su familia se fue al estado de Utah, Estados Unidos. Mientras estudiaba la secundaria, trabajó como cocinero en Wendys. Aprendió a tocar distintos instrumentos, entre ellos el acordeón y la guitarra viendo videos en internet, aunque después se inclinó más hacia las guitarras y el micrófono. Cuenta que, en sus ratos libres, componía y grababa canciones en privado y por pena a que se burlaran lo mantuvo en secreto, y un día por hobbies, decidió subir varias de sus canciones en YouTube bajo el nombre de Junior H, sin querer saber nada de sus canciones.​ Alejado de las redes por un mes sin saber nada de sus canciones subidas, un día por curiosidad, mientras trabajaba, se metió a revisar sus canciones en YouTube y al ver que su sencillo "No Eh Cambiado" tenía más de dos millones de visualizaciones y varios comentarios de apoyo, hizo que se enfocara más en este proyecto musical independiente.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVQKFIhIcWGLTCXsFlB7b5K',
    price: 80,
    coverImg: 'juniorh.jpeg',
    category: 'Sierreño',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://elpais.com/mexico/2023-05-17/junior-h-el-chico-triste-que-triunfa-a-base-de-corridos-tumbados.html',
    youtube: 'https://www.youtube.com/channel/UCwA5HHGsAlpU1UlhwWD52uA',
    facebook: 'https://www.facebook.com/juniorhoficial/photos',
    instagram: 'https://www.instagram.com/juniorh/',
    twitter: 'https://twitter.com/juniorh_oficial',
    spotify: 'https://open.spotify.com/artist/7Gi6gjaWy3DxyilpF1a8Is',
    deezer: 'https://www.deezer.com/mx/artist/54047652',
    apple: 'https://music.apple.com/mx/artist/junior-h/1442059565',
    soundcloud: 'https://soundcloud.com/juniorh-music',
    tidal: 'https://listen.tidal.com/artist/10590337',
    wiki: 'https://es.wikipedia.org/wiki/Junior_H',
    ticketmaster:
      'https://www.ticketmaster.com/junior-h-tickets/artist/2820826',

    tiktok: 'https://www.tiktok.com/@juniorh_h',
    napster: 'https://music.amazon.com.mx/artists/B07KFMJCTH/junior-h',
  },
]

export default CardData30
