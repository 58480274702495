const CardData19 = [
  {
    title: 'Barbara Streisand',
    description:
      'Barbra Streisand (Brooklyn, Nueva York; 24 de abril de 1942) es una actriz, cantante, compositora, productora y directora estadounidense. A lo largo de su extensa carrera de más de seis décadas, sus logros en la música, el cine y el teatro la han convertido en un icono de la cultura estadounidense. Tras una exitosa carrera como cantante durante los años sesenta, debutó en el cine con gran esplendor en el musical Funny Girl de 1968, que la estableció como una intérprete de éxito y que le premió con su primer Óscar como mejor actriz.',
    videourl:
      'https://youtube.com/playlist?list=PLqADcilQMwvbvI6kJ4Ho5DHBNuPMWdWjP',
    price: '$50',
    coverImg: 'BarbaraStreisand.jpg',
    category: 'Romantic',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://releaseme2.barbrastreisand.com/',
    youtube: 'https://www.youtube.com/channel/UCvwLGtS025Kafufr8SsR_EQ',
    facebook: 'https://www.facebook.com/barbrastreisand',
    instagram: 'https://www.instagram.com/barbrastreisand/',
    twitter: 'https://twitter.com/BarbaraStreis19',
    spotify: 'https://open.spotify.com/artist/7jmTilWYlKOuavFfmQAcu6',
    deezer: 'https://www.deezer.com/mx/artist/5657',
    apple: 'https://music.apple.com/mx/artist/barbra-streisand/249646',
    soundcloud: 'https://soundcloud.com/barbarastreisandofficial',
    tidal: 'https://listen.tidal.com/artist/70',
    wiki: 'https://es.wikipedia.org/wiki/Barbra_Streisand',
    ticketmaster:
      'https://www.ticketmaster.com/barbra-streisand-tickets/artist/734094',
    tiktok: 'https://www.tiktok.com/@barbrastreisand?lang=es',
    napster: 'https://us.napster.com/artist/barbra-streisand',
  },{
    title: 'Nina Simone',
    description:
      'Eunice Kathleen Waymon (Tryon, Carolina del Norte, 21 de febrero de 1933-Carry-le-Rouet, Francia, 21 de abril de 2003), más conocida por su nombre artístico Nina Simone, fue una cantante, compositora y pianista estadounidense de jazz, blues, rhythm and blues y soul. Se la conoce con el sobrenombre de High Priestess of Soul (Gran Sacerdotisa del soul)Estilísticamente, la palabra que mejor caracteriza a Nina Simone es eclecticismo. Su voz, con rango propio de una contralto,se caracterizaba por su pasión, su breathiness (voz jadeante, sofocada, sin aliento) y su trémolo.',

    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mBwW7nfNTqit12_Pf31v1C2q760N_C4vM',
    price: '$50',
    coverImg: 'ninasimone.jpg',
    category: 'Soul',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.ettajamesofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCJ-FRbWianyv9q-Ly9whFQQ',
    facebook: 'https://www.facebook.com/nina.simone',
    instagram: 'https://www.instagram.com/ninasimone',
    twitter: 'https://x.com/NinaSimoneMusic',
    spotify: 'https://open.spotify.com/artist/7G1GBhoKtEPnP86X2PvEYO',
    deezer: 'https://www.deezer.com/mx/artist/744',
    apple: 'https://music.apple.com/us/artist/nina-simone/79798?l=es-MX',
    soundcloud:'https://soundcloud.com/ninasimone',
    tidal: 'https://listen.tidal.com/artist/1684',
    wiki: 'https://es.wikipedia.org/wiki/Nina_Simone',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Jazz-Blues-and-RnB-Music/Nina-Simone-Boletos',
    tiktok: 'https://www.tiktok.com/@ninasimone',
    napster: 'https://music.amazon.com.mx/artists/B000QJJUJM/nina-simone',
  },
  {
    title: 'Aretha Franklin',
    description:
      'Aretha Louise Franklin fue una cantante estadounidense de soul, R&B y góspel. Apodada «Lady Soul» o «Queen of soul», fue la máxima exponente de dicho género, y una de las más grandes transmisoras de góspel de todos los tiempos, así como una de las artistas más influyentes en la música contemporánea..',
    videourl:
      'https://youtube.com/playlist?list=PLCx4rpClwwTAnYs_sgXpHj3_zPsYonM_8',
    price: '$50',
    coverImg: 'ArethaFranklin.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.arethafranklin.net/',
    youtube: 'https://www.youtube.com/channel/UCGDOBEUVPWkzmHo4dDa96zQ',
    facebook: 'https://www.facebook.com/arethafranklin/',
    instagram: 'https://www.instagram.com/arethafranklin/',
    twitter: 'https://twitter.com/ArethaFranklin',
    spotify: 'https://open.spotify.com/artist/7nwUJBm0HE4ZxD3f5cy5ok',
    deezer: 'https://www.deezer.com/mx/artist/2059',
    apple: 'https://music.apple.com/mx/artist/aretha-franklin/98742',
    soundcloud: 'https://soundcloud.com/arethafranklin',
    wiki: 'https://es.wikipedia.org/wiki/Aretha_Franklin',
    ticketmaster:
      'https://www.ticketmaster.com/aretha-franklin-tickets/artist/735118',
    tiktok: 'https://www.tiktok.com/@arethafranklin?lang=es',
    napster: 'https://us.napster.com/artist/aretha-franklin',
  },

  {
    title: 'Buena Vista Social Club',
    description:
      "Buena Vista Social Club es el nombre de un club social muy popular de La Habana, Cuba, cuyos miembros practicaban el baile y la música. También es el nombre de una agrupación musical creada en la década de los 90's, casi 50 años después de que el club cerrase, que inspiró una grabación hecha por el músico cubano Juan de Marcos González y por el guitarrista estadounidense Ry Cooder con músicos cubanos tradicionales, muchos de ellos antiguos miembros del club en el que se presentaron cuando su popularidad estaba en la cumbre.",
    videourl:
      'https://youtube.com/playlist?list=PLfdBj3OfNtmygSuRuS_c6Y_3sRvR7n1gB',
    price: 36,
    coverImg: 'buenavista.jpg',
    category: 'Salsa',
    location: 'Cuba',
    locationImg: 'cubaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.buenavistasocialclub.com',
    youtube: 'https://www.youtube.com/channel/UCJYl3tOsrTkCCidMjhxdAeQ',
    facebook: 'https://www.facebook.com/buenavistasocialclubofficial',
    instagram: 'https://www.instagram.com/buenavistasocialclubofficial/',
    twitter: 'https://twitter.com/BuenaVistaSC',
    spotify:
      'https://open.spotify.com/artist/11kBu957KTYoAltZHDm8gW?si=mmAssxVySLSykBrPbW68KQ&nd=1',
    deezer: 'https://www.deezer.com/mx/album/101847622',
    apple: 'https://music.apple.com/mx/artist/buena-vista-social-club/5620149',
    soundcloud: 'https://soundcloud.com/buena-vista-social-club',
    tidal: 'https://listen.tidal.com/artist/3521820',
    wiki: 'https://es.wikipedia.org/wiki/Buena_Vista_Social_Club',
    ticketmaster:
      'https://www.ticketmaster.com/buena-vista-social-club-tickets/artist/717599',
    tiktok: 'https://www.tiktok.com/@bvscofficial?lang=es',
    napster:
      'https://mx.napster.com/artist/buena-vista-social-club/album/buena-vista-social-club-world-circuit',
  },
  {
    title: 'Frank Sinatra',
    description:
      'Francis Albert Sinatra, conocido como Frank Sinatra, fue un cantante y actor estadounidense, considerado una de las figuras más importantes del siglo XX y uno de los cantantes más populares de todos los tiempos en todo el mundo. Dejó, a través de sus discos y actuaciones en directo, un legado canónico en lo que respecta a la interpretación vocal masculina de la música. Su popularidad llegó a ser inmensa en todo el mundo y prácticamente constante a lo largo de toda su carrera, sus grabaciones alcanzaron las listas musicales 209 veces. Es uno de los 33 artistas en ostentar tres estrellas en el Paseo de la Fama de Hollywood..',
    videourl:
      'https://youtube.com/playlist?list=PL4X0crpaJ94XBIbsOwJSbQCIIVu5xRhoC',
    price: '$50',
    coverImg: 'FrankSinatra.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.sinatra.com/',
    youtube: 'https://www.youtube.com/channel/UCJtvg6ZFwzdFdtcHBqetvwg',
    facebook: 'https://www.facebook.com/sinatra',
    instagram: 'https://www.instagram.com/sinatra/',
    twitter: 'https://twitter.com/franksinatra',
    spotify: 'https://open.spotify.com/artist/1Mxqyy3pSjf8kZZL4QVxS0',
    deezer: 'https://www.deezer.com/mx/artist/617',
    apple: 'https://music.apple.com/mx/artist/frank-sinatra/171366',
    soundcloud: 'https://soundcloud.com/frank-sinatra',
    tidal: 'https://listen.tidal.com/artist/362',
    wiki: 'https://es.wikipedia.org/wiki/Frank_Sinatra',
    ticketmaster:
      'https://www.ticketmaster.com/frank-sinatra-show-tickets/artist/2225939',
    tiktok: 'https://www.tiktok.com/@franksinatra',
    napster: 'https://us.napster.com/artist/frank-sinatra',
  },
  {
    title: 'Tina Turner',
    description:
      'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
    videourl:
      'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
    price: '$50',
    coverImg: 'TinaTurner.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tinaturnerofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
    facebook: 'https://www.facebook.com/TinaTurner/',
    instagram: 'https://www.instagram.com/tinaturner/?hl=en',
    twitter: 'https://twitter.com/LoveTinaTurner',
    spotify:
      'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1454',
    apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
    soundcloud: 'https://soundcloud.com/tina-turner-official',
    tidal: 'https://listen.tidal.com/artist/9214',
    wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
    ticketmaster:
      'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
    tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
    napster: 'https://us.napster.com/artist/tina-turner',
  },
  {
    title: 'Ray Charles',
    description:
      'Ray Charles Robinson, más conocido como Ray Charles, fue un cantante, saxofonista y pianista estadounidense de soul y R&B. Entre sus amigos y músicos él prefería que le llamaran Hermano Ray y era frecuentemente referido como El Genio. Charles comenzó a perder la visión a la edad de 5 años y a los 7 años era ciego. Fue pionero de la música soul durante los años 1950 por la combinación del blues, rhythm and blues y el estilo gospel dentro de la música que grabó para el sello Atlantic. Contribuyó a la integración de la música country, rhythm and blues y música pop durante los años 1960 con su gran éxito en ABC Records, notable con sus dos álbumes de Modern Sound. Mientras estuvo con ABC, Ray Charles fue uno de los primeros músicos afroamericanos en tener control artístico en la compañía para la cual grababa.',
    videourl: 'https://youtube.com/playlist?list=PLF729174559CABBE2',
    price: '$50',
    coverImg: 'RayCharles.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://raycharles.com/',
    youtube: 'https://www.youtube.com/channel/UCuDb3PN8JzDZLY-TUosFkgw',
    facebook: 'https://www.facebook.com/RayCharles',
    instagram: 'https://www.instagram.com/official.raycharles/',
    twitter: 'https://twitter.com/raycharles_tm',
    spotify: 'https://open.spotify.com/artist/1Mxqyy3pSjf8kZZL4QVxS0',
    deezer: 'https://www.deezer.com/mx/artist/1342',
    apple: 'https://music.apple.com/mx/artist/ray-charles/160926',
    soundcloud: 'https://soundcloud.com/raycharles',
    tidal: 'https://listen.tidal.com/artist/9572',
    wiki: 'https://es.wikipedia.org/wiki/Ray_Charles',
    ticketmaster:
      'https://www.ticketmaster.com/ray-charles-tickets/artist/857437',
    tiktok: 'https://www.tiktok.com/search?q=Ray%20Charles&t=1660884101665',
    napster: 'https://us.napster.com/artist/ray-charles',
  },

  {
    title: 'James Brown',
    description:
      'James Joseph Brown fue un cantante de soul y funk estadounidense. Progenitor de la música funk y una figura importante de la música y la danza del siglo XX, a menudo se le refiere por los apodos honoríficos Padrino del Soul, Sr. Dinamita y Hermano Soul No. 1',
    videourl:
      'https://youtube.com/playlist?list=PLho6UqUrhDcXJ1yQBwcbP2mAkhGFTmcFa',
    price: '$50',
    coverImg: 'JamesBrown.jpg',
    category: 'Soul',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.jamesbrown.com/',
    youtube: 'https://www.youtube.com/channel/UCOCZxe0gNRA7c3PGWPGoiGg',
    facebook: 'https://www.facebook.com/JamesBrown',
    instagram: 'https://www.instagram.com/jamesbrown/',
    twitter: 'https://twitter.com/JamesBrownsSoul',
    spotify: 'https://open.spotify.com/artist/7GaxyUddsPok8BuhxN6OUW',
    deezer: 'https://www.deezer.com/mx/artist/1622',
    apple: 'https://music.apple.com/mx/artist/james-brown/117118',
    soundcloud: 'https://soundcloud.com/james-brown-music',
    tidal: 'https://listen.tidal.com/artist/6361',
    wiki: 'https://es.wikipedia.org/wiki/James_Brown',
    ticketmaster:
      'https://www.ticketmaster.com/james-brown-tickets/artist/734650',
    tiktok: 'https://www.tiktok.com/@jamesbrown?lang=es',
    napster: 'https://us.napster.com/artist/james-brown',
  },
  {
    title: 'Miles Davis',
    description:
      'Miles Dewey Davis III (Alton, Illinois, 26 de mayo de 1926-Santa Mónica, California, 28 de septiembre de 1991), conocido como Miles Davis, fue un trompetista y compositor estadounidense de jazz. Se trata de una de las figuras más relevantes, innovadoras e influyentes de la historia del jazz, La carrera de Davis, que abarca cincuenta años, recorre la historia del jazz a lo largo de toda la segunda mitad del siglo xx, caracterizándose por su constante evolución y búsqueda de nuevos caminos artísticos: Davis participa con igual fuerza del bebop y del cool, como del hardbop y de la vanguardia jazzística, sobre todo en su vertiente modal y de fusión con el rock. El sonido de su trompeta es característico por su uso de la sordina de acero Harmon, que le proporcionaba un toque más personal e íntimo; el sonido es suave y melódico, a base de notas cortas, tendente al lirismo y a la introspección..',
    videourl:
      'https://youtube.com/playlist?list=PL1RJCGLa8Ni25nlVxEj51HWM2-MQFPZUe',
    price: null,
    coverImg: 'milesdavis.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.milesdavis.com/',
    youtube: 'https://www.youtube.com/channel/UC1ZS17c0DlqUjsXZK3K_bgA',
    facebook: 'https://www.facebook.com/MilesDavis/',
    instagram: 'https://www.instagram.com/milesdavis/?hl=en',
    twitter: 'https://twitter.com/milesdavis',
    spotify: 'https://open.spotify.com/artist/0kbYTNQb4Pb1rPbbaF0pT4',
    deezer: 'https://www.deezer.com/mx/artist/1910',
    apple: 'https://music.apple.com/us/artist/miles-davis/44984',
    soundcloud: 'https://soundcloud.com/milesdavissonymusic',
    tidal: 'https://listen.tidal.com/artist/55',
    wiki: 'https://es.wikipedia.org/wiki/Miles_Davis',
    ticketmaster:
      'https://www.ticketmaster.com/miles-davis-story-tickets/artist/833774',
    tiktok: 'https://www.tiktok.com/@milesdavisenthusiast?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJQ4GY/miles-davis',
  },
  {
    title: 'John Coltrane',
    description:
      'John William Coltrane (Hamlet, 23 de septiembre de 1926-Nueva York, 17 de julio de 1967), también conocido como John Coltrane, fue un músico estadounidense de jazz, saxofonista tenor y saxo soprano. Ocasionalmente, tocó el saxo alto y la flauta.Aunque también muy controvertido, se trata de uno de los músicos más relevantes e influyentes de la historia del jazz, a la altura de otros artistas como Louis Armstrong, Duke Ellington, Charlie Parker y Miles Davis. Se casó, en segundas nupcias, con la pianista de jazz Alice Coltrane (1937-2007).Su trayectoria musical estuvo marcada por una constante creatividad y siempre dentro de la vanguardia, abarca los principales estilos del jazz posteriores al bop: hard bop, free jazz y jazz modal. La discografía de Coltrane es considerable: grabó alrededor de cincuenta discos como líder en doce años y apareció en más de una docena de discos guiado por otros músicos..',
    videourl:
      'https://youtube.com/playlist?list=PLGUJd_6WZDWv_YGv6b4kd1IJbaH_Z7bGP',
    price: null,
    coverImg: 'johncoltrane.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.johncoltrane.com/',
    youtube: 'https://www.youtube.com/channel/UCGiKlUaxFFNXkEYIW6mfbBQ',
    facebook: 'https://www.facebook.com/johncoltrane',
    instagram: 'https://www.instagram.com/johncoltrane/',
    twitter: 'https://twitter.com/johncoltrane',
    spotify: 'https://open.spotify.com/artist/2hGh5VOeeqimQFxqXvfCUf',
    deezer: 'https://www.deezer.com/mx/artist/2449',
    apple: 'https://music.apple.com/us/artist/john-coltrane/120199',
    soundcloud: 'https://soundcloud.com/johncoltraneofficial',
    tidal: 'https://listen.tidal.com/artist/9042',
    wiki: 'https://es.wikipedia.org/wiki/John_Coltrane',
    ticketmaster:
      'https://www.ticketmaster.com/john-coltrane-tickets/artist/1408672',
    tiktok: 'https://www.tiktok.com/@johncoltranefan67?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJR4VI/john-coltrane',
  },
  {
    title: 'Thelonious Monk',
    description:
      'Thelonious Sphere Monk (Rocky Mount, Carolina del Norte, 10 de octubre de 1917-Weehawken, Nueva Jersey, 17 de febrero de 1982) fue un pianista y compositor estadounidense de jazz.Su estilo interpretativo y compositivo, formado plenamente en 1947, apenas varió en los 25 años siguientes. Pianista fundador del bebop, tocó también bajo el influjo del hard bop y del jazz modal. Es conocido por su estilo único de improvisación, así como por haber compuesto varios estándares de jazz como Round Midnight, Straight No Chaser, 52nd Street Theme y Blue Monk. Monk es el segundo compositor de jazz con más grabaciones después de Duke Ellington.',
    videourl:
      'https://youtube.com/playlist?list=PLBfzVegacir-5DUeYJ3FFMLacJmQ_MvLH',
    price: null,
    coverImg: 'theloniousmonk.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://theloniousmonk.store/',
    youtube: 'https://www.youtube.com/channel/UCHiOp5Z2ECvD00UChyfM5Xw',
    facebook: 'https://www.facebook.com/TheloniousMonk.Store/',
    instagram: 'https://www.instagram.com/theloniousmonk/',
    twitter: 'hhttps://twitter.com/tsmonkofficial',
    spotify:
      'https://open.spotify.com/artist/4PDpGtF16XpqvXxsrFwQnN?si=dcIcp4BoRD2B70r5zpjZdQ&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/5136',
    apple: 'https://music.apple.com/us/artist/thelonious-monk/45058',
    soundcloud: 'https://soundcloud.com/johncoltraneofficial',
    tidal: 'https://listen.tidal.com/artist/184',
    wiki: 'https://es.wikipedia.org/wiki/Thelonious_Monk',
    ticketmaster:
      'https://www.ticketmaster.com/thelonious-monk-tickets/artist/840257',
    tiktok: 'https://www.tiktok.com/@theoloniousmonk?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJKALE/thelonious-monk',
  },

  {
    title: 'Duke Ellington',
    description:
      'Edward Kennedy «Duke» Ellington (Washington D. C., 29 de abril de 1899-Nueva York, 24 de mayo de 1974) fue un compositor, pianista y líder de una big band, lo que lo llevó a una carrera que abarcó más de cincuenta años, desde 1923 hasta su muerte.Nacido en Washington D. C., a partir de mediados de los años veinte vivió en la ciudad de Nueva York, y ganó un perfil nacional a través de sus apariciones con la orquesta en el Cotton Club, en Harlem. En la década de los años treinta, su orquesta salió de gira a Europa. Aunque es considerado una figura fundamental en la historia del jazz, Ellington abraza la frase más allá de la categoría como un principio liberador, y se refiere a su música como parte de la categoría general Música estadounidense, más que de un género como el jazz.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mIuUVW0rwTB8GNaLPxUZZ0nN_rNM90iwA',
    price: null,
    coverImg: 'dukeellington.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://dukeellington.com/',
    youtube: 'https://www.youtube.com/channel/UCHiOp5Z2ECvD00UChyfM5Xw',
    facebook: 'https://www.facebook.com/dukeellingtonmusic',
    instagram: 'https://www.instagram.com/_duke.ellington__/',
    twitter: 'https://twitter.com/DukeEllington',
    spotify: 'https://open.spotify.com/artist/4F7Q5NV6h5TSwCainz8S5A',
    deezer: 'https://www.deezer.com/mx/artist/57504',
    apple: 'https://music.apple.com/us/artist/duke-ellington/39525',
    soundcloud: 'https://soundcloud.com/dukeellington',
    tidal: 'https://listen.tidal.com/artist/1156',
    wiki: 'https://es.wikipedia.org/wiki/Duke_Ellington',
    ticketmaster:
      'https://www.ticketmaster.com/duke-ellington-jazz-festival-tickets/artist/1071138',
    tiktok: 'https://www.tiktok.com/@dukeellington?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJK8PC/duke-ellington',
  },
  {
    title: 'Charlie Parker',
    description:
      "Charles Christopher Parker, Jr. (Kansas City, 29 de agosto de 1920 - Nueva York, 12 de marzo de 1955), conocido como Charlie Parker, fue un saxofonista y compositor estadounidense de jazz. Apodado Bird y Yardbird, es considerado uno de los mejores intérpretes de saxofón alto de la historia de ese género musical, siendo una de las figuras claves en su evolución y uno de sus artistas más legendarios y admirados. Su estilo rompe con el del swing y se basa en la improvisación sobre una melodía modificando los acordes, creando así nuevas variaciones sobre la estructura de los temas. En este sentido, aparte de su obra interpretativa, Parker es autor de varios temas que se han convertido en estándares del jazz, como «Ornithology», «Anthropology», «Scrapple from the Apple», «Ko Ko», «Now's the Time» y «Parker's Mood»..",
    videourl:
      'https://youtube.com/playlist?list=PL4NJlMSxRGhQ5Jj2O8YthxwygRnDSCI9q',
    price: null,
    coverImg: 'charlieparker.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://charlieparkermusic.com/',
    youtube: 'https://www.youtube.com/channel/UCf8UfCiGDDmMEEXXDLHPR5w',
    facebook: 'https://www.facebook.com/charlieparkermusic',
    instagram: 'https://www.instagram.com/charliebirdparkerofficial/',
    twitter: 'https://twitter.com/CharlieParkerKC',
    spotify: 'https://open.spotify.com/artist/4Ww5mwS7BWYjoZTUIrMHfC',
    deezer: 'https://www.deezer.com/mx/artist/2692',
    apple: 'https://music.apple.com/us/artist/charlie-parker/284786497',
    soundcloud: 'https://soundcloud.com/charlie-parker',
    tidal: 'https://listen.tidal.com/artist/872',
    wiki: 'https://es.wikipedia.org/wiki/Charlie_Parker',
    ticketmaster:
      'https://www.ticketmaster.com/duke-ellington-jazz-festival-tickets/artist/1071138',
    tiktok: 'https://www.tiktok.com/@charliebirdparker?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP73K/charlie-parker',
  },
  {
    title: 'Charles Mingus',
    description:
      'Nació en una base militar del Ejército de Estados Unidos, cerca de la ciudad de Nogales (estado de Arizona). Se crio en el área de Watts, en Los Ángeles (California). Su familia era de origen sueco y afroamericano por parte de sus abuelos paternos y chino y británico por parte de sus abuelos maternos. A pesar de esto, creció en un entorno familiar estricto y discriminatorio..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mgBsHc_l1-tiecL2XKouLmt2_Qg5I_LRc',
    price: null,
    coverImg: 'charlesmingus.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.charlesmingus.com/',
    youtube: 'https://www.youtube.com/channel/UCXSaOCck3oVxhB4W_4Ca3MQ',
    facebook: 'https://www.facebook.com/charlesmingus',
    instagram: 'https://www.instagram.com/charlesminguslegacy/',
    twitter: 'https://twitter.com/mingus',
    spotify:
      'https://open.spotify.com/artist/1W8TbFzNS15VwsempfY12H?si=fV-I4jvdRsmRpqPkzk4CbA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/5123',
    apple: 'https://music.apple.com/us/artist/charles-mingus/478880',
    soundcloud: 'https://soundcloud.com/charlesmingus',
    tidal: 'https://listen.tidal.com/artist/1070',
    wiki: 'https://es.wikipedia.org/wiki/Charles_Mingus',
    ticketmaster:
      'https://www.ticketmaster.com/charles-mingus-tickets/artist/871413',
    tiktok: 'https://www.tiktok.com/@charlesmingusswagaccount?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJHUVM/charles-mingus',
  },
  {
    title: 'Louie Armstrong',
    description:
      'Nueva Orleans, 4 de agosto de 1901 Nueva York, 6 de julio de 1971), también conocido como Satchmo o Pops, fue un trompetista y cantante estadounidense de jazz.Se trata de una de las figuras más carismáticas e innovadoras de la historia del jazz y, probablemente, su músico más popular. Gracias a sus habilidades musicales y a su brillante personalidad, transformó el jazz desde su condición inicial de música de baile, en una forma de arte popular. Aunque en el arranque de su carrera cimentó su fama sobre todo como cornetista y trompetista, más adelante sería su condición de vocalista la que le consagraría como una figura internacionalmente reconocida y de enorme influencia para el canto jazzístico..',
    videourl:
      'https://youtube.com/playlist?list=PL3poVfdBYskylFkE91EW_uls-iOzv6I6c',
    price: null,
    coverImg: 'louisarmstrong.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.louisarmstronghouse.org/',
    youtube: 'https://www.youtube.com/channel/UC9ZFNi6DcduUn9KUMhOnFUQ',
    facebook: 'https://www.facebook.com/louisarmstrong/',
    instagram: 'https://www.instagram.com/louisarmstronghouse/',
    twitter: 'https://twitter.com/ArmstrongHouse',
    spotify: 'https://open.spotify.com/artist/19eLuQmk9aCobbVDHc6eek',
    deezer: 'https://www.deezer.com/mx/artist/2337',
    apple: 'https://music.apple.com/us/artist/louis-armstrong/518462',
    soundcloud: 'https://soundcloud.com/charlesmingus',
    tidal: 'https://listen.tidal.com/artist/1070',
    wiki: 'https://es.wikipedia.org/wiki/Louis_Armstrong',
    ticketmaster:
      'https://www.ticketmaster.com/louis-armstrong-tradition-tickets/artist/875083',
    tiktok: 'https://www.tiktok.com/@louisarmstrongofficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJO150/louis-armstrong',
  },
]

export default CardData19
