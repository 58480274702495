const CardData3 = [
  {
    title: 'Bo Bundy',
    description:'El rapero estadounidense de raíces mexicanas Bo Bundy, presenta su primer estreno del año el tema y video musical “Devuélveme a mi chica”, una versión divertida del popular tema de la banda española, Hombres G.El video musical de la canción se desarrolla en un cuadrilátero en donde Bo Bundy lucha con la fuerza de su voz y la banda que lo acompaña para que le devuelvan a su chica, que es la historia de la canción, que sale bajo el sello Rancho Humilde.El tema es un adelanto de su próximo disco que estará compuesto por 26 canciones. Las doce primeras le rendirán homenaje a México, el país de sus raíces, con ritmos como el rap mexicano y las famosas cumbias de Coahuila, el norteño estado mexicano sonidero.Las catorce restantes hablarán de Texas, la tierra donde nació, ofreciendo en esta parte sonidos de rap en inglés, música country y rock. Bo Bundy el nombre artístico de Jorge Frías, hace referencia al cantante, productor y guitarrista norteamericano Bo Diddley. A Bodacious, conocido en el mundo del rodeo como el toro más peligroso del mundo.El artista recuerda también a Al Bundy, personaje ficticio de la reconocida comedia Married with Children (Casado con Hijos). Al gran luchador King Kong Bundy, e incluso al terrible asesino en serie, el estadounidense Ted Bundy.', 
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlV9K-9aIXjJsT1kOYv_7jae&si=F5XVghNQZDGxMuLk',
    price: '$50',
    coverImg: 'bobundy.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://schedule.sxsw.com/2024/artists/2198782',
    youtube: 'https://www.youtube.com/@MalavidaTV',
    facebook: 'https://www.facebook.com/bobundyy/',
    instagram: 'https://www.instagram.com/bobundyy/',
    twitter: 'https://x.com/BoBundy',
    spotify: 'https://open.spotify.com/artist/5Tm0Q6noHS5KjlsvFwHoFS',
    deezer: 'https://www.deezer.com/mx/artist/11040304',
    apple: 'https://music.apple.com/mx/artist/bo-bundy/1155766153',
    soundcloud: 'https://soundcloud.com/bobundy741',
    tidal: 'https://listen.tidal.com/artist/8136174',
    wiki: 'https://www.chicagotribune.com/2023/01/23/el-rapero-de-races-mexicanas-bo-bundy-recrea-devulveme-a-mi-chica-de-hombres-g/',
    ticketmaster:
      'https://www.songkick.com/artists/10103309-bo-bundy',
    tiktok: 'https://www.tiktok.com/@bobundyyy',
    napster: 'https://music.amazon.com.mx/artists/B01M0V2QB1/bo-bundy',
  },
  {
    title: 'CNG',
    description:'El artista de hip hop que se hizo conocido con un sencillo titulado "Not My President" continuó lanzando otros sencillos como "Smooth Operator" y "Westside," cada uno acompañado de un video musical en su canal de YouTube, CNG ONE. Creció en un entorno de violencia de pandillas y su familia se mudaba frecuentemente durante su infancia. Lanzó su canal de YouTube en 2016. Su nombre artístico, que significa "viene y va," refleja su estilo y enfoque musical. Participó en la serie de Netflix Rhythm + Flow, y su cuenta de Instagram ha acumulado más de 360,000 seguidores. El video musical de su canción "Risk Taker," lanzado en 2022, acumuló casi 200,000 vistas en YouTube en menos de tres meses.', 
    videourl:
      'https://www.youtube.com/playlist?list=PLF-cRDh2mrlWs2LTL6KZiBjhL-PRjeRRY',
    price: '$50',
    coverImg: 'cng.jpeg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.cngeazzy.com/',
    youtube: 'https://www.youtube.com/channel/UC3wEYbdUG7_n1qjSIsLXESA',
    facebook: 'https://www.facebook.com/cngeazy',
    instagram: 'https://www.instagram.com/cngeazzy/',
    twitter: 'https://x.com/CNGEAZZY',
    spotify: 'https://open.spotify.com/artist/4zYi7nhOqLxJ9u2YE8Ysca',
    deezer: 'https://www.deezer.com/mx/artist/9989392',
    apple: 'https://music.apple.com/mx/artist/cng/1367648730',
    soundcloud: 'https://soundcloud.com/comesngoes',
    tidal: 'https://listen.tidal.com/artist/8986434',
    wiki: 'https://www.famousbirthdays.com/people/cng.html',
    ticketmaster:
      'https://events.humanitix.com/cng-live',
    tiktok: 'https://www.tiktok.com/@cngeazzy',
    napster: 'https://music.amazon.com.mx/artists/B01CQP7A54/cng',
  },
  {
    title: 'Remik Gonzales',
    description:
      'El de Remik Gonzalez es uno de los nombres ineludibles cuando se habla del rap malandro, la expresión musical del lenguaje y el estilo de vida de los barrios menos favorecidos de urbes como su Tijuana natal.Remik González es un conocido rapero mexicano, popular en la escena de la música urbana latina. Su música se caracteriza por letras crudas y comentarios sociales, abordando temas como la vida en las calles, las luchas personales y las realidades de las comunidades marginadas. Remik ha construido un fuerte seguimiento gracias a su estilo auténtico y sus actuaciones llenas de energía, colaborando a menudo con otros artistas de música urbana. Es parte del creciente movimiento de trap y hip-hop latino, y su música resuena particularmente con las audiencias jóvenes que aprecian la autenticidad de sus historias. Algunas de sus canciones más populares incluyen Mil Lágrimas, Me Levanto, y colaboraciones con otros artistas reconocidos.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlVZJ1VzQ1VAWiJh9scW9auD&si=JDJ_bgKn5EemPnID',
    price: '$50',
    coverImg: 'remik.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.letras.com/remik-gonzalez/',
    youtube: 'https://www.youtube.com/channel/UC1hlr4QMZoNPEuxXOvsFhqA',
    facebook: 'https://www.facebook.com/RemikGonzalez664/?fref=ts',
    instagram: 'https://www.instagram.com/remikgonzalez664/',
    twitter: 'https://x.com/Diego18647815',
    spotify: 'https://open.spotify.com/artist/4Hzj6dfl1y5x5TzCSsvLB6',
    deezer: 'https://www.deezer.com/mx/artist/8682742',
    apple: 'https://music.apple.com/mx/artist/remik-gonzalez/966627770',
    soundcloud: 'https://soundcloud.com/remikgonzalez',
    tidal: 'https://listen.tidal.com/artist/7150581',
    wiki: 'https://www.letras.com/remik-gonzalez/',
    ticketmaster:
      'https://remik-gonzalez-tour-mis-demonios.boletia.com/',
    tiktok: 'https://www.tiktok.com/@remik.gonzalez.664?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B013SZ30SM/remik-gonzalez',
  },{
    title: 'Gera MX',
    description:
      'Gerardo Daniel Torres Montante (San Nicolás de los Garza, Nuevo León; 15 de julio de 1994 ), mejor conocido por su nombr MX (anteriormente Gera MXM), es un rapero y compositor mexicano. Su nombre artístico proviene de las primeras cuatro letras de su nombre real, Gera (Gerardo) y la abreviatura del sello Mexamafia ( MXM ), la abreviatura MX proviene de México.e artístico Gera.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nKCRl-R8dhnNTcmpHzJ5XiKzLseTEaij4',
    price: '$50',
    coverImg: 'GeraMX.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: 1000,
    likes: 1000,
    websiteurl: 'https://richvagosent.com/',
    youtube: 'https://www.youtube.com/watch?v=ZaJZ9GJwz30',
    facebook: 'https://www.facebook.com/GERAMX444/',
    instagram: 'https://www.instagram.com/geramx1/',
    twitter: 'https://twitter.com/mxgeramx',
    spotify: 'https://open.spotify.com/artist/2hejA1Dkf8v8R0koF44FvW',
    deezer: 'https://www.deezer.com/mx/artist/6661704',
    apple: 'https://music.apple.com/mx/artist/gera-mx/863348914',
    soundcloud: 'https://soundcloud.com/geramx-music',
    tidal: 'https://listen.tidal.com/artist/8511790',
    wiki: 'https://es.wikipedia.org/wiki/Gera_MX',
    ticketmaster:
      'https://www.ticketmaster.com./gera-mx-tickets/artist/2773162',
    tiktok: 'https://www.tiktok.com/@geramxfans.official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B079V2DR9G/gera-mx',
  },
  {
    title: 'Alzada',
    description:
      'El colectivo Alzada por fin se estableció como sello discográfico, enfocándose en agencia de managers y creadora de contenido con el único propósito de reforzar el posicionamiento del género hip hop y llevarlo a la alza desde la bella ciudad de Guadalajara para todo el mundo.​',
    videourl:
      'https://youtube.com/playlist?list=PLRyfHQgnqHtz76_ZfaBvPQcD1HutYHHm6',
    price: 50,
    coverImg: 'alzada.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://alzada.mx/',
    youtube: 'https://www.youtube.com/@ALZADAOficial',
    facebook: 'https://www.facebook.com/alzadaoficial',
    instagram: 'https://www.instagram.com/alzadaoficial/',
    twitter: 'https://twitter.com/alzadaoficial',
    spotify:
      'https://open.spotify.com/user/60ohztm5ou3u0x4ojga1kbmwu?si=tgDdqVhVQO62YkuJzO2KRA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4908447',
    apple: 'https://music.apple.com/mx/artist/alzada/299724687',
    soundcloud: 'https://soundcloud.com/alzada',
    tidal: 'https://listen.tidal.com/artist/23670566',
    wiki: 'https://es.wikipedia.org/wiki/Archivo:Neto_Pe%C3%B1a_Alzada.jpg',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@alzadaoficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B001NFOQWO/alzada',
  },
  {
    title: 'C Kan',
    description:
      'José Luis Maldonado Ramos (Guadalajara, Jalisco, 26 de julio de 1987),1​ más conocido por su nombre artístico C-Kan, es un rapero, compositor, productor y actor mexicano.2​ Su nombre artístico proviene de la palabra Can, (Perro)3​ y la letra C, de cancha: por el lugar en el que vivía La Cancha 98 C, Perro», estilizándose como C-Kan).4​ Ganó gran fama en 2012 con su álbum de estudio Voy por el sueño de muchos, alcanzando el número 1 de ventas en iTunes México​',
    videourl: 'https://youtube.com/playlist?list=UUkD89TEFWsTZluY6tk84yqg',
    price: 50,
    coverImg: 'ckan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.masteredtrax.com/',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/CKanMEX/',
    instagram: 'https://www.instagram.com/ckan98/',
    twitter: 'https://twitter.com/ckan98',
    spotify:
      'https://open.spotify.com/artist/1QhaqxeqF9sipS2gwbEKpu?si=qt0Wjxi4SiuG0poydNjOxA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4252576',
    apple: 'https://music.apple.com/mx/artist/c-kan/305830705',
    soundcloud: 'https://soundcloud.com/ckan98',
    tidal: 'https://listen.tidal.com/artist/4893770',
    wiki: 'https://es.wikipedia.org/wiki/C-Kan',
    ticketmaster: 'https://www.ticketmaster.com./ckan-tickets/artist/2258547',
    tiktok: 'https://www.tiktok.com/@c.kan98?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00ETJ7P4M/ckan',
  },
  {
    title: 'Santa Fe Klan',
    description:
      'Angel Jair Quezada Jasso (Guanajuato, Guanajuato, 29 de noviembre de 1999), más conocido por su nombre artístico Santa Fe Klan, es un rapero, cantante y compositor mexicano, que incorpora a su producción musical géneros como la cumbia y el rap mexicano.Nació y creció en la colonia Santa Fe de la ciudad de Guanajuato, de donde tomó su nombre artístico. Tuvo interés en la música desde muy pequeño luego de que sus padres le comprara instrumentos musicales. A los 13 años aprendió a grabar sus propias canciones y a los 14 ya contaba con un estudio propio. Se mudó de Guanajuato a Guadalajara para proseguir su carrera artística. En esa ciudad se unió a Alzada Récords, un grupo dedicado al impulso del rap mexicano, mismo con el que grabó sus primeras producciones, y que dejó en 2020. En ese año colaboró con Akwid y Neto Peña en el sencillo «Gallo de pelea».',
    videourl:
      'http://youtube.com/playlist?list=PLtm_sA0aLudCVWvu1Bo4FCcaHTfRpHkx_',
    price: '$50',
    coverImg: 'SantafeKlan.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Santa_Fe_Klan',
    youtube: 'https://www.youtube.com/channel/UC4Ge3cqrdyOszmnJTcMICcg',
    facebook: 'https://www.facebook.com/santafeklanoficial',
    instagram: 'https://www.instagram.com/santa_fe_klan_473/?hl=es-la',
    twitter: 'https://twitter.com/santa_fe_klan_',
    spotify: 'https://open.spotify.com/artist/4tm8CEdm4pkQsEh4jIr9Yp',
    deezer: 'https://www.deezer.com/mx/artist/10816636',
    apple: 'https://music.apple.com/mx/artist/santa-fe-klan/1140191076',
    soundcloud: 'https://soundcloud.com/santafeklan',
    tidal: 'https://listen.tidal.com/artist/8034076',
    wiki: 'https://es.wikipedia.org/wiki/Santa_Fe_Klan',
    ticketmaster:
      'https://www.ticketmaster.com/santa-fe-klan-tickets/artist/2802476',
    tiktok: 'https://www.tiktok.com/@santa.feklan473?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B01KW2YAQA/santa-fe-klan',
  },
  {
    title: 'Cartel de Santa',
    description:
      'Cartel de Santa es una banda mexicana de hip hop creada en Santa Catarina, Nuevo León. El grupo comenzó sus actividades en 1996 bajo distintos nombres y miembros antes de tomar su nombre y alineación definitivos. el grupo está compuesto por el vocalista principal Eduardo Dávalos De Luna y Román Rodríguez, más conocido como Rowan Rabia, Monoplug o simplemente Mono, antes también formaba parte del grupo Dharius, pero se separa del mismo a mediados de 2013 por problemas personales.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUOvGrt-AKKvC7HFT4HZmEi',
    price: 50,
    coverImg: 'CarteldeSanta.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://carteldesanta.com/',
    youtube: 'https://www.youtube.com/c/elcarteldesantatv',
    facebook: 'https://www.facebook.com/carteldesantaOG',
    instagram: 'https://www.instagram.com/carteldesantaoficial/',
    twitter: 'https://twitter.com/elcarteldesanta',
    spotify: 'https://open.spotify.com/artist/07PdYoE4jVRF6Ut40GgVSP',
    deezer: 'https://www.deezer.com/mx/artist/13883',
    apple: 'https://music.apple.com/mx/artist/cartel-de-santa/2351845',
    soundcloud: 'https://soundcloud.com/molotov-official',
    tidal: 'https://listen.tidal.com/artist/7717',
    wiki: 'https://es.wikipedia.org/wiki/Cartel_de_Santa',
    ticketmaster:
      'https://www.ticketmaster.com/cartel-de-santa-tickets/artist/1220878',
    tiktok: 'https://www.tiktok.com/@carteldesantaoficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKFGH6/cartel-de-santa',
  },
  {
    title: ' Dharius',
    description:
      'Alan Alejandro Maldonado Tamez (Monterrey, Nuevo León, 24 de septiembre de 1984), más conocido por su nombre artístico Dharius (anteriormente MC Dharius), es un rapero y compositor mexicano.Es conocido por haber formado parte de la agrupación de Hip Hop Cartel de Santa desde 1999 hasta 2013. El video y canción Me Alegro de Su Odio, fue la última composición que hizo con Cartel de Santa.',
    videourl:
      'http://youtube.com/playlist?list=PLJN9CFcMfX3L9w-yFy9aRUW3jwaQA8npw',
    price: '$50',
    coverImg: 'Dharius.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Dharius',
    youtube: 'https://www.youtube.com/channel/UCRWeaLWbhoHuHNjxLgG4asQ',
    facebook: 'https://www.facebook.com/Dhariuseloriginal',
    instagram: 'https://www.instagram.com/dha_dharius_official/',
    twitter: 'https://twitter.com/dhariuselocaso',
    spotify: 'https://open.spotify.com/artist/66RfYVdftqnuHRicyClgL0',
    deezer: 'https://www.deezer.com/mx/artist/4319377',
    apple: 'https://music.apple.com/mx/artist/dharius/546602103',
    soundcloud: 'https://soundcloud.com/dharius-official',
    tidal: 'https://listen.tidal.com/artist/5677431',
    wiki: 'https://es.wikipedia.org/wiki/Dharius',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Rap-Hip-Hop-Reggae/Dharius-Boletos',
    tiktok: 'https://www.tiktok.com/@dharius2021?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B009BKUZXM/dharius',
  },
  {
    title: 'Ill Zakiel',
    description:
      'Born in Guadalajara, Jalisco, Mexico and raised in Northern Nevada, ILL ZakieL has put years into his craft dating back to 2012 when he released his first solo single “Hate You All”. He has since released a large catalog of music and has been building a loyal following along the way.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lQzkX6e6_Xwa4691CA08-aDMbKGDkKeLw',
    price: '$50',
    coverImg: 'illZakiel.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.illzakiel.com/',
    youtube: 'https://www.youtube.com/channel/UCpAWUqOyXV0vNFlZ6768kMA',
    facebook: 'https://www.facebook.com/ILLZakieL',
    instagram: 'https://www.instagram.com/illzakiel/',
    twitter: 'https://twitter.com/iLLZakieL',
    spotify: 'https://open.spotify.com/artist/1YrZNlHcmFvsH09XE0GAgI',
    deezer: 'https://www.deezer.com/mx/artist/2022341',
    apple: 'https://music.apple.com/mx/artist/ill-zakiel/522215252',
    soundcloud: 'https://soundcloud.com/ill-zakiel',
    tidal: 'https://listen.tidal.com/artist/6776242',
    wiki: 'https://es.wikipedia.org/wiki/Zadkiel',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok:
      'https://www.tiktok.com/@illzakiel/video/7102888925886369070?is_copy_url=1&is_from_webapp=v1&lang=es',
    napster: 'https://music.amazon.com.mx/artists/B007XBD4XE/ill-zakiel',
  },

  {
    title: ' Aczino',
    description:
      'Mauricio Hernández González (Los Reyes, La Paz, Estado de México; 2 de julio de 1991), conocido comúnmente como Mau o Aczino, es un rapero y freestyler mexicano que cuenta con múltiples reconocimientos en torneos nacionales e internacionales.Es considerado por muchos aficionados y especialistas, como el mejor freestyler en la historia. En 2021 se convirtió en el primer bicampeón de Red Bull internacional.',
    videourl: 'http://youtube.com/playlist?list=UUGbyLk1twkkSSCFFMP1AZ9A',
    price: '$50',
    coverImg: 'Aczino.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Aczino',
    youtube: 'https://www.youtube.com/channel/UCGbyLk1twkkSSCFFMP1AZ9A',
    facebook: 'https://www.facebook.com/AczinoOficial/',
    instagram: 'https://www.instagram.com/aczino_oficial/',
    twitter: 'https://twitter.com/ACZINO',
    spotify: 'https://open.spotify.com/artist/4r1ZDYKzPt3iIjuq8LbT6X',
    deezer: 'https://www.deezer.com/mx/artist/7894558',
    apple: 'https://music.apple.com/mx/artist/aczino/951185338',
    soundcloud: 'https://soundcloud.com/aczino',
    tidal: 'https://listen.tidal.com/artist/7348435',
    wiki: 'https://es.wikipedia.org/wiki/Aczino',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Rap-Hip-Hop-Reggae/Aczino-Boletos',
    tiktok: 'https://www.tiktok.com/@aczino_oficial1?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00X4MSBNE/aczino',
  },
  {
    title: ' Millionario',
    description:
      'Cartel de Santa comenzó sus actividades desde 1996, pero se volvió famoso en 2002 y en 2011 formaron, junto con “Millonario”, que ya colaboraba con ellos y con Corona R.B.U., en la creación de su disquera Casa Babilonia Records.El 31 de julio de julio de 2015 se estrenó la película “Los Jefes”. En 2010, el canal de YouTube oficial del Cartel de Santa superó los 10.6 millones de suscriptores, convirtiéndose en el grupo de hip-hop con más seguidores en México.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lr4F8vE1aekX2nulrhlgFbcsPw_lNti_w',
    price: '$50',
    coverImg: 'millionario.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://carteldesanta.com/',
    youtube: 'https://www.youtube.com/channel/UC35vyo1h2K_jLNi8oFtC9QA',
    facebook: 'https://www.facebook.com/MILLONARIOBM?fref=ts',
    instagram: 'https://www.instagram.com/millonario_bm/',
    twitter: 'https://twitter.com/MILLONARIOBM',
    spotify: 'https://open.spotify.com/artist/2N2aJ1SXQxMkhHD8C6fhYD',
    deezer: 'https://www.deezer.com/mx/artist/5899300',
    apple: 'https://music.apple.com/mx/artist/millonario/899921339',
    soundcloud: 'https://soundcloud.com/millonarioofficial',
    tidal: 'https://listen.tidal.com/artist/5929487',
    wiki: 'https://es.wikipedia.org/wiki/Cartel_de_Santa',
    ticketmaster:
      'https://www.ticketmaster.com./millonario-tickets/artist/2768580',
    tiktok: 'https://www.tiktok.com/@millonario_bm?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00KUQWF0C/millonario',
  },
  {
    title: ' Aleman',
    description:
      'Erick Raúl Alemán Ramírez, mejor conocido como Alemán, es un rapero mexicano. Comenzó en el 2014 con el sello Homegrown Mafia lanzando su álbum debut Pase de Abordar. Su más conocido álbum Eclipse fue todo un éxito en varios países, realizando una gira por México, Chile, Argentina y España. Chapo Guzmán fue considerada una de las mejores canciones de rap mexicano por la revista Noisey Francia. La canción hace apología a la vida del narcotraficante sinaloense Joaquín Guzmán Loera.',
    videourl:
      'https://youtube.com/playlist?list=PLLNV8xbfak44h-H-e4VwNHwa7XMygb3N1&si=8qKCdf44Q5ZrtXdS',
    price: '$50',
    coverImg: 'Aleman.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://es.wikipedia.org/wiki/Alem%C3%A1n_(rapero)',
    youtube: 'https://www.youtube.com/channel/UColfxbsMdfxj8TxBK5Dpdiw',
    facebook: 'https://www.facebook.com/MxAlemanMx',
    instagram: 'https://twitter.com/MxAlemanMx',
    twitter:
      'https://twitter.com/MxAlemanMx?fbclid=IwAR25XdeBYr-NK6BBK2GvszynTbUFHbxka_JUQi5CBVolambKbI8rEWcPSpM',
    spotify: 'https://open.spotify.com/artist/4QFG9KrGWEbr6hNA58CAqE',
    deezer: 'https://app.napster.com/artist/aleman-2',
    apple: 'https://music.apple.com/mx/artist/alem%C3%A1n/263208292',
    soundcloud: 'https://soundcloud.com/alemanofficial',
    tidal: 'https://listen.tidal.com/artist/7112733',
    wiki: 'https://es.wikipedia.org/wiki/Alem%C3%A1n',
    ticketmaster: 'https://www.ticketmaster.com./aleman-tickets/artist/2766227',
    tiktok: 'https://www.tiktok.com/@aleman_oficial03?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0011XXHZA/aleman',
  },
  {
    title: 'Pat Boy',
    description:
      "LOS ÁNGELES, EEUU — Jesús 'Pat Boy' Chablé, se ha convertido en un referente en el mundo del rap, pero no de uno cualquiera, si no del Rap Maya.El deseo del artista de preservar su cultura indígena, le llevó por una travesía que le ha convertido en un pionero de dicho género musical más allá de sus fronteras y en el proceso ha logrado dar visibilidad a su comunidad dentro y fuera de México.Pat Boy, en entrevista con la Voz de América dijo ser un rapero con una misión, que es la de “promover y preservar” su lengua natal y su cultura.“Soy exponente de rap en lengua maya orgullosamente..",
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mtoW8WYF6VWwjF0dRmnmueDteajUll-l4',
    price: 50,
    coverImg: 'Patboy.jpeg',
    category: 'Rap Maya',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/albums/Bizarrap/Bzrp-music-sessions',
    youtube: 'https://www.youtube.com/@mayapatboyrap',
    facebook: 'https://www.facebook.com/Patboyrapmayaoficial/',
    instagram: 'https://www.instagram.com/patboy_rap_mayaoficial/',
    twitter: 'https://twitter.com/PatBoyrapmaya',
    spotify: 'https://open.spotify.com/artist/7w2LEbH35jdB5RamMTuf40',
    deezer: 'https://www.deezer.com/mx/artist/12530092',
    apple: 'https://music.apple.com/mx/artist/pat-boy/1141511081',
    soundcloud: 'https://soundcloud.com/pat-boy-rapmaya',
    tidal: 'https://tidal.com/browse/artist/10004800',
    wiki: 'https://en.wikipedia.org/wiki/Jes%C3%BAs_Pat_Chabl%C3%A9',
    ticketmaster: 'https://www.viagogo.com.mx',
    tiktok: 'https://www.tiktok.com/@patboyrapmaya?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07DZ4S79Q/pat-boy',
  },
  {
    title: 'Bizzarap',
    description:
      'Gonzalo Julián Conde (nacido el 29 de agosto de 1998), conocido profesionalmente como Bizarrap , es un productor discográfico y DJ argentino . Se especializa en EDM , trap latino y rap . Es conocido por sus Bzrp Music Sessions, que graba con una amplia variedad de artistas.  Fue nominado a un Premio Grammy Latino a Mejor Productor en 2021.Gonzalo nació el 29 de agosto de 1998 en Ramos Mejía , Argentina. Se interesó por la música desde muy joven, y tenía interés en la música electrónica y los DJ , más que en cantar. Sus principales influencias fueron Skrillex , David Guetta y Martin Garrix . A los 14 años comenzó a estudiar solfeo, se matriculó en clases de piano y comenzó a producir sus primeras canciones. .',
    videourl: 'https://youtube.com/playlist?list=UULFmS75G-98QihSusY7NfCZtw',
    price: 50,
    coverImg: 'bizzarap.jpeg',
    category: 'Rap Latino',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genius.com/albums/Bizarrap/Bzrp-music-sessions',
    youtube: 'https://www.youtube.com/channel/UCkD89TEFWsTZluY6tk84yqg',
    facebook: 'https://www.facebook.com/profile.php?id=100068674086489',
    instagram: 'https://www.instagram.com/bizarrap/',
    twitter: 'https://twitter.com/bizarrap',
    spotify: 'https://open.spotify.com/artist/716NhGYqD1jl2wI1Qkgq36',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/bizarrap/1239658213',
    soundcloud: 'https://soundcloud.com/bizarrap',
    tidal: 'https://listen.tidal.com/artist/8787172',
    wiki: 'https://en.wikipedia.org/wiki/Bizarrap',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B071VZ53VB/bizarrap',
  },
  {
    title: 'Quevedo',
    description:
      'Pedro Luis Domínguez Quevedo nació en Madrid .A la edad de un año, se trasladó a Brasil y regresó a España a la edad de cinco, instalándose en Las Palmas , Islas Canarias.Después de incursionar en el rap de estilo libre , Quevedo lanzó su carrera musical en 2020 junto al productor musical Linton. Su sencillo Ahora y Siempre (2021) alcanzó el decimonoveno y segundo puesto en las listas Global Viral 50 de Spotify y España Viral 50, respectivamente.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUd88g-4QLth1IBBRt38xvX',
    price: 50,
    coverImg: 'quevedo.jpg',
    category: 'Rap Latino',
    location: 'España',
    locationImg: 'spain.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://genius.com/Bizarrap-and-quevedo-quevedo-bzrp-music-sessions-vol-52-lyrics',
    youtube: 'https://www.youtube.com/channel/UCYEmGiiM21tGuCEtDJTfP1w',
    facebook: 'https://www.facebook.com/bizarrap',
    instagram: 'https://www.instagram.com/quevedo.pd/',
    twitter: 'https://twitter.com/pedrodquevedo',
    spotify:
      'https://open.spotify.com/artist/52iwsT98xCoGgiGntTiR7K?si=woV5b46PT7aKn3z1nmc7fg&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/12487862',
    apple: 'https://music.apple.com/mx/artist/quevedo/377761784',
    soundcloud: 'https://soundcloud.com/quevedo-music',
    tidal: 'https://listen.tidal.com/artist/8997487',
    wiki: 'https://en.wikipedia.org/wiki/Quevedo_(rapper)',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@bizarrap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0029L8HTY/quevedo',
  },
  {
    title: 'Villano Antillano',
    description:
      'Villana Santiago Pacheco (Bayamón, 27 de marzo de 1995),1​ mejor conocida como Villano Antillano, es una rapera, cantante y compositora puertorriqueña.En 2022 salta a la fama mundial tras lanzar su colaboración «Villano Antillano: Bzrp Music Sessions, Vol. 51» junto a Bizarrap,6​7​8​9​10​ la cual logró posicionarse como undécima en el Argentina Hot 100 y como duodécima en el Spain Songs de Billboard.11​12​ Fue la primera artista transgénero y no binaria en entrar en la Top 50: Global de Spotify.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mdjo7oY9b9QGkV-0hmbMjRCHa60yTzK4I',
    price: 50,
    coverImg: 'villanoantillano.jpg',
    category: 'Rap Latino',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://genius.com/Bizarrap-and-villano-antillano-villano-antillano-bzrp-music-sessions-vol-51-lyrics',
    youtube: 'https://www.youtube.com/channel/UCbOA3xYH--7nP0gQ2rgKUxg',
    facebook: 'https://www.facebook.com/villanoantillano',
    instagram: 'https://www.instagram.com/villanoantillano/',
    twitter: 'https://twitter.com/villanomalandro',
    spotify: 'https://open.spotify.com/artist/1pi7nGhOM7PTHR5YEgXVGq',
    deezer: 'https://www.deezer.com/mx/artist/53825042',
    apple: 'https://music.apple.com/mx/artist/villano-antillano/1441570441',
    soundcloud: 'https://soundcloud.com/user-104638701',
    tidal: 'https://listen.tidal.com/artist/10581014',
    wiki: 'https://es.wikipedia.org/wiki/Villano_Antillano',
    ticketmaster:
      'https://www.viagogo.com.mx/Boletos-Conciertos/Musica-Electronica/Bizarrap-Boletos',
    tiktok: 'https://www.tiktok.com/@villanoantillano?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B07K8WYMP9/villano-antillano',
  },
  {
    title: 'Cypress Hill',
    description:
      'Cypress Hill es un grupo de hip hop proveniente de South Gate. Fue formado en 1986 y a lo largo de su amplia y exitosa carrera han vendido más de 46 millones de álbumes en todo el mundo.',
    videourl:
      'http://youtube.com/playlist?list=PLciLqyN9QXEvoMDPgJMA_TUsQ_bGgzNCr',
    price: '$50',
    coverImg: 'cypressHill.jpg',
    category: 'Rap Latino',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://cypresshill.com/home/',
    youtube: 'https://www.youtube.com/channel/UCoO96UN3gv5WohJA8QuMgXQ',
    facebook: 'https://www.facebook.com/cypresshill',
    instagram: 'https://www.instagram.com/cypresshill/',
    twitter: 'https://twitter.com/cypresshill',
    spotify: 'https://open.spotify.com/artist/4P0dddbxPil35MNN9G2MEX',
    deezer: 'https://www.deezer.com/mx/artist/1222',
    apple: 'https://music.apple.com/mx/artist/cypress-hill/465802',
    soundcloud: 'https://soundcloud.com/cypress-hill',
    tidal: 'https://listen.tidal.com/artist/736',
    wiki: 'https://es.wikipedia.org/wiki/Cypress_Hill',
    ticketmaster:
      'https://www.ticketmaster.com./cypress-hill-tickets/artist/702466',
    tiktok: 'https://www.tiktok.com/@cypresshill?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000TD26YQ/cypress-hill',
  },

  {
    title: 'Canserbero',
    description:
      'Tirone José González Orama, más conocido como Canserbero, fue un rapero y compositor venezolano. Fue uno de los intérpretes más significativos del rap independiente en su país de origen y en América Latina.',

    videourl:
      'http://youtube.com/playlist?list=PL-m_fPZNskx4XGpqIjyabYAWI7SAwiisy',
    price: '$50',
    coverImg: 'Canserbero.jpg',
    category: 'Rap Latino',
    location: 'Venezuela',
    locationImg: 'venezuelaflag.jpg',
    openSpots: null,
    websiteurl: 'https://es.wikipedia.org/wiki/Canserbero',
    youtube: 'https://www.youtube.com/channel/UC-Gh7ndBnNZvGRjCzlt2Ecw',
    facebook: 'https://www.facebook.com/RapLatino01',
    instagram: 'https://www.instagram.com/fundacionelcanserbero/',
    twitter: 'https://twitter.com/canserbero_',
    spotify:
      'https://open.spotify.com/playlist/2dli6Ryq81JzFpthcCKT2o?si=5vqkdMiVS4Sspjt5WIGwug&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/4813087',
    apple: 'https://music.apple.com/mx/artist/canserbero/579925647',
    soundcloud: 'https://soundcloud.com/canserberoofficial',
    tidal: 'https://listen.tidal.com/artist/5189643',
    wiki: 'https://es.wikipedia.org/wiki/Canserbero',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@el_canserbero_oficial_?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00D2M05ZS/canserbero',
  },

  {
    title: 'Bascur',
    description:
      'La gran masacre es la tercera placa del rapero Chileno Bascur que consta de 20 canciones y las colaboraciones de Septimo Fragmento, Byrone, CHR, Massibo, Rayking, Margihuanero, A.R.B., B-zantino, Hechyzero, K320, Wensho, Masue, Grafy, Omega, Lord Sucio, Sejo, Liricistas, Jack Dee, De Killtros, Dj Kosmos, Rude, Mister pee, Brizy, Ley 20 mil, Aden y Adickta Sinfonia',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_lauJQJvd_QsAgd7tEzqlFoBp0kxhtWeGM',
    price: '$50',
    coverImg: 'Bascur.jpg',
    category: 'Rap Latino',
    location: 'Chile',
    locationImg: 'chileflag.jpg',
    openSpots: null,
    websiteurl: 'https://www.last.fm/es/music/BASCUR/+wiki',
    youtube: 'https://www.youtube.com/channel/UC4IddGaAAIhJegVKJXuMUKg',
    facebook: 'https://www.facebook.com/BASCUR.RAP',
    instagram: 'https://www.instagram.com/bascur_beaese/',
    twitter: 'https://twitter.com/RapBascur',
    spotify: 'https://open.spotify.com/artist/1gr15bpTzqHwHxj9PVZf4N',
    deezer: 'https://www.deezer.com/mx/artist/7894544',
    apple: 'https://music.apple.com/mx/artist/bascur/867826388',
    soundcloud: 'https://soundcloud.com/bascur',
    tidal: 'https://listen.tidal.com/artist/6332755',
    wiki: 'https://www.last.fm/es/music/BASCUR/+wiki',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@bascur_rap?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00X4MSFB2/bascur',
  },
  {
    title: 'Neto Peña',
    description:
      'Alan Rodrigo Ledesma, mejor conocido en el medio artístico como Neto Peña, es un cantante de mexicano de rap y hip-hop. Nació en Guadalajara, Jalisco, México, el 8 de febrero de 1987. Neto Peña se ha convertido en unos de los artistas más influyentes de la nueva escena del rap en México, logrando colaborar con diferentes artistas de su país natal,',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_m1KAxcg-1WhRxMka9GtYfJoYLRehbwiZU',
    price: '$50',
    coverImg: 'netopeña.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCrq2ArYU6XzMg_uAVPHCE3Q',
    facebook: 'https://www.facebook.com/elnetoalzada',
    instagram: 'https://www.instagram.com/elneto_oficial/',
    twitter: 'https://twitter.com/netomaisterra',
    spotify: 'https://open.spotify.com/artist/0U5RYP2HMdGv2GhicLhkOI',
    deezer: 'https://www.deezer.com/mx/artist/10632397',
    apple: 'https://music.apple.com/mx/artist/neto-pe%C3%B1a/1129504547',
    soundcloud: 'https://soundcloud.com/netopena',
    tidal: 'https://listen.tidal.com/artist/7957765',
    wiki: 'https://negropasion.com/collections/neto-pena',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@elnetiktok?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B01HRAFT06/neto-pe%C3%B1a',
  },
  {
    title: 'Robot95',
    description:
      'Kevin Ramón Gutiérrez, –más conocido como Robot95– bajara el ritmo a su acelerada vida. Esta situación le dio el tiempo que necesitaba para pulir su gran trabajo concebido en 2019, el resultado es el álbum Siempre arriba, donde Gutiérrez, de la mano de su productor Beat Boy, muestra a su público su faceta más innovadora, pero sin perder su esencia. Se aleja del rap más puro que le lanzó a la fama para adentrarse en los ritmos del trap, la música electrónica y el reggaetón. El resumen de este álbum es pura buena onda, probablemente muy necesaria en esta vuelta a la normalidad que tanto se está haciendo de rogar. Robot nació en Mexicali, Baja California.',

    videourl:
      'http://youtube.com/playlist?list=PLkgg6K9XZ_GeXh53tu5mHej5gLkf4uaZ5',
    price: '$50',
    coverImg: 'Robot95.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    websiteurl:
      'https://connect.gigwell.com/roster/roster.html?agency=tsm-eco&origin=book&profile=www.morejuice.mx',
    youtube: 'https://www.youtube.com/channel/UCliRD7DSeqcuzudajJdWaLg',
    facebook: 'https://www.facebook.com/Robot4v/',
    instagram: 'https://www.instagram.com/ro_bot95/',
    twitter: 'https://twitter.com/ro_bot95',
    spotify: 'https://open.spotify.com/artist/30CTTIqrcr82nS6B40j975',
    deezer: 'https://www.deezer.com/search/Robot95',
    apple: 'https://music.apple.com/mx/artist/robot95/1522968183',
    soundcloud: 'https://soundcloud.com/robot-scmusic',
    tidal: 'https://listen.tidal.com/artist/20194305',
    wiki: 'https://metalmagazine.eu/es/post/interview/robot95',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@robot95oficial?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B08CM3B7MH/robot95',
  },
  {
    title: 'Control Machete',
    description:
      'Control Machete fue un grupo de hip hop mexicano originario de la ciudad de Monterrey, Nuevo León, México. Era integrado por el scratcher y tecladista Antonio "DJ Toy Selectah" Hernández, el vocalista Patricio Chapa Elizalde (también conocido como "Pato Machete") y el vocalista "Fermín IV" (Fermín Caballero).Control Machete combinaba cultura y música del norte de México con el género de hip hop, y formaron parte del movimiento musical denominado Avanzada Regia.',

    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_l2A8mVUYuqKro42_wwcAKuTofdD-ZuPvM',
    price: '$50',
    coverImg: 'ControlMachete.jpg',
    category: 'Rap Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    websiteurl: 'https://myspace.com/controlmachete',
    youtube: 'https://www.youtube.com/channel/UCC6DLIBOTh0yoXYOmHR2fLw',
    facebook: 'https://www.facebook.com/groups/15988717078',
    instagram: 'https://www.instagram.com/controlmacheteoficial/',
    twitter: 'https://twitter.com/CMacheteOficial',
    spotify: 'https://open.spotify.com/artist/628gUkswCfCS1hIOOHmIpK',
    deezer: 'https://www.deezer.com/mx/artist/1411',
    apple: 'https://music.apple.com/mx/artist/control-machete/90394',
    soundcloud: '',
    tidal: 'https://listen.tidal.com/artist/3640924',
    wiki: 'https://es.wikipedia.org/wiki/Control_Machete',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=control%20machete&t=1683425925567',
    napster: 'https://music.amazon.com.mx/artists/B00130IIZ0/control-machete',
  },
]

export default CardData3
