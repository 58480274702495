const CardData11 = [
  {
    title: 'Danna Paola',
    description:
      'Danna Paola Rivera Munguía (Ciudad de México, 23 de junio de 1995) es una cantante, actriz, modelo y compositora mexicana. Ha sido ganadora de diferentes premios, entre los que se encuentran 9 Kids Choice Awards, 4 MTV Millennial Awards, 4 MTV Italian Music Awards, 1 MTV Europe Music Awards y 2 premios de la Sociedad de Autores y Compositores de México por «Oye Pablo» y «Sodio». Además fue nominada al Grammy Latino por su sexto álbum de estudio K.O. en 2021..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mGnO3i2McuE6K6O4qSKx4F9shuqnG0bKg',
    price: '$50',
    coverImg: 'dannapaola.png',
    category: 'Bachatta',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://universalmusicmexico.lnk.to/TourXT4S1S',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/DannaPaola',
    instagram: 'https://www.instagram.com/dannapaola/',
    twitter: 'https://twitter.com/dannapaola',
    spotify: 'https://open.spotify.com/artist/5xSx2FM8mQnrfgM1QsHniB',
    deezer: 'https://www.deezer.com/mx/artist/318108',
    apple: 'https://music.apple.com/mx/artist/danna-paola/23148284',
    soundcloud: 'https://soundcloud.com/danna-paola-official',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://en.wikipedia.org/wiki/Danna_Paola',
    ticketmaster:
      'https://www.ticketmaster.com.mx/danna-paola-boletos/artist/2581279',
    tiktok: 'https://www.tiktok.com/@dannapaola?lang=es',
    napster: 'https://us.napster.com/artist/danna-paola',
  },
  {
    title: 'Rauw Alejandro',
    description:
      'Raúl Alejandro Ocasio Ruiz (born January 10, 1993), known professionally as Rauw Alejandro, is a Puerto Rican singer and songwriter. Referred to as the King of Modern Reggaeton, he belongs to the new generation of Puerto Rican urban musicians. His debut studio album, Afrodisíaco, was released in November 2020. His second studio album, Vice Versa, released in June 2021, featured the lead single Todo de Ti. He has won one Latin Grammy Award from four nominations..',
    videourl:
      'https://youtube.com/playlist?list=PLhK8A9K_cnZS4LXKUgFVIU8ogoASU3FKO',
    price: 80,
    coverImg: 'Rauw.png',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rauwalejandro.com/en/',
    youtube: 'https://www.youtube.com/channel/UC_Av98lDjf5KvFib5elhpYg',
    facebook: 'https://www.facebook.com/rauwalejandro',
    instagram: 'https://www.instagram.com/rauwalejandro/',
    twitter: 'https://twitter.com/rauwalejandro',
    spotify: 'https://open.spotify.com/artist/1mcTU81TzQhprhouKaTkpq',
    deezer: 'https://www.deezer.com/en/artist/11289472',
    apple: 'https://music.apple.com/mx/artist/rauw-alejandro/1107186883',
    soundcloud: 'https://soundcloud.com/rauwalejandro',
    tidal: 'https://listen.tidal.com/artist/8239477',
    wiki: 'https://en.wikipedia.org/wiki/Rauw_Alejandro',
    ticketmaster:
      'https://www.ticketmaster.com/rauw-alejandro-tickets/artist/2628019',
    tiktok: 'https://www.tiktok.com/@rauwalejandro?lang=es',
    napster: 'https://us.napster.com/artist/rauw-alejandro',
  },
  {
    title: 'Nikki Nicole',
    description:
      'Nicole Denise Cucco (Rosario, Santa Fe; 25 de agosto de 2000), conocida artísticamente como Nicki Nicole, es una cantante, rapera y compositora argentina.En 2019, saltó a la fama con el sencillo «Wapo Traketero», logrando obtener millones de reproducciones en YouTube en un corto período de tiempo después de ser publicado. La canción se convirtió en un fenómeno viral, logrando ingresar al listado Argentina Hot 100 de Billboard.1​2​3​En agosto de ese mismo año terminó ganando más reconocimiento a nivel nacional e internacional con la publicación de su Music Session junto al productor Bizarrap, la cual actualmente cuenta con más de 168 millones de reproducciones. En noviembre de ese mismo año lanzó su primer álbum de estudio de nombre Recuerdos, en el cual se encontraron temas como «Años luz», «Shorty», «Plegarias», entre otros. El álbum recibió nominaciones a distintos premios...',
    videourl:
      'https://youtube.com/playlist?list=PLx9s57CIB6KpDBix2RWKfFLnjT2uh9lk5',
    price: '$50',
    coverImg: 'nikkinicole.jpg',
    category: 'Bachatta',
    location: 'Argentina',
    locationImg: 'argentinaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.nickinicole.com/',
    youtube: 'https://www.youtube.com/channel/UCDJT6nxGrFEU3vut0nfgVWQ',
    facebook: 'https://www.facebook.com/naikinai19',
    instagram: 'https://www.instagram.com/nicki.nicole/',
    twitter: 'https://twitter.com/naikinai19',
    spotify: 'https://open.spotify.com/artist/2UZIAOlrnyZmyzt1nuXr9y',
    deezer: 'https://www.deezer.com/mx/artist/13299379',
    apple: 'https://music.apple.com/us/artist/nicki-nicole/1292945516',
    soundcloud: 'https://soundcloud.com/musica-urbana-rp/nicki-nicole-frio',
    tidal: 'https://listen.tidal.com/artist/13775348',
    wiki: 'https://es.wikipedia.org/wiki/Nicki_Nicole',
    ticketmaster:
      'https://www.ticketmaster.com/nicki-nicole-tickets/artist/Z7r9jZa3aR',
    tiktok: 'https://www.tiktok.com/@nicki.nicole',
    napster: 'https://us.napster.com/artist/nicki-nicole',
  },
  {
    title: 'Farruko',
    description:
      'Debutó en 2007 con «Bla, bla, bla», la cual tuvo la producción de Phantom y Villa y con el cual logró posicionarse dentro del ámbito musical, por lo que gracias a esto, lanzó canciones como «El Penthouse», «El paseo por el bloque» con Ñengo Flow y «No me atrevo» en 2007, «Escala a mi cama» con Galante & Killatonez y Ñengo Flow, «Sata es» con Galante & Killatonez y «Sexo fuera del planeta» con Fifer en 2008, con las cuales logró consolidarse como unos los talentos nuevos del reguetón.',
    videourl: 'http://youtube.com/playlist?list=PL98809762BD444BAB',
    price: 80,
    coverImg: 'Farruko.jpg',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://farruko.com/',
    youtube: 'https://www.youtube.com/c/farruko',
    facebook: 'https://www.facebook.com/farrukoofficial/',
    instagram: 'https://www.instagram.com/farruko/',
    twitter: 'https://twitter.com/FarrukoOfficial',
    spotify: 'https://open.spotify.com/artist/329e4yvIujISKGKz1BZZbO',
    deezer: 'https://www.deezer.com/mx/artist/614223',
    apple: 'https://music.apple.com/mx/artist/farruko/364377482',
    soundcloud: 'https://soundcloud.com/farrukooficial',
    tidal: 'https://listen.tidal.com/artist/4016402',
    wiki: 'https://es.wikipedia.org/wiki/Farruko',
    ticketmaster:
      'https://www.ticketmaster.com./farruko-tickets/artist/1936418',
    tiktok: 'https://www.tiktok.com/@farruko.fans?lang=es',
    napster: 'https://app.napster.com/artist/farruko',
  },
  {
    title: 'Karol G',
    description:
      'Carolina Giraldo Navarro Medellín, Colombia; 14 de febrero de 1991, conocida artísticamente como Karol G, es una cantante y compositora colombiana de reguetón, pop y trap latino.Se lanzó internacionalmente con la canción «301» junto con el cantante Reykon el líder en 2012, posicionado en el top de varias estaciones de radios y canales de televisión,3​ y le permitió realizar conciertos en países como Aruba, Colombia, Ecuador y Estados Unidos.4​ Obtuvo mayor reconocimiento en 2013, cuando lanzó la canción «Amor de dos» junto con Nicky Jam.5​ En 2016, luego de que lanzó varios sencillos, firmó un contrato con la discográfica Universal Music Latin Entertainment, y hubo un ascenso en su carrera..',
    videourl:
      'https://youtube.com/playlist?list=PLpTxN0bsTsj5GSZ4xME8tbhxez793WTob',
    price: '$50',
    coverImg: 'karolG.jpeg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.karolgmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCZuPJZ2kGFdlbQu1qotZaHw',
    facebook: 'https://www.facebook.com/KarolGOficial',
    instagram: 'https://www.instagram.com/karolg/',
    twitter: 'https://twitter.com/KarolGOnStats',
    spotify: 'https://open.spotify.com/artist/790FomKkXshlbRYZFtlgla',
    deezer: 'https://www.deezer.com/mx/artist/5297021',
    apple: 'https://music.apple.com/us/artist/karol-g/290814601',
    soundcloud: 'https://soundcloud.com/karolgofficial',
    tidal: 'https://listen.tidal.com/artist/5237820',
    wiki: 'https://es.wikipedia.org/wiki/Karol_G',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=Karol+G',
    tiktok: 'https://www.tiktok.com/@karolg',
    napster: 'https://us.napster.com/artist/karol-g',
  },
  {
    title: 'Maluma',
    description:
      'Maluma has a number of singles that have charted within the top ten on Billboard Hot Latin Songs, including Felices los 4, Borró Cassette, and Corazón. His collaborative efforts Chantaje with Shakira and Medellín with Madonna have reached the top of the Hot Latin Songs and the Dance Club Songs chart, respectively. He has worked with other international artists, such as Ricky Martin, J Balvin, and The Weeknd. Maluma has won a Latin Grammy Award, an MTV Video Music Award, two Latin American Music Awards, and been nominated for a Grammy Award for Best Latin Pop Album.',
    videourl:
      'http://youtube.com/playlist?list=PLI2uE28GO_poIPrHuSGt2Ru6Er5AzCyk5',
    price: 36,
    coverImg: 'maluma.jpg',
    category: 'Bachatta',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://maluma.online/?fbclid=IwAR2kZKmUfqJ3sRMh-jl3eHSM0apR2-lEeKxsGC9d_VT1XsfmqCbVgyzZ5eY',
    youtube: 'https://www.youtube.com/c/MalumaOfficialChannel',
    facebook: 'https://www.facebook.com/MALUMAMUSIK',
    instagram: 'https://www.instagram.com/maluma/?hl=en',
    twitter:
      'https://twitter.com/maluma?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
    spotify: 'https://open.spotify.com/artist/1r4hJ1h58CWwUQe3MxPuau',
    deezer: 'https://www.deezer.com/mx/artist/1424602',
    apple: 'https://music.apple.com/mx/artist/maluma/448916501',
    soundcloud: 'https://soundcloud.com/malumaofficial',
    tidal: 'https://listen.tidal.com/artist/4098182',
    wiki: 'https://es.wikipedia.org/wiki/Maluma',
    ticketmaster: 'https://www.ticketmaster.com./maluma-tickets/artist/2095829',
    tiktok: 'https://www.tiktok.com/@maluma1472?lang=es',
    napster: 'https://app.napster.com/artist/maluma',
  },
  {
    title: 'JBalvin',
    description:
      'José Álvaro Osorio Balvín, conocido artísticamente como J Balvin, es un cantante, compositor y productor discográfico colombiano. Ha logrado posicionarse tanto en el mercado musical hispano como en el mercado musical de habla inglesa, llegando a colocar sus temas en número uno en varias listas musicales, entre ellas Billboard. También es conocido como el Príncipe del Reguetón.',
    videourl:
      'http://youtube.com/playlist?list=PLWgVwtnp9KujXASQJ5gPUqHlEeLx-A4IE',
    price: '$50',
    coverImg: 'jBalvin.jpg',
    category: 'Reggaeton',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://jbalvin.com/',
    youtube: 'https://www.youtube.com/channel/UCt-k6JwNWHMXDBGm9IYHdsg',
    facebook: 'https://www.facebook.com/JBalvinReal',
    instagram: 'https://www.instagram.com/jbalvin.man.0/',
    twitter: 'https://twitter.com/jbalvin',
    spotify: 'https://open.spotify.com/artist/1vyhD5VmyZ7KMfW5gqLgo5',
    deezer: 'https://www.deezer.com/mx/artist/4860761',
    apple: 'https://music.apple.com/mx/artist/j-balvin/444520760',
    soundcloud: 'https://soundcloud.com/j-balvin-official',
    tidal: 'https://listen.tidal.com/artist/5054713',
    wiki: 'https://es.wikipedia.org/wiki/J_Balvin',
    ticketmaster:
      'https://www.ticketmaster.com./j-balvin-tickets/artist/1907948',
    tiktok: 'https://www.tiktok.com/@jbalvin2794?lang=es',
    napster: 'https://app.napster.com/artist/j-balvin',
  },
  {
    title: 'Pitbull',
    description:
      'Armando Christian Pérez (nacido el 15 de enero de 1981), conocido profesionalmente como Pitbull, es un rapero estadounidense. Comenzó su carrera a principios de la década de 2000 y grabó reguetón, hip hop latino y música crunk bajo una variedad de sellos discográficos. Firmó con TVT Records para lanzar su álbum de estudio debut, M.I.A.M.I. (2004), que fue producido ejecutivamente por Lil Jon. Entró moderadamente en el Billboard 200, al igual que sus segundos y terceros álbumes, El Mariel (2006) y The Boatlift (2007). Su cuarto álbum, Pitbull Starring in Rebelution (2009), fue apoyado por los sencillos "I Know You Want Me (Calle Ocho)" y "Hotel Room Service", ambos los cuales le dieron su gran avance comercial y alcanzaron el número dos y el ocho en el Billboard Hot 100 de EE.UU., respectivamente..',
    videourl:
      'https://youtube.com/playlist?list=PLuNzHiV4iahko2NJAQaKxJdADQB7LHFLi&si=LggpengpTd4yL9ZZ',
    price: '$50',
    coverImg: 'pitbull.jpg',
    category: 'Reggaeton',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://pitbullmusic.com/',
    youtube: 'https://www.youtube.com/channel/UCv8nzwVPQDRjkPCkEsOdEwA',
    facebook: 'https://www.facebook.com/pitbull',
    instagram: 'https://www.instagram.com/pitbull/',
    twitter: 'https://x.com/pitbull',
    spotify: 'https://open.spotify.com/artist/0TnOYISbd1XYRBk9myaseg',
    deezer: 'https://www.deezer.com/mx/artist/776',
    apple: 'https://music.apple.com/mx/artist/pitbull/27044968',
    soundcloud: 'https://soundcloud.com/pitbull',
    tidal: 'https://listen.tidal.com/artist/3518085',
    wiki: 'https://es.wikipedia.org/wiki/Pitbull_(cantante)',
    ticketmaster:
      'https://www.ticketmaster.com./pitbull-tickets/artist/985347',
    tiktok: 'https://www.tiktok.com/@pitbull?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B08PP63TH5/pitbull',
  },
  {
    title: 'Ozuna',
    description:
      'Juan Carlos Ozuna Rosado (pronunciación en español: [xwaŋ ˈkaɾlos oˈsuna roˈsaðo]; nacido el 13 de marzo de 1992), conocido simplemente por su apellido Ozuna, es un cantante puertorriqueño, nacido y criado en San Juan, Puerto Rico, hijo de un padre dominicano y una madre puertorriqueña. Cinco de sus álbumes de estudio han encabezado la lista de Billboard Top Latin Albums, siendo Aura (2018) el que alcanzó el número siete en el Billboard 200. Su estilo musical se define principalmente como reguetón y trap latino, aunque ha colaborado con varios artistas de diferentes géneros y su música toma influencias de una amplia variedad de géneros, incluyendo pop, rock, hip hop, R&B, reggae, bachata, dembow y electrónica, entre otros.',
    videourl:
      'https://youtube.com/playlist?list=PLzAzZbNGlKlruXW-KxEl0iqMoXHQslMK5&si=-3BJnP-kChOo-e3h',
    price: '$50',
    coverImg: 'ozuna.gif',
    category: 'Reggaeton',
    location: 'Puerto Rico',
    locationImg: 'puertorico.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://ozuna.rosecityworks.com/',
    youtube: 'https://www.youtube.com/channel/UCjIA3wwhi0QjSOXAZwOXbPA',
    facebook: 'https://www.facebook.com/ozunapr/',
    instagram: 'https://www.instagram.com/ozuna/',
    twitter: 'https://x.com/ozuna',
    spotify: 'https://open.spotify.com/artist/1i8SpTcr7yvPOmcqrbnVXY',
    deezer: 'https://www.deezer.com/mx/artist/4937383',
    apple: 'https://music.apple.com/mx/artist/ozuna/283578837',
    soundcloud: 'https://soundcloud.com/pitbull',
    tidal: 'https://listen.tidal.com/artist/5371163',
    wiki: 'https://en.wikipedia.org/wiki/Ozuna',
    ticketmaster:
      'https://www.ticketmaster.com/ozuna-tickets/artist/2298720',
    tiktok: 'https://www.tiktok.com/@ozuna?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B00123JB04/ozuna',
  },
]

export default CardData11
