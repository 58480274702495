const CardData23 = [
  {
    title: 'Musica-Regional-Mexicana-2024',
    description:
      'La Música Regional Mexicana sigue consolidándose como un pilar fundamental en la escena musical, y en 2024, su popularidad no muestra signos de desaceleración. Esta lista de Top 100 Regional Mexicano 2024 presenta una cuidada selección de las canciones más escuchadas y queridas por el público, abarcando géneros como el norteño, corridos, mariachi, y banda.Desde los ritmos alegres que animan las fiestas hasta las letras profundas que cuentan historias de amor y desamor, cada canción en esta lista refleja la rica cultura y tradiciones de México. Artistas tanto establecidos como nuevos talentos brillan en esta selección, destacando la versatilidad y creatividad que caracteriza a la música regional.No te pierdas la oportunidad de disfrutar de los éxitos que están dominando las plataformas de streaming y las estaciones de radio en este 2024. ¡Sumérgete en el ritmo y la pasión de la música regional mexicana y descubre qué temas están marcando tendencia este año!',

    videourl: 'https://youtube.com/playlist?list=PLx-DiefbXCRLgPLqRUHqhyvfGU88ozFDI&si=MAA9JXi_AAOncg6f',
    price: '$50',
    coverImg: 'sombrero.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'La Lista Pop  2024-Top Hits',
    description:
      'La lista de Billboard Latino Hot 100 de 2024 destaca las canciones más populares del año, reuniendo lo mejor de diversos géneros musicales, como el pop, reggaetón, hip-hop, y música electrónica. Este ranking refleja las canciones más reproducidas en plataformas de streaming, ventas digitales, y radios a nivel mundial.En este listado, los éxitos de artistas consolidados y emergentes se entrelazan, mostrando la diversidad y evolución de la música en el 2024. Desde los himnos veraniegos hasta las baladas más emocionantes, estas 100 canciones representan lo más escuchado, bailado y coreado por millones de personas alrededor del mundo.Una guía imprescindible para quienes quieren estar al día con los sonidos y ritmos que definieron el año.',

    videourl: 'https://youtube.com/playlist?list=PLXl9q53Jut6n6Xz8w0b6K2QibEa5RqnWW&si=7xlrWbwQDT6IPCkE',
    price: '$50',
    coverImg: 'flyingsungod.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Las Mejores Canciones de Norteñas de Todos Los Tiempos',
    description:
      'La lista de Billboard Latino Hot 100 de 2024 destaca las canciones más populares del año, reuniendo lo mejor de diversos géneros musicales, como el pop, reggaetón, hip-hop, y música electrónica. Este ranking refleja las canciones más reproducidas en plataformas de streaming, ventas digitales, y radios a nivel mundial.En este listado, los éxitos de artistas consolidados y emergentes se entrelazan, mostrando la diversidad y evolución de la música en el 2024. Desde los himnos veraniegos hasta las baladas más emocionantes, estas 100 canciones representan lo más escuchado, bailado y coreado por millones de personas alrededor del mundo.Una guía imprescindible para quienes quieren estar al día con los sonidos y ritmos que definieron el año.',

    videourl: 'https://youtube.com/playlist?list=PL-PXKb5jSjwa0wzEh_vzH_bGXL4XBhU-O&si=LKvrWjxFGWTHCclO',
    price: '$50',
    coverImg: 'ranch.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Las Mejores Canciones de Banda Mexicana',
    description:
      'En esta lista de Las Mejores Canciones de Banda Mexicana, encontrarás los éxitos más icónicos y recientes que definen este estilo musical tan característico de México.Desde temas llenos de pasión y desamor hasta canciones alegres que no pueden faltar en cualquier fiesta, esta selección reúne lo mejor de las bandas más grandes del género, como Banda MS, El Recodo, La Arrolladora, entre muchas otras. Ya sea para celebrar, recordar o simplemente disfrutar del sonido de la Banda Mexicana, esta colección es un homenaje a las canciones que han dejado huella en la música regional.',

    videourl: 'https://www.youtube.com/playlist?list=PLZ9vJ4WHO8bXtCQeW0qZOyTJMs4RkIo4S',
    price: '$50',
    coverImg: 'bandatuba.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Las Mejores Canciones Latinas de 2024',
    description:
      'La lista de Billboard Latino Hot 100 de 2024 destaca las canciones más populares del año, reuniendo lo mejor de diversos géneros musicales, como el pop, reggaetón, hip-hop, y música electrónica. Este ranking refleja las canciones más reproducidas en plataformas de streaming, ventas digitales, y radios a nivel mundial.En este listado, los éxitos de artistas consolidados y emergentes se entrelazan, mostrando la diversidad y evolución de la música en el 2024. Desde los himnos veraniegos hasta las baladas más emocionantes, estas 100 canciones representan lo más escuchado, bailado y coreado por millones de personas alrededor del mundo.Una guía imprescindible para quienes quieren estar al día con los sonidos y ritmos que definieron el año.',

    videourl: 'https://youtube.com/playlist?list=PLE3_jXvy9MZ4esOSoO__cOeWuAfDpGLKc&si=htAbJOsIq-V1hy_s',
    price: '$50',
    coverImg: 'icon.png',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Rap Mexicano 2024 Éxitos 🇲🇽 Hip Hop',
    description:
      'El Rap Mexicano sigue creciendo y evolucionando en 2024, con artistas que llevan el Hip Hop en español a nuevas alturas. Esta lista de éxitos reúne lo mejor del talento mexicano, destacando rimas poderosas, beats innovadores y líricas que tocan temas sociales, culturales y personales, reflejando la realidad y el espíritu de la juventud mexicana.En los Éxitos de Rap en Español 2024, encontrarás una mezcla de veteranos del rap y artistas emergentes que están definiendo el sonido del Hip Hop Mexicano. Desde el underground hasta las grandes plataformas, el rap en México se ha convertido en una voz influyente en la música urbana a nivel mundial. ¡Sumérgete en los ritmos y las rimas más pegajosas de 2024 y descubre los artistas que están marcando el futuro del rap en español!',

    videourl: 'https://www.youtube.com/playlist?list=PLKuKwq1ghrvU_RcTHIYenyB__DyjiK46E',
    price: '$50',
    coverImg: 'homies.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'MIX Lo Más Nuevo de Cumbias Sonideras',
    description:
      'La Cumbia Sonidera sigue moviendo masas en 2024 con ritmos contagiosos y sonidos electrónicos que le dan un giro moderno al clásico género de cumbia. Este MIX de Lo Más Nuevo de Cumbias Sonideras recopila los éxitos más frescos y vibrantes que están haciendo bailar a la gente en las calles, fiestas y eventos sonideros de México y más allá. Con mezclas llenas de energía, bajos profundos y esos inolvidables efectos sonideros, este mix es el soundtrack perfecto para los amantes de la cumbia. Aquí encontrarás tanto temas de DJs y sonideros emergentes como hits de los grandes del género, combinando lo tradicional con lo moderno. ¡Prepárate para mover los pies al ritmo de las cumbias sonideras más nuevas que están rompiendo',

    videourl: 'https://youtube.com/playlist?list=PLsi73zTznribx9Xorydusl2Hg4klb9Iah&si=NVEjdLzyaZRMeHOs',
    price: '$50',
    coverImg: 'sonidero.png',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'TOP 100 Canciones de 2024 ',
    description:
      'La lista de Billboard Hot 100 de 2024 destaca las canciones más populares del año, reuniendo lo mejor de diversos géneros musicales, como el pop, reggaetón, hip-hop, y música electrónica. Este ranking refleja las canciones más reproducidas en plataformas de streaming, ventas digitales, y radios a nivel mundial.En este listado, los éxitos de artistas consolidados y emergentes se entrelazan, mostrando la diversidad y evolución de la música en el 2024. Desde los himnos veraniegos hasta las baladas más emocionantes, estas 100 canciones representan lo más escuchado, bailado y coreado por millones de personas alrededor del mundo.Una guía imprescindible para quienes quieren estar al día con los sonidos y ritmos que definieron el año.',

    videourl: 'https://youtube.com/playlist?list=PLDIoUOhQQPlXr63I_vwF9GD8sAKh77dWU&si=iXGhihOuPRKyIdUi',
    price: '$50',
    coverImg: 'blender.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: '100 Éxitos Calientes 2024 ',
    description:
      'La lista de Billboard Hot 100 de 2024 destaca las canciones más populares del año, reuniendo lo mejor de diversos géneros musicales, como el pop, reggaetón, hip-hop, y música electrónica. Este ranking refleja las canciones más reproducidas en plataformas de streaming, ventas digitales, y radios a nivel mundial.En este listado, los éxitos de artistas consolidados y emergentes se entrelazan, mostrando la diversidad y evolución de la música en el 2024. Desde los himnos veraniegos hasta las baladas más emocionantes, estas 100 canciones representan lo más escuchado, bailado y coreado por millones de personas alrededor del mundo.Una guía imprescindible para quienes quieren estar al día con los sonidos y ritmos que definieron el año.',

    videourl: 'https://youtube.com/playlist?list=PLBMPbZf5dAhOfbAfgLiRv4V726aw3kJvu&si=xwxI6CIKH1wVkRtJ',
    price: '$50',
    coverImg: 'djanimation.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Mejores Canciones de Este Año',
    description:
      'El 2024 nos trae una explosión de talento y creatividad musical, con los Top 100 Songs capturando lo mejor de los éxitos globales. Hot Hits 2024 reúne los sencillos más destacados del año, abarcando una amplia variedad de géneros que dominan las listas de reproducción, como el pop, trap, reggaetón, R&B, y más. En esta selección de las Top Songs 2024, encontrarás los temas más virales y reproducidos, aquellos que han dejado huella en plataformas como Spotify, Apple Music, y YouTube. Ya sea que estés buscando la canción perfecta para una fiesta, un momento de relajación o inspiración, esta lista tiene algo para cada estado de ánimo, reflejando lo mejor de la música moderna y las tendencias más frescas del año.',

    videourl: 'https://youtube.com/playlist?list=PLDIoUOhQQPlWc-Kd6TCjTRIl0Z6fSQV0X&si=FZiL_L26MJAQw9tw',
    price: '$50',
    coverImg: 'djmixer.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Top 10 Canciones de Esta Semana',
    description:
      'En la emocionante y dinámica escena musical de 2024, esta semana se destacan las Top 10 Songs que han conquistado las listas de éxitos. Estas canciones, elegidas por su popularidad en streaming, ventas y difusión en radio, reflejan las preferencias actuales de los oyentes en todo el mundo.Desde pegajosos ritmos de pop hasta poderosas baladas, esta lista incluye artistas consagrados y nuevos talentos que están dando de qué hablar. Cada una de estas canciones ha resonado en las plataformas musicales, capturando la atención y el corazón de los fans.No te pierdas la oportunidad de escuchar las melodías que están marcando tendencia y que, sin duda, definirán el ambiente musical de esta semana. ¡Descubre qué temas están en la cima de las listas y disfruta de lo mejor de la música en 2024!',

    videourl: 'https://youtube.com/playlist?list=PLOHoVaTp8R7cvDSHDv8kEwmiFy7CfyBpj&si=Lr8lxAX6OnfWkT10',
    price: '$50',
    coverImg: 'musicray.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Mejor Playlist de Reggae 2024',
    description:
      'Sumérgete en las vibraciones positivas de la Mejor Playlist de Reggae 2024, una selección cuidadosamente curada con los éxitos más destacados del reggae de este año. En esta lista, encontrarás las Mejores Canciones de Reggae de la semana, con una mezcla de leyendas consagradas y artistas emergentes que mantienen vivo el espíritu del género. Desde los ritmos relajantes hasta las letras cargadas de mensajes sociales, estas canciones capturan la esencia del reggae moderno, brindando una experiencia musical que te transportará a paisajes tropicales y llenará tu día de energía positiva. ¡No te pierdas los temas que están marcando tendencia en el reggae este 2024!',

    videourl: 'https://www.youtube.com/playlist?list=PLxvodScTx2RsV2VIDIBksBmuLFngu3mgU',
    price: '$50',
    coverImg: 'reggaestar.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Electronica 2024 Playlist',
    description:
      'La escena de la música electrónica en 2024 está vibrando con energía, presentando lo último en Electrónica y Música Dance. Esta playlist captura los lanzamientos más frescos y los ritmos más electrizantes del año, desde los beats pegajosos del house y techno, hasta los sonidos experimentales del EDM y el future bass.En la lista de Electrónica 2024 Lo Más Nuevo, encontrarás canciones que dominan las pistas de baile en todo el mundo, con producciones innovadoras de artistas emergentes y DJs consagrados. Esta Playlist de Electrónica 2024 es ideal para poner el ambiente perfecto en cualquier fiesta, entrenamiento o simplemente para disfrutar del poder transformador de la música dance. ¡Prepárate para sumergirte en los ritmos que están marcando el año!',

    videourl: 'https://youtube.com/playlist?list=PLFcGX84jKOu6zyD8m5ponylKThkmkzA90&si=bTs08lfWVY6Uq56m',
    price: '$50',
    coverImg: 'astrodj2.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: ' ♫ Best Hip Hop Playlist 2024',
    description:
      'En la emocionante y dinámica escena musical de 2024, esta semana se destacan las Top 10 Songs que han conquistado las listas de éxitos. Estas canciones, elegidas por su popularidad en streaming, ventas y difusión en radio, reflejan las preferencias actuales de los oyentes en todo el mundo.Desde pegajosos ritmos de pop hasta poderosas baladas, esta lista incluye artistas consagrados y nuevos talentos que están dando de qué hablar. Cada una de estas canciones ha resonado en las plataformas musicales, capturando la atención y el corazón de los fans.No te pierdas la oportunidad de escuchar las melodías que están marcando tendencia y que, sin duda, definirán el ambiente musical de esta semana. ¡Descubre qué temas están en la cima de las listas y disfruta de lo mejor de la música en 2024!',

    videourl: 'https://youtube.com/playlist?list=PLuUrokoVSxlfUJuJB_D8j_wsFR4exaEmy&si=wlz_4MUDylOnieM3',
    price: '$50',
    coverImg: 'hiphop.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Exitos de Musica de los 60',
    description:
      'Los años 60 fueron una década revolucionaria para la música, con una explosión de géneros y estilos que dejaron una huella imborrable en la historia. En esta lista de Éxitos de los 60, encontrarás las canciones que marcaron a una generación, desde el rock & roll hasta el soul, el pop y la música folk.Bandas legendarias como The Beatles, The Rolling Stones, y The Beach Boys redefinieron el panorama musical, mientras que artistas como Elvis Presley, Aretha Franklin, y Bob Dylan conquistaron al mundo con sus voces inconfundibles. Cada canción de esta década lleva consigo el espíritu de cambio social y cultural, convirtiendo la música de los 60 en un símbolo de la libertad y la expresión artística.Esta colección de éxitos es una joya para los amantes de la música clásica, llena de himnos que han resistido la prueba del tiempo y continúan inspirando a nuevas generaciones.!',

    videourl: 'https://www.youtube.com/watch?v=l3LFML_pxlY&list=RDQMOG8HyDRc7JU&start_radio=1',
    price: '$50',
    coverImg: 'sixties.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Exitos de Musica de los 70',
    description:
      'Los años 70 fueron una época dorada para la música, marcada por una increíble diversidad de géneros y la consolidación de nuevos estilos que revolucionaron la industria. En esta lista de Éxitos de Música de los 70, encontrarás una mezcla inolvidable de rock clásico, disco, soul, funk y baladas románticas, que definieron una década de creatividad y experimentación musical.Bandas icónicas como Led Zeppelin, Queen, y Pink Floyd dominaron el rock, mientras que artistas como Stevie Wonder, Donna Summer, y The Jackson 5 llevaron el soul y el disco a su máximo esplendor. Además, el soft rock y el folk, con exponentes como Fleetwood Mac y The Eagles, ofrecieron melodías suaves y letras profundas que capturaron el corazón de millones.Cada una de estas canciones es un viaje nostálgico a una era vibrante y colorida, donde la música servía como un reflejo de los cambios sociales y culturales. ¡Sumérgete en los sonidos legendarios de los 70 y revive los éxitos que siguen siendo clásicos hasta el día de hoy!!',

    videourl: 'https://youtube.com/playlist?list=PLmXxqSJJq-yUF3jbzjF_pa--kuBuMlyQQ&si=289rjCdsenMwVFdY',
    price: '$50',
    coverImg: 'seventies.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Playlist Exitos de Musica de los 80',
    description:
      'La década de los 80 fue un período vibrante y dinámico en la historia de la música, caracterizado por la explosión del pop, el rock, el new wave y el nacimiento del hip hop. Esta Playlist de Éxitos de Música de los 80 reúne los temas más icónicos que marcaron la época y que siguen resonando en la cultura popular.Desde los ritmos pegajosos de artistas como Madonna, Michael Jackson, y Prince, hasta los himnos de bandas de rock como Bon Jovi, Guns N Roses, y Duran Duran, cada canción en esta lista es un reflejo de la diversidad musical que definió la década. También incluye clásicos del synth-pop, baladas inolvidables y temas que se convirtieron en parte de la banda sonora de la juventud de millones.Revive la nostalgia y disfruta de los sonidos que dieron forma a la música contemporánea con esta selección de éxitos atemporales. ¡Ideal para bailar, recordar y celebrar todo lo que los 80 tienen para ofrecer!',

    videourl: 'https://youtube.com/playlist?list=PLpYWKGMgRA1uU9lyMtR8vQBzc4HrjpqYu&si=MjXauRiLntvjAC5X',
    price: '$50',
    coverImg: 'eighties.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Playlist Exitos de Musica de los 80',
    description:
      'La década de los 80 fue un período vibrante y dinámico en la historia de la música, caracterizado por la explosión del pop, el rock, el new wave y el nacimiento del hip hop. Esta Playlist de Éxitos de Música de los 80 reúne los temas más icónicos que marcaron la época y que siguen resonando en la cultura popular.Desde los ritmos pegajosos de artistas como Madonna, Michael Jackson, y Prince, hasta los himnos de bandas de rock como Bon Jovi, Guns N Roses, y Duran Duran, cada canción en esta lista es un reflejo de la diversidad musical que definió la década. También incluye clásicos del synth-pop, baladas inolvidables y temas que se convirtieron en parte de la banda sonora de la juventud de millones.Revive la nostalgia y disfruta de los sonidos que dieron forma a la música contemporánea con esta selección de éxitos atemporales. ¡Ideal para bailar, recordar y celebrar todo lo que los 80 tienen para ofrecer!',

    videourl: 'https://youtube.com/playlist?list=PLpYWKGMgRA1uU9lyMtR8vQBzc4HrjpqYu&si=MjXauRiLntvjAC5X',
    price: '$50',
    coverImg: 'eighties.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Lo Mejor y mas Escuchado de los 90',
    description:
      'La década de los 90 fue un período transformador en la música, marcado por una increíble variedad de géneros y estilos que definieron la cultura popular. En esta recopilación de Lo Mejor y Más Escuchado de los 90, encontrarás los éxitos que marcaron la pauta en el pop, rock, grunge, hip hop y R&B. Desde el fenómeno del grunge con bandas icónicas como Nirvana y Pearl Jam, hasta el auge del pop con artistas como Britney Spears, Backstreet Boys, y TLC, cada canción capturó el espíritu de la época. El hip hop también vivió un crecimiento sin precedentes, con leyendas como Tupac Shakur, Notorious B.I.G., y Dr. Dre dejando su huella en la música y la sociedad. Esta selección destaca los temas más escuchados y amados de la década, recordando un tiempo en el que la música se convirtió en una forma de expresión y un vehículo para el cambio cultural. ¡Revive la nostalgia de los 90 y disfruta de los sonidos que definieron una generación!',

    videourl: 'https://www.youtube.com/playlist?list=PL7DA3D097D6FDBC02',
    price: '$50',
    coverImg: 'ninties.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Top 1000 Canciones de Classic Rock',
    description:
      'La música Classic Rock sigue siendo atemporal y en esta lista de las Top 1000 Canciones de Classic Rock se celebra lo mejor de décadas doradas, donde el rock dejó una huella indeleble en la historia de la música. Esta selección incluye himnos icónicos y clásicos de bandas legendarias como Led Zeppelin, The Rolling Stones, Pink Floyd, Queen, AC/DC, y muchos más. Cada canción en esta lista resalta el poder de las guitarras eléctricas, los solos épicos, y las voces inconfundibles que definieron el rock clásico y su evolución. Desde los éxitos más grandes hasta gemas menos conocidas, este recorrido por los mejores temas de Classic Rock te llevará por un viaje inolvidable a través del legado de la música que ha inspirado a generaciones. ¡Ideal para los amantes del rock y aquellos que quieran redescubrir los sonidos que dieron forma a la historia musical!',

    videourl: 'https://youtube.com/playlist?list=PLfEQL-7jlf6nw0H2ryQYEoid-BbsuVp_y&si=gdRRfgJkO7EoZgDR',
    price: '$50',
    coverImg: 'classicrock.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Rock Alternativo 2024 - Nuevas Canciones',
    description:
      'El Rock Alternativo sigue evolucionando en 2024, trayendo consigo una ola de nuevas bandas y sonidos que rompen moldes y desafían las convenciones musicales. En esta lista de Nuevas Canciones de Rock Alternativo 2024, encontrarás una combinación de géneros experimentales, letras profundas y estilos únicos que capturan el espíritu del rock moderno. Desde el indie rock hasta el post-punk y el rock electrónico, estas canciones representan lo mejor de la escena alternativa actual, destacando a los artistas que están marcando tendencia y llevando el género hacia nuevas fronteras. Este 2024, el rock alternativo sigue siendo un espacio de creatividad y libertad, reflejando las emociones y el caos del mundo contemporáneo a través de sus ritmos y sonidos. ¡Descubre las nuevas joyas del rock alternativo que están sonando este año!!',

    videourl: 'https://www.youtube.com/playlist?list=PLOHoVaTp8R7dxy9puoQHF6MBX2LH4OqmE',
    price: '$50',
    coverImg: 'altmush.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  {
    title: 'Canciones y Videos Nuevos de Metal 2024',
    description:
      'El 2024 trae una ola de poder y energía con las nuevas canciones y videos de metal, donde las bandas más icónicas y los talentos emergentes continúan empujando los límites del género. Desde el metal clásico hasta el metal extremo, este año ha visto lanzamientos impresionantes que capturan la intensidad, velocidad y agresividad características del metal.Los nuevos videos de metal 2024 no solo complementan el sonido con imágenes impactantes, sino que también ofrecen una experiencia visual que va de la mano con la fuerza de la música. Ya sea que te guste el thrash, death, black metal, o el metalcore, estos lanzamientos ofrecen una diversidad de estilos para satisfacer a todos los fanáticos.Prepárate para explorar riffs poderosos, baterías implacables y voces guturales que harán vibrar tu alma metalera. ¡No te pierdas las nuevas canciones y videos de metal que están definiendo el 2024!',

    videourl: 'https://youtube.com/playlist?list=PLkqz3S84Tw-SIwPfYMPEZqvv0lqSRM7PK&si=GhvQfNFgmOA0JunZ',
    price: '$50',
    coverImg: 'hangloose.jpg',
    category: 'Hits and Remix',
    location: 'Mexico',
    locationImg: 'icon.png',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.billboard.com/',
    youtube: 'https://www.youtube.com/@banderamusical',
    facebook: 'https://www.facebook.com/profile.php?id=100087385321347',
    instagram: 'https://www.instagram.com/banderamusical/',
    twitter: 'https://x.com/BanderaMusical',
    spotify:
      'https://open.spotify.com/playlist/37i9dQZF1DX21ow0o1PZDE',
    deezer: 'https://www.deezer.com/mx/album/557429562',
    apple: 'https://music.apple.com/mx/playlist/2024-hits-viral-top-hits-2024/pl.8e5670f6b5e445ccb434325a5c4ee400',
    soundcloud: 'https://soundcloud.com/spinninrecords/sets/dance-top-hits-2023',
    tidal: 'https://listen.tidal.com/album/349712878',
    wiki: 'https://es.wikipedia.org/wiki/MTV_Video_Music_Awards',
    ticketmaster:
      'https://www.ticketmaster.com',
    tiktok: 'https://www.tiktok.com/@bandera_musical',
    napster: 'https://music.amazon.com.mx/user-playlists/d30f0ff99c3e4ead888ec51df7bc9548m12x',
  },
  
]
export default CardData23
