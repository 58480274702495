const CardData4 = [
 
  {
    title: 'Yuridia',
    description:
      'Yuridia Francisca Gaxiola Flores (Hermosillo, 4 de octubre de 1986), conocida como Yuridia, es una cantante y compositora mexicana. Saltó a la fama en 2005 como participante de la cuarta generación del reality show mexicano La Academia, donde obtuvo el segundo lugar.Cuenta con seis álbumes de estudio: La voz de un ángel, (2005), Habla el corazón (2006), Entre mariposas (2007), Nada es color de rosa (2009), Para mí (2011) y 6. (2015) y un álbum en vivo Primera fila: Yuridia(2017). Es una de las intérpretes de origen mexicano con gran logro comercial en la industria musical, registrando más de 2 millones de álbumes vendidos...',
    videourl:
      'https://youtube.com/playlist?list=PLyzN_J2WwjznyWCf3y9LQrG1Kz132CBFk',
    price: '$50',
    coverImg: 'Yuridia.jpg',
    category: 'Regional',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://yuritaflowers.com/',
    youtube: 'https://www.youtube.com/channel/UCrkMQGSWGoNokFc0vKfciKA',
    facebook: 'https://www.facebook.com/yuritaflowers',
    instagram: 'https://www.instagram.com/yuritaflowers/',
    twitter: 'https://twitter.com/yuritaflowers',
    spotify:
      'https://open.spotify.com/artist/5B8ApeENp4bE4EE3LI8jK2?autoplay=true',
    deezer: 'https://www.deezer.com/en/artist/274393',
    apple: 'https://music.apple.com/mx/artist/yuridia/119903555',
    soundcloud: 'https://soundcloud.com/yuridiamusic',
    tidal: 'https://listen.tidal.com/artist/3633725',
    wiki: 'https://es.wikipedia.org/wiki/Yuridia',
    ticketmaster: 'https://www.ticketmaster.com.mx/',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B00136B3EC/yuridia',
  }, {
    title: 'OV7',
    description:
      'OV7 (conocido en sus inicios como La Onda Vaselina, luego Onda Vaselina) es un grupo musical pop mexicano formado en el año 1989. El grupo surgió en el año 1989 como una agrupación infantil creada por la cantante y actriz mexicana Julissa con el nombre de La Onda Vaselina. En el año 2000, el grupo se independiza de su fundadora y resurge con su nombre y concepto actual. En el año 2003, el grupo decide separarse para brindarle la oportunidad a sus integrantes de desarrollarse de forma independiente. En el año 2022 la famosa agrupación retoma sus presentaciones bajo el concepto de OV7 Treinta. Gira qué celebra más de 30 años de trayectoria',
    category: 'Pop',
    videourl: 'https://youtube.com/playlist?list=PLC7DFC3C897F1E77B',
    price: 80,
    coverImg: 'ov7.jpg',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://dreaminpeace.store/',
    youtube: 'https://www.youtube.com/channel/UC7tJh7b0ledI9fe1A_-dzRw',
    facebook: 'https://www.facebook.com/OV7OFICIAL',
    instagram: 'https://www.instagram.com/oficialov7/',
    twitter: 'https://twitter.com/ov7oficial',
    spotify:
      'https://open.spotify.com/artist/5zaT4Qu9otu6z4oyWjRqM2?si=xg1B-_dNSnKtXPgUNkIQqA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/491621',
    apple: 'https://music.apple.com/mx/artist/ov7/170956727',
    soundcloud: 'https://soundcloud.com/ov7official',
    tidal: 'https://listen.tidal.com/artist/1414',
    wiki: 'https://es.wikipedia.org/wiki/OV7',
    ticketmaster: 'https://www.ticketmaster.com/ov7-tickets/artist/1631627',
    tiktok: 'https://www.tiktok.com/@oficialov7?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B0013A38NC/ov7',
  },
  {
    title: 'Jesse y Joy',
    description:
      'Jesse & Joy son un dúo mexicano de música pop, formado en 2005 por los hermanos Jesse (1982) 1​ y Joy Huerta (1986) en la Ciudad de México.El dúo ha lanzado seis álbumes de estudio, dos álbumes en vivo y un EP en Warner Music Latina. También ha realizado giras internacionales y ha ganado un Premio Grammy al Mejor Álbum Latino y cinco Premios Grammy Latinos en varias categorías.Jesse & Joy son probablemente unos de los artistas más puros y talentosos de nuestra generación, escribió Latin Times.',
    videourl:
      'https://youtube.com/playlist?list=PLy1NnxDiUL4iyLeRCsWq3vpQ6c_sInCyr',
    price: '$50',
    coverImg: 'jesseyjoy.jpg',
    category: 'Regional Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://jesseyjoy.com/',
    youtube: 'https://www.youtube.com/channel/UC6awBq3lKY35T__puHY2OYQ',
    facebook: 'https://www.facebook.com/JesseyJoyOficial',
    instagram: 'https://www.instagram.com/jesseyjoy/',
    twitter: 'https://twitter.com/jesseyjoy',
    spotify: 'https://open.spotify.com/artist/1mX1TWKpNxDSAH16LgDfiR',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/jesse-joy/167779502',
    soundcloud: 'https://soundcloud.com/user-996270785',
    tidal: 'https://listen.tidal.com/artist/12832964',
    wiki: 'https://es.wikipedia.org/wiki/Jesse_%26_Joy',
    ticketmaster:
      'https://www.ticketmaster.com.mx/jesse-joy-zapopan-07-05-2023/event/14005E6189360940',
    tiktok: 'https://www.tiktok.com/@jesseandjoy',
    napster: 'https://music.amazon.com.mx/artists/B001231YC2/jesse-joy',
  },
  {
    title: 'Camilo',
    description:
      'Camilo Andrés Echeverry Correa, conocido simplemente como Camilo, es un cantautor y músico colombiano. Debutó en 2008, después de ganar el concurso de talentos Factor X de su país en 2007.',
    videourl:
      'http://youtube.com/playlist?list=PLl9yuNCZfI6pQFNQ90lEAdv8VoCOUvE8i',
    price: '$50',
    coverImg: 'Camilo.jpg',
    category: 'Balada',
    location: 'Camilo',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: '',
    youtube: 'https://www.youtube.com/channel/UCw0aKHSGFGyrnMeVnHeYMyw',
    facebook: 'https://www.facebook.com/camilomusica',
    instagram: 'https://www.instagram.com/camilo/',
    twitter: 'https://twitter.com/CamiloMusica',
    spotify: 'https://open.spotify.com/artist/28gNT5KBp7IjEOQoevXf9N',
    deezer: 'https://www.deezer.com/mx/artist/58568762',
    apple: 'https://music.apple.com/mx/artist/camilo/1457386767',
    soundcloud: 'https://soundcloud.com/camiloofficial',
    tidal: 'https://listen.tidal.com/artist/3824470',
    wiki: 'https://es.wikipedia.org/wiki/Camilo',
    ticketmaster: 'https://www.ticketmaster.com./camilo-tickets/artist/2773173',
    tiktok: 'https://www.tiktok.com/@camilo?lang=es',
    napster: 'https://app.napster.com/artist/camilo',
  },
  {
    title: 'Dua Lipa',
    description:
      'Dua Lipa (Londres, 22 de agosto de 1995) es una cantante, compositora, modelo y actriz británica de origen albanokosovar. Después de trabajar como modelo, firmó con Warner Bros. Records en 2014 y lanzó su álbum debut homónimo en 2017. El álbum alcanzó el número 3 en la UK Albums Chart y lanzó ocho sencillos, incluidos «Be the One» e «IDGAF», y el sencillo número 1 del Reino Unido «New Rules», que también llegó al puesto número 6 en Estados Unidos. En 2018, ganó dos premios Brit a la artista solista femenina británica y artista revelación británica.',
    videourl:
      'https://youtube.com/playlist?list=PLNrotoZZ8BaoXT_LJuwEyESQlctWNDCwD&si=CD9RJAMy-wfQy72w',
    price: '$50',
    coverImg: 'dualipa.jpg',
    category: 'Pop Americano',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.dualipa.com/?frontpage=true',
    youtube: 'https://www.youtube.com/channel/UC-J-KZfRV8c13fOCkhXdLiQ',
    facebook: 'https://www.facebook.com/DuaLipa',
    instagram: 'https://www.instagram.com/dualipa/',
    twitter: 'https://twitter.com/DUALIPA',
    spotify: 'https://open.spotify.com/artist/6M2wZ9GZgrQXHCFfjv46we',
    deezer: 'https://www.deezer.com/mx/artist/8706544',
    apple: 'https://music.apple.com/mx/artist/dua-lipa/1031397873',
    soundcloud: 'https://soundcloud.com/dualipa',
    tidal: 'https://listen.tidal.com/artist/7162333',
    wiki: 'https://es.wikipedia.org/wiki/Dua_Lipa',
    ticketmaster:
      'https://www.ticketmaster.com/dua-lipa-tickets/artist/2179476',
    tiktok: 'https://www.tiktok.com/@dualipaofficial?lang=es',
    napster: 'https://us.napster.com/artist/dua-lipa',
  },
  
  {
    title: 'Lady Gaga',
    description:
      'Stefani Joanne Angelina Germanotta, más conocida por su nombre artístico Lady Gaga, es una cantante, compositora, productora, bailarina, actriz, activista y diseñadora de moda estadounidense. Nacida y criada en la ciudad de Nueva York, estudió en la escuela Convent of the Sacred Heart y asistió por un tiempo breve a la Tisch School of the Arts, perteneciente a la Universidad de Nueva York, hasta que abandonó sus estudios para enfocarse en su carrera musical. Fue así como irrumpió en la escena del rock en el Lower East Side de Manhattan y firmó un contrato con Streamline Records hacia fines de 2007. En la época en que trabajaba como compositora para dicha discográfica, su voz llamó la atención del artista Akon, quien la hizo firmar un contrato con Kon Live Distribution',
    videourl:
      'https://youtube.com/playlist?list=PLf5BnbCRqFj5c1wwBIIOU5ir7VVAgiAEO',
    price: '$50',
    coverImg: 'LadyGaga.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.ladygaga.com/',
    youtube: 'https://www.youtube.com/channel/UCNL1ZadSjHpjm4q9j2sVtOA',
    facebook: 'https://www.facebook.com/ladygaga',
    instagram: 'https://www.instagram.com/ladygaga/',
    twitter: 'https://twitter.com/ladygaga',
    spotify: 'https://open.spotify.com/artist/1HY2Jd0NmPuamShAr6KMms',
    deezer: 'https://www.deezer.com/mx/artist/75491',
    apple: 'https://music.apple.com/mx/artist/lady-gaga/277293880',
    soundcloud: 'https://soundcloud.com/ladygaga',
    tidal: 'https://listen.tidal.com/artist/3534754',
    wiki: 'https://es.wikipedia.org/wiki/Lady_Gaga',
    ticketmaster:
      'https://www.ticketmaster.com/lady-gaga-tickets/artist/1249444',
    tiktok: 'https://www.tiktok.com/@ladygaga?lang=es',
    napster: 'https://us.napster.com/artist/lady-gaga',
  },
  {
    title: 'Demi Lovato',
    description:
      'Demetria Devonne Lovato, cuyo nombre artístico es Demi Lovato, es una celebridad estadounidense profesional en el canto, la actuación y la composición. Fundó junto con Nick Jonas el sello discográfico Safehouse Records..',
    videourl:
      'https://youtube.com/playlist?list=PLy4Kg0J0TkebIr4dkt4rEgDns_ZwP0rzh',
    price: '$50',
    coverImg: 'demiLovato.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://www.demilovato.com/',
    youtube: 'https://www.youtube.com/channel/UCZkURf9tDolFOeuw_4RD7XQ',
    facebook: 'https://www.facebook.com/DemiLovato',
    instagram: 'https://www.instagram.com/ddlovato/',
    twitter:
      'https://twitter.com/ddlovato?fbclid=IwAR0O4EFAwe-J9akxjfltKN7XRDEwy5wAi1_pjdm_cbFTQCY4KB-twhabAms',
    spotify: 'https://open.spotify.com/artist/6S2OmqARrzebs0tKUEyXyp',
    deezer: 'https://www.deezer.com/mx/artist/193875',
    apple: 'https://music.apple.com/mx/artist/demi-lovato/280215821',
    soundcloud: 'https://soundcloud.com/ddlovato',
    tidal: 'https://listen.tidal.com/artist/3544816',
    wiki: 'https://es.wikipedia.org/wiki/Demi_Lovato',
    ticketmaster:
      'https://www.ticketmaster.com/demi-lovato-tickets/artist/1224909',
    tiktok: 'https://www.tiktok.com/@ddlovato?lang=es',
    napster: 'https://us.napster.com/artist/demi-lovato',
  },
  {
    title: 'Adele',
    description:
      'Adele Laurie Blue Adkins (Londres, 5 de mayo de 1988), conocida simplemente como Adele, es una cantautora y multinstrumentista británica. Es una de las artistas musicales con mayores ventas del mundo, con más de 120 millones de ventas entre discos y sencillos.',
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_n5Ls1CYg63QxBxeuJf4ICzSY-kvAMLQu8',
    price: '$50',
    coverImg: 'adele.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    merchsiteurl: 'https://shop.adele.com/',
    websiteurl: 'https://www.adele.com/',
    youtube: 'https://www.youtube.com/channel/UCsRM0YB_dabtEPGPTKo-gcw',
    facebook: 'https://www.facebook.com/adele?fref=ts',
    instagram: 'https://www.instagram.com/adele/',
    twitter: 'https://twitter.com/Adele',
    spotify: 'https://open.spotify.com/artist/4dpARuHxo51G3z768sgnrY',
    deezer: 'https://www.deezer.com/mx/artist/75798',
    apple: 'https://music.apple.com/mx/artist/adele/262836961',
    soundcloud: 'https://soundcloud.com/adelemusic',
    tidal: 'https://listen.tidal.com/artist/3521920',
    wiki: 'https://es.wikipedia.org/wiki/Adele',
    ticketmaster: 'https://www.ticketmaster.com/adele-tickets/artist/1159272',
    tiktok: 'https://www.tiktok.com/@adele.offi?lang=es',
    napster: 'https://us.napster.com/artist/adele',
  },
  {
    title: 'Nelly Furtado',
    description:'Nelly Kim Furtado Neto (Victoria, Columbia Británica, 2 de diciembre de 1978), conocida simplemente como Nelly Furtado, es una cantante, compositora y productora musical luso-canadiense.​ Es ampliamente conocida por su versatilidad musical y experimentación de géneros.​ Ha vendido más de 40 millones de discos en todo el mundo,​ lo que la convierte en una de las artistas más exitosas de la historia tanto de su país natal como su país de origen',
    videourl:
      'https://youtube.com/playlist?list=PL4P9RkJjj8u7y7JNUeoAD5Ls05x85dbIk&si=sQEOBnQw1EgLnrSQ',
    price: '$50',
    coverImg: 'nelly.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    merchsiteurl: 'https://www.nellyfurtado.com/',
    websiteurl: 'https://www.nellyfurtado.com/',
    youtube: 'https://www.youtube.com/channel/UC5Y-gAeGpzgzu0ObzKnhPFA',
    facebook: 'https://www.facebook.com/nellyfurtado',
    instagram: 'https://www.instagram.com/nellyfurtado/?hl=en',
    twitter: 'https://x.com/nellyfurtado?lang=en',
    spotify: 'https://open.spotify.com/artist/2jw70GZXlAI8QzWeY2bgRc',
    deezer: 'https://www.deezer.com/mx/artist/133',
    apple: 'https://music.apple.com/mx/artist/nelly-furtado/1260160',
    soundcloud: 'https://soundcloud.com/nellyfurtado',
    tidal: 'https://listen.tidal.com/artist/10666',
    wiki: 'https://es.wikipedia.org/wiki/Nelly_Furtado',
    ticketmaster: 'https://www.ticketmaster.com/nelly-furtado-tickets/artist/703714',
    tiktok: 'https://www.tiktok.com/@nellyfurtadoofficial',
    napster: 'https://music.amazon.com/artists/B000RW2LHG/nelly-furtado',
  },
  {
    title: 'Lorde',
    description:'Ella Marija Lani Yelich-O Connor (nacida el 7 de noviembre de 1996), conocida profesionalmente como Lorde (/lɔːrd/ LORD), es una cantante y compositora neozelandesa. Es conocida por su estilo poco convencional de música pop y su escritura introspectiva.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nVGvb7kwnHAKZO9htUrmIXK4LypUFCfoY&si=99-Z6qLbQYq9lB_g',
    price: '$50',
    coverImg: 'lorde.jpg',
    category: 'Soul-Pop',
    location: 'Inglaterra',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    merchsiteurl: 'https://www.lorde.co.nz/',
    websiteurl: 'https://www.lorde.co.nz/',
    youtube: 'https://www.youtube.com/channel/UCOxhwqKKlVq_NaD0LVffGuw',
    facebook: 'https://www.facebook.com/lordemusic',
    instagram: 'https://www.instagram.com/lorde/?hl=en',
    twitter: 'https://x.com/lorde?lang=en',
    spotify: 'https://open.spotify.com/artist/163tK9Wjr9P9DmM0AVK7lm?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/4448485',
    apple: 'https://music.apple.com/us/artist/lorde/602767352',
    soundcloud: 'https://soundcloud.com/nellyfurtado',
    tidal: 'https://listen.tidal.com/artist/4931831',
    wiki: 'https://es.wikipedia.org/wiki/Lorde',
    ticketmaster: 'https://www.ticketmaster.com/lorde-tickets/artist/1901987',
    tiktok: 'https://www.tiktok.com/@lordeupdates',
    napster: 'https://music.amazon.com/artists/B00BLWJZRA/lorde',
  },
  {
    title: 'Avril Lavigne',
    description:
      'Avril Ramona Lavigne es una cantante, compositora, diseñadora de moda, productora musical, actriz y filántropa franco-canadiense. Algunos críticos de revistas como Billboard la llaman la princesa del pop punk. Comenzó su carrera musical en diciembre de 2001, cuando después de una presentación en una feria country, despertó el interés del productor L.A. Reid y firmó para Arista Records.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lXBMcqqDcHMW9fNqwE6UNC05-8DORHzbI',
    price: '$50',
    coverImg: 'AvrilLavigne.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://lovesux.avrillavigne.com/?ref=https://www.bing.com/',
    youtube: 'https://www.youtube.com/channel/UC_k8KFBzEf0uQ8LRFSAgSUA',
    facebookurl: 'https://www.facebook.com/avrillavigne',
    instagram: 'https://www.instagram.com/AvrilLavigne/',
    twitter: 'https://twitter.com/AvrilLavigne',
    spotify: 'https://open.spotify.com/artist/0p4nmQO2msCgU4IF37Wi3j',
    deezer: 'https://www.deezer.com/mx/artist/35',
    apple: 'https://music.apple.com/mx/artist/avril-lavigne/459885',
    soundcloud: 'https://soundcloud.com/avrillavigne',
    tidal: 'https://listen.tidal.com/artist/1567',
    wiki: 'https://es.wikipedia.org/wiki/Avril_Lavigne',
    ticketmaster:
      'https://www.ticketmaster.com/avril-lavigne-tickets/artist/833634',
    tiktok: 'https://www.tiktok.com/@avrillavigne?lang=es',
    napster: 'https://us.napster.com/artist/avril-lavigne',
  },
  {
    title: 'Taylor Swift',
    description:
      'Taylor Alison Swift (Reading, Pensilvania; 13 de diciembre de 1989) es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.Criada en Wyomissing (Pensilvania), se mudó a Nashville (Tennessee) a los 14 años para realizar una carrera de música country. Firmó con la compañía discográfica independiente Big Machine Records y se convirtió en la artista más joven contratada por Sony/ATV Music Publishing House. En 2006 lanzó su álbum debut homónimo Taylor Swift, el cual la estableció como una estrella en la música country. «Our Song», su tercer sencillo, la convirtió en la persona más joven en escribir temas sin ayuda de nadie e interpretar una canción número uno en la lista de Hot Country Songs. Recibió una nominación a mejor artista nuevo en los Premios Grammy de 2008.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kOjcMYd-zFhGK7iPzOb1m2-3ituAZSNe0',
    price: '$50',
    coverImg: 'TaylorSwift.jpg',
    category: 'Rock-Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.taylorswift.com/',
    youtube: 'https://www.youtube.com/channel/UCqECaJ8Gagnn7YCbPEzWH6g',
    facebookurl: 'https://www.facebook.com/TaylorSwift',
    instagram: 'https://www.instagram.com/taylorswift/',
    twitter: 'https://twitter.com/taylorswift13',
    spotify: 'https://open.spotify.com/artist/06HL4z0CvFAxyc27GXpf02',
    deezer: 'https://www.deezer.com/mx/artist/12246',
    apple: 'https://music.apple.com/mx/artist/taylor-swift/159260351',
    soundcloud: 'https://soundcloud.com/taylorswiftofficial',
    tidal: 'https://listen.tidal.com/artist/3557299',
    wiki: 'https://es.wikipedia.org/wiki/Taylor_Swift',
    ticketmaster:
      'https://www.ticketmaster.com/taylor-swift-tickets/artist/1094215',
    tiktok: 'https://www.tiktok.com/@taylorswift',
    napster: 'https://us.napster.com/artist/taylor-swift',
  },
  {
    title: 'Elton John',
    description:
      'Sir Elton John es un cantante, pianista, compositor y músico británico. Con una carrera de más de 50 años, ha lanzado más de 30 álbumes de estudio y ha vendido más de 300 millones de copias en todo el mundo, siendo uno de los artistas musicales más exitosos de la historia.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mr6JQDWNhkKYRCe0LUbmROXYR8XUD0N6A',
    price: 36,
    coverImg: 'Elton John.jpg',
    category: 'Funk',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.eltonjohn.com/',
    youtube: 'https://www.youtube.com/channel/UCcd0tBtip8YzdTCUw3OVv_Q',
    facebook: 'https://www.facebook.com/EltonJohn/',
    instagram: 'https://www.instagram.com/eltonjohn/',
    twitter: 'https://twitter.com/eltonofficial',
    spotify: 'https://open.spotify.com/artist/3PhoLpVuITZKcymswpck5b',
    deezer: 'https://www.deezer.com/mx/artist/413',
    apple: 'https://music.apple.com/mx/artist/elton-john/54657',
    soundcloud: 'https://soundcloud.com/elton-john',
    tidal: 'https://listen.tidal.com/artist/6890',
    wiki: 'https://es.wikipedia.org/wiki/Elton_John',
    ticketmaster:
      'https://www.ticketmaster.com/elton-john-tickets/artist/735394',
    tiktok: 'https://www.tiktok.com/@eltonjohn?lang=es',
    napster: 'https://us.napster.com/artist/elton-john',
  },
  {
    title: 'The Black Keys',
    description:
      'The Black Keys es un banda de rock estadounidense formada en Akron en 2001 y compuesta por dan Auerbach y Patrick Carney. El dúo comenzó de forma independiente, grabando música en sótanos y produciendo sus propios álbumes, antes de emerger como una de las bandas más populares de garage rock en la década de 2010. El crudo sonido de blues rock de la banda está relacionado directamente con las influencias de Auerbach, incluyendo a Howlin Wolf y Robert Johnson.',
    videourl: 'https://youtube.com/playlist?list=PL059BE1592AA3EA47',
    price: 125,
    coverImg: 'TheBlackKeys.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://theblackkeys.com/',
    youtube: 'https://www.youtube.com/channel/UCJL3h2-wEOB6EigQOBZ3ryg',
    facebook: 'https://www.facebook.com/TheBlackKeys',
    instagram: 'https://www.instagram.com/theblackkeys/',
    twitter: 'https://twitter.com/theblackkeys',
    spotify: 'https://open.spotify.com/artist/7mnBLXK823vNxN3UWB7Gfz',
    deezer: 'https://www.deezer.com/mx/artist/2483',
    apple: 'https://music.apple.com/mx/artist/the-black-keys/5893059',
    soundcloud: 'https://soundcloud.com/theblackkeys',
    wiki: 'https://es.wikipedia.org/wiki/The_Black_Keys',
    tidal: 'https://listen.tidal.com/artist/64643',
    ticketmaster:
      'https://www.ticketmaster.com/the-black-keys-tickets/artist/847164',
    merch: 'https://theblackkeys.com/collections/all-products',
    tiktok: 'https://www.tiktok.com/@theblackkeys?lang=es',
    napster: 'https://mx.napster.com/artist/the-black-keys',
  },

  {
    title: 'The Black Eyed Peas',
    description:
      'Black Eyed Peas es una banda estadounidense de hip hop/pop formada en 1995 en Los Ángeles, California. Han sido ganadores del premio Grammy seis veces. El grupo está actualmente integrado por los vocalistas Will.i.am, Apl.de.Ap, Taboo y J. Rey Soul. Fergie fue la vocalista femenina hasta su salida en 2017.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nl-K4EFsySnvvBanoKeMSw7Sjgcr94oWo',
    price: 125,
    coverImg: 'TheBlackEyedPeas.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.blackeyedpeas.com/',
    youtube: 'https://www.youtube.com/channel/UCBFaOy1_APEXEyA6Gws_Y1g',
    facebook: 'https://www.facebook.com/blackeyedpeas/',
    instagram: 'https://www.instagram.com/bep/',
    twitter: 'https://twitter.com/bep',
    spotify: 'https://open.spotify.com/artist/1yxSLGMDHlW21z4YXirZDS',
    deezer: 'https://www.deezer.com/mx/artist/1020109',
    apple: 'https://music.apple.com/mx/artist/black-eyed-peas/360391',
    wiki: 'https://es.wikipedia.org/wiki/Black_Eyed_Peas',
    soundcloud: 'https://soundcloud.com/black-eyed-peas-official',
    tidal: 'https://listen.tidal.com/artist/7806',
    ticketmaster:
      'https://www.ticketmaster.ie/the-black-eyed-peas-co-galway-06-04-2022/event/18005B789FC5528A',
    tiktok: 'https://www.tiktok.com/@blackeyedpeas?lang=es',
    napster: 'https://mx.napster.com/artist/the-black-eyed-peas',
  },
  
  {
    title: 'Harry Styles',
    description:
      'Harry Edward Styles es un cantante, compositor y actor británico. Inició su carrera como cantante en 2010 como integrante de la boy band One Direction, con la que participó en el programa The X Factor y quedó en tercer lugar. Pese a no ganar, la agrupación firmó un contrato discográfico con el sello Syco, con el que publicaron los álbumes Up All Night, Take Me Home, Midnight Memories, Four y Made in the A.M., los cuales alcanzaron la primera posición en las listas de los más vendidos en numerosos países, entre estos los Estados Unidos y el Reino Unido, además de registrar altas ventas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kzrF1uoBy1nqk215mLiPzzhUnc_hfFs40',
    price: '$50',
    coverImg: 'harrystyles.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.hstyles.co.uk/',
    youtube: 'https://www.youtube.com/c/HarryStyles',
    facebook: 'https://www.facebook.com/harrystyles',
    instagram: 'https://www.instagram.com/harrystyles/',
    twitter: 'https://twitter.com/Harry_Styles',
    spotify: 'https://open.spotify.com/artist/6KImCVD70vtIoJWnq6nGn3',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/harry-styles/471260289',
    soundcloud: 'https://soundcloud.com/harrystyles',
    tidal: 'https://listen.tidal.com/artist/8669861',
    wiki: 'https://es.wikipedia.org/wiki/Harry_Styles',
    ticketmaster:
      'https://www.ticketmaster.com/harry-styles-tickets/artist/2366444',
    napster: 'https://web.napster.com/artist/art.146985808',
    tiktok: 'https://www.tiktok.com/@officialharry?lang=es',
  },
  {
    title: 'Sam Smith',
    description:
      'Samuel Frederick Smith, cuyo nombre artístico es Sam Smith, es una celebridad británica profesional en el canto y la composición. En 2019, Smith se declaró de género no binario y tener los pronombres they/them en inglés.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nrECM3EEps3lyUkyO8L3uQTJsclWhcYlM',
    price: '$50',
    coverImg: 'samsmith.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://samsmithworld.com/',
    youtube: 'https://www.youtube.com/channel/UCvpDeGlR5wLP9Z3Tb6K0Xfg',
    facebook: 'https://www.facebook.com/samsmithworld',
    instagram: 'https://www.instagram.com/samsmith/',
    twitter: 'https://twitter.com/samsmith',
    spotify: 'https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI',
    deezer: 'https://www.deezer.com/mx/artist/1097709',
    apple: 'https://music.apple.com/mx/artist/sam-smith/156488786',
    soundcloud: 'https://soundcloud.com/samsmithworld',
    tidal: 'https://listen.tidal.com/artist/4306587',
    wiki: 'https://es.wikipedia.org/wiki/Sam_Smith',
    ticketmaster:
      'https://www.ticketmaster.com/sam-smith-tickets/artist/1785301',
    napster: 'https://web.napster.com/artist/art.21025078',
    tiktok: 'https://www.tiktok.com/@samsmith?lang=es',
  },
  {
    title: 'John Legend',
    description:
      "John Roger Stephens, conocido como John Legend, es un cantante, compositor, pianista y actor estadounidense. Ha ganado once premios Grammy, un Premio Globo de Oro y un Oscar. En 2007, Legend recibió el Premio 'Hal David Starlight'  del Salón de la Fama de los Compositores.",
    videourl:
      'https://youtube.com/playlist?list=PL5odlUFMuUzzKmkiEuqFk3YFBossORfYB',
    price: '$50',
    coverImg: 'johnlegend.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.johnlegend.com/',
    youtube: 'https://www.youtube.com/channel/UCEa-JnNdYCIFn3HMhjGEWpQ',
    facebook: 'https://www.facebook.com/johnlegend',
    instagram: 'https://www.instagram.com/johnlegend_fc/',
    twitter: 'https://twitter.com/johnlegend',
    spotify: 'https://open.spotify.com/artist/5y2Xq6xcjJb2jVM54GHK3t',
    deezer: 'https://www.deezer.com/mx/artist/313',
    apple: 'https://music.apple.com/mx/artist/john-legend/16586443',
    soundcloud: 'https://soundcloud.com/johnlegend',
    tidal: 'https://listen.tidal.com/artist/490',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/john-legend-tickets/artist/936461',
    napster: 'https://web.napster.com/artist/art.6432383',
    tiktok: 'https://www.tiktok.com/@johnlegend570?lang=es',
  },
  {
    title: 'Justin Timberlake',
    description:
      "Justin Randall Timberlake (Memphis; 31 de enero de 1981) es un cantante, compositor, productor discográfico, actor, bailarín y empresario estadounidense. Criado en la comunidad de Shelby Forest, ubicada entre Memphis y Millington, comenzó su carrera como cantante en los programas de televisión Star Search y Mickey Mouse Club. A finales de los años 1990 adquirió fama internacional al pertenecer a la boy band 'N Sync, cuyos dos primeros álbumes fueron certificados con disco de diamante tras vender más de 10 millones de copias solo en los Estados Unidos, además de haber alcanzado la cima de numerosos listados alrededor del mundo",
    videourl:
      'https://youtube.com/playlist?list=PLOivmTxGQl85Wfn2671PhKUbNWLYqBW14',
    price: '$50',
    coverImg: 'justintimberlake.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://justintimberlake.com/',
    youtube: 'https://www.youtube.com/channel/UC-y8ci7xfsu4L3zkSuHae0A',
    facebook: 'https://www.facebook.com/justintimberlake',
    instagram: 'https://www.instagram.com/justintimberlake/',
    twitter: 'https://twitter.com/jtimberlake',
    spotify: 'https://open.spotify.com/artist/31TPClRtHm23RisEBtV3X7',
    deezer: 'https://www.deezer.com/mx/artist/1147',
    apple: 'https://music.apple.com/mx/artist/justin-timberlake/398128',
    soundcloud: 'https://soundcloud.com/justintimberlake',
    tidal: 'https://listen.tidal.com/artist/1569',
    wiki: 'https://es.wikipedia.org/wiki/Justin_Timberlake',
    ticketmaster:
      'https://www.ticketmaster.com/justin-timberlake-tickets/artist/847841',
    napster: 'https://web.napster.com/artist/art.56237',
    tiktok: 'https://www.tiktok.com/@justintimberlake?lang=es',
  },
  {
    title: 'Bruno Mars',
    description:
      'Peter Gene Hernandez, conocido artísticamente como Bruno Mars, es un cantante, compositor, productor musical y bailarín estadounidense. Mars comenzó a crear música desde una edad muy temprana y actuó en muchos escenarios de su pueblo natal a lo largo de su niñez realizando imitaciones. Se graduó de la escuela secundaria y se mudó a Los Ángeles para proseguir con su carrera musical..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m0ApIZblcRk9r_9DXSyf69qfT6RhNmy0U',
    price: '$50',
    coverImg: 'BrunoMars.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.brunomars.com/',
    youtube: 'https://www.youtube.com/channel/UCoUM-UJ7rirJYP8CQ0EIaHA',
    facebook: 'https://www.facebook.com/brunomars',
    instagram: 'https://www.instagram.com/brunomars/?hl=en',
    twitter:
      'https://twitter.com/brunomars?fbclid=IwAR0dFGgCSmr_rVTyHvrSn3-9vjjrsSYJUFXsulRL2ssMjipNopi-eZNOMY0',
    spotify: 'https://open.spotify.com/artist/0du5cEVh5yTK9QJze8zA0C',
    deezer: 'https://www.deezer.com/mx/artist/429675',
    apple: 'https://music.apple.com/mx/artist/bruno-mars/278873078',
    soundcloud: 'https://soundcloud.com/brunomars',
    tidal: 'https://listen.tidal.com/artist/3658521',
    wiki: 'https://www.tiktok.com/@brunomars?lang=es',
    ticketmaster:
      'https://www.ticketmaster.com/bruno-mars-tickets/artist/1466801',
    tiktok: 'https://www.tiktok.com/@brunomars?lang=es',
    napster: 'https://web.napster.com/artist/art.26871501',
  },
  {
    title: 'Britney Spears',
    description:
      'Britney Jean Spears (McComb, Misisipi; 2 de diciembre de 1981) es una cantante, bailarina, compositora, modelo, actriz, diseñadora de modas y empresaria estadounidense. Comenzó a actuar desde niña, a través de papeles en producciones teatrales. Después adquirió fama al participar en el programa de televisión The Mickey Mouse Club.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8FKUB87QCDXkCddEw2uzuxL6PDp-vFCg',
    price: '$50',
    coverImg: 'britneyspears.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://britneyspears.com/',
    youtube: 'https://www.youtube.com/channel/UCgffc95YDBlkGrBAJUHUmXQ',
    facebook: 'https://www.facebook.com/britneyspears',
    instagram: 'https://www.instagram.com/britneyspears/',
    twitter: 'https://twitter.com/britneyspears',
    spotify: 'https://open.spotify.com/artist/26dSoYclwsYLMAKD3tpOr4',
    deezer: 'https://www.deezer.com/mx/artist/483',
    apple: 'https://music.apple.com/mx/artist/britney-spears/217005',
    soundcloud: 'https://soundcloud.com/britneyspears',
    tidal: 'https://listen.tidal.com/artist/1532',
    wiki: 'https://es.wikipedia.org/wiki/Britney_Spears',
    ticketmaster:
      'https://www.ticketmaster.com/britney-spears-tickets/artist/723427',
    tiktok: 'https://www.tiktok.com/@britneyspears?lang=es',
    napster: 'https://us.napster.com/artist/britney-spears',
  },
  {
    title: 'Michael Jackson',
    description:
      'Michael Joseph Jackson (Gary, Indiana, 29 de agosto de 1958-Los Ángeles, 25 de junio de 2009),1​ fue un cantante, compositor, productor y bailarín estadounidense.2​3​4​ Apodado como el Rey del Pop,5​ sus contribuciones y reconocimiento en la historia de la música y el baile durante más de cuatro décadas, así como su publicitada vida personal, lo convirtieron en una figura internacional en la cultura popular. Su música incluye una amplia acepción de géneros como el pop, rhythm and blues (soul y funk), rock, disco y dance, y es reconocido como el «artista musical más exitoso de todos los tiempos» por los Guinness World Records.',
    videourl:
      'https://youtube.com/playlist?list=PLUeQUfurHN6bjde_KttpUONL9C0wjJvGF',
    price: '$50',
    coverImg: 'michaelJackson.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.michaeljackson.com/',
    youtube: 'https://www.youtube.com/channel/UC5OrDvL9DscpcAstz7JnQGA',
    facebook: 'https://www.facebook.com/michaeljackson',
    instagram: 'https://www.instagram.com/michaeljackson/',
    twitter: 'https://twitter.com/michaeljackson',
    spotify: 'https://open.spotify.com/artist/3fMbdgg4jU18AjLCKBhRSm',
    deezer: 'https://www.deezer.com/mx/artist/259',
    apple: 'https://music.apple.com/mx/artist/michael-jackson/32940',
    soundcloud: 'https://soundcloud.com/mjimmortal',
    tidal: 'https://listen.tidal.com/artist/606',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Jackson',
    ticketmaster:
      'https://www.ticketmaster.com/michael-jackson-tribute-tickets/artist/1454599',
    tiktok: 'https://www.tiktok.com/@michael_jackson_08?lang=es',
    napster: 'https://us.napster.com/artist/michael-jackson',
  },
  {
    title: 'Madonna',
    description:
      'Madonna Louise Ciccone, conocida simplemente como Madonna, es una cantante, bailarina, compositora, actriz, empresaria e icono estadounidense. Madonna pasó su infancia en Bay City y en 1978 se mudó a la ciudad de Nueva York para realizar una carrera de danza contemporánea. Después de participar en dos grupos musicales, Breakfast Club y Emmy, en 1982 firmó con Sire Records y lanzó su álbum debut Madonna al año siguiente.',
    videourl:
      'https://youtube.com/playlist?list=PLvHf4SnA7f8uK47aAgk6EzgbNcRxK98LM',
    price: '$50',
    coverImg: 'madonna.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.madonna.com/',
    youtube: 'https://www.youtube.com/c/madonna',
    facebook: 'https://www.facebook.com/madonna',
    instagram: 'https://www.instagram.com/madonna',
    twitter: 'https://twitter.com/madonna',
    spotify: 'https://open.spotify.com/artist/6tbjWDEIzxoDsBA1FuhfPW',
    deezer: 'https://www.deezer.com/mx/artist/290',
    apple: 'https://music.apple.com/mx/artist/madonna/20044',
    soundcloud: 'https://soundcloud.com/madonna',
    tidal: 'https://listen.tidal.com/artist/15545',
    wiki: 'https://es.wikipedia.org/wiki/Madonna',
    ticketmaster: 'https://www.ticketmaster.com/madonna-tickets/artist/768011',
    tiktok: 'https://www.tiktok.com/@madonna?lang=es',
    napster: 'https://us.napster.com/artist/madonna',
  },
  {
    title: 'Prince',
    description:
      'Prince Rogers Nelson (7 de junio de 1958 - 21 de abril de 2016), más comúnmente conocido como Prince , fue un cantautor, músico y productor discográfico estadounidense. Ganador de numerosos premios y nominaciones , es ampliamente considerado como uno de los mejores músicos de su generación. [3] Era conocido por su personalidad extravagante y andrógina ; [4] [5] su amplio rango vocal, que incluía un falsete de largo alcance y gritos agudos; y su habilidad como multiinstrumentista, a menudo prefiriendo tocar todos o la mayoría de los instrumentos en sus grabaciones. [6] Prince produjo sus propios álbumes, siendo pionero en laSonido de Mineápolis . Su música incorporó una amplia variedad de estilos, incluyendo funk , R&B , rock , new wave , soul , synth-pop , pop , jazz y hip hop ..',
    videourl:
      'https://youtube.com/playlist?list=PLrwXzbX3SWntQDX7LNhvTSVuXU_bujnLw',
    price: '$50',
    coverImg: 'prince.jpeg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl:
      'https://www.prince.com/?fbclid=IwAR0rvAyV-OG2baN0CTL4YwCp9LJe4mKeKFKkKohULZ1Mw2QviE41O0pGOWg',
    youtube: 'https://www.youtube.com/channel/UCv3mNSNjuWldihk1DUdnGtw',
    facebook: 'https://www.facebook.com/prince',
    instagram: 'https://store.prince.com/store',
    twitter: 'https://twitter.com/prince',
    spotify: 'https://open.spotify.com/artist/5a2EaR3hamoenG9rDuVn8j',
    deezer: 'https://www.deezer.com/mx/artist/1878',
    apple: 'https://music.apple.com/us/artist/prince/155814',
    soundcloud: 'https://soundcloud.com/prince',
    tidal: 'https://listen.tidal.com/artist/4847',
    wiki: 'https://es.wikipedia.org/wiki/Prince',
    ticketmaster:
      'https://www.ticketmaster.com/the-prince-experience-tickets/artist/1466441',
    tiktok: 'https://www.tiktok.com/@prince.4.ever?lang=es',
    napster: 'https://us.napster.com/artist/prince',
  },

  {
    title: 'Michael Buble',
    description:
      "Michael Steven Bublé es un cantante, compositor y actor canadiense. Ha ganado diversos premios, incluyendo cuatro Grammy y varios Juno. Los logros obtenidos alcanzaron modestas posiciones en las listas de los Estados Unidos, pero su álbum homónimo estuvo entre los diez primeros del Líbano, del Reino Unido y en su propio país. Pero no fue hasta 2005 en donde se convirtió en todo un éxito comercial en los Estados Unidos con su álbum It's time, del que vendió 8 millones de copias. Está casado con la actriz argentina Luisana Lopilato. Michael también ha aparecido en la serie Rove en cuatro ocasiones..",
    videourl: 'https://youtube.com/playlist?list=PLC54F72FA80FCD5A3',
    price: '$50',
    coverImg: 'michaelbuble.jpeg',
    category: 'Rythm & Blues',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.michaelbuble.com/higher?ref=Typed/Bookmarked',
    youtube: 'https://www.youtube.com/channel/UCHqQruhGENdmWy_oeH1f8QA',
    facebook: 'https://www.facebook.com/MichaelBuble',
    instagram: 'https://www.instagram.com/michael.buble_official/',
    twitter: 'https://twitter.com/MichaelBuble',
    spotify: 'https://open.spotify.com/artist/1GxkXlMwML1oSg5eLPiAz3',
    deezer: 'https://www.deezer.com/mx/artist/1478',
    apple: 'https://music.apple.com/mx/artist/michael-bubl%C3%A9/799597',
    soundcloud: 'https://soundcloud.com/michaelbuble',
    tidal: 'https://listen.tidal.com/artist/12263',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Bubl%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/michael-buble-tickets/artist/869115',
    napster: 'https://web.napster.com/artist/art.61479',
    tiktok: 'https://www.tiktok.com/@michaelbuble?lang=es',
  },

  {
    title: 'Shakira',
    description:
      'Shakira Isabel Mebarak Ripoll (Barranquilla, Atlántico, Colombia; 2 de febrero de 1977), conocida simplemente como Shakira, es una cantautora, bailarina, actriz, embajadora de buena voluntad de UNICEF y empresaria colombiana.',
    videourl: 'https://youtube.com/playlist?list=PL4324C6BD2E9BA7F8',
    price: 50,
    coverImg: 'Shakira.jpg',
    category: 'Pop',
    location: 'Colombia',
    locationImg: 'coloflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.shakira.com/',
    youtube: 'https://www.youtube.com/results?search_query=Shakira',
    facebook: 'https://www.facebook.com/shakira',
    instagram: 'https://www.instagram.com/shakira/',
    twitter: 'https://www.facebook.com/shakira',
    spotify: 'https://open.spotify.com/artist/0EmeFodog0BfCgMzAIvKQp',
    deezer: 'https://www.deezer.com/mx/artist/160',
    apple: 'https://music.apple.com/mx/artist/shakira/889327',
    soundcloud: 'https://soundcloud.com/shakira',
    tidal: 'https://listen.tidal.com/artist/1087',
    wiki: 'https://es.wikipedia.org/wiki/Shakira',
    ticketmaster: 'https://www.ticketmaster.com./shakira-tickets/artist/779049',
    tiktok: 'https://www.tiktok.com/@shakira?lang=es',
    napster: 'https://app.napster.com/artist/shakira',
  },
  {
    title: 'Becky G',
    description:
      'Rebbeca Marie Gómez1​ (Inglewood, California; 2 de marzo de 1997),​ conocida artísticamente como Becky G, es una cantante y actriz estadounidense de origen mexicano. Obtuvo reconocimiento por primera vez en 2011 cuando comenzó a publicar videos de ella misma interpretando canciones populares en YouTube. Uno de sus videos llamó la atención del productor Dr. Luke, quien posteriormente le ofreció un contrato discográfico conjunto con Kemosabe Records y RCA Records. Mientras trabajaba en su debut, Gómez colaboró ​​​​con los artistas will.i.am, Cody Simpson y Cher Lloyd. Su sencillo debut oficial, «Becky from the Block» (2013), recibió una recepción positiva tras su lanzamiento. Lanzó su EP debut, Play It Again (2013), más tarde ese mismo año. Su segundo sencillo, Cant Get Enough» (2014), contó con la voz invitada de Pitbull y llegó a lo más alto de la lista Latin Rhythm Airplay en los Estados Unidos..',
    videourl:
      'https://youtube.com/playlist?list=PLAE2PW67Xm8biSTHd94VEUmvu7pbAnRDe',
    price: '$50',
    coverImg: 'beckyg.jpg',
    category: 'Pop Latino',
    location: 'Mexico',
    locationImg: 'mexiflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.iambeckyg.com/',
    youtube: 'https://www.youtube.com/channel/UCgtNC51EUSgcZ6kKyVoPxKA',
    facebook: 'https://www.facebook.com/iambeckyg',
    instagram: 'https://www.instagram.com/iambeckyg/',
    twitter: 'https://twitter.com/iambeckyg',
    spotify:
      'https://open.spotify.com/artist/4obzFoKoKRHIphyHzJ35G3?si=3aYrWCo9RIemtKGtcwyDWA&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/7218',
    apple: 'https://music.apple.com/us/artist/becky-g/550411604',
    soundcloud: 'https://soundcloud.com/iambeckyg',
    tidal: 'https://listen.tidal.com/artist/4903321',
    wiki: 'https://es.wikipedia.org/wiki/Becky_G',
    ticketmaster:
      'https://www.ticketmaster.com.mx/becky-g-boletos/artist/1896954',
    tiktok: 'https://www.tiktok.com/@iambeckyg',
    napster: 'https://music.amazon.com.mx/artists/B0088V0L5W/becky-g',
  },
  {
    title: 'Post Malone',
    description:
      'Austin Richard Post (Siracusa, Nueva York, Estados Unidos; 4 de julio de 1995), más conocido por su nombre artístico Post Malone, es un cantante y compositor estadounidense.1​ Ganó un gran reconocimiento en agosto de 2015 gracias al sencillo debut «White Iverson». Más adelante pasó a lanzar «Congratulations» y «Rockstar», que, respectivamente, alcanzaron los números 8 y 1 en el Billboard Hot 100, de los Estados Unidos. Posteriormente consiguió un contrato discográfico con Republic Records.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlUKA-troAfBM-xSZzJAz5Uw',
    price: '$50',
    coverImg: 'postmalone.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UCeLHszkByNZtPKcaVXOCOQQ',
    facebook: 'https://www.facebook.com/postmalone',
    instagram: 'https://www.instagram.com/postmalone/',
    twitter: 'https://twitter.com/postmalone',
    spotify: 'https://open.spotify.com/artist/246dkjvS1zLTtiykXe5h60',
    deezer: 'https://www.deezer.com/mx/artist/7543848',
    apple: 'https://music.apple.com/mx/artist/post-malone/966309175',
    soundcloud: 'https://soundcloud.com/postmalone',
    tidal: 'https://listen.tidal.com/artist/6521080',
    wiki: 'https://es.wikipedia.org/wiki/Post_Malone',
    ticketmaster:
      'https://www.ticketmaster.com/post-malone-tickets/artist/2119390',
    tiktok: 'https://www.tiktok.com/@postmalone?lang=es',
    napster: 'https://us.napster.com/artist/post-malone',
  },
  {
    title: 'The Weeknd',
    description:
      'Abel Makkonen Tesfaye (Toronto, Ontario; 16 de febrero de 1990), conocido por su nombre artístico The Weeknd, es un cantante, compositor y productor canadiense, conocido por éxitos como «Save Your Tears», «Blinding Lights», «The Hills», «Starboy», «Earned It», entre otros.Empezó a ganar reconocimiento a finales de 2010, cuando subió de forma anónima varias canciones a YouTube bajo el nombre de The Weeknd. Durante el 2011 lanzó tres mixtapes de nueve pistas, titulados House of Balloons, Thursday y Echoes of Silence, que fueron muy aclamados por la crítica.1​ En el siguiente año, lanzó su primer trabajo recopilatorio titulado Trilogy, con treinta pistas remasterizadas de sus mixtapes y tres canciones más. Fue lanzado al mercado por la compañías discográficas Republic Records y XO..',
    videourl:
      'https://youtube.com/playlist?list=PLK2zhq9oy0K6rjySCH1nARTssbv8m2Kfm',
    price: '$50',
    coverImg: 'theweeknd.jpg',
    category: 'Pop Americano',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://www.postmalone.com/',
    youtube: 'https://www.youtube.com/channel/UC0WP5P-ufpRfjbNrmOWwLBQ',
    facebook: 'https://www.facebook.com/theweeknd',
    instagram: 'https://www.instagram.com/theweeknd/',
    twitter: 'https://twitter.com/theweeknd',
    spotify: 'https://open.spotify.com/artist/1Xyo4u8uXC1ZmMpatF05PJ',
    deezer: 'https://www.deezer.com/mx/artist/4050205',
    apple: 'https://music.apple.com/mx/artist/the-weeknd/479756766',
    soundcloud: 'https://soundcloud.com/theweeknd',
    tidal: 'https://listen.tidal.com/artist/4761957',
    wiki: 'https://es.wikipedia.org/wiki/The_Weeknd',
    ticketmaster:
      'https://www.ticketmaster.com/the-weeknd-tickets/artist/1697014',
    tiktok: 'https://www.tiktok.com/@theweeknd?lang=es',
    napster: 'https://us.napster.com/artist/the-weeknd',
  },
  {
    title: 'Glass Animals',
    description:
      'Glass Animals es una banda inglesa de indie rock originaria de Oxford. Sus miembros son Dave Bayley (vocalista principal, guitarra), Drew MacFarlane (guitarra, teclado, voces), Edmund Irwin-Singer (bajo, teclado, voces), y Joe Seaward (batería).1​ El grupo liberó su álbum debut Zaba en junio de 2014, bajo el sello Wolf Tone, del productor Paul Epworth, mientras que su segundo álbum How to Be a Human Being fue lanzado el 26 de agosto de 2016, siendo aclamado por la crítica. Su tercer álbum, Dreamland fue estrenado en agosto de 2020. La banda ha realizado tours internacionalmente y se ha presentado en festivales como Austin City Limits, Summer Well, Glastonbury, Coachella, Bonnaroo, Lollapalooza, Reading/Leeds, Falls Festival (Australia), Firefly Music Festival,2​ St Jerome Laneway Festival, Southbound y Bestival.',
    videourl:
      'https://youtube.com/playlist?list=PL8tkzXKlhGxma29yf8aCx8F65q62pVtJZ',
    price: 50,
    coverImg: 'glassanimals.jpeg',
    category: 'Pop',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'https://opensource.glassanimals.com/',
    youtube: 'https://www.youtube.com/channel/UCJTs-KheOMNstaGrDL4K55Q',
    facebook: 'https://www.facebook.com/glassanimals',
    instagram: 'https://www.instagram.com/glassanimals/',
    twitter: 'https://twitter.com/glassanimals',
    spotify: 'https://open.spotify.com/artist/4yvcSjfu4PC0CYQyLy4wSq',
    deezer: 'https://www.deezer.com/mx/artist/2489131',
    apple: 'https://music.apple.com/mx/artist/glass-animals/528928008',
    soundcloud: 'https://soundcloud.com/glassanimals',
    tidal: 'https://listen.tidal.com/artist/4611746',
    wiki: 'https://es.wikipedia.org/wiki/Glass_Animals',
    ticketmaster:
      'https://www.ticketmaster.com./glass-animals-tickets/artist/1916866',
    tiktok: 'https://www.tiktok.com/@glassanimalsofficial?lang=es',
    napster: 'https://us.napster.com/artist/glass-animals',
  },
  {
    title: 'Rihanna',
    description:
      'Robyn Rihanna Fenty, conocida simplemente como Rihanna, es una cantante, empresaria, diseñadora de moda, actriz, diplomática, escritora y bailarina barbadense. Es conocida por fusionar algunos géneros caribeños con música pop y por reinventar su imagen a través de los años. Su impacto en la cultura popular la ha llevado a convertirse en un icono de la música y de la moda, por lo que se refieren a ella como la «Princesa del R&B» y «Reina de la Moda». Rihanna es además considerada la artista musical más influyente y exitosa del siglo XXI.',
    videourl:
      'https://youtube.com/playlist?list=PL-8M5stZkYjoIoESZb5jRfOs1mWHS4LPH',
    price: '$50',
    coverImg: 'Rihanna.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: null,
    websiteurl: 'http://www.rihannanow.com/',
    youtube: 'https://www.youtube.com/channel/UCcgqSM4YEo5vVQpqwN-MaNw',
    facebook: 'https://www.facebook.com/rihannaitalia',
    instagram: 'https://www.instagram.com/BadGalRiRi/',
    twitter: 'https://twitter.com/rihanna',
    spotify: 'https://open.spotify.com/artist/5pKCCKE2ajJHZ9KAiaK11H',
    deezer: 'https://www.deezer.com/mx/artist/564',
    apple: 'https://music.apple.com/mx/artist/rihanna/63346553',
    soundcloud: 'https://soundcloud.com/rihanna',
    tidal: 'https://listen.tidal.com/artist/10665',
    wiki: 'https://es.wikipedia.org/wiki/Rihanna',
    ticketmaster: 'https://www.ticketmaster.com/rihanna-tickets/artist/1013826',
    tiktok: 'https://www.tiktok.com/@rihanna?lang=es',
    napster: 'https://us.napster.com/artist/rihanna',
  },

]

export default CardData4
