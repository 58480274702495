export const MenuItems2 = [
    {
      title: 'Auditorio Telmex',
      path: '/auditoriotelmex',
      cName: 'dropdown-link'
    },
    {
      title: 'C3 Stage',
      path: '/Ctres',
      cName: 'dropdown-link'
    },
    {
      title: 'Teatro Diana',
      path: '/teatrodiana',
      cName: 'dropdown-link'
    },
    {
      title: 'Estadio Tres de Marzo',
      path: '/tresdemarzo',
      cName: 'dropdown-link'
    },
  ];