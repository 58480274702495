const CardData29 = [
  {
    title: 'The Black Eyed Peas',
    description:
      'Black Eyed Peas es una banda estadounidense de hip hop/pop formada en 1995 en Los Ángeles, California. Han sido ganadores del premio Grammy seis veces. El grupo está actualmente integrado por los vocalistas Will.i.am, Apl.de.Ap, Taboo y J. Rey Soul. Fergie fue la vocalista femenina hasta su salida en 2017.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nl-K4EFsySnvvBanoKeMSw7Sjgcr94oWo',
    price: 125,
    coverImg: 'TheBlackEyedPeas.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://bandera.netlify.app/',
    youtube: 'https://www.youtube.com/channel/UCBFaOy1_APEXEyA6Gws_Y1g',
    facebook: 'https://www.facebook.com/blackeyedpeas/',
    instagram: 'https://www.instagram.com/bep/',
    twitter: 'https://twitter.com/bep',
    spotify: 'https://open.spotify.com/artist/1yxSLGMDHlW21z4YXirZDS',
    deezer: 'https://www.deezer.com/mx/artist/1020109',
    apple: 'https://music.apple.com/mx/artist/black-eyed-peas/360391',
    wiki: 'https://es.wikipedia.org/wiki/Black_Eyed_Peas',
    soundcloud: 'https://soundcloud.com/black-eyed-peas-official',
    tidal: 'https://listen.tidal.com/artist/7806',
    ticketmaster:
      'https://www.ticketmaster.ie/the-black-eyed-peas-co-galway-06-04-2022/event/18005B789FC5528A',
    tiktok: 'https://www.tiktok.com/@blackeyedpeas?lang=es',
    napster: 'https://mx.napster.com/artist/the-black-eyed-peas',
  },
  {
    title: 'Sade',
    description:
      'Helen Folasade Adu Ibadán, 16 de enero de 1959), más conocida como Sade, es una cantante y compositora nigeriana nacionalizada británica Durante su adolescencia no paraba de escuchar discos de Nina Simone, Peggy Lee y Astrud Gilberto.Sus estudios de diseño la llevaron a abrir una boutique, pero su amor por la música la llevó a cantar en un grupo funky de cierto carácter latino que respondía al nombre de Arriba. Desde ese momento descubrió un extraño placer al escribir letras. Más tarde, esta banda pasó a llamarse Pride, y luego cambió a Sade, que es una abreviatura de Folasade. Y con Sade comenzó a conocerse también a su vocalista..',

    videourl:
      'https://youtube.com/playlist?list=PL7A67YeJT9U7oMK2xMU5JJcEj6vwQHcEt',
    price: '$50',
    coverImg: 'sade.jpg',
    category: 'Soul',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sade.com/',
    youtube: 'https://www.youtube.com/channel/UCegKDBFMUSQNeWe4FAB7aig',
    facebook: 'https://www.facebook.com/sadeofficial',
    instagram: 'https://www.instagram.com/sade/',
    twitter: 'https://twitter.com/SadeOfficial',
    spotify: 'https://open.spotify.com/artist/47zz7sob9NUcODy0BTDvKx',
    deezer: 'https://www.deezer.com/mx/artist/202',
    apple: 'https://music.apple.com/mx/artist/sade/475591',
    soundcloud: 'https://soundcloud.com/sadeofficial',
    tidal: 'https://listen.tidal.com/artist/485',
    wiki: 'https://es.wikipedia.org/wiki/Sade_Adu',
    ticketmaster: 'https://www.ticketmaster.com/sade-tickets/artist/736033',
    napster: 'https://music.amazon.com.mx/artists/B000QKKF3G/sade',
    tiktok: 'https://www.tiktok.com/@sadeaduwife',
  },
  {
    title: 'Calvin Harris',
    description:
      'Adam Richard Wiles (Dumfries, Dumfries y Galloway, 17 de enero de 1984), más conocido como Calvin Harris, es un DJ, cantante y productor británico. Actualmente ocupa el puesto 16 de los 100 mejores DJ de la revista DJ Magazine. Su álbum debut, seleccionado por la Industria Fonográfica Británica, I Created Disco, fue lanzado en 2007 y contenía sencillos tales como Acceptable in the 80s y The Girls que ingresaron en el Top 10 del Reino Unido.',
    videourl:
      'https://youtube.com/playlist?list=PLCnkz7la5RxMxu9cbTxpXhYrwWDrweJVA',
    price: '$50',
    coverImg: 'calvinharris.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://loveregenerator.com/',
    youtube: 'https://www.youtube.com/channel/UCIjYyZxkFucP_W-tmXg_9Ow',
    facebook: 'https://www.facebook.com/search/top?q=calvin%20harris',
    instagram: 'https://www.instagram.com/calvinharris/',
    twitter: 'https://twitter.com/CalvinHarris',
    spotify: 'https://open.spotify.com/artist/7CajNmpbOovFoOoasH2HaY',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/calvin-harris/201955086',
    soundcloud: 'https://soundcloud.com/calvinharris',
    tidal: 'https://listen.tidal.com/artist/8401',
    wiki: 'https://es.wikipedia.org/wiki/Calvin_Harris',
    ticketmaster:
      'https://www.ticketmaster.com/calvin-harris-tickets/artist/1149552',
    napster: 'https://web.napster.com/artist/art.13622534',
    tiktok: 'https://www.tiktok.com/@calvinharris?lang=es',
  },

  {
    title: 'Harry Styles',
    description:
      'Harry Edward Styles es un cantante, compositor y actor británico. Inició su carrera como cantante en 2010 como integrante de la boy band One Direction, con la que participó en el programa The X Factor y quedó en tercer lugar. Pese a no ganar, la agrupación firmó un contrato discográfico con el sello Syco, con el que publicaron los álbumes Up All Night, Take Me Home, Midnight Memories, Four y Made in the A.M., los cuales alcanzaron la primera posición en las listas de los más vendidos en numerosos países, entre estos los Estados Unidos y el Reino Unido, además de registrar altas ventas.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kzrF1uoBy1nqk215mLiPzzhUnc_hfFs40',
    price: '$50',
    coverImg: 'harrystyles.jpg',
    category: 'Pop',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.hstyles.co.uk/',
    youtube: 'https://www.youtube.com/c/HarryStyles',
    facebook: 'https://www.facebook.com/harrystyles',
    instagram: 'https://www.instagram.com/harrystyles/',
    twitter: 'https://twitter.com/Harry_Styles',
    spotify: 'https://open.spotify.com/artist/6KImCVD70vtIoJWnq6nGn3',
    deezer: 'https://www.deezer.com/mx/artist/5313805',
    apple: 'https://music.apple.com/mx/artist/harry-styles/471260289',
    soundcloud: 'https://soundcloud.com/harrystyles',
    tidal: 'https://listen.tidal.com/artist/8669861',
    wiki: 'https://es.wikipedia.org/wiki/Harry_Styles',
    ticketmaster:
      'https://www.ticketmaster.com/harry-styles-tickets/artist/2366444',
    napster: 'https://web.napster.com/artist/art.146985808',
    tiktok: 'https://www.tiktok.com/@officialharry?lang=es',
  },
  {
    title: 'Sam Smith',
    description:
      'Samuel Frederick Smith, cuyo nombre artístico es Sam Smith, es una celebridad británica profesional en el canto y la composición. En 2019, Smith se declaró de género no binario y tener los pronombres they/them en inglés.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nrECM3EEps3lyUkyO8L3uQTJsclWhcYlM',
    price: '$50',
    coverImg: 'samsmith.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://samsmithworld.com/',
    youtube: 'https://www.youtube.com/channel/UCvpDeGlR5wLP9Z3Tb6K0Xfg',
    facebook: 'https://www.facebook.com/samsmithworld',
    instagram: 'https://www.instagram.com/samsmith/',
    twitter: 'https://twitter.com/samsmith',
    spotify: 'https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI',
    deezer: 'https://www.deezer.com/mx/artist/1097709',
    apple: 'https://music.apple.com/mx/artist/sam-smith/156488786',
    soundcloud: 'https://soundcloud.com/samsmithworld',
    tidal: 'https://listen.tidal.com/artist/4306587',
    wiki: 'https://es.wikipedia.org/wiki/Sam_Smith',
    ticketmaster:
      'https://www.ticketmaster.com/sam-smith-tickets/artist/1785301',
    napster: 'https://web.napster.com/artist/art.21025078',
    tiktok: 'https://www.tiktok.com/@samsmith?lang=es',
  },
  {
    title: 'John Legend',
    description:
      "John Roger Stephens, conocido como John Legend, es un cantante, compositor, pianista y actor estadounidense. Ha ganado once premios Grammy, un Premio Globo de Oro y un Oscar. En 2007, Legend recibió el Premio 'Hal David Starlight'  del Salón de la Fama de los Compositores.",
    videourl:
      'https://youtube.com/playlist?list=PL5odlUFMuUzzKmkiEuqFk3YFBossORfYB',
    price: '$50',
    coverImg: 'johnlegend.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.johnlegend.com/',
    youtube: 'https://www.youtube.com/channel/UCEa-JnNdYCIFn3HMhjGEWpQ',
    facebook: 'https://www.facebook.com/johnlegend',
    instagram: 'https://www.instagram.com/johnlegend_fc/',
    twitter: 'https://twitter.com/johnlegend',
    spotify: 'https://open.spotify.com/artist/5y2Xq6xcjJb2jVM54GHK3t',
    deezer: 'https://www.deezer.com/mx/artist/313',
    apple: 'https://music.apple.com/mx/artist/john-legend/16586443',
    soundcloud: 'https://soundcloud.com/johnlegend',
    tidal: 'https://listen.tidal.com/artist/490',
    wiki: 'https://es.wikipedia.org/wiki/John_Legend',
    ticketmaster:
      'https://www.ticketmaster.com/john-legend-tickets/artist/936461',
    napster: 'https://web.napster.com/artist/art.6432383',
    tiktok: 'https://www.tiktok.com/@johnlegend570?lang=es',
  },
  {
    title: 'Justin Timberlake',
    description:
      "Justin Randall Timberlake (Memphis; 31 de enero de 1981) es un cantante, compositor, productor discográfico, actor, bailarín y empresario estadounidense. Criado en la comunidad de Shelby Forest, ubicada entre Memphis y Millington, comenzó su carrera como cantante en los programas de televisión Star Search y Mickey Mouse Club. A finales de los años 1990 adquirió fama internacional al pertenecer a la boy band 'N Sync, cuyos dos primeros álbumes fueron certificados con disco de diamante tras vender más de 10 millones de copias solo en los Estados Unidos, además de haber alcanzado la cima de numerosos listados alrededor del mundo",
    videourl:
      'https://youtube.com/playlist?list=PLOivmTxGQl85Wfn2671PhKUbNWLYqBW14',
    price: '$50',
    coverImg: 'justintimberlake.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://justintimberlake.com/',
    youtube: 'https://www.youtube.com/channel/UC-y8ci7xfsu4L3zkSuHae0A',
    facebook: 'https://www.facebook.com/justintimberlake',
    instagram: 'https://www.instagram.com/justintimberlake/',
    twitter: 'https://twitter.com/jtimberlake',
    spotify: 'https://open.spotify.com/artist/31TPClRtHm23RisEBtV3X7',
    deezer: 'https://www.deezer.com/mx/artist/1147',
    apple: 'https://music.apple.com/mx/artist/justin-timberlake/398128',
    soundcloud: 'https://soundcloud.com/justintimberlake',
    tidal: 'https://listen.tidal.com/artist/1569',
    wiki: 'https://es.wikipedia.org/wiki/Justin_Timberlake',
    ticketmaster:
      'https://www.ticketmaster.com/justin-timberlake-tickets/artist/847841',
    napster: 'https://web.napster.com/artist/art.56237',
    tiktok: 'https://www.tiktok.com/@justintimberlake?lang=es',
  },
  {
    title: 'Bruno Mars',
    description:
      'Peter Gene Hernandez, conocido artísticamente como Bruno Mars, es un cantante, compositor, productor musical y bailarín estadounidense. Mars comenzó a crear música desde una edad muy temprana y actuó en muchos escenarios de su pueblo natal a lo largo de su niñez realizando imitaciones. Se graduó de la escuela secundaria y se mudó a Los Ángeles para proseguir con su carrera musical..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m0ApIZblcRk9r_9DXSyf69qfT6RhNmy0U',
    price: '$50',
    coverImg: 'BrunoMars.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.brunomars.com/',
    youtube: 'https://www.youtube.com/channel/UCoUM-UJ7rirJYP8CQ0EIaHA',
    facebook: 'https://www.facebook.com/brunomars',
    instagram: 'https://www.instagram.com/brunomars/?hl=en',
    twitter:
      'https://twitter.com/brunomars?fbclid=IwAR0dFGgCSmr_rVTyHvrSn3-9vjjrsSYJUFXsulRL2ssMjipNopi-eZNOMY0',
    spotify: 'https://open.spotify.com/artist/0du5cEVh5yTK9QJze8zA0C',
    deezer: 'https://www.deezer.com/mx/artist/429675',
    apple: 'https://music.apple.com/mx/artist/bruno-mars/278873078',
    soundcloud: 'https://soundcloud.com/brunomars',
    tidal: 'https://listen.tidal.com/artist/3658521',
    wiki: 'https://www.tiktok.com/@brunomars?lang=es',
    ticketmaster:
      'https://www.ticketmaster.com/bruno-mars-tickets/artist/1466801',
    tiktok: 'https://www.tiktok.com/@brunomars?lang=es',
    napster: 'https://web.napster.com/artist/art.26871501',
  },
  {
    title: 'Britney Spears',
    description:
      'Britney Jean Spears (McComb, Misisipi; 2 de diciembre de 1981) es una cantante, bailarina, compositora, modelo, actriz, diseñadora de modas y empresaria estadounidense. Comenzó a actuar desde niña, a través de papeles en producciones teatrales. Después adquirió fama al participar en el programa de televisión The Mickey Mouse Club.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_l8FKUB87QCDXkCddEw2uzuxL6PDp-vFCg',
    price: '$50',
    coverImg: 'britneyspears.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://britneyspears.com/',
    youtube: 'https://www.youtube.com/channel/UCgffc95YDBlkGrBAJUHUmXQ',
    facebook: 'https://www.facebook.com/britneyspears',
    instagram: 'https://www.instagram.com/britneyspears/',
    twitter: 'https://twitter.com/britneyspears',
    spotify: 'https://open.spotify.com/artist/26dSoYclwsYLMAKD3tpOr4',
    deezer: 'https://www.deezer.com/mx/artist/483',
    apple: 'https://music.apple.com/mx/artist/britney-spears/217005',
    soundcloud: 'https://soundcloud.com/britneyspears',
    tidal: 'https://listen.tidal.com/artist/1532',
    wiki: 'https://es.wikipedia.org/wiki/Britney_Spears',
    ticketmaster:
      'https://www.ticketmaster.com/britney-spears-tickets/artist/723427',
    tiktok: 'https://www.tiktok.com/@britneyspears?lang=es',
    napster: 'https://us.napster.com/artist/britney-spears',
  },
  {
    title: 'Michael Jackson',
    description:
      'Michael Joseph Jackson (Gary, Indiana, 29 de agosto de 1958-Los Ángeles, 25 de junio de 2009),1​ fue un cantante, compositor, productor y bailarín estadounidense.2​3​4​ Apodado como el Rey del Pop,5​ sus contribuciones y reconocimiento en la historia de la música y el baile durante más de cuatro décadas, así como su publicitada vida personal, lo convirtieron en una figura internacional en la cultura popular. Su música incluye una amplia acepción de géneros como el pop, rhythm and blues (soul y funk), rock, disco y dance, y es reconocido como el «artista musical más exitoso de todos los tiempos» por los Guinness World Records.',
    videourl:
      'https://youtube.com/playlist?list=PLUeQUfurHN6bjde_KttpUONL9C0wjJvGF',
    price: '$50',
    coverImg: 'michaelJackson.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.michaeljackson.com/',
    youtube: 'https://www.youtube.com/channel/UC5OrDvL9DscpcAstz7JnQGA',
    facebook: 'https://www.facebook.com/michaeljackson',
    instagram: 'https://www.instagram.com/michaeljackson/',
    twitter: 'https://twitter.com/michaeljackson',
    spotify: 'https://open.spotify.com/artist/3fMbdgg4jU18AjLCKBhRSm',
    deezer: 'https://www.deezer.com/mx/artist/259',
    apple: 'https://music.apple.com/mx/artist/michael-jackson/32940',
    soundcloud: 'https://soundcloud.com/mjimmortal',
    tidal: 'https://listen.tidal.com/artist/606',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Jackson',
    ticketmaster:
      'https://www.ticketmaster.com/michael-jackson-tribute-tickets/artist/1454599',
    tiktok: 'https://www.tiktok.com/@michael_jackson_08?lang=es',
    napster: 'https://us.napster.com/artist/michael-jackson',
  },
  {
    title: 'Madonna',
    description:
      'Madonna Louise Ciccone, conocida simplemente como Madonna, es una cantante, bailarina, compositora, actriz, empresaria e icono estadounidense. Madonna pasó su infancia en Bay City y en 1978 se mudó a la ciudad de Nueva York para realizar una carrera de danza contemporánea. Después de participar en dos grupos musicales, Breakfast Club y Emmy, en 1982 firmó con Sire Records y lanzó su álbum debut Madonna al año siguiente.',
    videourl:
      'https://youtube.com/playlist?list=PLvHf4SnA7f8uK47aAgk6EzgbNcRxK98LM',
    price: '$50',
    coverImg: 'madonna.jpg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.madonna.com/',
    youtube: 'https://www.youtube.com/c/madonna',
    facebook: 'https://www.facebook.com/madonna',
    instagram: 'https://www.instagram.com/madonna',
    twitter: 'https://twitter.com/madonna',
    spotify: 'https://open.spotify.com/artist/6tbjWDEIzxoDsBA1FuhfPW',
    deezer: 'https://www.deezer.com/mx/artist/290',
    apple: 'https://music.apple.com/mx/artist/madonna/20044',
    soundcloud: 'https://soundcloud.com/madonna',
    tidal: 'https://listen.tidal.com/artist/15545',
    wiki: 'https://es.wikipedia.org/wiki/Madonna',
    ticketmaster: 'https://www.ticketmaster.com/madonna-tickets/artist/768011',
    tiktok: 'https://www.tiktok.com/@madonna?lang=es',
    napster: 'https://us.napster.com/artist/madonna',
  },
  {
    title: 'Prince',
    description:
      'Prince Rogers Nelson (7 de junio de 1958 - 21 de abril de 2016), más comúnmente conocido como Prince , fue un cantautor, músico y productor discográfico estadounidense. Ganador de numerosos premios y nominaciones , es ampliamente considerado como uno de los mejores músicos de su generación. [3] Era conocido por su personalidad extravagante y andrógina ; [4] [5] su amplio rango vocal, que incluía un falsete de largo alcance y gritos agudos; y su habilidad como multiinstrumentista, a menudo prefiriendo tocar todos o la mayoría de los instrumentos en sus grabaciones. [6] Prince produjo sus propios álbumes, siendo pionero en laSonido de Mineápolis . Su música incorporó una amplia variedad de estilos, incluyendo funk , R&B , rock , new wave , soul , synth-pop , pop , jazz y hip hop ..',
    videourl:
      'https://youtube.com/playlist?list=PLrwXzbX3SWntQDX7LNhvTSVuXU_bujnLw',
    price: '$50',
    coverImg: 'prince.jpeg',
    category: 'Pop',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.prince.com/?fbclid=IwAR0rvAyV-OG2baN0CTL4YwCp9LJe4mKeKFKkKohULZ1Mw2QviE41O0pGOWg',
    youtube: 'https://www.youtube.com/channel/UCv3mNSNjuWldihk1DUdnGtw',
    facebook: 'https://www.facebook.com/prince',
    instagram: 'https://store.prince.com/store',
    twitter: 'https://twitter.com/prince',
    spotify: 'https://open.spotify.com/artist/5a2EaR3hamoenG9rDuVn8j',
    deezer: 'https://www.deezer.com/mx/artist/1878',
    apple: 'https://music.apple.com/us/artist/prince/155814',
    soundcloud: 'https://soundcloud.com/prince',
    tidal: 'https://listen.tidal.com/artist/4847',
    wiki: 'https://es.wikipedia.org/wiki/Prince',
    ticketmaster:
      'https://www.ticketmaster.com/the-prince-experience-tickets/artist/1466441',
    tiktok: 'https://www.tiktok.com/@prince.4.ever?lang=es',
    napster: 'https://us.napster.com/artist/prince',
  },
  {
    title: 'Tina Turner',
    description:
      'Anna Mae Bullock, más conocida como Tina Turner, es una cantante, compositora, bailarina, actriz y coreógrafa de nacionalidad suiza y origen estadounidense, cuya carrera se desarrolló durante más de cincuenta años, además de ser una de las principales y mayores componentes de rock siendo considerada como la «Reina del Rock». Se retiró del escenario y la música en 2013 a la edad de setenta y tres años después de una carrera musical de cincuenta y cuatro años.',
    videourl:
      'https://youtube.com/playlist?list=PLGRnTVgjhDFKabSWJfU4s-pkLwTz6jVJP',
    price: '$50',
    coverImg: 'TinaTurner.jpg',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.tinaturnerofficial.com/',
    youtube: 'https://www.youtube.com/channel/UCfBkblXPcSNt784AZz5J6AQ',
    facebook: 'https://www.facebook.com/TinaTurner/',
    instagram: 'https://www.instagram.com/tinaturner/?hl=en',
    twitter: 'https://twitter.com/LoveTinaTurner',
    spotify:
      'https://open.spotify.com/artist/1zuJe6b1roixEKMOtyrEak?si=vmbXj7mUSjWUXe6AujvE8g&nd=1',
    deezer: 'https://www.deezer.com/mx/artist/1454',
    apple: 'https://music.apple.com/mx/artist/tina-turner/488075',
    soundcloud: 'https://soundcloud.com/tina-turner-official',
    tidal: 'https://listen.tidal.com/artist/9214',
    wiki: 'https://es.wikipedia.org/wiki/Tina_Turner',
    ticketmaster:
      'https://www.ticketmaster.com/tina-turner-tickets/artist/736358',
    tiktok: 'https://www.tiktok.com/@tina.turner.love?lang=es',
    napster: 'https://us.napster.com/artist/tina-turner',
  },
  {
    title: 'Michael Buble',
    description:
      "Michael Steven Bublé es un cantante, compositor y actor canadiense. Ha ganado diversos premios, incluyendo cuatro Grammy y varios Juno. Los logros obtenidos alcanzaron modestas posiciones en las listas de los Estados Unidos, pero su álbum homónimo estuvo entre los diez primeros del Líbano, del Reino Unido y en su propio país. Pero no fue hasta 2005 en donde se convirtió en todo un éxito comercial en los Estados Unidos con su álbum It's time, del que vendió 8 millones de copias. Está casado con la actriz argentina Luisana Lopilato. Michael también ha aparecido en la serie Rove en cuatro ocasiones..",
    videourl: 'https://youtube.com/playlist?list=PLC54F72FA80FCD5A3',
    price: '$50',
    coverImg: 'michaelbuble.jpeg',
    category: 'Rythm & Blues',
    location: 'Canada',
    locationImg: 'canada.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.michaelbuble.com/higher?ref=Typed/Bookmarked',
    youtube: 'https://www.youtube.com/channel/UCHqQruhGENdmWy_oeH1f8QA',
    facebook: 'https://www.facebook.com/MichaelBuble',
    instagram: 'https://www.instagram.com/michael.buble_official/',
    twitter: 'https://twitter.com/MichaelBuble',
    spotify: 'https://open.spotify.com/artist/1GxkXlMwML1oSg5eLPiAz3',
    deezer: 'https://www.deezer.com/mx/artist/1478',
    apple: 'https://music.apple.com/mx/artist/michael-bubl%C3%A9/799597',
    soundcloud: 'https://soundcloud.com/michaelbuble',
    tidal: 'https://listen.tidal.com/artist/12263',
    wiki: 'https://es.wikipedia.org/wiki/Michael_Bubl%C3%A9',
    ticketmaster:
      'https://www.ticketmaster.com/michael-buble-tickets/artist/869115',
    napster: 'https://web.napster.com/artist/art.61479',
    tiktok: 'https://www.tiktok.com/@michaelbuble?lang=es',
  },
  {
    title: 'Alicia Keys',
    description:
      'Alicia Augello Cook (Nueva York, 25 de enero de 1981) conocida como Alicia Keys, es una cantante compositora, productora musical y actriz estadounidense. Ha vendido más de 75 millones de discos a nivel mundial y ha ganado numerosos premios, incluyendo 16 Grammys, 17 Premios Billboard y tres American Music Awards. Su álbum debut Songs in A Minor (2001) ganó cinco Grammys.',
    videourl:
      'https://youtube.com/playlist?list=PL9_UKVdymCPeiMreTKiHFcKIoXA9_moVN',
    price: '$50',
    coverImg: 'aliciakeys.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.aliciakeys.com/',
    youtube: 'https://www.youtube.com/channel/UCK5X3f0fxO4YnVKVZP8p6hg',
    facebook: 'https://www.facebook.com/aliciakeys/',
    instagram: 'https://www.instagram.com/aliciakeys/',
    twitter: 'https://twitter.com/aliciakeys',
    spotify: 'https://open.spotify.com/artist/3DiDSECUqqY1AuBP8qtaIa',
    deezer: 'https://www.deezer.com/mx/artist/228',
    apple: 'https://music.apple.com/mx/artist/alicia-keys/316069',
    soundcloud: 'https://soundcloud.com/aliciakeys',
    tidal: 'https://listen.tidal.com/artist/1552',
    wiki: 'https://es.wikipedia.org/wiki/Alicia_Keys',
    ticketmaster:
      'https://www.ticketmaster.com/alicia-keys-tickets/artist/807171',
    tiktok: 'https://www.tiktok.com/@aliciakeys',
    napster: 'https://music.amazon.com.mx/artists/B000RHRHPM/alicia-keys',
  },
  {
    title: 'Keith Sweat',
    description:
      "Keith Douglas Sweat (born July 22, 1961) is an American singer, songwriter, record producer, and an early figure in the new jack swing musical movement. He is known for his collection of hits including I Want Her, Make It Last Forever, I'll Give All My Love to You, Make You Sweat, Get Up on It, Twisted and Nobody. He has released 13 solo albums (2 as a part of the R&B supergroup LSG) and discovered the groups Silk and Kut Klose",
    videourl:
      'http://youtube.com/playlist?list=OLAK5uy_nlB9yG-3k_bPWvyqGZ_H4SMjqb2MZIiuA',
    price: '$50',
    coverImg: 'keithSweat.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://thesweathotel.iheart.com/?pname=thesweathotel.com&sc=dnsredirect',
    youtube: 'https://www.youtube.com/channel/UCcakvzPhugt3zkRPON_mXNw',
    facebook: 'https://www.facebook.com/ogkeithsweat/',
    instagram: 'https://www.instagram.com/keithsweat/',
    twitter: 'https://twitter.com/OGKeithSweat',
    spotify: 'https://open.spotify.com/artist/2r09Inibex3C4ZNTUVSG3m',
    deezer: 'https://www.deezer.com/mx/artist/6326',
    apple: 'https://music.apple.com/mx/artist/keith-sweat/335680',
    soundcloud: 'https://soundcloud.com/keithsweatofficial',
    tidal: 'https://listen.tidal.com/artist/14696',
    wiki: 'https://en.wikipedia.org/wiki/Keith_Sweat',
    ticketmaster:
      'https://www.ticketmaster.com/keith-sweat-tickets/artist/736235',

    tiktok: 'https://www.tiktok.com/@keithsweat_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000RSC2HE/keith-sweat',
  },
  {
    title: 'Usher',
    description:
      'Usher Terrence Terry Raymond IV (Dallas, Texas; 14 de octubre de 1978), más conocido como Usher, es un cantante, compositor, bailarín, productor de música y actor estadounidense.Ha vendido más de 75 millones de álbumes y 200 millones de copias a lo largo de su carrera. También es parte del grupo de propietarios del equipo de la NBA Cleveland Cavaliers y tiene su propio sello discográfico llamado US Records..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mc_zIlBg-x0OdrXmJb37eyUXb2RZXHLhY',
    price: '$50',
    coverImg: 'usher.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.usherworld.com/',
    youtube: 'https://www.youtube.com/channel/UCaNrhBiXsXIM2epDl_kEzgQ',
    facebook: 'https://www.facebook.com/usher',
    instagram: 'https://www.instagram.com/usher/',
    twitter: 'https://twitter.com/Usher',
    spotify: 'https://open.spotify.com/artist/23zg3TcAtWQy7J6upgbUnj',
    deezer: 'https://www.deezer.com/mx/artist/17',
    apple: 'https://music.apple.com/mx/artist/usher/15885',
    soundcloud: 'https://soundcloud.com/usher-raymond-music',
    tidal: 'https://listen.tidal.com/artist/1518',
    wiki: 'https://es.wikipedia.org/wiki/Usher',
    ticketmaster: 'https://www.ticketmaster.com/usher-tickets/artist/766722',
    tiktok: 'https://www.tiktok.com/@usher?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000QKPZ04/usher',
  },
  {
    title: 'Beyonce',
    description:
      "Beyoncé Giselle Knowles-Carter (Houston, 4 de septiembre de 1981),​ conocida simplemente como Beyoncé, es una cantautora, productora, actriz, directora, diseñadora y empresaria estadounidense.En su ciudad natal se presentó a diversos concursos de canto y baile cuando era niña y saltó a la fama a finales de los años 1990 como vocalista principal del grupo femenino de R&B, Destiny's Child. Dirigido por su padre, Mathew Knowles, se convirtió en uno de los grupos femeninos con mayores ventas de la historia.",
    videourl:
      'https://youtube.com/playlist?list=PLHFeMk_LSwG5WMlOMrny0r3SQyFYx7W2M',
    price: '$50',
    coverImg: 'beyonce.jpeg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.beyonce.com/',
    youtube: 'https://www.youtube.com/channel/UCuHzBCaKmtaLcRAOoazhCPA',
    facebook: 'https://www.facebook.com/beyonce',
    instagram: 'https://www.instagram.com/beyonce/',
    twitter: 'https://twitter.com/Beyonce',
    spotify: 'https://open.spotify.com/artist/6vWDO969PvNqNYHIOW5v0m',
    deezer: 'https://www.deezer.com/mx/artist/145',
    apple: 'https://music.apple.com/mx/artist/beyonc%C3%A9/1419227',
    soundcloud: 'https://soundcloud.com/beyonce',
    tidal: 'https://listen.tidal.com/artist/1566',
    wiki: 'https://es.wikipedia.org/wiki/Beyonc%C3%A9',
    ticketmaster: 'https://www.ticketmaster.com/beyonce-tickets/artist/894191',
    tiktok: 'https://www.tiktok.com/@beyonce?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000T2D81C/beyonc%C3%A9',
  },
  {
    title: 'Mary J Blige',
    description:
      'Mary Jane Blige (El Bronx, Nueva York, 11 de enero de 1971)​ es una actriz y cantante estadounidense de R&B contemporáneo, de soul y hip-hop, influida por Chaka Khan, Aretha Franklin y Anita Baker entre otras muchas figuras de la música soul.Nació en Nueva York y pasó parte de su infancia en Savannah, para en su juventud volver a Yonkers en su ciudad natal.3​ Cuando vivía en White Plains (Nueva York) se grabó en un karaoke cantando el tema de Anita Baker Caught Up in the Rapture. La grabación resultante fue enviada a Andre Harrel, gerente de Uptown Records, por el padrastro de Mary. Harrel se quedó impresionado por su voz y la contrató para que hiciera los coros a Father MC..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mL4V3aGUV7dbQ6an2fL0Q4DpjlYeN6OKI',
    price: '$50',
    coverImg: 'maryjblige.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.maryjblige.com/',
    youtube: 'https://www.youtube.com/channel/UCj5CgwX_iJEqJsqUJyYjg4A',
    facebook: 'https://www.facebook.com/maryjblige',
    instagram: 'https://www.instagram.com/therealmaryjblige/',
    twitter: 'https://twitter.com/maryjblige',
    spotify: 'https://open.spotify.com/artist/1XkoF8ryArs86LZvFOkbyr',
    deezer: 'https://www.deezer.com/mx/artist/152',
    apple: 'https://music.apple.com/mx/artist/mary-j-blige/1392280',
    soundcloud: 'https://soundcloud.com/officialmaryjblige',
    tidal: 'https://listen.tidal.com/artist/7036',
    wiki: 'https://es.wikipedia.org/wiki/Mary_J._Blige',
    ticketmaster:
      'https://www.ticketmaster.com/mary-j-blige-tickets/artist/767544',
    tiktok: 'https://www.tiktok.com/@maryjblige?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000RW2KGS/mary-j-blige',
  },
  {
    title: 'R Kelly',
    description:
      'Robert Sylvester Kelly (Chicago, Illinois, 8 de enero de 1967),​ más conocido por su nombre artístico R. Kelly, es un cantante, compositor y productor musical. Nativo de Chicago, Kelly comenzó a actuar desde 1980 y debutó en 1992 con el grupo Public Announcement. En 1993, Kelly lanzó su primer álbum como solista con el álbum 12 Play. También se le conoce por una colección de grandes éxitos, incluyendo la mundialmente conocida I Believe I Can Fly, Bump N’ Grind, Your Body’s Callin’, Gotham City, Ignition (Remix), If I Could Turn Back the Hands of Time, The World’s Greatest y I’m a Flirt (Remix). En 1998, ganó tres premios Grammy por I Believe I Can Fly. Su sonido distintivo y estilo ha influido en numerosos artistas de hip hop y R&B contemporáneo. Kelly se convirtió en la primera estrella de música en jugar baloncesto profesional, cuando firmó en 1997.',
    videourl:
      'https://youtube.com/playlist?list=PLF-cRDh2mrlX8juzKzs5THTQLyVpOUogS',
    price: '$50',
    coverImg: 'RKelly.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://r-kelly.com/',
    youtube: 'https://www.youtube.com/channel/UCNRcoQxfabvUAULNbIy7qPg',
    facebook: 'https://www.facebook.com/thepiedpiperofrnb',
    instagram: 'https://www.instagram.com/rkelly.fan.page/',
    twitter: 'https://twitter.com/Ignition_Remix',
    spotify: 'https://open.spotify.com/artist/2mxe0TnaNL039ysAj51xPQ',
    deezer: 'https://www.deezer.com/mx/artist/289831',
    apple: 'https://music.apple.com/mx/artist/r-kelly/140083',
    soundcloud: 'https://soundcloud.com/r-kellyofficial',
    tidal: 'https://listen.tidal.com/artist/1548',
    wiki: 'https://es.wikipedia.org/wiki/R._Kelly',
    ticketmaster: 'https://www.ticketmaster.com/r-kelly-tickets/artist/766948',
    tiktok: 'https://www.tiktok.com/@rkelly595?lang=en',
    napster: 'https://music.amazon.com.mx/artists/B000QJK40Q/r-kelly',
  },
  {
    title: 'Rihanna',
    description:
      'Robyn Rihanna Fenty, conocida simplemente como Rihanna, es una cantante, empresaria, diseñadora de moda, actriz, diplomática, escritora y bailarina barbadense. Es conocida por fusionar algunos géneros caribeños con música pop y por reinventar su imagen a través de los años. Su impacto en la cultura popular la ha llevado a convertirse en un icono de la música y de la moda, por lo que se refieren a ella como la «Princesa del R&B» y «Reina de la Moda». Rihanna es además considerada la artista musical más influyente y exitosa del siglo XXI.',
    videourl:
      'https://youtube.com/playlist?list=PL-8M5stZkYjoIoESZb5jRfOs1mWHS4LPH',
    price: '$50',
    coverImg: 'Rihanna.jpg',
    category: 'Rythm & Blues',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.rihannanow.com/',
    youtube: 'https://www.youtube.com/channel/UCcgqSM4YEo5vVQpqwN-MaNw',
    facebook: 'https://www.facebook.com/rihannaitalia',
    instagram: 'https://www.instagram.com/BadGalRiRi/',
    twitter: 'https://twitter.com/rihanna',
    spotify: 'https://open.spotify.com/artist/5pKCCKE2ajJHZ9KAiaK11H',
    deezer: 'https://www.deezer.com/mx/artist/564',
    apple: 'https://music.apple.com/mx/artist/rihanna/63346553',
    soundcloud: 'https://soundcloud.com/rihanna',
    tidal: 'https://listen.tidal.com/artist/10665',
    wiki: 'https://es.wikipedia.org/wiki/Rihanna',
    ticketmaster: 'https://www.ticketmaster.com/rihanna-tickets/artist/1013826',
    tiktok: 'https://www.tiktok.com/@rihanna?lang=es',
    napster: 'https://us.napster.com/artist/rihanna',
  },
]

export default CardData29
