const CardData22 = [
  {
    title: 'Mozart',
    description:
      'Johannes Chrysostomus Wolfgangus Theophilus Mozarta​ (Salzburgo, 27 de enero de 1756-Viena, 5 de diciembre de 1791), más conocido como Wolfgang Amadeus Mozart, fue un compositor, pianista, director de orquesta y profesor del antiguo Arzobispado de Salzburgo (anteriormente parte del Sacro Imperio Romano Germánico, actualmente parte de Austria), maestro del Clasicismo, considerado como uno de los músicos más influyentes y destacados de la historia. ',
    category: 'Classica',
    videourl: 'https://youtu.be/gpUPMSuXrzI',
    price: 80,
    coverImg: 'mozart.jpeg',
    location: 'Austria',
    locationImg: 'austria.png',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.operaphila.org/whats-on/on-stage-2016-2017/figaro/composer/',
    youtube: 'https://www.youtube.com/@classicalmusic923',
    facebook: 'https://www.facebook.com/groups/111312582236031',
    instagram: 'https://www.instagram.com/mozartclassics/',
    twitter: 'https://twitter.com/MozartQuote',
    spotify: 'https://open.spotify.com/artist/4NJhFmfw43RLBLjQvxDuRS',
    deezer: 'https://www.deezer.com/mx/album/103717472',
    apple: 'https://music.apple.com/mx/search?term=Mozart',
    soundcloud: 'https://soundcloud.com/mozart',
    tidal: 'https://listen.tidal.com/artist/3518587',
    wiki: 'https://es.wikipedia.org/wiki/Wolfgang_Amadeus_Mozart',
    ticketmaster: 'https://www.ticketmaster.com/mozart-tickets/artist/2184326',
    tiktok: 'https://www.tiktok.com/@theoffical_mozart?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000QJON8A/wolfgang-amadeus-mozart',
  },
  {
    title: 'Bethoven',
    description:
      'Ludwig van Beethovena​ (Bonn, Arzobispado de Colonia; 16 de diciembre de 1770b​- Viena, 26 de marzo de 1827) fue un compositor, director de orquesta, pianista y profesor de piano alemán. Su legado musical abarca, cronológicamente, desde el Clasicismo hasta los inicios del Romanticismo. Es considerado uno de los compositores más importantes de la historia de la música y su legado ha influido de forma decisiva en la evolución posterior de este arte.Siendo el último gran representante del clasicismo vienés (después de Christoph Willibald Gluck, Joseph Haydn y Wolfgang Amadeus Mozart), Beethoven consiguió hacer trascender la música del Romanticismo, influyendo en diversidad de obras musicales del siglo xix. Su arte se expresó en numerosos géneros y aunque las sinfonías fueron la fuente principal de su popularidad internacional, su impacto resultó ser principalmente significativo en sus obras para piano y música de cámara.. ',
    category: 'Classica',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_lC2jM_5KCvDIIhvDY3rJu8OLCQA14f5aw',
    price: 80,
    coverImg: 'bethoveen.jpg',
    location: 'Germany',
    locationImg: 'germany.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.biografiasyvidas.com/monografia/beethoven/',
    youtube: 'https://www.youtube.com/channel/UCnsAooIr-Dsr8zJOCSadQcA',
    facebook: 'https://www.facebook.com/BeethovenAndBeyond',
    instagram: 'https://www.instagram.com/ludwig.van.beethoven/',
    twitter: 'https://twitter.com/SaysBeethoven',
    spotify: 'https://open.spotify.com/artist/2wOqMjp9TyABvtHdOSOTUS',
    deezer: 'https://www.deezer.com/mx/artist/6144',
    apple: 'https://music.apple.com/mx/artist/ludwig-van-beethoven/1130028',
    soundcloud: 'https://soundcloud.com/ludwigvanbeethovenofficial',
    tidal:
      'https://listen.tidal.com/playlist/5849ba81-0955-4e5e-bd82-b1b4c5ab3d5e',
    wiki: 'https://es.wikipedia.org/wiki/Ludwig_van_Beethoven',
    ticketmaster:
      'https://www.ticketmaster.com/ludwig-van-beethoven-tickets/artist/2508392',
    tiktok: 'https://www.tiktok.com/@ludwigvanbeethoven?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000QHD644/ludwig-van-beethoven',
  },
  {
    title: 'Pyotr Ilyich Tchaikovsky',
    description:
      "Piotr Ilich Chaikovski (en ruso: Пётр Ильич Чайковский, romanización Piotr Il'ič Čajkovskij, pronunciación Acerca de este sonido /ˈpʲotr ɪlʲˈjit͡ɕ t͡ɕɪjˈkofskʲɪj/ (?·i))nota 1​ (Vótkinsk, 25 de abriljul./ 7 de mayo de 1840greg.-San Petersburgo, 25 de octubrejul./ 6 de noviembre de 1893greg.)nota 2​ fue un compositor ruso del período del Romanticismo. Es autor de algunas de las obras de música clásica más famosas del repertorio actual, como los ballets El lago de los cisnes, La bella durmiente y El cascanueces, la Obertura 1812, la obertura-fantasía Romeo y Julieta, el Primer concierto para piano, el Concierto para violín, sus sinfonías Cuarta, Quinta y Sexta y las óperas Eugenio Oneguin y La dama de picas.. ",
    category: 'Classica',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_miO0tlI6l-SdVsQhhqSJHmCESVcgodmY0',
    price: 80,
    coverImg: 'Tchaikovsky.jpg',
    location: 'Russia',
    locationImg: 'russia.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.biografiasyvidas.com/biografia/c/chaikovski.htm',
    youtube: 'https://www.youtube.com/channel/UC6UJm5ElaATOPzXxNkuLDJg',
    facebook: 'https://www.facebook.com/profile.php?id=100044902903214',
    instagram: 'https://www.instagram.com/pyotr_ilyich_tchaikovsky_/',
    twitter: 'https://twitter.com/Tchaikovskyism',
    spotify:
      'https://open.spotify.com/artist/3MKCzCnpzw3TjUYs2v7vDA?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/309998',
    apple: 'https://music.apple.com/mx/artist/pyotr-ilyich-tchaikovsky/262899',
    soundcloud: 'https://soundcloud.com/pyotr-ilyich-tchaikovsky',
    tidal: 'https://listen.tidal.com/artist/4101908',
    wiki: 'https://es.wikipedia.org/wiki/Piotr_Ilich_Chaikovski',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=concerto',
    tiktok: 'https://www.tiktok.com/@pyotr_ilyich_tchaikovsky?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP7QM',
  },
  {
    title: 'Johann Sebastian Bach',
    description:
      'Johann Sebastian Bach (pronunciado /ˈjoːhan zeˈbasti̯an bax/ ( escuchar); Eisenach, Sacro Imperio Romano Germánico, 21 de marzo de 1685.-Leipzig, Sacro Imperio Romano Germánico, 28 de julio de 1750) fue un compositor, músico, director de orquesta, maestro de capilla, cantor y profesor alemán del período barroco.Fue el miembro más importante de una de las familias de músicos más destacadas de la historia, con más de 35 compositores famosos: la familia Bach. Tuvo una gran fama como organista y clavecinista en toda Europa por su gran técnica y capacidad de improvisar música al teclado. Además del órgano y del clavecín, tocaba el violín y la viola da gamba.. ',
    category: 'Classica',
    videourl: 'https://youtu.be/6JQm5aSjX6g',
    price: 80,
    coverImg: 'sebastianbach.jpeg',
    location: 'Alemania',
    locationImg: 'germany.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://musicaclasica.com.ar/5-obras-geniales-de-johann-sebastian-bach/',
    youtube: 'https://www.youtube.com/channel/UCFtSXTlIMFFkyJbHO3V5b7A',
    facebook: 'https://www.facebook.com/profile.php?id=100072684025794',
    instagram: 'https://www.instagram.com/bachclassicals/',
    twitter: 'https://twitter.com/bachbachbachh',
    spotify:
      'https://open.spotify.com/artist/5aIqB5nVVvmFsvSdExz408?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/1900',
    apple: 'https://music.apple.com/mx/artist/johann-sebastian-bach/11862',
    soundcloud: 'https://soundcloud.com/johann-sebastian-bach-official',
    tidal: 'https://listen.tidal.com/artist/14753',
    wiki: 'https://es.wikipedia.org/wiki/Johann_Sebastian_Bach',
    ticketmaster:
      'https://www.ticketmaster.com/johann-sebastian-bach-tickets/artist/2571286',
    tiktok: 'https://www.tiktok.com/@johannsebastian_bach?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B000QJHKUS/johann-sebastian-bach',
  },
  {
    title: 'Igor Stravinsky',
    description:
      "Ígor Fiódorovich Stravinski (en ruso: Игорь Фёдорович Стравинский; Oranienbaum, 17 de junio de 1882-Nueva York, 6 de abril de 1971) fue un compositor y director de orquesta ruso y uno de los músicos más importantes y trascendentales del siglo XX.Su larga vida le permitió conocer gran variedad de corrientes musicales. Resultan justificadas sus protestas contra quienes lo tildaban de músico del porvenir: «Es algo absurdo. No vivo en el pasado ni en el futuro. Estoy en el presente». En su presente compuso una gran cantidad de obras clásicas abordando varios estilos como el primitivismo, el neoclasicismo y el serialismo, pero es conocido mundialmente sobre todo por tres obras de uno de sus períodos iniciales —el llamado «período ruso»—: El pájaro de fuego (L'Oiseau de feu, 1910), Petrushka (1911) y La consagración de la primavera (Le sacre du printemps, 1913). ",
    category: 'Classica',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kBqd-spbP1HPOL1GwW4W1cTwWUkJTTgDM',
    price: 80,
    coverImg: 'igorstravinsky.jpeg',
    location: 'Russia',
    locationImg: 'russia.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.biografiasyvidas.com/biografia/c/chaikovski.htm',
    youtube: 'https://www.youtube.com/channel/UCELh0PB52k1uiZP91LKW9VA',
    facebook: 'https://www.facebook.com/igorstravinsky',
    instagram: 'https://www.instagram.com/igorstravinsky_/',
    twitter: 'https://twitter.com/Igor_Stravinsky',
    spotify: 'https://open.spotify.com/artist/7ie36YytMoKtPiL7tUvmoE',
    deezer: 'https://www.deezer.com/mx/artist/73571',
    apple: 'https://music.apple.com/mx/artist/igor-stravinsky/239538',
    soundcloud: 'https://soundcloud.com/igorstravinsky-music',
    tidal: 'https://listen.tidal.com/artist/7110',
    wiki: 'https://es.wikipedia.org/wiki/%C3%8Dgor_Stravinski',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=concerto',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=igor%20stravinsky&t=1684535469434',
    napster: 'https://music.amazon.com.mx/artists/B000QJNOXA/igor-stravinsky',
  },
  {
    title: 'Frederic Chopin',
    description:
      'Frédéric François Chopinnota 1​ (en polaco Fryderyk Franciszek Chopin;nota 2​ Żelazowa Wola, Gran Ducado de Varsovia, 1 de marzo1​2​nota 3​ de 1810-París, 17 de octubre de 1849) fue un profesor, compositor y virtuoso pianista polaco, considerado uno de los más importantes de la historia y uno de los mayores representantes del Romanticismo musical.3​4​5​6​ Su maravillosa técnica, su refinamiento estilístico y su elaboración armónica se han comparado históricamente, por su influencia en la música posterior, con las de Wolfgang Amadeus Mozart, Ludwig van Beethoven, Johannes Brahms, Franz Liszt o Serguéi Rajmáninov.[cita requerida La descendencia pedagógica de Chopin ha llegado hasta pianistas como Maurizio Pollini y Alfred Cortot, por medio de Georges Mathias y Emile Descombes, respectivamente.. ',
    category: 'Classica',
    videourl: 'https://youtu.be/wygy721nzRc',
    price: 80,
    coverImg: 'fredericchopin.jpeg',
    location: 'Polonia',
    locationImg: 'poland.png',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://historia.nationalgeographic.com.es/a/frederic-francois-chopin-poeta-piano_17219',
    youtube: 'https://www.youtube.com/channel/UCyTnUReB5s38R-ZKlb2wyVg',
    facebook: 'https://www.facebook.com/fred.chopin',
    instagram: 'https://www.instagram.com/frederic.chopin__/',
    twitter: 'https://twitter.com/FredericChopin',
    spotify: 'https://open.spotify.com/artist/7y97mc3bZRFXzT2szRM4L4',
    deezer: 'https://www.deezer.com/mx/artist/8473',
    apple: 'https://music.apple.com/mx/search?term=fr%C3%A9d%C3%A9ric%20chopin',
    soundcloud: 'https://soundcloud.com/chopin-frederic',
    tidal: 'https://listen.tidal.com/artist/3518588',
    wiki: 'https://es.wikipedia.org/wiki/Fr%C3%A9d%C3%A9ric_Chopin',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=concerto',
    tiktok: 'https://www.tiktok.com/@fredric_chopin?lang=es',
    napster:
      'https://music.amazon.com.mx/artists/B00123YZH8/fr%C3%A9d%C3%A9ric-chopin',
  },
  {
    title: 'Johannes Brahms',
    description:
      'Johannes Brahms (Hamburgo, 7 de mayo de 1833-Viena, 3 de abril de 1897) fue un compositor, pianista y director de orquesta alemán del romanticismo, considerado el más clásico de los compositores de dicho periodo. Nacido en Hamburgo en una familia luterana, pasó gran parte de su vida profesional en Viena.Se mantuvo fiel toda su vida al clasicismo romántico y conservador, influenciado por Wolfgang Amadeus Mozart, Joseph Haydn y, particularmente, por Ludwig van Beethoven y Robert Schumann. Fue posiblemente el mayor representante del círculo conservador en la Guerra de los románticos. Sus oponentes, los progresistas radicales de Weimar, estaban representados por Franz Liszt, los integrantes de la posteriormente llamada Nueva Escuela Alemana y por Richard Wagner. Los escritores contemporáneos y posteriores lo han considerado como tradicionalista e innovador. Su música está firmemente arraigada en las estructuras y técnicas de composición de los maestros clásicos. ... ',
    category: 'Classica',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_k4uF06duCPkvAUDE5N-98EPMAe3ek2OaU',
    price: 80,
    coverImg: 'johannesbrahms.jpeg',
    location: 'Alemania',
    locationImg: 'germany.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.buscabiografias.com/biografia/verDetalle/2976/Johannes%20Brahms',
    youtube: 'https://www.youtube.com/channel/UCJtFbUvNYtWoVPw_xraB6sg',
    facebook: 'https://www.facebook.com/BrahmsHamburg',
    instagram: 'https://www.instagram.com/johannes.brahms/',
    twitter: 'https://twitter.com/J_Brahms1833',
    spotify: 'https://open.spotify.com/artist/5wTAi7QkpP6kp8a54lmTOq',
    deezer: 'https://www.deezer.com/mx/artist/7128',
    apple: 'https://music.apple.com/mx/artist/johannes-brahms/264688',
    soundcloud: 'https://soundcloud.com/johannesbrahms',
    tidal: 'https://listen.tidal.com/artist/3655876',
    wiki: 'https://es.wikipedia.org/wiki/Johannes_Brahms',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=concerto',
    tiktok: 'https://www.tiktok.com/@johannes.brahms?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJZ92/johannes-brahms',
  },
  {
    title: 'Richard Wagner',
    description:
      'Wilhelm Richard Wagner (Leipzig, Reino de Sajonia, Confederación del Rin, 22 de mayo de 1813-Venecia, Reino de Italia, 13 de febrero de 1883) fue un compositor, director de orquesta, poeta, ensayista, dramaturgo y teórico musical alemán del Romanticismo. Destacan principalmente sus óperas (calificadas como «dramas musicales» por el propio compositor) en las que, a diferencia de otros compositores, asumió también el libreto y la escenografía.En sus inicios, fundamentó su reputación como compositor en obras como El holandés errante y Tannhäuser, que seguían la tradición romántica de Weber y Meyerbeer. Transformó el pensamiento musical con la idea de la «obra de arte total» (Gesamtkunstwerk), la síntesis de todas las artes poéticas, visuales, musicales y escénicas, que desarrolló en una serie de ensayos entre 1849 y 1852, y que plasmó en la primera mitad de su monumental tetralogía El anillo del nibelungo.. ',
    category: 'Classica',
    videourl: 'https://youtu.be/F00B56TYWBo',
    price: 80,
    coverImg: 'richardwagner.jpeg',
    location: 'Italy',
    locationImg: 'italy.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://herder.com.mx/es/autores-writers/richard-wagner',
    youtube: 'https://www.youtube.com/channel/UCqRT-aGYojd-in6xTOT2-OQ',
    facebook: 'https://www.facebook.com/groups/28688858078',
    instagram: 'https://www.instagram.com/richard._wagner/',
    twitter: 'https://twitter.com/BostonWagner',
    spotify: 'https://open.spotify.com/artist/1C1x4MVkql8AiABuTw6DgE',
    deezer: 'https://www.deezer.com/mx/artist/10523',
    apple: 'https://music.apple.com/mx/artist/richard-wagner/276997',
    soundcloud: 'https://soundcloud.com/richardwagnerofficial',
    tidal: 'https://listen.tidal.com/artist/3687950',
    wiki: 'https://es.wikipedia.org/wiki/Richard_Wagner',
    ticketmaster: 'https://www.ticketmaster.com.mx/search?q=concerto',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=richard%20wagner&t=1684537863072',
    napster: 'https://music.amazon.com.mx/artists/B000QJPOAG/richard-wagner',
  },
  {
    title: 'Claude Debussy',
    description:
      'Achille Claude Debussya​ (pronunciado /aʃil(ə) klod(ə) dəbysi/; Saint-Germain-en-Laye, 22 de agosto de 1862-París, 25 de marzo de 1918) fue un compositor francés, uno de los más influyentes de finales del siglo xix y principios del xx. Algunos autores lo consideran el primer compositor impresionista, aunque él rechazaba categóricamente el término.Nacido en el seno de una familia de recursos modestos y escasa participación cultural, mostró suficiente talento musical para ser admitido en el mejor centro de estudios musicales de Francia, el Conservatorio de París, a la edad de diez años.. ',
    category: 'Classica',
    videourl: 'https://www.youtube.com/live/UqTC8NJP2pM?si=T7TNTnvcuX2Vj0YC',
    price: 80,
    coverImg: 'claudedebussy.jpeg',
    location: 'France',
    locationImg: 'france.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.buscabiografias.com/biografia/verDetalle/4381/Claude%20Debussy',
    youtube: 'https://www.youtube.com/channel/UCDBe28OI2PycHdECfoaIWtA',
    facebook: 'https://www.facebook.com/ClaudeAchilleDebussy',
    instagram: 'https://www.instagram.com/claude_debussy/',
    twitter: 'https://twitter.com/DebussyHomie',
    spotify: 'https://open.spotify.com/artist/1Uff91EOsvd99rtAupatMP',
    deezer: 'https://www.deezer.com/mx/artist/5176',
    apple: 'https://music.apple.com/mx/artist/claude-debussy/219163',
    soundcloud: 'https://soundcloud.com/claude-debussy',
    tidal: 'https://listen.tidal.com/artist/57418',
    wiki: 'https://es.wikipedia.org/wiki/Claude_Debussy',
    ticketmaster:
      'https://www.ticketmaster.fr/fr/salle/theatre-claude-debussy-maisons-alfort/idsite/9578',
    tiktok: 'https://www.tiktok.com/@claude_debussy70?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJPJB0/claude-debussy',
  },
  {
    title: 'Antonio Vivaldi',
    description:
      'Antonio Vivaldi (Venecia, 4 de marzo de 1678-Viena, 28 de julio de 1741) fue un compositor, violinista, impresario, profesor y sacerdote católico veneciano del Barroco. Era apodado Il prete rosso («El cura rojo») por ser sacerdote y pelirrojo. Se le considera uno de los más grandes compositores barrocos, su influencia durante su vida se extendió por toda Europa y fue fundamental en el desarrollo de la música instrumental de Johann Sebastian Bach. Su maestría se refleja en haber cimentado el género del concierto, el más importante de su época. Compuso unas setecientas setenta obras, entre las cuales se cuentan más de cuatrocientos conciertos, para flauta, violín, y una variedad de otros instrumentos musicales, y cerca de cuarenta y seis óperas. Es especialmente popular como autor de la serie de conciertos para violín y orquesta Las cuatro estaciones... ',
    category: 'Classica',
    videourl: 'https://www.youtube.com/watch?v=O6NRLYUThrY',
    price: 80,
    coverImg: 'vivaldi.jpg',
    location: 'Italy',
    locationImg: 'italy.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: '',
    youtube: 'https://www.youtube.com/@HALIDONMUSIC',
    facebook: 'https://www.facebook.com/antonio.vivaldi.50',
    instagram: 'https://www.instagram.com/antonio._.vivaldi/',
    twitter: 'https://twitter.com/Antonio_VivaIdi',
    spotify: 'https://open.spotify.com/artist/2QOIawHpSlOwXDvSqQ9YJR',
    deezer: 'https://www.deezer.com/mx/artist/4081',
    apple: 'https://music.apple.com/mx/artist/antonio-vivaldi/242604',
    soundcloud: 'https://soundcloud.com/antoniovivaldi',
    tidal: 'https://listen.tidal.com/artist/3518585',
    wiki: 'https://es.wikipedia.org/wiki/Antonio_Vivaldi',
    ticketmaster:
      'https://www.ticketmaster.com/antonio-vivaldi-tickets/artist/2571287',
    tiktok: 'https://www.tiktok.com/@.antonio_vivaldi?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B008KJ6W4Q/antonio-vivaldi',
  },
]

export default CardData22
