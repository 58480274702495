const CardData37=[
    {
    title: 'Simply Red',
    description:
      "El grupo firmó con Elektra Records y realizó su primer álbum, Picture book, con el que Simply Red se colocó en el número 2 de las listas inglesas, en las que se mantuvieron durante 30 semanas consecutivas. Este LP contenía la versión de Valentine Brothers Money's too tight to mention y una composición de Mick en su primer grupo, The Frantic Elevators: Holding back the years. Estos fueron los primeros grandes éxitos del grupo.",
    videourl:
      'https://youtube.com/playlist?list=PL966EB62A778ED491&si=8XyfUHJW6h1h8hXO',
    price: 36,
    coverImg: 'SimplyRed.jpg',
    category: 'Soul',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.simplyred.com/',
    youtube: 'https://www.youtube.com/@simplyred',
    facebook: 'https://www.facebook.com/simplyred',
    instagram: 'https://www.instagram.com/simplyredofficial/',
    twitter: 'https://twitter.com/SimplyRedHQ',
    spotify: 'https://open.spotify.com/artist/1fa0cOhromAZdq2xRA4vv8',
    deezer: 'https://www.deezer.com/mx/artist/1516',
    apple: 'https://music.apple.com/mx/artist/simply-red/163002',
    soundcloud: 'https://soundcloud.com/official-simply-red',
    tidal: 'https://listen.tidal.com/artist/8372',
    wiki: 'https://es.wikipedia.org/wiki/Simply_Red',
    ticketmaster:
      'https://www.ticketmaster.com/simply-red-tickets/artist/736121',
    tiktok: 'https://www.tiktok.com/@simplyredofficial?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJSMAK/simply-red',
  },
  {
    title: 'Tears for Fears',
    description:
      "Tears for Fears es un dúo británico de pop rock fundado por Curt Smith y Roland Orzabal en 1981.1​2​ La banda tomó su nombre de un capítulo del libro de psicoterapia Prisoners of Pain (Prisioneros del dolor) del psicólogo estadounidense Arthur Janov.​ Su estilo pop rock, combinación de new wave y soul, se refleja en cada una de sus producciones musicales.​ Sus principales influencias musicales han sido The Beatles, Human League y Duran Duran, aunque también han llegado a sonar con similitud con Supertramp y Spandau Ballet. Canciones como Everybody Wants to Rule the World, Shout y Sowing the Seeds of Love convirtieron a Tears for Fears en uno de los grupos más importantes de los años 80, y en uno de los grupos más reconocidos a nivel mundial.",
    videourl:
      'https://youtube.com/playlist?list=PLFT2U25Xu77X2znxJs9ERnneQ2Xl9I7ho&si=SlcdUpGnamMRH0KT',
    price: 36,
    coverImg: 'tearsforfears.png',
    category: 'Soul',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://tearsforfears.com/',
    youtube: 'https://www.youtube.com/channel/UCyH3IAelkUFM0u6Iacx22lA',
    facebook: 'https://www.facebook.com/TearsForFears/',
    instagram: 'https://www.instagram.com/tearsforfearsmusic',
    twitter: 'https://x.com/tearsforfears',
    spotify: 'https://open.spotify.com/artist/4bthk9UfsYUYdcFyqxmSUU',
    deezer: 'https://www.deezer.com/mx/artist/1192',
    apple: 'https://music.apple.com/us/artist/tears-for-fears/45218',
    soundcloud: 'https://soundcloud.com/tearsforfearsmusic',
    tidal: 'https://listen.tidal.com/artist/3824',
    wiki:'https://es.wikipedia.org/wiki/Tears_for_Fears',
    ticketmaster:
      'https://www.ticketmaster.com/tears-for-fears-tickets/artist/736266',
    tiktok: 'https://www.tiktok.com/@tearsforfears',
    napster: 'https://music.amazon.com.mx/artists/B000VWN42I/tears-for-fears',
  },
   {
    title: 'Elton-John',
    description:
      'Elton Hercules John (Reginald Kenneth Dwight; Pinner, Middlesex, Inglaterra, 25 de marzo de 1947) es un cantante, compositor y músico de rock y pop británico. Con una carrera de más de 60 años, ha lanzado 32 álbumes de estudio y ha vendido más de 300 millones de copias en todo el mundo, siendo uno de los artistas musicales más exitosos de la historia. Ha colaborado con el letrista Bernie Taupin desde 1967 y muchas de sus canciones han alcanzado la cima de las listas de éxito en el mundo. Es el único artista en mantener al menos una canción dentro del Billboard Hot 100 durante 30 años consecutivos, desde 1970 hasta 2000. Su canción «Candle in the Wind 1997», reescrita en ocasión de la muerte de Diana de Gales, vendió más de 33 millones de copias y es el segundo sencillo más vendido en la historia.​ Su trabajo también se ha extendido hacia la composición, la producción y, en ocasiones, la actuación.Nacido y criado en Pinner, suburbio de Londres, John aprendió a tocar el piano a una edad muy temprana. Formó su primera banda, Bluesology, en 1962, y se alió con el letrista Bernie Taupin en 1967. Durante los siguientes dos años escribieron canciones para otros artistas como Lulu, y al mismo tiempo, trabajó para las bandas The Hollies y The Scaffold.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mr6JQDWNhkKYRCe0LUbmROXYR8XUD0N6A',
    price: 36,
    coverImg: 'Elton John.jpg',
    category: 'Funk',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.eltonjohn.com/',
    youtube: 'https://www.youtube.com/channel/UCcd0tBtip8YzdTCUw3OVv_Q',
    facebook: 'https://www.facebook.com/EltonJohn/',
    instagram: 'https://www.instagram.com/eltonjohn/',
    twitter: 'https://twitter.com/eltonofficial',
    spotify: 'https://open.spotify.com/artist/3PhoLpVuITZKcymswpck5b',
    deezer: 'https://www.deezer.com/mx/artist/413',
    apple: 'https://music.apple.com/mx/artist/elton-john/54657',
    soundcloud: 'https://soundcloud.com/elton-john',
    tidal: 'https://listen.tidal.com/artist/6890',
    wiki: 'https://es.wikipedia.org/wiki/Elton_John',
    ticketmaster:
      'https://www.ticketmaster.com/elton-john-tickets/artist/735394',
    tiktok: 'https://www.tiktok.com/@eltonjohn?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJK22G/elton-john',
  },
  {
    title: '*Sting*',
    description:
      'Gordon Matthew Thomas Sumner (Wallsend, Tyneside del Norte; 2 de octubre de 1951), conocido artísticamente como Sting, es un músico británico que se desempeñó inicialmente como bajista, y más tarde como cantante y voz líder, bajista y compositor del grupo musical The Police, formando luego su propia banda. Como miembro de The Police y como solista, Sting ha vendido más de cien millones de discos,​ ha recibido diecisiete Premios Grammy por su trabajo, recibiendo el primero por mejor interpretación de rock instrumental en 1981, un Premio Globo de Oro, un Premio Primetime Emmy y hasta cuatro nominaciones a los premios Óscar por mejor canción.​ En 2002, Sting recibió el Premio Ivor Novello a la trayectoria, reconocimiento de la Academia Británica de Compositores y Autores;​ y también fue incluido en el Salón de la Fama de los Compositores. En 2017, fue galardonado con el Polar Music Prize​ y en 2019, recibió el Premio BMI por su canción Every Breath You Take.​ Como miembro de The Police, fue inducido en el Salón de la Fama del Rock and Roll, en 2003.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kk7sE13ypRgPTNaJTq3yvhZ2CavuO0U8w',
    price: '$50',
    coverImg: 'Sting.jpg',
    category: 'New Age',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.sting.com/splash',
    youtube: 'https://www.youtube.com/channel/UCwERP8ZAI10RtZIAPsZQDDw',
    facebook: 'https://www.facebook.com/sting',
    instagram: 'https://www.instagram.com/theofficialsting/',
    twitter: 'https://twitter.com/OfficialSting',
    spotify: 'https://open.spotify.com/artist/0Ty63ceoRnnJKVEYP0VQpk',
    deezer: 'https://www.deezer.com/mx/artist/368',
    apple: 'https://music.apple.com/mx/artist/sting/94804',
    soundcloud: 'https://soundcloud.com/sting',
    tidal: 'https://listen.tidal.com/artist/17356',
    wiki: 'https://es.wikipedia.org/wiki/Sting',
    ticketmaster: 'https://www.ticketmaster.com/sting-tickets/artist/723578',
    tiktok: 'https://www.tiktok.com/@official_sting?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJNH82/sting',
  },
  {
    title: 'The Police',
    description:
      'The Police (español: La Policía) fue una banda británica de rock formada en Londres en 1977. Durante la mayor parte de su historia, la formación consistió en el compositor principal Sting (voz principal y bajo), Andy Summers (guitarra) y Stewart Copeland (batería y percusión). The Police se hizo mundialmente popular a fines de la década de 1970 y principios de la de 1980. Emergiendo en la escena new wave británica, tocaron un estilo de rock influenciado por los géneros punk, pop, reggae y jazz.',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mOkIk3yeaq2H0DmAIabGZ5jsRLMxXdcgQ',
    price: '$50',
    coverImg: 'police.jpg',
    category: 'Classic Soft Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thepolice.com/#/',
    youtube: 'https://www.youtube.com/channel/UCuCY8MR9LcAGIXetnKx5SnQ',
    facebook: 'https://www.facebook.com/thepolice',
    instagram: 'https://www.instagram.com/thepolicebandofficial/',
    twitter: 'https://x.com/thepoliceband',
    spotify: 'https://open.spotify.com/artist/5NGO30tJxFlKixkPSgXcFE',
    deezer: 'https://www.deezer.com/mx/artist/1981',
    apple: 'https://music.apple.com/mx/artist/the-police/93818',
    soundcloud: 'https://soundcloud.com/the-police-official',
    tidal: 'https://listen.tidal.com/artist/27426',
    wiki: 'https://es.wikipedia.org/wiki/The_Police',
    ticketmaster: 'https://www.ticketmaster.com/the-police-tickets/artist/1101273',
    tiktok: 'https://www.tiktok.com/@thepolice?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJQZ2W/the-police',
  },
  {
    title: 'Joe Satriani',
    description:
      "Joe 'Satch' Satriani (Westbury, Nueva York, 15 de julio de 1956) es un virtuoso guitarrista de rock instrumental estadounidense. Fue nominado en 15 ocasiones a los Premios Grammy y ha vendido más de 10 millones de discos en todo el mundo.Según otros guitarristas ha conseguido dominar casi todas las técnicas de ejecución de su instrumento, incluyendo tapping a dos manos, sweep picking, volume swells y tap harmonics. Además es reconocido como un gran profesor de guitarra, entre sus alumnos se incluyen: Tom Morello, Alex Skolnick, Larry LaLonde, Andy Timmons, Reb Beach, Rick Hunolt, Charlie Hunter, Kirk Hammett y Steve Vai.",
    videourl:
      'https://youtube.com/playlist?list=PLY90ZuSuNbRhsWk4hLVadAvUC60_mtzPr',
    price: 36,
    coverImg: 'JoeSatriani.jpg',
    category: 'Rock ',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.satriani.com/',
    facebook: 'https://www.facebook.com/joesatriani/',
    instagram: 'https://www.instagram.com/joesatriani/',
    twitter: 'https://twitter.com/chickenfootjoe',
    youtube: 'https://www.youtube.com/channel/UCiynI3-MFYbiZ6FQqWNLh7w',
    spotify: 'https://open.spotify.com/artist/2yzxX2DI9LFK8VFTyW2zZ8',
    deezer: 'https://www.deezer.com/mx/artist/496',
    apple: 'https://music.apple.com/mx/artist/joe-satriani/466941',
    soundcloud: 'https://soundcloud.com/joesatrianiofficial',
    tidal: 'https://listen.tidal.com/artist/686',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Satriani',
    ticketmaster:
      'https://www.ticketmaster.com/joe-satriani-tickets/artist/736048',
    tiktok: 'https://www.tiktok.com/@joesatriani?lang=es',
    napster: 'https://music.amazon.com/artists/B0010VKX64/joe-satriani',
  },
  {
    title: 'Gentle Giant',
    description:
      'Gentle Giant fue una banda británica de rock progresivo activa entre 1970 y 1980. La banda era conocida por la complejidad y sofisticación de su música y por las variadas habilidades musicales de sus miembros. Todos los miembros de la banda eran multiinstrumentistas. Aunque no tuvieron éxito comercial, lograron una seguidilla de culto.La banda afirmaba que su objetivo era expandir las fronteras de la música popular contemporánea con el riesgo de volverse muy impopular, aunque esta postura cambiaría significativamente con el tiempo.La música de Gentle Giant era considerada compleja incluso según los estándares del rock progresivo aprovechando una amplia gama de música que incluía folk, soul, jazz y música clásica. A diferencia de muchos de sus contemporáneos del rock progresivo, sus influencias clásicas iban más allá de lo romántico e incorporaban elementos de música medieval, barroca y modernista de música de cámara..​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_mKXtoF9kZhSpjfOw--psZRc0v_jcO2i-o&si=dpLvXfRynvjkeIN3',
    price: 36,
    coverImg: 'gentlegiant.jpeg',
    category: ' Progressive Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://gentlegiantband.com/',
    youtube: 'https://www.youtube.com/channel/UCgS2qyzQgcZoxoECW0vcRXg',
    facebook: 'facebook.com/gentlegiantband',
    instagram: 'instagram.com/gentlegiantband',
    twitter: 'twitter.com/GGIANTMUSIC',
    spotify: 'https://open.spotify.com/search/Gentle%20Giant',
    deezer: 'https://www.deezer.com/mx/artist/1679',
    apple: 'https://music.apple.com/us/artist/gentle-giant/84494',
    soundcloud: 'https://soundcloud.com/gentle-giant-official',
    tidal: 'https://listen.tidal.com/album/1229926',
    wiki: 'https://es.wikipedia.org/wiki/Gentle_Giant',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@gentlegiantband?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000W06QTM/gentle-giant',
  },
  
  {
    title: 'Dire-Straits',
    description:
      'Dire Straits (pronunciación: /daɪr streɪts/) fue una banda de rock británica formada en Londres en 1977 por Mark Knopfler (voz principal y guitarra principal), David Knopfler (guitarra rítmica y coros), John Illsley (bajo y coros) y Pick Withers (batería y percusión), estando activos hasta el año 1995. Dire Straits es considerado como uno de los grupos musicales más exitosos de la historia de la música rock.Su primer sencillo, Sultans of Swing, de su álbum debut homónimo de 1978, llegó a los diez primeros en las listas de éxitos del Reino Unido y los Estados Unidos. Le siguieron éxitos como Romeo and Juliet (1981), Private Investigations (1982), Twisting by the Pool (1983), Money for Nothing (1985) y Walk of Life (1985).​ Su álbum de mayor éxito comercial, Brothers in Arms (1985), ha vendido más de 30 millones de copias; fue el primer álbum en vender un millón de copias en disco compacto​ y es el octavo álbum más vendido en la historia del Reino Unido. Según el Guinness Book of British Hit Albums, Dire Straits ha pasado más de 1100 semanas en la lista de álbumes del Reino Unido, la quinta mayor cantidad de todos los tiempos',
    videourl:
      'https://youtube.com/playlist?list=PLFw8JYl3SAY7GfEZXekVjN9STaJ7iEoQC',
    price: '$50',
    coverImg: 'dIREsTRAITS.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.direstraits.com/',
    youtube: 'https://www.youtube.com/channel/UC9HKX4sDEYdgDEQTV9iy9yw',
    facebook: 'https://www.facebook.com/DireStraits/',
    instagram: 'https://www.instagram.com/direstraitshq/',
    twitter: 'https://twitter.com/DireStraits77',
    spotify: 'https://open.spotify.com/artist/0WwSkZ7LtFUFjGjMZBMt6T',
    deezer: 'https://www.deezer.com/mx/artist/176',
    apple: 'https://music.apple.com/mx/artist/rush/50526',
    soundcloud:
      'https://soundcloud.com/search?q=dire%20straits%20money%20for%20nothing&query_urn=soundcloud%3Asearch-autocomplete%3Adf8cbc07b1564c0c82db1d6a8a496235',
    tidal: 'https://listen.tidal.com/artist/29893',
    wiki: 'https://es.wikipedia.org/wiki/Dire_Straits',
    ticketmaster: 'https://es.wikipedia.org/wiki/Dire_Straits',
    tiktok: 'https://www.tiktok.com/@direstraits?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJPPF0/dire-straits',
  },
  {
    title: 'Air Supply',
    description:
      'Air Supply es un dúo australiano de soft rock formado por el inglés Graham Russell y el australiano Russell Hitchcock en la ciudad de Melbourne en 1973. A lo largo de su historia, el dúo ha lanzado más de 40 sencillos, de los cuales 11 han alcanzado el Hot 100 de Billboard, 20 el Billboard Adult Contemporary, 14 el Aria Chart de Australia, 03 sencillos en el año 2016 se se posicionaron en el Billboard dance club chart, 4 el UK Albums Chart del Reino Unido, más de 35 sencillos las listas de Asia y América Latina y algunos más han ingresado en listas europeas, de África y del resto del mundo',
    videourl: 'https://youtube.com/playlist?list=UULIx3q_0KLVHAxgxsvh_vSQ',
    price: null,
    coverImg: 'airsupply.jpeg',
    category: 'Soft Rock',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://airsupplymusic.com/',
    youtube: 'https://www.youtube.com/channel/UCLIx3q_0KLVHAxgxsvh_vSQ',
    facebook: 'https://www.facebook.com/airsupplymusic/',
    instagram: 'https://www.instagram.com/airsupplymusic/',
    twitter: 'https://twitter.com/AirSupplySongs',
    spotify: 'https://open.spotify.com/artist/4xXCRXOfQKQ2gjWxNhNzYW',
    deezer: 'https://www.deezer.com/mx/artist/3876',
    apple: 'https://music.apple.com/mx/artist/air-supply/13563',
    soundcloud: 'https://soundcloud.com/air-supply-official',
    tidal: 'https://listen.tidal.com/artist/3672176',
    wiki: 'https://es.wikipedia.org/wiki/Air_Supply',
    ticketmaster:
      'https://www.ticketmaster.com/air-supply-tickets/artist/734403',
    tiktok: 'https://www.tiktok.com/@airsupplymusic?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJHRHE/air-supply',
  },
  {
    title: 'Genesis',
    description:
      'Genesis​ fue un grupo británico de rock progresivo creado en 1967 por Tony Banks, Mike Rutherford, Peter Gabriel y Anthony Phillips.​Tras la marcha del guitarrista y compositor Anthony Phillips (quien fue brevemente reemplazado por Mick Barnard) y tras varios cambios de bateristas, el grupo quedó conformado en su primera formación "clásica" (1970-1975) por Peter Gabriel (voz y flauta), Tony Banks (teclados), Mike Rutherford (bajo y guitarras), Phil Collins (batería y percusión) y Steve Hackett (guitarra líder). Tras la salida de Gabriel -en 1975-​ y de Hackett -en 1977​ el grupo quedó reducido a un cuarteto, ahora con Daryl Stuermer, y posteriormente a un trío, con Collins como cantante y baterista, Rutherford como guitarrista y bajista y Banks como tecladista. A partir de la década de 1980, la música de Genesis vería un cambio fundamental de estilo, del rock progresivo hacia el pop-rock, coincidiendo con la época de mayor éxito comercial y con Phil Collins como su líder más visible',
    videourl: 'https://youtube.com/playlist?list=OLAK5uy_mpfHaVaLCaW9ZhJeGFwVEiskyKXQvIo80&si=02dKs6xKX69vDo5w',
    price: null,
    coverImg: 'genesis.jpg',
    category: 'Soft Rock',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://genesis-music.com/',
    youtube: 'https://www.youtube.com/@genesis',
    facebook: 'https://www.facebook.com/genesis/',
    instagram: 'https://www.instagram.com/genesis_band',
    twitter: 'https://x.com/genesis_band',
    spotify: 'https://open.spotify.com/playlist/4DkSDa7bJQaBL9Cwlc8hFd?',
    deezer: 'https://www.deezer.com/mx/artist/3037',
    apple: 'https://music.apple.com/mx/artist/genesis/95966',
    soundcloud: 'https://soundcloud.com/genesis4',
    tidal: 'https://listen.tidal.com/artist/9163',
    wiki: 'https://es.wikipedia.org/wiki/Genesis',
    ticketmaster:
      'https://www.ticketmaster.com/genesis-tickets/artist/723546',
    tiktok: 'https://www.tiktok.com/@genesis_band?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJIAY8/genesis',
  },
  {
    title: 'Phil Collins',
    description:
      'Philip David Charles Collins (Chiswick, Middlesex; 30 de enero de 1951), conocido por su nombre artístico Phil Collins, es un baterista, cantante, compositor, productor y actor británico, y uno de los artistas de mayor éxito de la música rock. Fue nombrado teniente de la Real Orden Victoriana (LVO).Entre 1984 y 1989 Phil Collins lideró la lista de éxitos estadounidense «Billboard Hot 100» como cantante en ocho ocasiones, siete como solista y una con Genesis, banda de la que fue miembro entre 1970 y 1996',
    videourl: 'https://www.youtube.com/playlist?list=PLEF9AC9E0A3ABFD37',
    price: null,
    coverImg: 'philcollins.jpg',
    category: 'Soft Rock',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://philcollins.com/',
    youtube: 'https://www.youtube.com/channel/UC4JeRiQvPQ_nb1BZWjk9QoA',
    facebook: 'https://www.facebook.com/philcollins',
    instagram: 'https://www.instagram.com/officialphilcollins',
    twitter: 'https://x.com/PhilCollinsFeed',
    spotify: 'https://open.spotify.com/artist/4lxfqrEsLX6N1N4OCSkILp',
    deezer: 'https://www.deezer.com/mx/artist/186',
    apple: 'https://music.apple.com/mx/artist/phil-collins/153718',
    soundcloud: 'https://soundcloud.com/phil-collins-official',
    tidal: 'https://listen.tidal.com/artist/12871',
    wiki: 'https://es.wikipedia.org/wiki/Phil_Collins',
    ticketmaster:
      'https://www.ticketmaster.com/phil-collins-tickets/artist/734793',
    tiktok: 'https://www.tiktok.com/@officialphilcollins?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJNH64/phil-collins',
  },
  {
    title: 'Peter Gabriel',
    description:
      'Peter Brian Gabriel (Chobham, Surrey, Inglaterra, 13 de febrero de 1950), más conocido como Peter Gabriel, es un cantautor y productor británico de rock.​ Fue el vocalista principal y flautista de la banda de rock progresivo Genesis.​ Fundó este grupo con sus compañeros Tony Banks, Anthony Phillips y Mike Rutherford cuando estudiaba en la escuela secundaria Charterhouse.​ Tras abandonar Genesis, comenzó una exitosa carrera como solista y más adelante, dirigió sus esfuerzos en la producción y promoción de «World of Music» (WOMAD)​ así como en desarrollar la distribución de música por métodos digitales.​ Se ha involucrado en varios esfuerzos humanitarios.',
    videourl: 'https://www.youtube.com/playlist?list=OLAK5uy_msF4JnHSYG71ODu7kcvzFMybtRoS_xrQY',
    price: null,
    coverImg: 'petergabriel.jpg',
    category: 'Soft Rock',
    location: 'England',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://petergabriel.com/',
    youtube: 'https://www.youtube.com/@itspetergabriel',
    facebook: 'https://www.facebook.com/PeterGabriel',
    instagram: 'https://www.instagram.com/itspetergabriel/',
    twitter: 'https://x.com/itspetergabriel',
    spotify: 'https://open.spotify.com/artist/7C4sUpWGlTy7IANjruj02I',
    deezer: 'https://www.deezer.com/mx/artist/1048',
    apple: 'https://music.apple.com/mx/artist/peter-gabriel/115433',
    soundcloud: 'https://soundcloud.com/peter-gabriel-official',
    tidal: 'https://listen.tidal.com/artist/9606',
    wiki: 'https://es.wikipedia.org/wiki/Peter_Gabriel',
    ticketmaster:
      'https://www.ticketmaster.com/peter-gabriel-tickets/artist/735139',
    tiktok: 'https://www.tiktok.com/@itspetergabriel_official?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJLUU/peter-gabriel',
  },
  {
    title: 'Chicago',
    description:
      'Chicago es una banda de rock estadounidense. La agrupación es, junto a Blood, Sweat & Tears, la banda más representativa,​ y la más exitosa en términos comerciales,​ de lo que se dieron en llamar Big Bands de Rock. Se fundó en la ciudad de su mismo nombre en 1967, aunque inicialmente se denominó Chicago Transit Authority, nombre que debieron recortar por problemas jurídicos con la autoridad de transporte público de la ciudad. El grupo permanece activo en la actualidad.',
    videourl:
      'https://youtube.com/playlist?list=PLVyXA9x6nTuflr_WsUQl49JbYbDWjVhDd&si=pvOdUAeUWPKXwbKG',
    price: 36,
    coverImg: 'chicago.jpg',
    category: 'Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://chicagotheband.com/',
    youtube: 'https://www.youtube.com/channel/UCK0TuNeYqg9oYGSksX-jNkg',
    facebook: 'https://www.facebook.com/Chicago.Official',
    instagram:
      'https://www.instagram.com/chicagotheband',
    twitter: 'https://x.com/chicagotheband',
    spotify: 'https://open.spotify.com/artist/0f3EsoviYnRKTkmayI3cux',
    deezer: 'https://www.deezer.com/mx/artist/8486',
    apple: 'https://music.apple.com/mx/artist/chicago/197043',
    soundcloud: 'https://soundcloud.com/chicago-17',
    tidal: 'https://listen.tidal.com/artist/13397',
    wiki: 'https://es.wikipedia.org/wiki/Chicago_(banda)',
    ticketmaster:
      'https://www.ticketmaster.com/chicago-tickets/artist/734746',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=Chicago%20band&t=1726295294761',
    napster: 'https://music.amazon.com.mx/artists/B000QJJS6M/chicago',
  },
  {
    title: 'Foreigner',
    description:
      'Foreigner es una banda de rock británico-estadounidense formada en la ciudad de Nueva York en 1976 por el guitarrista Mick Jones, el vocalista Lou Gramm, el baterista Dennis Elliott, el tecladista Al Greenwood, el bajista Ed Gagliardi y el multiinstrumentista Ian McDonald, quien también fue miembro fundador de King Crimson. Foreigner es una de las bandas más vendidas de todos los tiempos, con ventas mundiales de más de 80 millones de discos, incluyendo 37.5 millones en los EE.UU.​',
    videourl:
      'https://youtube.com/playlist?list=UULPr79-QZv1_VGlxVWLc28xrQ&si=q1I2bBakxEFF_27b',
    price: 36,
    coverImg: 'foreigner.jpeg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'britusa.jpeg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.foreigneronline.com/',
    youtube: 'https://www.youtube.com/channel/UCr79-QZv1_VGlxVWLc28xrQ',
    facebook: 'https://www.facebook.com/Foreigner/',
    instagram: 'https://www.instagram.com/foreignerlive/',
    twitter: 'https://x.com/ForeignerMusic',
    spotify:
      'https://open.spotify.com/artist/6IRouO5mvvfcyxtPDKMYFN',
    deezer: 'https://www.deezer.com/mx/artist/2310',
    apple: 'https://music.apple.com/mx/artist/foreigner/564219',
    soundcloud: 'https://soundcloud.com/foreigner',
    tidal: 'https://listen.tidal.com/artist/7623',
    wiki: 'https://en.wikipedia.org/wiki/Foreigner_(band)',
    ticketmaster:
      'https://www.ticketmaster.com/foreigner-tickets/artist/735109',
    tiktok: 'https://www.tiktok.com/search/video?lang=es&q=Foreigner&t=1726246176559',
    napster: 'https://music.amazon.com.mx/artists/B0011Z79O8/foreigner',
  },
  {
    title: 'Robert Palmer',
    description:
      'Robert Allen Palmer (Batley, 19 de enero de 1949-París, 26 de septiembre de 2003) fue un músico, productor, compositor y multinstrumentista inglés. Considerado como un cantante de rock, a lo largo de su carrera interpretó una variedad de géneros musicales como blue-eyed soul, funk, blues, jazz, reggae, new wave, synth pop, rhythm and blues, dance-rock, bossa nova y calypso, entre otros. Inició su carrera con quince años en la banda The Mandrakes, con quienes teloneó los conciertos de artistas como Jimi Hendrix, Fleetwood Mac y The Who. Después de un período con el grupo The Alan Bown Set (1969-1970), se unió a la agrupación de jazz rock Dada, que en 1971 se reestructuró para formar Vinegar Joe. Con dicha banda grabó tres álbumes de estudio hasta que optaron por separarse en 1974. En ese mismo año, comenzó su carrera solista con el disco Sneakin Sally Through the Alley, grabado con el apoyo de los músicos de The Meters y el guitarrista de Little Feat, Lowell George. Durante la segunda parte de la década de 1970, publicó cuatro producciones con las que obtuvo un parcial éxito comercial en el Reino Unido y los Estados Unidos. Después del lanzamiento de Clues (1980) y Pride (1983), participó en el supergrupo The Power Station que en 1985 editó su álbum debut homónimo. Su repentina popularidad los llevó a organizar una gira de conciertos, pero Palmer renunció días antes de que iniciara para enfocarse nuevamente en su carrera solista. En la segunda parte de la década de 1980 consiguió un importante éxito comercial en varios países con los discos Riptide (1985) y Heavy Nova (1988). Gracias a sus respectivos sencillos «Addicted to Love» y «Simply Irresistible», ganó en dos ocasiones el premio Grammy a la mejor interpretación vocal de rock masculina y recibió tres nominaciones en los Brit Awards.',
    videourl: 'https://youtube.com/playlist?list=PL8UcgQHNFSbH8Ezinp2EVZd5l8rEdLPiD&si=45jsHz9nXJHWLGEj',
    price: null,
    coverImg: 'robertpalmer.jpeg',
    category: 'Soft Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://robertpalmer.com/',
    youtube: 'https://www.youtube.com/channel/UCmHLw9BUePIRhg0bJsdlHXA',
    facebook: 'https://www.facebook.com/robertpalmersinger/',
    instagram: 'https://www.rollingstone.com/author/robert-palmer/',
    twitter: 'https://x.com/search?q=Robert%20Palmer%20Singer&src=typed_query&f=media',
    spotify: 'https://open.spotify.com/artist/530Sdm7eqqzWBdDmILMgnu?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/2551',
    apple: 'https://music.apple.com/mx/artist/robert-palmer/80385',
    soundcloud: 'https://soundcloud.com/robert-palmer-official',
    tidal: 'https://listen.tidal.com/artist/11020',
    wiki: 'https://es.wikipedia.org/wiki/Robert_Palmer',
    ticketmaster:
      'https://www.ticketmaster.com/robert-palmer-tickets/artist/735817',
    tiktok: 'https://www.tiktok.com/search/video?q=Robert%20Palmer&t=1726256498949',
    napster: 'https://music.amazon.com.mx/artists/B000QJR666/robert-palmer',
  },
  {
    title: 'Lionel Richie',
    description:
      'Lionel Brockman Richie, Jr. (Tuskegee, Alabama; 20 de junio de 1949)1​ es un cantautor y actor estadounidense.En los años 1970 formó parte de la banda musical The Commodores, propiedad del famoso sello musical Motown de Detroit (Míchigan). Un grupo estadounidense, famoso por su estilo musical funk/soul, que destacó en las décadas de 1970 y 1980. Se formaron en Detroit en 1968, y al año firmaron contrato con Motown Records. El grupo es más conocido por baladas como "Easy", "Still2 y "Three Times A Lady", aunque grabó principalmente funk y música bailable en temas como "Brick house", "Say Yeah", "Fancy Dancer", "Too Hot Ta Trot", "Nightshift", entre otros..​',
    videourl:
      'https://youtube.com/playlist?list=PLv6gVwjfKeYSrFAGcsYRI5fksu6-v1k0p',
    price: 36,
    coverImg: 'lionelritchie.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://lionelrichie.com/',
    youtube: 'https://www.youtube.com/channel/UCM3iG_kXUM_9HHVIDI7vEtg',
    facebook: 'https://www.facebook.com/LionelRichie',
    instagram: 'https://www.instagram.com/lionelrichie/',
    twitter: 'https://twitter.com/LionelRichie',
    spotify: 'https://open.spotify.com/artist/3gMaNLQm7D9MornNILzdSl',
    deezer: 'https://www.deezer.com/mx/artist/3445',
    apple: 'https://music.apple.com/us/artist/lionel-richie/48397',
    soundcloud: 'https://soundcloud.com/lionelrichieofficial',
    tidal: 'https://listen.tidal.com/artist/17277',
    wiki: 'https://es.wikipedia.org/wiki/Lionel_Richie',
    ticketmaster: 'https://www.ticketmaster.com/',
    tiktok: 'https://www.tiktok.com/@lionelrichie?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJP758/lionel-richie',
  },
  {
    title: 'Paul Mc Cartney',
    description:
      'James Paul McCartney (Liverpool, 18 de junio de 1942) es un cantautor, compositor, músico, pacifista, filántropo, multiinstrumentista, escritor, pintor y actor británico; que junto a John Lennon, George Harrison y Ringo Starr, ganó fama mundial por ser el bajista y uno de los cantantes de la legendaria banda de rock británica The Beatles, reconocida como la banda más popular e influyente en la historia de la música moderna; su asociación compositora con Lennon es una de las más célebres del siglo xx. Después de la separación de la banda, continuó su carrera musical en solitario y formó Wings con su primera esposa, Linda, y Denny Laine.​',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_nf-H2Yn991SPn_LsBt92Qx7p69RxslkEQ',
    price: 36,
    coverImg: 'paulmccartney.jpg',
    category: ' Soft Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.paulmccartney.com/',
    youtube: 'https://www.youtube.com/channel/UCvGnJy9RnXX0kGSnDPKCZzQ',
    facebook: 'https://www.facebook.com/PaulMcCartney',
    instagram: 'https://www.instagram.com/paulmccartney/',
    twitter: 'https://x.com/PaulMcCartney',
    spotify: 'https://open.spotify.com/artist/4STHEaNw4mPZ2tzheohgXB',
    deezer: 'https://www.deezer.com/mx/artist/1446',
    apple: 'https://music.apple.com/us/artist/paul-mccartney/12224',
    soundcloud: 'https://soundcloud.com/paulmccartney',
    tidal: 'https://listen.tidal.com/artist/8849',
    wiki: 'https://es.wikipedia.org/wiki/Paul_McCartney',
    ticketmaster: 'https://www.ticketmaster.com.mx/paul-mccartney-boletos/artist/735610',
    tiktok: 'https://www.tiktok.com/@paulmccartney?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJQ8KG/paul-mccartney',
  },
  {
    title: 'Paul Simon',
    description:
      'Paul Frederic Simon (Newark, Nueva Jersey, 13 de octubre de 1941) es un cantante, músico y renombrado compositor musical estadounidense.1​ La carrera musical de Simon se ha extendido por más de seis décadas. Es ampliamente considerado como uno de los compositores más aclamados en la historia de la música popular. Se graduó en el Queens College y estudió durante un breve periodo en la Escuela de Derecho de Brooklyn. Simon formó el dúo Simon & Garfunkel con su amigo de la escuela Art Garfunkel en 1956. Lanzaron cinco álbumes de estudio y se convirtieron en uno de los grupos más aclamados de la década de 1960. Simon compuso casi todas sus canciones, incluyendo The Sound of Silence, Mrs. Robinson, America, Bridge over Troubled Water y The Boxer.​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kxpean9PtBnd3vF7tTMObwrVqooBzREVw&si=gbt2--PCMhmoKtnO',
    price: 36,
    coverImg: 'paulsimon.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://sevenpsalms.paulsimon.com/',
    youtube: 'https://www.youtube.com/channel/UCRfCiKTwOOKV8aoxE9M44bA',
    facebook: 'https://www.facebook.com/paulsimon/',
    instagram: 'https://www.instagram.com/paulsimonofficial/',
    twitter: 'https://x.com/PaulSimonMusic',
    spotify: 'https://open.spotify.com/artist/4STHEaNw4mPZ2tzheohgXB',
    deezer: 'https://www.deezer.com/mx/artist/1445',
    apple: 'https://music.apple.com/us/artist/paul-simon/152016',
    soundcloud: 'https://soundcloud.com/paulsimon',
    tidal: 'https://listen.tidal.com/artist/6928',
    wiki: 'https://es.wikipedia.org/wiki/Paul_Simon',
    ticketmaster: 'https://www.ticketmaster.com/paul-simon-tickets/artist/736119',
    tiktok: 'https://www.tiktok.com/@officialpaulsimonmusic?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QJJU60/paul-simon',
  },
  {
    title: 'Dave Matthews Band',
    description:
      'Dave Matthews Band (también conocidos por el acrónimo DMB) es una banda estadounidense de rock formada en el año 1991 en Charlottesville, Virginia. La banda fue fundada por el cantante, compositor y guitarrista Dave Matthews, el bajista Stefan Lessard, el baterista Carter Beauford y el saxofonista LeRoi Moore; a los cuales se les unió el violinista Boyd Tinsley poco tiempo después. Luego del fallecimiento de Moore en 2008, Jeff Coffin tomó su lugar como el saxofonista principal de la banda. Desde 1998 hasta mayo de 2008 la banda contó con la presencia del teclista Butch Taylor durante las presentaciones en vivo y en el año 2005 se integró el trompetista Rashawn Ross, aunque ambos músicos no están incluidos entre los miembros oficiales del grupo. Otro importante músico que forma parte del grupo durante presentaciones en vivo, pero no es parte oficial de la banda, es el guitarrista Tim Reynolds.​',
    videourl:
      'https://www.youtube.com/playlist?list=PLOTmY-rTH6lDvgo0oqhzjQQ-jabGV6TvY',
    price: 36,
    coverImg: 'DaveMathewsBand.jpg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://davematthewsband.com/',
    youtube: 'https://www.youtube.com/davematthewsband',
    facebook: 'https://www.facebook.com/davematthewsband',
    instagram: 'https://www.instagram.com/davematthewsband/',
    twitter: 'https://x.com/davematthewsbnd',
    spotify: 'https://open.spotify.com/artist/2TI7qyDE0QfyOlnbtfDo7L',
    deezer: 'https://www.deezer.com/mx/artist/245',
    apple: 'https://music.apple.com/us/artist/dave-matthews-band/60288452',
    soundcloud: 'https://soundcloud.com/dave-matthews-band',
    tidal: 'https://listen.tidal.com/artist/36309',
    wiki: 'https://es.wikipedia.org/wiki/Dave_Matthews_Band',
    ticketmaster: 'https://www.ticketmaster.com/dave-matthews-band-tickets/artist/746531',
    tiktok: 'https://www.tiktok.com/@davematthewsband',
    napster: 'https://music.amazon.com.mx/artists/B00136F9KQ/dave-matthews-band',
  }, 
  {
    title: 'Counting Crows',
    description:
      'El cantante Adam Duritz y el guitarrista David Bryson (ambos miembros de la banda The Himalayans) formaron Counting Crows en San Francisco en 1991.El origen del nombre Counting Crows (traducción literal en español: Contando Cuervos) proviene de una rima infantil que aparece en la película Signs of Life y que Adam Duritz incluyó en la canción A Murder Of One, en donde canta: I dreamt I saw you walking up a hillside in the snow / Casting shadows on the winter sky as you stood there counting crows (soñé que te vi subiendo una colina, en la nieve / trazando sombras sobre el cielo invernal, mientras estabas allí parada, contando cuervos). La rima: One for sorrow Two for joy / Three for girls and four for boys / Five for silver Six for gold and / Seven for a secret never to be told" (Uno por la tristeza, dos por la alegría, tres por las niñas y cuatro por los niños, cinco por la plata, seis por el oro, siete por un secreto que jamás será contado).​',
    videourl:
      'https://www.youtube.com/playlist?list=PLOTmY-rTH6lDvgo0oqhzjQQ-jabGV6TvY',
    price: 36,
    coverImg: 'countingcrows.jpeg',
    category: ' Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.countingcrows.com/',
    youtube: 'https://www.youtube.com/davematthewsband',
    facebook: 'https://www.facebook.com/countingcrows',
    instagram: 'https://www.instagram.com/countingcrows/',
    twitter: 'https://x.com/davematthewsbnd',
    spotify: 'https://open.spotify.com/artist/0vEsuISMWAKNctLlUAhSZC',
    deezer: 'https://www.deezer.com/mx/artist/321',
    apple: 'https://music.apple.com/us/artist/counting-crows/35719',
    soundcloud: 'https://soundcloud.com/countingcrows',
    tidal: 'https://listen.tidal.com/artist/25055',
    wiki: 'https://es.wikipedia.org/wiki/Counting_Crows',
    ticketmaster: 'https://www.ticketmaster.com/counting-crows-tickets/artist/765319',
    tiktok: 'https://www.tiktok.com/@countingcrowsmusic',
    napster: 'https://music.amazon.com.mx/artists/B000QKA6MG/counting-crows',
  },
  {
    title: 'Eagles',
    description:
      'Eagles es una banda estadounidense de country rock y hard rock formada en Los Ángeles, California, EE. UU., en 1971, hasta el 2016, en que finalizó la carrera de la banda, con la muerte de uno de los fundadores y su vocalista Glenn Frey. La banda es mayormente conocida por su canción “Hotel California” de 1976, acreditada por Don Felder y Don Henley',
    videourl:
      'http://youtube.com/playlist?list=PLeAaNAa15XZODgxo5U58cxloYhjCek0TE',
    price: 80,
    coverImg: 'eagles.jpg',
    location: 'USA',
    category: 'Rock Clasico',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://eagles.com/',
    youtube: 'https://www.youtube.com/channel/UC49r4GNHHpc-eQ9hmD2Rg6A',
    facebook: 'https://www.facebook.com/EaglesBand',
    instagram: 'https://www.instagram.com/thebandeagles/',
    twitter: 'https://twitter.com/TheEgles',
    spotify: 'https://open.spotify.com/artist/0ECwFtbIWEVNwjlrfc6xoL',
    deezer: 'https://www.deezer.com/mx/artist/210',
    apple: 'https://music.apple.com/mx/artist/eagles/1053549',
    soundcloud: 'https://soundcloud.com/eaglesofficial',
    tidal: 'https://listen.tidal.com/artist/16139',
    wiki: 'https://es.wikipedia.org/wiki/Eagles',
    ticketmaster: 'https://www.ticketmaster.com/eagles-tickets/artist/734977',
    tiktok: 'https://www.tiktok.com/@officaleaglesmusic?lang=es',
    napster: 'https://music.amazon.com/artists/B000QK73XQ/eagles',
  },
  {
    title: 'Steely Dan',
    description:
      'Steely Dan es una banda de rock estadounidense fundada en 1971 en el Bard College en Annandale-on-Hudson, Nueva York por los miembros principales Walter Becker y Donald Fagen. Combinando elementos de rock, jazz, música latina, R&B, blues y una producción de estudio sofisticada con letras crípticas e irónicas, la banda disfrutó de un éxito comercial y de crítica desde principios de la década de 1970 hasta su separación en 1981. Inicialmente, la banda tenía una formación principal, pero en 1974, Becker y Fagen retiraron a la banda de las presentaciones en vivo por completo para convertirse en una banda de estudio solo, optando por grabar con un elenco rotatorio de músicos de sesión. Rolling Stone los ha llamado los antihéroes musicales perfectos para los años setenta.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_m2pu_kyUn6OVjwuPgltPcWkC4Y406qFKM',
    price: 80,
    coverImg: 'steelyDan.jpg',
    location: 'USA',
    category: 'Rock Clasico',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'http://www.steelydan.com/#!/',
    youtube: 'https://www.youtube.com/channel/UCbSAYU9A4L4JbUkGmy4BN5Q',
    facebook: 'https://www.facebook.com/SteelyDan/',
    instagram: 'https://www.instagram.com/steelydan_official/',
    twitter: 'https://twitter.com/steely_dan_bot',
    spotify: 'https://open.spotify.com/artist/6P7H3ai06vU1sGvdpBwDmE',
    deezer: 'https://www.deezer.com/mx/artist/1156',
    apple: 'https://music.apple.com/mx/artist/steely-dan/59606',
    soundcloud: 'https://soundcloud.com/steely-dan-official',
    tidal: 'https://listen.tidal.com/artist/14143',
    wiki: 'https://es.wikipedia.org/wiki/Steely_Dan',
    ticketmaster:
      'https://www.ticketmaster.com/steely-dan-tickets/artist/736195',
    tiktok:
      'https://www.tiktok.com/search?lang=es&q=steely%20dan%20do%20it%20again&t=1654382034846',
    napster: 'https://music.amazon.com/artists/B000QJPANM/steely-dan',
  }, 
   {
    title: 'The Outfield',
    description:
      'The Outfield fue una banda de rock británica, formada en Londres en 1984. La formación de la banda estaba compuesta por el guitarrista John Spinks, el vocalista y bajista Tony Lewis y el baterista Alan Jackman..',
    videourl: 'https://youtube.com/playlist?list=UU0Tu8aLTRBWUK3bdAN0D6LA',
    price: 36,
    coverImg: 'tHEoUTFIELD.jpg',
    category: 'Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://theoutfield.com/',
    youtube: 'https://www.youtube.com/c/linkin-park',
    facebook: 'https://www.facebook.com/profile.php?id=100044235213807',
    instagram: 'https://www.instagram.com/tonylewisoutfield/',
    twitter: 'https://twitter.com/TonyLewisMusic',
    spotify: 'https://open.spotify.com/artist/1zxDewzd2j1ZdSBGaYcr0y',
    deezer: 'https://www.deezer.com/mx/artist/7743',
    apple: 'https://music.apple.com/mx/artist/the-outfield/438557068',
    soundcloud: 'https://soundcloud.com/the-outfield',
    tidal: 'listen.tidal.com/artist/35025',
    wiki: 'https://es.wikipedia.org/wiki/The_Outfield',
    ticketmaster: 'https://www.ticketmaster.com/outfield-tickets/artist/735804',
    tiktok: 'https://www.tiktok.com/@the_outfield_?lang=es',
    napster: 'https://music.amazon.com/artists/B00GPFZ9A0/the-outfield',
  },
  {
    title: 'Rod Stewart',
    description:
      'Roderick David Rod Stewart es un músico, compositor y productor británico de rock, conocido mundialmente por haber sido vocalista en las bandas The Jeff Beck Group y Faces, como también por su exitosa y extensa carrera como solista. Con su distinguida voz rasposa inició su carrera como músico callejero para luego participar en The Dimensions y en el supergrupo The Steampacket durante los primeros años de la década de los sesenta. Sin embargo alcanzó el éxito después de ingresar a The Jeff Beck Group, donde solo participó en sus dos primeros álbumes, y luego en Faces hasta 1975 cuando la agrupación inglesa se separó..',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nDMPXRotjxJ2k77CTaUXdq_NVPTMv9gwc',
    price: '$50',
    coverImg: 'rodstewart.gif',
    category: 'Jazz',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://rodstewart.com/',
    youtube: 'https://www.youtube.com/user/RodStewart',
    facebook: 'https://www.facebook.com/rodstewart',
    instagram: 'https://www.instagram.com/sirrodstewart/',
    twitter: 'https://twitter.com/rodstewart',
    spotify: 'https://open.spotify.com/artist/2y8Jo9CKhJvtfeKOsYzRdT',
    deezer: 'https://www.deezer.com/mx/artist/1440',
    apple: 'https://music.apple.com/mx/artist/rod-stewart/57471',
    soundcloud: 'https://soundcloud.com/rodstewartofficial',
    tidal: 'https://listen.tidal.com/artist/1639',
    wiki: 'https://es.wikipedia.org/wiki/Rod_Stewart',
    ticketmaster: 'https://www.tiktok.com/@sirrodstewart?lang=es',
    tiktok: 'https://listen.tidal.com/artist/1639',
    napster: 'https://music.amazon.com/artists/B000RSHJUE/rod-stewart',
  },
  {
    title: 'The Doobie Brothers',
    description:
      'The Doobie Brothers es una banda estadounidense de rock, formada en 1970 por Tom Johnston, John Hartman y el bajista Greg Murph, que luego fue sustituido por Dave Shogren; todos ellos de California. En el año siguiente, ya transformados en quinteto, lanzaron su primer LP Doobie Brothers. El sonido que hacían era un estilo country rock, con algo de tendencia hacia el gospel y con la utilización de instrumentos de viento. En 1972, el LP Toulouse Street ganó el primer disco de oro, que sería una constante en la carrera del conjunto, de ahí en adelante.',
    videourl:
      'http://youtube.com/playlist?list=PLk_w8MBwTJDGMpwOsFSbc11WZ1lSkfJ8_',
    price: 80,
    coverImg: 'theDoobieBrothers.jpg',
    location: 'USA',
    category: 'Rock Clasico',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://thedoobiebrothers.com/',
    youtube: 'https://www.youtube.com/user/DoobiesOfficial',
    facebook: 'https://www.facebook.com/thedoobiebrothers',
    instagram: 'https://www.instagram.com/doobiebrothersofficial/',
    twitter: 'https://twitter.com/TheDoobieBros',
    spotify: 'https://open.spotify.com/artist/39T6qqI0jDtSWWioX8eGJz',
    deezer: 'https://www.deezer.com/mx/artist/1144',
    apple: 'https://music.apple.com/mx/artist/the-doobie-brothers/117992093',
    soundcloud: 'https://soundcloud.com/thedoobiebrothers',
    tidal: 'https://listen.tidal.com/artist/107',
    wiki: 'https://es.wikipedia.org/wiki/The_Doobie_Brothers',
    ticketmaster:
      'https://www.ticketmaster.com/the-doobie-brothers-tickets/artist/734946',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=the%20doobie%20brothers&t=1654382425154',
    napster: 'https://music.amazon.com/artists/B00G7G4MHI/the-doobie-brothers',
  },{
    title: 'Little River Band',
    description:
      'Little River Band o LRB es una banda de soft rock australiana formada en Melbourne a marzo de 1975. El grupo eligió el nombre después de pasar por una carretera que conduce al municipio de Victoria de Little River, cerca de Geelong, mientras se dirigían a una presentación. Little River Band han disfrutado de un extenso éxito comercial, no solo en Australia, sino también en Estados Unidos. Durante su carrera, la banda vendió más de 25 millones de discos y lograron tener 13 éxitos en el U.S. Top 40 hits agregando todos los premios ganados en Australia.Los miembros originales eran: Glenn Shorrock; Graham Goble; Beeb Birtles; Ric Formosa; Roger McLachlan y Derek Pellicci.​',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kRJ9t0VGNRpGW6_CCPo4Nd6QmZ6RPtiZc',
    price: 36,
    coverImg: 'littleriverband.jpg',
    category: ' Soft Rock',
    location: 'Australia',
    locationImg: 'australia.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://reallittleriverband.com/',
    youtube: 'https://www.youtube.com/channel/UC5G7TEx54qe73K_I9q5ZUZQ',
    facebook: 'https://www.facebook.com/littleriverband/',
    instagram: 'https://www.instagram.com/little_river_band/',
    twitter: 'https://twitter.com/littleriverband',
    spotify:
      'https://open.spotify.com/artist/6clbbhnIqpHnqxwtOWcilg?autoplay=true',
    deezer: 'https://www.deezer.com/mx/artist/9527',
    apple: 'https://music.apple.com/mx/artist/little-river-band/553680',
    soundcloud: 'https://soundcloud.com/little-river-band-official',
    tidal: 'https://listen.tidal.com/artist/3505336',
    wiki: 'https://es.wikipedia.org/wiki/Little_River_Band',
    ticketmaster:
      'https://www.ticketmaster.com/little-river-band-tickets/artist/735521',
    tiktok: 'https://www.tiktok.com/@offical.littleriverband?lang=es',
    napster: 'https://music.amazon.com.mx/artists/B000QKLC9M/little-river-band',
  },
  {
    title: 'Fleetwood Mac',
    description:
      'Fleetwood Mac es una banda de rock nacida en 1967 en Londres, que a lo largo de su carrera ha sufrido constantes cambios en sus miembros y ha tenido diversos períodos de popularidad. Fue fundada por los vocalistas y guitarristas Peter Green y Jeremy Spencer, el bajista Bob Brunning y el baterista Mick Fleetwood. Con la posterior llegada de John McVie y Danny Kirwan. El apellido de John McVie combinado con el de Mick Fleetwood fue escogido para titular la banda y junto a él son los únicos músicos que han estado en toda la carrera de la agrupación inglesa. En conjunto con sus primeros trabajos, se situaron como una de las bandas más importantes del boom del blues británico de fines de los sesenta y principios de los setenta.',
    videourl:
      'https://youtube.com/playlist?list=PLpg0EOoO2vdUrhGf9IY_QBkIfaUAnLS8O',
    price: 36,
    coverImg: 'fleetWoodMac.jpg',
    category: 'Rock Clasico',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.fleetwoodmac.com/',
    youtube: 'https://www.youtube.com/channel/UCAb60rVrvVQVfSgrX1UWb0g',
    facebook: 'https://www.facebook.com/FleetwoodMac',
    instagram: 'https://www.instagram.com/fleetwoodmacfanclub/',
    twitter: 'https://twitter.com/fleetwoodmac',
    spotify: 'https://open.spotify.com/artist/08GQAI4eElDnROBrJRGE0X',
    deezer: 'https://www.deezer.com/mx/artist/169',
    apple: 'https://music.apple.com/mx/artist/fleetwood-mac/158038',
    soundcloud: 'https://soundcloud.com/fleetwoodmacofficial',
    tidal: 'https://listen.tidal.com/artist/1134',
    wiki: 'https://es.wikipedia.org/wiki/Fleetwood_Mac',
    ticketmaster:
      'https://www.ticketmaster.com/rumours-of-fleetwood-mac-live-in-san-diego-california-11-23-2022/event/0A005C7880A71A62',
    tiktok: 'https://www.tiktok.com/@fleetwooddmac?lang=es',
    napster: 'https://music.amazon.com/artists/B000QJJPUG/fleetwood-mac',
  },
  
  {
    title: 'Daryl Hall & John Oates',
    description:
      'Hall & Oates fue un dúo estadounidense de soul formado en Filadelfia en 1970, por Daryl Hall (cantante) y John Oates (guitarra eléctrica y coros). Se estima que el dúo vendió 80 millones de grabaciones a lo largo de su carrera, convirtiéndose en el dúo musical con más ventas de todos los tiempos. Llegaron al número 1 en el Billboard Hot 100 un total de seis veces junto con muchas otras canciones que entraron en el Top 40 de dicha lista. Tienen siete álbumes certificados platino y seis álbumes certificados oro por la RIAA.​ Este éxito no se reflejó en el Reino Unido, donde nunca llegaron a tener una canción o álbum en el número uno, solo entraron en el Top 10 de la UK Singles Chart un total de dos veces junto con seis sencillos alcanzando el Top 40 de la misma lista y solo dos de sus álbumes entraron en el Top 10 de la UK Albums Chart. La revista Billboard los nombró en la posición diecisiete en su lista de los "100 más grandes artistas de todos los tiempos".3​ Fueron incluidos en el Salón de la Fama del Rock and Roll en abril de 2014. El 2 de septiembre de 2016 recibieron una estrella en el Paseo de la fama de Hollywood.',
    videourl: 'https://youtube.com/playlist?list=PL0Ewn91tEfzRObh44bkUkf2M2cuZdVYR_&si=PIJvNMR4_-od1piz',
    price: null,
    coverImg: 'hallandoates.jpg',
    category: 'Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://hallandoates.com/',
    youtube: 'https://www.youtube.com/channel/UCSRoN5Sxu5M-jc0JXt3E-xg',
    facebook: 'https://www.facebook.com/hallandoates/',
    instagram: 'https://www.instagram.com/hallandoatesofficial',
    twitter: 'https://x.com/halloates',
    spotify: 'https://open.spotify.com/artist/77tT1kLj6mCWtFNqiOmP9H',
    deezer: 'https://www.deezer.com/mx/artist/3529',
    apple: 'https://music.apple.com/mx/artist/daryl-hall-john-oates/180586',
    soundcloud: 'https://soundcloud.com/daryl-hall-john-oates',
    tidal: 'https://listen.tidal.com/artist/2470',
    wiki: 'https://es.wikipedia.org/wiki/Hall_%26_Oates',
    ticketmaster:
      'https://www.ticketmaster.com/daryl-hall-john-oates-tickets/artist/848102',
    tiktok: 'https://www.tiktok.com/@hallandoatesofficial',
    napster: 'https://music.amazon.com/artists/B000QKP2VQ/daryl-hall-john-oates',
  },
  {
    title: 'Earth, Wind & Fire',
    description:
      'Earth, Wind & Fire (en español: Tierra, Viento y Fuego), conocidos también como EWF, es un grupo musical estadounidense, formado en Chicago (Illinois) en 1970. Fue fundado por Maurice White.1​ Utiliza varios géneros de música, una fusión de disco, R&B, funk con el jazz, soul, góspel, pop, blues, psicodelia, folk, música africana y rock and roll. Durante su carrera han sido 20 veces candidatos a los Grammy, ganando 6 premios.2​ También obtuvieron su propia estrella en el Paseo de la Fama de Hollywood, junto a la del jamaicano Bob Marley.Con ventas de más de 90 millones de discos, es una de las bandas más vendidas del mundo de todos los tiempos. La banda fue fundada en Chicago por Maurice White en 1969, habiendo surgido de una banda anterior conocida como Salty Peppers. Otros miembros destacados de EWF han incluido a Philip Bailey, Verdine White, Ralph Johnson, Larry Dunn, Al McKay, Roland Bautista, Robert Brookins, Sonny Emory, Fred Ravel, Ronnie Laws, Sheldon Reynolds y Andrew Woolfolk. La banda es conocida por su sonido de kalimba, sección de trompeta dinámica, espectáculos escénicos enérgicos y elaborados, y el contraste entre la voz en falsete de Philip Bailey y el barítono de Maurice White.',
    videourl: 'https://www.youtube.com/playlist?list=PLnCl80bcL3NwKaOgONttk6AbBp-StNMrg',
    price: null,
    coverImg: 'ewf.jpg',
    category: 'Soft Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.earthwindandfire.com/',
    youtube: 'https://www.youtube.com/channel/UCztiH7D-fHwsMyUF8r15vsQ',
    facebook: 'https://www.facebook.com/EarthWindandFire',
    instagram: 'https://www.instagram.com/earthwindandfire/?hl=en',
    twitter: 'https://x.com/earthwindfire',
    spotify: 'https://open.spotify.com/artist/4QQgXkCYTt3BlENzhyNETg',
    deezer: 'https://www.deezer.com/mx/artist/248',
    apple: 'https://music.apple.com/mx/artist/earth-wind-fire/290699',
    soundcloud: 'https://soundcloud.com/earthwindandfire',
    tidal: 'https://listen.tidal.com/artist/637',
    wiki: 'https://es.wikipedia.org/wiki/Earth,_Wind_%26_Fire',
    ticketmaster:
      'https://www.ticketmaster.com/earth-wind-fire-tickets/artist/734980',
    tiktok: 'https://www.tiktok.com/@earthwindandfire',
    napster: 'https://music.amazon.com/artists/B000QJKEJW/earth-wind-fire',
  }, {
    title: 'Men at Work',
    description:
      'Men at Work es una banda de rock australiana formada en 1978. Sus miembros fueron Colin Hay en la voz principal; Jerry Speiser en la batería y Ron Strykert en la guitarra. A ellos se les unieron Greg Ham en la flauta y teclados y luego John Rees en el bajo.Esta formación logró un éxito nacional e internacional en la década de 1980. En enero de 1983, fueron los primeros artistas australianos en tener en simultáneo un álbum y sencillo en el primer lugar en las listas de Billboard de Estados Unidos, Business as Usual (publicado el 9 de noviembre de 1981) y "Down Under" (1981), respectivamente. Con estos últimos trabajos lograron la misma distinción simultánea de un álbum n.º 1 y un sencillo n.º 1 en las listas de Australia, Nueva Zelanda y Reino Unido. Su segundo álbum, Cargo (2 de mayo de 1983), también fue número 1 en Australia , N° 2 en Nueva Zelanda, N° 3 en Estados Unidos y N.º 8 en el Reino Unido. Su tercer álbum, Two Hearts, alcanzó el top 20 en Australia y top 50 en Estados Unidos.En los premios Grammy de 1983 ganaron la categoría de Mejor Nuevo Artista; mientras que en los ARIA Music Awards de 1994 fueron incluidos en el Salón de la Fama. Men At Work ha vendido más de 30 millones de discos en todo el mundo.El grupo se disolvió en 1986, siendo reformado en 1996 para separarse nuevamente en 2012.En mayo de 2001 "Down Under" fue incluida en el lugar número 4 en el Top 30 de canciones australianas de la APRA, y Business as Usual apareció en el libro de los 100 Mejores Discos Australianos.',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_nBY6ctNjnFNxKu_lixzniP8Whibs_Ahq8',
    price: 36,
    coverImg: 'MenatWork.jpg',
    category: 'Rock',
    location: 'Australia',
    locationImg: 'australia.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl:
      'https://www.colinhay.com/menatwork/',
    youtube: 'https://www.youtube.com/channel/UCba5jqDeexXGSxGrmoltlQw',
    facebook: 'https://www.facebook.com/menatwork',
    instagram:
      'https://www.instagram.com/explore/tags/menatworkrockaustralianband/',
    twitter: 'https://twitter.com/MenAtWorkBand',
    spotify: 'https://open.spotify.com/artist/0f3EsoviYnRKTkmayI3cux',
    deezer: 'https://www.deezer.com/mx/artist/8486',
    apple: 'https://music.apple.com/mx/artist/men-at-work/15412031',
    soundcloud: 'https://soundcloud.com/men-at-work-official',
    tidal: 'https://listen.tidal.com/artist/619',
    wiki: 'https://es.wikipedia.org/wiki/Men_at_Work',
    ticketmaster:
      'https://www.ticketmaster.com/men-at-work-tickets/artist/838003',
    tiktok:
      'https://www.tiktok.com/search/video?lang=es&q=Men%20at%20Work&t=1726295536836',
    napster: 'https://music.amazon.com/artists/B001368YBW/men-at-work',
  },
  {
    title: 'Jeff Beck',
    description:
      'Geoffrey Arnold «Jeff» Beck (Wallington, Gran Londres, 24 de junio de 1944 - 10 de enero de 2023)​ fue un guitarrista británico de rock y blues que tocó en varias bandas influyentes en las décadas de 1960 y 1970. Fue uno de los tres notables guitarristas que tocaron en The Yardbirds, siendo Eric Clapton y Jimmy Page los otros dos. Durante 25 años mantuvo una esporádica carrera en solitario. A pesar de no haber alcanzado nunca la proyección comercial de sus contemporáneos, Beck tuvo un gran reconocimiento, especialmente en la comunidad de guitarristas.​ Experimentó con el blues rock, el rockabilly, el heavy metal y el jazz fusión y en su última época había absorbido influencias del techno, creando una innovadora mezcla de música heavy metal y música electrónica.',
    videourl:
      'https://www.youtube.com/playlist?list=OLAK5uy_mpBbKDrUgDKi4dtBFm6xzv7fXktMd3vJM',
    price: '$50',
    coverImg: 'jeffbeck.jpg',
    category: 'Classic Rock',
    location: 'UK',
    locationImg: 'england.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://www.jeffbeck.com/',
    youtube: 'https://www.youtube.com/channel/UCd5c-3Ff2IiBqSQaT8FHHRg',
    facebook: 'https://www.facebook.com/jeffbeck',
    instagram: 'https://www.instagram.com/jeffbeckofficial/',
    twitter: 'https://x.com/jeffbeckmusic',
    spotify: 'https://open.spotify.com/artist/0AD4odMWVQ2wUSlgxOB5Rl',
    deezer: 'https://www.deezer.com/mx/artist/3084',
    apple: 'https://music.apple.com/mx/artist/jeff-beck/476310',
    soundcloud: 'https://soundcloud.com/jeff-beck-official',
    tidal: 'https://listen.tidal.com/artist/1021',
    wiki: 'https://es.wikipedia.org/wiki/Jeff_Beck',
    ticketmaster: 'https://www.viagogo.com/pr/Concert-Tickets/Rock-and-Pop/Jeff-Beck-Tickets',
    tiktok: 'https://www.tiktok.com/@jeffbeck446',
    napster: 'https://music.amazon.com/artists/B000QJPRJO/jeff-beck',
  },

  {
    title: 'Joe Walsh',
    description:
      'Fidler Joseph «Joe» Walsh (Wichita, Kansas, 20 de noviembre de 1947) es un guitarrista, cantante y compositor estadounidense de hard rock, country rock y blues rock. Conocido por su maestría con la guitarra eléctrica, ha sido miembro de tres bandas de éxito: James Gang, Barnstorm y The Eagles. Además, ha experimentado como artista en solitario obteniendo un gran éxito. Con James Gang compuso temas reconocidos como "Walk Away" y "Funk #49". En solitario compuso su éxito cómico "Lifes Been Good" y "Rocky Mountain Way", siendo con este último tema que se utilizó por primera vez el talk box (inventado por un amigo de Joe, Bob Hulzon, para tocar el tema en vivo). Con The Eagles, ayudó a Don Henley, Glenn Frey y Don Felder con el tema "Hotel California", como guitarrista principal...',
    videourl:
      'https://youtube.com/playlist?list=OLAK5uy_kO-C0vdcx2Eqb83ShUUNQgSQ5famxrtBQ&si=FGhZ-BuoBp_CyFOB',
    price: '$50',
    coverImg: 'joewalsh.jpg',
    category: 'Classic Rock',
    location: 'USA',
    locationImg: 'usaflag.jpg',
    openSpots: null,
    likes: 1000,
    websiteurl: 'https://joewalsh.com/',
    youtube: 'https://www.youtube.com/user/JoeWalshOfficial',
    facebook: 'https://www.facebook.com/JoeWalshOfficial/',
    instagram: 'https://www.instagram.com/joewalshofficial//',
    twitter: 'https://twitter.com/JoeWalsh',
    spotify: 'https://open.spotify.com/artist/5bDxAyJiTYBat1YnFJhvEK',
    deezer: 'https://www.deezer.com/mx/artist/688',
    apple: 'https://music.apple.com/mx/artist/joe-walsh/34507',
    soundcloud: 'https://soundcloud.com/joewalshmusic',
    tidal: 'https://listen.tidal.com/artist/4063',
    wiki: 'https://es.wikipedia.org/wiki/Joe_Walsh',
    ticketmaster: 'https://www.tiktok.com/@joewalshofficial?lang=es',
    tiktok: 'https://listen.tidal.com/artist/4063',
    napster: 'https://music.amazon.com/artists/B000QJPE1K/joe-walsh',
  },
]
export default CardData37;