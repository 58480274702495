import './intro.css'
import ButtonTreeCategories from './ButtonTreeCategories'
import Navbar from './Navbar/Navbar';
import Particle from './Particle';
import Footer from './Footer/Footer'





function Intro() {
  return (
    <div className="hero">
    <Navbar />
    <Particle id="particles"/>
      <div>
        <h1 className="title2">Bienvenido</h1>
        <div>
          <h4 className="headertext">
            {' '}
            Escoje el tipo de musica que mas te gusta`???
          </h4>

          <ButtonTreeCategories />
          
        </div>
        <img className="indianaut" src="./images/indianaut.png" alt="" />

        <img className="indianaut" src="./images/indianaut.png" alt="" />

        <div>
        <div className="intro-video-container">
        <img
         src={`${process.env.PUBLIC_URL}/videos/MexicanTelevision.gif`}
        className="intro-video"
        width="100%"
        height="100%"
        alt="Mexican Television"
        />
         </div>

        <img className="indianaut" src="./images/indianaut.png" alt="" />
        <img className="indianaut2" src="./images/indianaut.png" alt="" />

      </div>
      {/* import Comments from '../Comments/Comments'
        <Comments
        commentsUrl="http://localhost:3004/comments"
        currentUserId="1"
      />
       <SponsorContainer />
  <Clients />
  <Footer/>*/}
    </div>
    </div>
  )
}

export default Intro
