const CardData34 = [
    {
      title: 'George Strait',
      description:
        'George Harvey Strait (18 de mayo de 1952) es un cantante de música country, actor y productor de Estados Unidos. A Strait se le conoce como "King of Country" y la crítica le sitúa como una leyenda viva.Es conocido por su estilo inconfundible de western swing, baladas de bar, honky-tonk y country fresco pero tradicional. Geoge Strait tiene el récord del mundo de mayor número de sencillos que han sido número uno, más que cualquier otro artista en la historia de la música de cualquier género, habiendo grabado 60 números uno de Billboard hasta 2010.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_lSHjFIkw2u08nTspqkzSPYRcdNVoYsW3E',
      price: 50,
      coverImg: 'georgestrait.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'http://www.georgestrait.com/',
      youtube: 'https://www.youtube.com/channel/UCOmZHz5W3F17HraXlJveEJA',
      facebook: 'https://www.facebook.com/georgestrait?ref=stream',
      instagram: 'https://www.instagram.com/georgestrait/?hl=en',
      twitter: 'https://twitter.com/GeorgeStrait',
      spotify: 'https://open.spotify.com/artist/5vngPClqofybhPERIqQMYd',
      deezer: 'https://www.deezer.com/mx/artist/5235',
      apple: 'https://music.apple.com/mx/artist/george-strait/96353',
      soundcloud: 'https://soundcloud.com/george-strait-music',
      tidal: 'https://listen.tidal.com/artist/24902',
      wiki: 'https://es.wikipedia.org/wiki/George_Strait',
      ticketmaster:
        'https://www.ticketmaster.com./george-strait-tickets/artist/732947',
      tiktok: 'https://www.tiktok.com/@georgestrait?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000QKQXX2/george-strait',
    },
    {
      title: 'Blake Shelton',
      description:
        'Blake Tollison Shelton (Ada, Oklahoma; 18 de junio de 1976) es un cantante estadounidense de música country. En 2001 hizo su debut con el sencillo "Austin", de su primer álbum, Blake Shelton. "Austin" pasó cinco semanas en el número uno del ranking Hot Country Songs. Esta canción fue el primer sencillo de su álbum certificado de oro, que también produjo dos Top 20. Aunque el álbum fue lanzado en Giant Records Nashville, Shelton fue transferido a Warner Bros. Records Nashville después de que Giant cerrara a finales de 2001..',
      videourl: 'https://youtube.com/playlist?list=PL40FD0D0A57E8A307',
      price: 50,
      coverImg: 'blakeshelton.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.blakeshelton.com/',
      youtube: 'https://www.youtube.com/channel/UC9nxc-xiH1AnL9RGstfgurg',
      facebook: 'https://www.facebook.com/blakeshelton',
      instagram: 'https://www.instagram.com/blakeshelton/',
      twitter: 'https://twitter.com/blakeshelton',
      spotify: 'https://open.spotify.com/artist/1UTPBmNbXNTittyMJrNkvw',
      deezer: 'https://www.deezer.com/en/artist/8638',
      apple: 'https://music.apple.com/mx/artist/blake-shelton/189204',
      soundcloud: 'https://soundcloud.com/blakeshelton',
      tidal: 'https://listen.tidal.com/artist/14126',
      wiki: 'https://es.wikipedia.org/wiki/Blake_Shelton',
      ticketmaster:
        'https://www.ticketmaster.com./blake-shelton-tickets/artist/807367',
      tiktok: 'https://www.tiktok.com/@blakeshelton?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000W298F4/blake-shelton',
    },
    {
      title: 'Morgan Wallen',
      description:
        'Morgan Cole Wallen (Sneedville, Estados Unidos; 13 de mayo de 1993) es un cantante y compositor estadounidense de música country, en marzo de 2023, alcanza la más alta posición del Billboard 200 tras vender 500.000 copias en una semana de su disco One Thing at a Time, además logra colocar cinco temas de dicho álbum en el top 10 del Billboard Hot 100, siendo «Last Night» el obtener el número #1.Anteriomente había firmado con la discográfica Big Loud Records, lanzando cuatro sencillos: «The Way I Talk», «Up Down», que colabora Florida Georgia Line, «Whiskey Glasses» y «Chasin You». El disco Dangerous: The Double Album en 2021 alcanza también buenas ventas en Estados Unidos..',
      videourl:
        'https://youtube.com/playlist?list=PLHP0tKi5klmzFSkiQVfnBQct96H6vBh5m',
      price: 50,
      coverImg: 'morganwallen.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://morganwallen.com/',
      youtube: 'https://www.youtube.com/@morganwallen',
      facebook: 'https://www.facebook.com/morgancwallen',
      instagram: 'https://www.instagram.com/morganwallen/',
      twitter: 'https://twitter.com/MorganWallen',
      spotify:
        'https://open.spotify.com/artist/4oUHIQIBe0LHzYfvXNW4QM?autoplay=true',
      deezer: 'https://www.deezer.com/en/artist/8638',
      apple: 'https://music.apple.com/mx/artist/morgan-wallen/829142092',
      soundcloud: 'https://soundcloud.com/blakeshelton',
      tidal: 'https://listen.tidal.com/artist/14126',
      wiki: 'https://es.wikipedia.org/wiki/Morgan_Wallen',
      ticketmaster:
        'https://www.ticketmaster.com./morgan-wallen-tickets/artist/2288122',
      tiktok: 'https://www.tiktok.com/@morganwallen?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B00QNUADXK/morgan-wallen',
    },
    {
      title: 'Luke Combs',
      description:
        'Luke Albert Combs (Charlotte, Carolina del Norte, Estados Unidos; 2 de marzo de 1990), más conocido como Luke Combs, es un cantante y compositor estadounidense. Es considerado el nuevo referente del country y uno de los cantantes más populares y exitosos de dicho género..',
      videourl:
        'https://youtube.com/playlist?list=PLrDUJ02xVqCOKAvaL8seNhAk-a4UO8E_n',
      price: 50,
      coverImg: 'lukecombs.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://morganwallen.com/',
      youtube: 'https://www.youtube.com/channel/UCOSIXyYdT93OzpRnAuWaKjQ',
      facebook: 'https://www.facebook.com/LukeCombs/',
      instagram: 'https://www.instagram.com/lukecombs/',
      twitter: 'https://twitter.com/lukecombs',
      spotify: 'https://open.spotify.com/artist/718COspgdWOnwOFpJHRZHS',
      deezer: 'https://www.deezer.com/en/artist/9626504',
      apple: 'https://music.apple.com/mx/artist/luke-combs/815635315',
      soundcloud: 'https://soundcloud.com/lukecombs',
      tidal: 'https://listen.tidal.com/artist/7400952',
      wiki: 'https://es.wikipedia.org/wiki/Luke_Combs',
      ticketmaster:
        'https://www.ticketmaster.com./luke-combs-tickets/artist/2150342',
      tiktok: 'https://www.tiktok.com/@lukecombs?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B00IAGXUC6/luke-combs',
    },
    {
      title: 'Chris Stapleton',
      description:
        'Christopher Alvin Stapleton (Lexington, Kentucky, Estados Unidos; 15 de abril de 1978), más conocido como Chris Stapleton, es un cantante, compositor y guitarrista estadounidense.1​2​ Él es un compositor reconocido contando con seis No. 13​ incluyendo «Never Wanted Nothing More» el cual fue No. 1 durante cinco semanas, grabado por Kenny Chesney, «Loves Gonna Make It Alright» grabado por George Strait, y «Come Back Song» el cual fue grabado por Darius Rucker.',
      videourl:
        'https://youtube.com/playlist?list=PLjugUpCYbwhxHk8l5qWI6FLmpqSqlaZ57',
      price: 50,
      coverImg: 'chrisstapleton.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://morganwallen.com/',
      youtube: 'https://www.youtube.com/channel/UCsdXkstc8jFC3zpMYdEz_zA',
      facebook: 'https://www.facebook.com/chrisstapletonofficial?fref=ts',
      instagram: 'https://www.instagram.com/chrisstapleton/',
      twitter: 'https://twitter.com/chrisstapleton',
      spotify: 'https://open.spotify.com/artist/4YLtscXsxbVgi031ovDDdh',
      deezer: 'https://www.deezer.com/en/artist/343290',
      apple: 'https://music.apple.com/us/artist/chris-stapleton/1752134',
      soundcloud: 'https://soundcloud.com/chris-stapleton-music',
      tidal: 'https://listen.tidal.com/artist/5143486',
      wiki: 'https://es.wikipedia.org/wiki/Chris_Stapleton',
      ticketmaster:
        'https://www.ticketmaster.com./chris-stapleton-tickets/artist/1828177',
      tiktok: 'https://www.tiktok.com/@chrisstapleton?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B000V61ZVG/chris-stapleton',
    },
    {
      title: 'Zach Bryan',
      description:
        'Zachary Lane Bryan (nacido el 2 de abril de 1996) es un cantante y compositor estadounidense de Oologah, Oklahoma. Veterano de la Armada de los Estados Unidos, su álbum debut en una discográfica importante, American Heartbreak, entró en el número cinco de la lista Billboard 200 en Estados Unidos.Bryan nació en Okinawa, Japón, mientras su familia estaba destinada en la Armada en el extranjero, pero creció en Oologah, Oklahoma. Es hijo de Dewayne Bryan y Annette DeAnn (Mullen) Bryan, y tiene una hermana llamada Mackenzie. Siguiendo una tradición familiar, Bryan fue miembro en servicio activo de la Armada de los Estados Unidos, alistándose a los 17 años. Comenzó a escribir canciones a los 14 años y utilizó su tiempo libre mientras estaba en la Armada para componer música para su disfrute..',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mH5PG0dIBP9yQU-7taTaBZYkeqavExs6Q',
      price: 50,
      coverImg: 'zachbryan.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.zachbryan.com/',
      youtube: 'https://www.youtube.com/channel/UCwK3C8Vgphad4PweezfUBAQ',
      facebook: 'https://www.facebook.com/profile.php?id=100092440971341',
      instagram: 'https://www.instagram.com/zachlanebryan/',
      twitter: 'https://twitter.com/zachlanebryan',
      spotify: 'https://open.spotify.com/artist/40ZNYROS4zLfyyBSs2PGe2',
      deezer: 'https://www.deezer.com/en/artist/71855892',
      apple: 'https://music.apple.com/us/artist/zach-bryan/1436413980',
      soundcloud: 'https://soundcloud.com/achane',
      tidal: 'https://listen.tidal.com/artist/16568778',
      wiki: 'https://en.wikipedia.org/wiki/Zach_Bryan',
      ticketmaster: 'https://www.ticketmaster.com/artist/2811359',
      tiktok: 'https://www.tiktok.com/@zachlanebryan?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B07HDGX8WJ/zach-bryan',
    },
    {
      title: 'Walker Hayes',
      description:
        'Walker Hayes (Mobile, 27 de diciembre de 1979) es un cantante country estadounidense. Ha publicado tres sencillos que han estrado en la lista de éxitos Hot Country Songs y ha lanzado dos álbumes.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_lDxGNlHKO7sOQUdH_XqVl4sM6rNOxJ5Oo',
      price: 50,
      coverImg: 'walkerhayes.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://www.walkerhayes.com/#home',
      youtube: 'https://www.youtube.com/channel/UC9PEiOh3oGRJvbAO_-acrRg',
      facebook: 'https://www.facebook.com/walkerhayes/',
      instagram: 'https://www.instagram.com/walkerhayes/',
      twitter: 'https://twitter.com/walkerhayes',
      spotify: 'https://open.spotify.com/artist/7sKxqpSqbIzphAKAhrqvlf',
      deezer: 'https://www.deezer.com/en/artist/398476',
      apple: 'https://music.apple.com/us/artist/walker-hayes/382876655',
      soundcloud: 'https://soundcloud.com/walkerhayes',
      tidal: 'https://listen.tidal.com/artist/3718752',
      wiki: 'https://es.wikipedia.org/wiki/Walker_Hayes',
      ticketmaster:
        'https://www.ticketmaster.com./walker-hayes-tickets/artist/1495006',
      tiktok: 'https://www.tiktok.com/@walkerhayesofficial?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B003W95S76/walker-hayes',
    },
    {
      title: 'Kane Brown',
      description:
        'Kane Allen Brown (Chattanooga, Estados Unidos; 21 de octubre de 1993), más conocido como Kane Brown es un cantante y compositor estadounidense de música country. Inicialmente él llamó la atención del público a través de las redes sociales.1​ Lanzó su primer EP, titulado Closer, en junio de 2015. Un nuevo sencillo, «Used to Love Sober» fue lanzado en octubre de 2015. También de que Brown firmará con RCA Nashville a comienzos de 2016, la canción fue incluida en su EP Chapter 1, el cual fue lanzado en marzo de 2016. El lanzó su primer álbum de estudio homónimo, Kane Brown el 2 de diciembre de 2016..',
      videourl:
        'https://youtube.com/playlist?list=PLpa-6y72uD3t5eVedM6KpXtmF-6xhgOxw',
      price: 50,
      coverImg: 'kanebrown.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: '',
      youtube: 'https://www.youtube.com/channel/UC2QTDn02Xobvy_N2bb_Zzlw',
      facebook: 'https://www.facebook.com/kaneallenbrown',
      instagram: 'https://www.instagram.com/k.anebrown/',
      twitter: 'https://twitter.com/kanebrown',
      spotify: 'https://open.spotify.com/artist/3oSJ7TBVCWMDMiYjXNiCKE',
      deezer: 'https://www.deezer.com/en/artist/827425',
      apple: 'https://music.apple.com/us/artist/kane-brown/930928857',
      soundcloud: 'https://soundcloud.com/kane-brown-music',
      tidal: 'https://listen.tidal.com/artist/6160546',
      wiki: 'https://es.wikipedia.org/wiki/Kane_Brown',
      ticketmaster: 'https://www.ticketmaster.com/artist/2182670',
      tiktok: 'https://www.tiktok.com/@kanebrown?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B00OMRI16M/kane-brown',
    },
    {
      title: 'William Beckman',
      description:
        'William Beckmann está atraído por cosas que tienen historias que contar: edificios históricos, guitarras antiguas, la máquina de escribir vintage en la que escribe todas sus letras. Y si llamas al cantautor de Texas un “alma vieja”, el joven de 28 años lo tomará como un cumplido. Beckmann — un emocionante vocalista que canta tanto en inglés como en español — ha estado grabando y actuando con un tipo de música country vibrante y moderna que también rinde homenaje a lo que vino antes. Originario del pequeño pueblo fronterizo de Del Rio, TX, William Beckmann creció con el country clásico, así como con los sonidos de mariachi y Norteño del norte de México. Su álbum de 2022, Faded Memories, presenta el sencillo de ritmo lento “Bourbon Whiskey,” la hipnótica versión de “I’m On Fire” de Bruce Springsteen, y el inquietante “In The Dark.” El álbum recibió elogios de críticos como American Songwriter, Billboard, CMT y más. El álbum de 2023, Here’s To You, Here’s To Me, incluyó el favorito de los fans, “Tennessee Drinkin’,” y fue la quinta canción consecutiva de Beckmann en alcanzar el Top 20 en el Texas Regional Radio Chart. Ha pasado el último año de gira con artistas como Parker McCollum, Hank Williams Jr., Charley Crockett y la Randy Rogers Band, además de agotar cinco de sus propios conciertos en el legendario Gruene Hall y ser cabeza de cartel en Billy Bob’s Texas. En la primavera de 2023, Beckmann debutó en el Grand Ole Opry y un año después firmó un contrato discográfico con Warner Music Nashville.',
      videourl:
        'https://youtube.com/playlist?list=OLAK5uy_mCe_3W-HEDNF0_wuwLSB3cIZskmg-0bHo&si=SFJnrq33SR8w5UpU',
      price: 50,
      coverImg: 'williambeckman.jpg',
      category: 'Country',
      location: 'USA',
      locationImg: 'usaflag.jpg',
      openSpots: null,
      likes: 1000,
      websiteurl: 'https://williambeckmann.com/',
      youtube: 'https://www.youtube.com/channel/UCqY_qkhSgoR7B1ptXV3K8fg',
      facebook: '',
      instagram: 'https://www.instagram.com/williambeckmann/',
      twitter: 'https://x.com/thewillbeckmann',
      spotify: 'https://open.spotify.com/artist/3AJURVT7BuD01gl1nQS5tb',
      deezer: 'https://www.deezer.com/mx/artist/53687082',
      apple: 'https://music.apple.com/us/artist/william-beckmann/1441328489',
      soundcloud: 'https://soundcloud.com/william-beckman-879705738',
      tidal: 'https://listen.tidal.com/artist/10553883',
      wiki: 'https://williambeckmann.com/',
      ticketmaster: 'https://www.ticketmaster.com/william-beckmann-tickets/artist/2826144',
      tiktok: 'https://www.tiktok.com/@william_beckmann?lang=es',
      napster: 'https://music.amazon.com.mx/artists/B07K6Y7J2V/william-beckmann',
    },
  ]
  export default CardData34
  